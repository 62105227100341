import React, { useState, useEffect, useCallback } from 'react';
import './style.css';
import { useHistory } from 'react-router-dom';
import check_active from './check_active.svg'
import check_disable from './check_disable.svg'
import { Oval } from  'react-loader-spinner'
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import cross from './cross.svg'

const TableGeneral = ({ onDateChange }) => {
  const history = useHistory();

  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);
  const [ page, setPage ] = useState(1)
  const [ sDay, setSDAY ] = useState()
  const [ sMonth, setSMonth ] = useState()
  const [ sYear, setSYear ] = useState()
  const [ eDay, setEDAY ] = useState()
  const [ eMonth, setEMonth ] = useState()
  const [ eYear, setEYear ] = useState()
  const [ formattedStartDate, setFormattedStartDate ] = useState('')
  const [ formattedEndDate, setFormattedEndDate ] = useState('')
  const [ dateforvisualstart, setDateforvisualstart ] = useState('')
  const [ dateforvisualend, setDateforvisualend ] = useState('')
  const [ fulldate, setFulldate ] = useState('')

  const handleSelect = (ranges, date) => {
    setDateRange([ranges.selection]);
  
    const startDate = ranges.selection.startDate;
    
    const endDate = ranges.selection.endDate;
  
    if ( endDate) {
      // Получите день, месяц и год для начальной и конечной даты
      const startDay = startDate.getDate();
      const startMonth = startDate.getMonth() + 1; // Месяцы начинаются с 0
      const startYear = startDate.getFullYear();
      setDateforvisualstart(startDay + '/' + startMonth + '/' + startYear)
      const endDay = endDate.getDate();
      const endMonth = endDate.getMonth() + 1; // Месяцы начинаются с 0
      const endYear = endDate.getFullYear();
      setDateforvisualend(endDay + '/' + endMonth + '/' + endYear)
      setFulldate(startDay + '/' + startMonth + '/' + startYear + ' - ' + endDay + '/' + endMonth + '/' + endYear)
      // Создайте строки для начальной и конечной даты в нужном формате
      const formattedStartDate = `${startDay}/${startMonth}/${startYear}`;
      const formattedEndDate = `${endDay}/${endMonth}/${endYear}`;
  
      // Сохраните даты и отправьте запрос
      setStartDay(startDay);
      setStartMonth(startMonth);
      setStartYear(startYear);
      setEndDay(endDay);
      setEndMonth(endMonth);
      setEndYear(endYear);
  
      console.log(formattedStartDate, formattedEndDate);
      setData([]);
      
      fetchData(startDay, startMonth, startYear, endDay, endMonth, endYear);
    }
  
    if (onDateChange) {
      onDateChange(ranges.selection);
    }
  };
  
  useEffect(() => {
    fetchData();
  }, [page]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [day, setDay] = useState('');
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const [endDate, setEndDate] = useState(null);
  const [endDay, setEndDay] = useState('');
  const [endMonth, setEndMonth] = useState('');
  const [endYear, setEndYear] = useState('');
  const [startDay, setStartDay] = useState('');
const [startMonth, setStartMonth] = useState('');
const [startYear, setStartYear] = useState('');

const [ modalOpen, setModalOpen ] = useState(false)

const handleDateChange = (date) => {
  setSelectedDate(date);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  setStartDay(day);
  setStartMonth(month);
  setStartYear(year);
  fetchData(day, month, year, endDay, endMonth, endYear);
};

const handleEndDateChange = (date) => {
  setEndDate(date);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  setEndDay(day);
  setEndMonth(month);
  setEndYear(year);
  fetchData(startDay, startMonth, startYear, day, month, year);
};

  const handleClearDate = () => {
    setSDAY('');
    setSMonth('');
    setSYear('');
    setEDAY('');
    setEMonth('');
    setEYear('');
    fetchData('', '', '','', '', ''); // Sending empty parameters to fetch data
    setDateRange()
  };

  const handleClick = () => {
    history.push('/modelcreate');
  };

  const [showForm, setShowForm] = useState(false);

  const toggleForm = () => {
    setShowForm(!showForm);
  };

  // Тестовые данные для таблицы
  const initialData = [

    // Добавьте больше тестовых данных здесь...
  ];
  
  const [loading, setLoading] = useState(true);
const [totalBalance, setTotalBalance] = useState(0);
const [totalOutgoingMessages, setTotalOutgoingMessages] = useState(0);
const [totalIncomingMessages, setTotalIncomingMessages] = useState(0);
const [totalOutgoingCalls, setTotalOutgoingCalls] = useState(0);
const [totalIncomingCallsa, setTotalIncomingCallsa] = useState(0);
const [totalIncomingCallsb, setTotalIncomingCallsb] = useState(0);
const [totalTotalTime, setTotalTotalTime] = useState(0);
const [totalRandomTime, setTotalRandomTime] = useState(0);
const [totalPrivateTime, setTotalPrivateTime] = useState(0);
const [totalTotalOnline, setTotalTotalOnline] = useState(0);

function formatTime(seconds) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  const formattedTime = `${padWithZero(hours)}:${padWithZero(minutes)}:${padWithZero(remainingSeconds)}`;
  return formattedTime;
}

function padWithZero(number) {
  return number.toString().padStart(2, '0');
}


const fetchData = useCallback(async (sDay, sMonth, sYear, eDay, eMonth, eYear) => {
  setLoading(true);
  try {
    const requestData = {};
    if (sDay && sMonth && sYear) {
      requestData.day = sDay;
      requestData.month = sMonth;
      requestData.year = sYear;
    }
    if (eDay && eMonth && eYear) {
      requestData.end_day = eDay;
      requestData.end_month = eMonth;
      requestData.end_year = eYear;
    }

    requestData.page = page;
    requestData.page_size = 20;
    requestData.filter = 'signed_up';

    const response = await fetch('https://golive.mobi/api/user/m-list/pag/', {
      method: 'POST',
      headers: {
        Authorization: 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
        'Content-Type': 'application/json; charset=UTF-8',
      },
      body: JSON.stringify(requestData), // Include the requestData in the body
    });

      if (response.ok) {
        const responseData = await response.json();
        setData(responseData);
        const totalBalance = responseData.reduce((sum, item) => sum + (item.balance ?? 0), 0);
        const totalOutgoingMessages = responseData.reduce((sum, item) => sum + (item.messages?.outcoming ?? 0), 0);
        const totalIncomingMessages = responseData.reduce((sum, item) => sum + (item.messages?.incoming ?? 0), 0);
        const totalOutgoingCalls = responseData.reduce((sum, item) => sum + (item.calls?.outcoming ?? 0), 0);
        const totalIncomingCallsa = responseData.reduce((sum, item) => sum + (item.calls?.incoming_private ?? 0), 0);
        const totalIncomingCallsb = responseData.reduce((sum, item) => sum + (item.calls?.incoming_random ?? 0), 0);
        const totalTotalTime = responseData.reduce((sum, item) => sum + (item.time?.total ?? 0), 0);
        const totalRandomTime = responseData.reduce((sum, item) => sum + (item.time?.random ?? 0), 0);
        const totalPrivateTime = responseData.reduce((sum, item) => sum + (item.time?.private ?? 0), 0);
        const totalTotalOnline = responseData.reduce((sum, item) => sum + (item.time_online ?? 0), 0);
  

        setTotalBalance(totalBalance);
        setTotalOutgoingMessages(totalOutgoingMessages);
        setTotalIncomingMessages(totalIncomingMessages);
        setTotalOutgoingCalls(totalOutgoingCalls);
        setTotalIncomingCallsa(totalIncomingCallsa);
        setTotalIncomingCallsb(totalIncomingCallsb);
        setTotalTotalTime(totalTotalTime);
        setTotalRandomTime(totalRandomTime);
        setTotalPrivateTime(totalPrivateTime);
        setTotalTotalOnline(totalTotalOnline);
        setLoading(false);
      } else {
        console.log('Error:', response.status);
      }
    } catch (error) {
      console.log('Error:', error);
    }
  }, [page]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // Состояние для выбора элементов
  const [data, setData] = useState(initialData);
  const [selectAll, setSelectAll] = useState(false);

  const toggleSelectAll = () => {
    setSelectAll(!selectAll);
    const updatedData = data.map((item) => ({ ...item, selected: !selectAll }));
    setData(updatedData);
  };

  const toggleSelectItem = (id) => {
    const updatedData = data.map((item) => {
      if (item.id === id) {
        return { ...item, selected: !item.selected };
      }
      return item;
    });
    setData(updatedData);
  };

  const handleModelClick = useCallback(
    (id) => {
      localStorage.setItem('id_models', id); // Save ID to localStorage
      history.push(`/profile_model/${id}`); // Navigate to the profile_model page with the selected ID
    },
    [history]
  );


  const handleRemove = async () => {
    // Get the IDs of selected rows
    const selectedIds = data.filter((item) => item.selected).map((item) => item.id);
  
    try {
      const requestData = [];
      selectedIds.forEach((id) => {
        const item = data.find((item) => item.id === id);
        if (item) {
          requestData.push({
            id,
            signed_up: item.signed_up === 1 ? 1 : 0,
          });
        }
      });
      const response = await fetch('https://golive.mobi/api/user/m-delete/', {
        method: 'POST',
        headers: {
          Authorization: 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
      });
  
      if (response.ok) {
        // Handle successful response (e.g., show a success message, refresh data)
        console.log('Selected rows successfully deleted!');
        // Reload the data after successful deletion
        fetchData(); // Assuming fetchData fetches data and updates the state
      } else {
        console.log('Error:', response.status);
      }
    } catch (error) {
      console.log('Error:', error);
    }
  };
  
  
  const handleUnRemove = async () => {
    // Get the IDs of selected rows
    const selectedIds = data.filter((item) => item.selected).map((item) => item.id);
  
    try {
      const requestData = selectedIds.map((id) => ({ id, signed_up: 1 }));
      const response = await fetch('https://golive.mobi/api/user/m-undelete/', {
        method: 'POST',
        headers: {
          Authorization: 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
      });
  
      if (response.ok) {
        // Handle successful response (e.g., show a success message, refresh data)
        console.log('Selected rows successfully undeleted!');
        // Reload the data after successful deletion
        fetchData(); // Assuming fetchData fetches data and updates the state
      } else {
        console.log('Error:', response.status);
      }
    } catch (error) {
      console.log('Error:', error);
    }
  };

  const [sortedData, setSortedData] = useState([]);
  const [selectedSortOption, setSelectedSortOption] = useState('');

  const sortData = (option) => {
    let sorted = [...data];
  
    if (option === 'sort1') {
      sorted = sorted.sort((a, b) => (a.messages?.outcoming > b.messages?.outcoming ? 1 : -1));
    } else if (option === 'sort2') {
      sorted = sorted.sort((a, b) => (a.messages?.incoming > b.messages?.incoming ? 1 : -1));
    } else if (option === 'sort3') {
      sorted = sorted.sort((a, b) => (a.calls?.outcoming > b.calls?.outcoming ? 1 : -1));
    } else if (option === 'sort4a') {
      sorted = sorted.sort((a, b) => (a.calls?.incoming_private > b.calls?.incoming_private ? 1 : -1));
    } else if (option === 'sort4b') {
      sorted = sorted.sort((a, b) => (a.calls?.incoming_random > b.calls?.incoming_random ? 1 : -1));
    } else if (option === 'sortf4') {
      sorted = sorted.sort((a, b) => (a.balance || 0) - (b.balance || 0));
    } else if (option === 'sort5') {
      sorted = sorted.sort((a, b) => (a.time?.total > b.time?.total ? 1 : -1));
    } else if (option === 'sort6') {
      sorted = sorted.sort((a, b) => (a.time?.random > b.time?.random ? 1 : -1));
    } else if (option === 'sort7') {
      sorted = sorted.sort((a, b) => (a.time?.private > b.time?.private ? 1 : -1));
    } else if (option === 'sort8') {
      sorted = sorted.sort((a, b) => (a.time_online > b.time_online ? 1 : -1));
    }
  
    // Custom sorting to move bot 0 to the top, bot 2 to the bottom, and bot 1 to the end
    sorted = sorted.sort((a, b) => {
      if (a.bot === 0) return -1;
      if (a.bot === 2) return 1;
      if (a.bot === 1 && b.bot !== 0 && b.bot !== 2) return 1;
      return 0; // Включите эту строку, чтобы сортировать ботов между собой
    });
  
    setSortedData(sorted);
  };
  
    
  
  
  useEffect(() => {
    if (selectedSortOption !== '') {
      sortData(selectedSortOption);
    } else {
      setSortedData(data); // Show unsorted data when no sorting option is selected
    }
  }, [selectedSortOption, data]);
  

  const [sortOptions, setSortOptions] = useState({
    sort1: false,
    sort2: false,
    sort3: false,
    sort4a: false,
    sort4b: false,
    sortf4: false,
    sort5: false,
    sort6: false,
    sort7: false,
    sort8: false,
    noSorting: true, // New option to handle "No sorting"
  });
  
  const handleSortOptionChange = (option) => {
    setSortOptions((prevOptions) => ({
      sort1: option === 'sort1' ? !prevOptions.sort1 : false,
      sort2: option === 'sort2' ? !prevOptions.sort2 : false,
      sort3: option === 'sort3' ? !prevOptions.sort3 : false,
      sortf4: option === 'sortf4' ? !prevOptions.sortf4 : false,
      sort4a: option === 'sort4a' ? !prevOptions.sort4a : false,
      sort4b: option === 'sort4b' ? !prevOptions.sort4b : false,
      sort5: option === 'sort5' ? !prevOptions.sort5 : false,
      sort6: option === 'sort6' ? !prevOptions.sort6 : false,
      sort7: option === 'sort7' ? !prevOptions.sort7 : false,
      sort8: option === 'sort8' ? !prevOptions.sort8 : false,
      noSorting: option === 'noSorting' ? !prevOptions.noSorting : false,
    }));
  };
  
  useEffect(() => {
    const selectedOption = Object.keys(sortOptions).find((option) => sortOptions[option]);
    setSelectedSortOption(selectedOption);
  }, [sortOptions]);

  const handleSortOptionClick = (option) => {
    handleSortOptionChange(option);
  };

  const handleNextPage = () => {
    setPage(page + 1);
    //window.scrollTo(0, 0); // Прокрутка страницы вверх
  };
  
  const handlePreviousPage = () => {
    if (page > 1) {
      setPage(page - 1);
      //window.scrollTo(0, 0); // Прокрутка страницы вверх
    }
  };

  return (
    <div>


<div style={{ display: 'flex', justifyContent: 'space-between', marginTop:'-1%' }}>


      {modalOpen === true && (
      <div style={{ position: 'absolute', zIndex: 10000, border: '1px solid grey', marginLeft: '5%', marginTop: '-9%', backgroundColor: 'white', boxShadow: 'rgba(0, 0, 0, 0.2) -8px 6px 4px'  }}>
        <img src={cross} style={{ width: '35px', marginLeft: '93%', backgroundColor: 'white'}} onClick={() => setModalOpen(false)}/>
        <br />
        <DateRangePicker
        ranges={dateRange}
        onChange={handleSelect}
        rangeColors={['#D22A2A']}
        direction="vertical"
        scroll={{ enabled: true }}
  calendarFocus="backwards"
  months={1}
      /></div>)}
  {/*<button onClick={handleClearDate} style={{
    fontFamily: 'inter',
    fontSize: '500',
    color: 'white',
    border: '1px solid #DADEE6',
    borderRadius: '8px',
    background: '#D22A2A',
    marginRight: '1%',
    width: '110px',
    height: '36px',
    marginTop: '0%',
    marginRight: '75%',
    marginLeft: '1%'
  }}>Clear</button>*/}
</div>
<br />
{/*<p>{sDay} {sMonth} {sYear} {eDay} {eMonth} {eYear}</p>*/}

      <div>
        <button className="create-button" onClick={handleClick}>
          + New Models
        </button>
        <button className="create-button" style={{ width: '255px', background: 'rgb(51 61 255)'}} onClick={() => setModalOpen(true)}>
          Date range: {fulldate || 'All period'}
        </button>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h3 style={{marginLeft: '1%'}}></h3>
          <div style={{ display: 'flex', marginRight: '1%'}}>
          <button className="remove-button" style={{ width: '90px'}} onClick={handleRemove}>Block</button>
          <button className="remove-button" style={{ width: '90px'}} onClick={handleUnRemove}>Unblock</button>
          </div>
        </div>
    
        {loading && (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
  <Oval
    height={200}
    width={200}
    color="#0000FF"
    wrapperStyle={{}}
    wrapperClass=""
    visible={true}
    ariaLabel='oval-loading'
    secondaryColor="#87CEFA"
    strokeWidth={2}
    strokeWidthSecondary={2}
  />
</div>

    )}

    {!loading && (

        <table className="table">
          <thead style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.07)' }}>
            <tr>
              <th>
                <input
                  className="checkbox"
                  type="checkbox"
                  checked={selectAll}
                  onChange={toggleSelectAll}
                  style={{
                    alignItems: 'center',
                    display: 'flex',
                  }}
                />
              </th>

              <th style={{ color: 'black', fontFamily: "Noto Sans Mono", weight: '500', fontSize: '12px', textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)'}}>ID</th>
              <th style={{ color: 'black', fontFamily: "Noto Sans Mono", weight: '500', fontSize: '12px', textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)'}}>Name</th>
              <th style={{ color: 'black', fontFamily: "Noto Sans Mono", weight: '500', fontSize: '12px', textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)' }} onClick={() => handleSortOptionClick('sortf4')}>Balance {sortOptions.sortf4 ? '▼' : ''}</th>
              <th style={{ color: 'black', fontFamily: "Noto Sans Mono", weight: '500', fontSize: '12px', textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)'}} >Average response time</th>
              <th style={{ color: 'black', fontFamily: "Noto Sans Mono", weight: '500', fontSize: '12px', textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)'}} >Time until first response</th>
              <th style={{ color: 'black', fontFamily: "Noto Sans Mono", weight: '500', fontSize: '12px', textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)'}} onClick={() => handleSortOptionClick('sort1')}>Outgoing messages {sortOptions.sort1 ? '▼' : ''}</th>
              <th style={{ color: 'black', fontFamily: "Noto Sans Mono", weight: '500', fontSize: '12px', textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)'}} onClick={() => handleSortOptionClick('sort2')}>Incoming messages {sortOptions.sort2 ? '▼' : ''}</th>
              <th style={{ color: 'black', fontFamily: "Noto Sans Mono", weight: '500', fontSize: '12px', textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)'}} onClick={() => handleSortOptionClick('sort3')}>Outgoing calls {sortOptions.sort3 ? '▼' : ''}</th>
              <th style={{ color: 'black', fontFamily: "Noto Sans Mono", weight: '500', fontSize: '12px', textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)'}} onClick={() => handleSortOptionClick('sort4a')}>Incoming private calls {sortOptions.sort4a ? '▼' : ''}</th>
              <th style={{ color: 'black', fontFamily: "Noto Sans Mono", weight: '500', fontSize: '12px', textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)'}} onClick={() => handleSortOptionClick('sort4b')}>Incoming random calls {sortOptions.sort4b ? '▼' : ''}</th>
              <th style={{ color: 'black', fontFamily: "Noto Sans Mono", weight: '500', fontSize: '12px', textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)'}} onClick={() => handleSortOptionClick('sort5')}>Total time {sortOptions.sort5 ? '▼' : ''}</th>
              <th style={{ color: 'black', fontFamily: "Noto Sans Mono", weight: '500', fontSize: '12px', textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)'}} onClick={() => handleSortOptionClick('sort6')}>Random time {sortOptions.sort6 ? '▼' : ''}</th>
              <th style={{ color: 'black', fontFamily: "Noto Sans Mono", weight: '500', fontSize: '12px', textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)'}} onClick={() => handleSortOptionClick('sort7')}>Private time {sortOptions.sort7 ? '▼' : ''}</th>
              <th style={{ color: 'black', fontFamily: "Noto Sans Mono", weight: '500', fontSize: '12px', textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)'}} onClick={() => handleSortOptionClick('sort8')}>Time online {sortOptions.sort8 ? '▼' : ''}</th>
              {/**<th style={{ color: 'black', fontFamily: "Noto Sans Mono", weight: '500', fontSize: '12px', textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)'}}>Gifts</th>
              <th style={{ color: 'black', fontFamily: "Noto Sans Mono", weight: '500', fontSize: '12px', textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)'}}>Fan</th>
                <th style={{ color: 'black', fontFamily: "Noto Sans Mono", weight: '500', fontSize: '12px', textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)'}}>Average cost of a gift</th>**/}
            </tr>
          </thead>
          <tbody>
          {sortedData
    .filter((item) => item.signed_up === 1) // Filter the data based on signed_up === 1
    .map((item, index) => (
              <tr
              key={item.id}
              className={`${index % 2 === 0 ? 'even-row' : 'odd-row'} ${item.selected ? 'selected-row' : 'non-selected-row'}`}
            >
                <td>
                  <input
                    type="checkbox"
                    checked={item.selected}
                    onChange={() => toggleSelectItem(item.id)}
                    style={{
                      borderRight: '1px solid rgba(0, 0, 0, 0.07)'
                      
                    }}
                  />
                </td>

                <td style={{
                  color: item.selected ? '#6D33FF' : '#606C80',
                  fontFamily: 'Noto Sans Mono',
                  fontWeight: '400',
                  fontSize: '12px',
                  textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)',  borderRight: '1px solid rgba(0, 0, 0, 0.07)'
                }}>
                  
                  <a style={{
                  color: item.selected ? '#6D33FF' : '#606C80',
                  fontFamily: 'Noto Sans Mono',
                  fontWeight: '400',
                  fontSize: '12px',
                  textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)',  borderRight: '1px solid rgba(0, 0, 0, 0.07)'
                }} href={`profile_model/${item.id}`}>{item.id}  {item.bot === 1 ? '🤖 ' : ''} {item.bot === 2 ? '💼 ' : ''}
                  </a>
                  </td>
                  <td style={{
                  color: item.selected ? '#6D33FF' : 'black',
                  fontFamily: 'Noto Sans Mono',
                  fontWeight: '400',
                  fontSize: '12px',
                  textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)',  borderRight: '1px solid rgba(0, 0, 0, 0.07)'
                }}>
                  <a href={`profile_model/${item.id}`} style={{
                  color: item.selected ? '#6D33FF' : 'black',
                  fontFamily: 'Noto Sans Mono',
                  fontWeight: '400',
                  fontSize: '12px',
                  textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)',  borderRight: '1px solid rgba(0, 0, 0, 0.07)'
                }} >
                  {item.name} 
                  </a>
                  </td>
                  <td style={{
                  color: item.selected ? '#6D33FF' : 'black',
                  fontFamily: 'Noto Sans Mono',
                  fontWeight: '400',
                  fontSize: '12px',
                  textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)',  borderRight: '1px solid rgba(0, 0, 0, 0.07)'
                }}>
                 
                  {item.balance} 
                  
                  </td>

                  <td style={{
                  color: item.selected ? '#6D33FF' : 'black',
                  fontFamily: 'Noto Sans Mono',
                  fontWeight: '400',
                  fontSize: '12px',
                  textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)',  borderRight: '1px solid rgba(0, 0, 0, 0.07)'
                }}>
                 
                  {item.avg_answer_time || 0} 
                  
                  </td>

                  <td style={{
                  color: item.selected ? '#6D33FF' : 'black',
                  fontFamily: 'Noto Sans Mono',
                  fontWeight: '400',
                  fontSize: '12px',
                  textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)',  borderRight: '1px solid rgba(0, 0, 0, 0.07)'
                }}>
                 
                  {item.first_response || 0} 
                  
                  </td>

                  <td  style={{
  color: item.selected ? '#6D33FF' : '#606C80',
  fontFamily: 'Noto Sans Mono',
  fontWeight: '400',
  fontSize: '12px',
  textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)', background: 'rgba(255, 241, 215, 0.3)'
}}>
  {item.messages?.outcoming ?? 0}
</td>
                 {/**  <td style={{
    color: item.selected ? '#6D33FF' : '#606C80',
    fontFamily: 'Noto Sans Mono',
    fontWeight: '400',
    fontSize: '12px',
    textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)'
  }}>{item.newChats}</td>**/}
<td style={{
  color: item.selected ? '#6D33FF' : '#606C80',
  fontFamily: 'Noto Sans Mono',
  fontWeight: '400',
  fontSize: '12px',
  textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)', background: 'rgba(216, 255, 212, 0.3)'
}}>
  {item.messages?.incoming ?? 0}
</td>
<td style={{
  color: item.selected ? '#6D33FF' : '#606C80',
  fontFamily: 'Noto Sans Mono',
  fontWeight: '400',
  fontSize: '12px',
  textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)', background: 'rgba(255, 241, 215, 0.3)'
}}>
  {item.calls?.outcoming ?? 0}
</td>
<td style={{
  color: item.selected ? '#6D33FF' : '#606C80',
  fontFamily: 'Noto Sans Mono',
  fontWeight: '400',
  fontSize: '12px',
  textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)', background: 'rgba(216, 255, 212, 0.3)'
}}>
  {item.calls?.incoming_private ?? 0}
</td>
<td style={{
  color: item.selected ? '#6D33FF' : '#606C80',
  fontFamily: 'Noto Sans Mono',
  fontWeight: '400',
  fontSize: '12px',
  textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)', background: 'rgba(216, 255, 212, 0.3)'
}}>
  {item.calls?.incoming_random ?? 0}
</td>
<td style={{
  color: item.selected ? '#6D33FF' : '#606C80',
  fontFamily: 'Noto Sans Mono',
  fontWeight: '400',
  fontSize: '12px',
  textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)'
}}>
  {formatTime(item.time?.total ?? 0)}
</td>
<td style={{
  color: item.selected ? '#6D33FF' : '#606C80',
  fontFamily: 'Noto Sans Mono',
  fontWeight: '400',
  fontSize: '12px',
  textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)'
}}>
  {formatTime(item.time?.random ?? 0)}
</td>
<td style={{
  color: item.selected ? '#6D33FF' : '#606C80',
  fontFamily: 'Noto Sans Mono',
  fontWeight: '400',
  fontSize: '12px',
  textAlign: 'left', 
}}>
  {formatTime(item.time?.private ?? 0)}
</td>
<td style={{
  color: item.selected ? '#6D33FF' : '#606C80',
  fontFamily: 'Noto Sans Mono',
  fontWeight: '400',
  fontSize: '12px',
  textAlign: 'left', 
}}>
  {formatTime(item.time_online ?? 0)}
</td>
                {/**<td style={{
    color: item.selected ? '#6D33FF' : '#606C80',
    fontFamily: 'Noto Sans Mono',
    fontWeight: '400',
    fontSize: '12px',
    textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)'
  }}>{item.privateTime}</td>
                <td style={{
    color: item.selected ? '#6D33FF' : '#606C80',
    fontFamily: 'Noto Sans Mono',
    fontWeight: '400',
    fontSize: '12px',
    textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)'
  }}>{item.gifts}</td>
                <td style={{
    color: item.selected ? '#6D33FF' : '#606C80',
    fontFamily: 'Noto Sans Mono',
    fontWeight: '400',
    fontSize: '12px',
    textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)'
  }}>{item.avgCostOfGift}</td>**/}

  
              </tr>

              
            ))}
          </tbody>
          
          <tfoot>
  <tr style={{ background: '#CCCCFF', borderTop: '1px solid white'}}>
    <td style={{
  
  fontFamily: 'Noto Sans Mono',
  fontWeight: '400',
  fontSize: '12px',
  textAlign: 'left', 
}}>Total</td>
    <td>{/* Empty cell for ID column, as it doesn't have a sum */}</td>
    <td>{/* Empty cell for Name column, as it doesn't have a sum */}</td>
    <td style={{

fontFamily: 'Noto Sans Mono',
fontWeight: '400',
fontSize: '12px',
textAlign: 'left', 
}}>{totalBalance}</td>
    <td style={{

  fontFamily: 'Noto Sans Mono',
  fontWeight: '400',
  fontSize: '12px',
  textAlign: 'left', 
}}>{totalOutgoingMessages}</td>
    <td style={{

  fontFamily: 'Noto Sans Mono',
  fontWeight: '400',
  fontSize: '12px',
  textAlign: 'left', 
}}>{totalIncomingMessages}</td>
    <td style={{

  fontFamily: 'Noto Sans Mono',
  fontWeight: '400',
  fontSize: '12px',
  textAlign: 'left', 
}}>{totalOutgoingCalls}</td>
    <td style={{

  fontFamily: 'Noto Sans Mono',
  fontWeight: '400',
  fontSize: '12px',
  textAlign: 'left', 
}}>{totalIncomingCallsa}</td>
    <td style={{

fontFamily: 'Noto Sans Mono',
fontWeight: '400',
fontSize: '12px',
textAlign: 'left', 
}}>{totalIncomingCallsb}</td>
    <td style={{

  fontFamily: 'Noto Sans Mono',
  fontWeight: '400',
  fontSize: '12px',
  textAlign: 'left', 
}}>{formatTime(totalTotalTime)}</td>
    <td style={{

  fontFamily: 'Noto Sans Mono',
  fontWeight: '400',
  fontSize: '12px',
  textAlign: 'left', 
}}>{formatTime(totalRandomTime)}</td>
    <td style={{

  fontFamily: 'Noto Sans Mono',
  fontWeight: '400',
  fontSize: '12px',
  textAlign: 'left', 
}}>{formatTime(totalPrivateTime)}</td>
    <td style={{

fontFamily: 'Noto Sans Mono',
fontWeight: '400',
fontSize: '12px',
textAlign: 'left', 
}}>{formatTime(totalTotalOnline)}</td>
    {/* Add other cells for other columns' totals if needed */}
  </tr>
</tfoot>

        </table>

    )}

{!loading &&
<div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
          <button className="remove-button" style={{ backgroundColor: '#6D33FF' }} onClick={handlePreviousPage}>
            &lt; Previous
          </button>
          <button className="remove-button" style={{ backgroundColor: '#FFF', width: '40px', color: 'black' }}>
            {page}
          </button>
          <button className="remove-button" style={{ backgroundColor: '#6D33FF' }} onClick={handleNextPage}>
            Next &gt;
          </button>
                </div>
}

      </div>
    </div>
  );
};

export default TableGeneral;
