import React, { useState } from 'react';

import TableGeneral from './TableGeneral_users';
import './style.css';

const ProfileInfo = () => {
  const [showProfileInfo, setShowProfileInfo] = useState(true);
  const [showUserActivity, setShowUserActivity] = useState(false);

  const toggleProfileInfo = () => {
    setShowProfileInfo(!showProfileInfo);
    setShowUserActivity(false);
  };

  const toggleUserActivity = () => {
    setShowUserActivity(!showUserActivity);
    setShowProfileInfo(false);
  };

  return (
    <div>
      
          <TableGeneral />
        
    </div>
  );
};

export default ProfileInfo;
