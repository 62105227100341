import React, { useState, useEffect, useCallback } from 'react';
import './style.css';
import { Oval } from 'react-loader-spinner';

const StreamPreviewList = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      alert('Copied!');
    } catch (error) {
      console.error('Failed to copy:', error);
    }
  };

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await fetch('https://golive.mobi/api/feedback/user/list/', {
        method: 'POST',
        headers: {
          Authorization: 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ page })
      });
      const json = await response.json();
      if (response.ok) {
        setData(json.list);
        setTotalPages(json.total_pages);
      } else {
        console.error('Failed to fetch data:', json);
        setData([]);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setData([]);
    }
    setLoading(false);
  }, [page]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (loading) {
    return <div className="loader-container"><Oval color="#00BFFF" height={80} width={80} /></div>;
  }

  return (
    <>
    <br /><br />
      <table style={{ width: '100%', borderCollapse: 'collapse' }}>
        <thead>
          <tr style={{ backgroundColor: 'rgb(255 255 255)' }}>
            <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Feedback ID</th>
            <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Date</th>
            <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>User ID</th>
            <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Name</th>
            <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Type</th>
            <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Text</th>
          </tr>
        </thead>
        <tbody>
          {data.map(item => (
            <tr key={item.feedback_id}>
              <td style={{ border: '1px solid #ddd', padding: '8px' }}>{item.feedback_id}</td>
              <td style={{ border: '1px solid #ddd', padding: '8px' }}>{new Date(item.date * 1000).toLocaleDateString()}</td>
              <td style={{ border: '1px solid #ddd', padding: '8px', cursor: 'pointer' }} onClick={() => copyToClipboard(item.user.id.slice(0, 5))}>{item.user.id.slice(0, 5)}</td>
              <td style={{ border: '1px solid #ddd', padding: '8px' }}>{item.user.name}</td>
              <td style={{ border: '1px solid #ddd', padding: '8px' }}>{item.type}</td>
              <td style={{ border: '1px solid #ddd', padding: '8px' }}>{item.text}</td>
            </tr>
          ))}
        </tbody>
      </table>
      {totalPages > 1 && (
        <div style={{ display: 'flex', justifyContent: 'center', margin: '20px' }}>
          {Array.from({ length: totalPages }, (_, i) => (
            <button key={i + 1} style={{ padding: '10px', margin: '0 5px', fontSize: '16px' }} onClick={() => setPage(i + 1)} disabled={page === i + 1}>
              {i + 1}
            </button>
          ))}
        </div>
      )}
    </>
  );
};

export default StreamPreviewList;
