import React, { useState } from 'react';
import Noface from './Noface';
import Nudity from './Nudity';
import Checkban from './Checkban';
import Checkuser from './Checkuser';
import CheckVerif from './CheckVerif';

const Models = () => {
  // Use `selectedModel` to track which component to show
  const [selectedModel, setSelectedModel] = useState('noface');

  // Function to render the selected component based on `selectedModel`
  const renderModel = () => {
    switch (selectedModel) {
      case 'noface':
        return <Noface />;
      case 'nudity':
        return <Nudity />;
      case 'checkban':
        return <Checkban />;
      case 'checkuser':
        return <Checkuser />;  
      case 'banstream':
        return <CheckVerif />;  
      default:
        return <Noface />;
    }
  };

  return (
    <div className="col-md-6 align-self-center" style={{ marginTop: '1%' }}>
      <div style={{ marginTop: '1%', marginBottom: '1%' }}>
        <button
          onClick={() => setSelectedModel('noface')}
          className={selectedModel === 'noface' ? 'active-button' : 'inactive-button'}
        >
          Ban list
        </button>
        {/*<button
          onClick={() => setSelectedModel('nudity')}
          className={selectedModel === 'nudity' ? 'active-button' : 'inactive-button'}
        >
          Nudity
  </button>*/}
        <button
          onClick={() => setSelectedModel('checkban')}
          className={selectedModel === 'checkban' ? 'active-button' : 'inactive-button'}
        >
          Search banned by name
        </button>
        <button
          onClick={() => setSelectedModel('checkuser')}
          className={selectedModel === 'checkuser' ? 'active-button' : 'inactive-button'}
        >
          Search user by name
        </button>
        <button
          onClick={() => setSelectedModel('banstream')}
          className={selectedModel === 'banstream' ? 'active-button' : 'inactive-button'}
        >
          List live-verification
        </button>
      </div>
      {renderModel()}
    </div>
  );
};

export default Models;
