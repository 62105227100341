import React, { useState, useEffect, useCallback } from 'react';
import './style.css';
import { useHistory } from 'react-router-dom';
import check_active from './check_active.svg';
import check_disable from './check_disable.svg';
import edit_button from '../assets/edit_button.svg';
import accept_button from '../assets/accept.svg';
import decline_button from '../assets/decline.svg';

const TableGeneral = () => {
  const history = useHistory();

  const [showForm, setShowForm] = useState(false);
  const [searchModels, setSearchModels] = useState('');
  const [searchStatus, setSearchStatus] = useState('');
  const [data, setData] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [editedRowId, setEditedRowId] = useState(null);
  const [editedBillingNote, setEditedBillingNote] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [searchedData, setSearchedData] = useState([]);
  const [modelname, setModelname] = useState('');
  const [statusInputClicked, setStatusInputClicked] = useState(false);
  const [searchPaymentSystem, setSearchPaymentSystem] = useState('');
  const [paymentSystemInputClicked, setPaymentSystemInputClicked] = useState(false);
  const [searchWatched, setSearchWatched] = useState('');
  const [numRowsToShow, setNumRowsToShow] = useState(data.length);
  const [watchedInputClicked, setWatchedInputClicked] = useState(false);

  const handleNumRowsChange = (e) => {
    const value = parseInt(e.target.value);
    setNumRowsToShow(value > 0 ? value : data.length);
  };

  


  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    // Filter the data array based on the value in localStorage
    const filteredItems = data.filter(item => item.name === modelname);
    setFilteredData(filteredItems);
  }, [modelname]);


  

  const [modelsInputClicked, setModelsInputClicked] = useState(false);

  const handlePaymentSystemInputChange = (e) => {
    setSearchPaymentSystem(e.target.value);
    setPaymentSystemInputClicked(true);
  };

  useEffect(() => {
    if (modelsInputClicked) {
      handleClick();
    }
  }, [searchModels, modelsInputClicked]);

  useEffect(() => {
    if (statusInputClicked) {
      handleClick();
    }
  }, [searchStatus, statusInputClicked]);

  useEffect(() => {
    if (paymentSystemInputClicked) {
      handleClick();
    }
  }, [searchPaymentSystem, paymentSystemInputClicked]);

  useEffect(() => {
    if (statusInputClicked) {
      handleClick();
    }
  }, [searchStatus, statusInputClicked]);
  
  useEffect(() => {
    if (watchedInputClicked) {
      handleClick();
    }
  }, [searchWatched, watchedInputClicked]);
  
  useEffect(() => {
    if (paymentSystemInputClicked) {
      handleClick();
    }
  }, [searchPaymentSystem, paymentSystemInputClicked]);
  // ... rest of the code ...

  const handleModelsInputChange = (e) => {
    setSearchModels(e.target.value);
    setModelsInputClicked(true);
  };

  const fetchData = async () => {
    try {
      const response = await fetch('https://golive.mobi/api/moder/blacklist/list/', {
        method: 'POST',
        headers: {
          Authorization: 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
        },
        body: JSON.stringify({}),
      });
      const apiData = await response.json();
      const formattedData = apiData.map(item => ({
        id: item.id,
        text: item.text,
        watched: item.watched,
        date: item.date,
        note: item.note,
        user1: item.user1,
        user1_status: item.user1_status,
        user2: item.user2,
        user2_status: item.user2_status,
      }));
      console.log('Formatted Data:', formattedData);

    setData(formattedData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const toggleSelectAll = () => {
    setSelectAll(!selectAll);
    const updatedData = data.map(item => ({ ...item, selected: !selectAll }));
    setData(updatedData);
  };

  const toggleSelectItem = id => {
    const updatedData = data.map(item => {
      if (item.id === id) {
        return { ...item, selected: !item.selected };
      }
      return item;
    });
    setData(updatedData);
  };

  const handleEditButtonClick = (id, billingNote, billingStatus) => {
    setEditedRowId(id);
    setEditedBillingNote(billingNote);
    setSelectedStatus(billingStatus);
    
    // Save the selected ID to localStorage
    localStorage.setItem('billingcheck', id);
    
    // Redirect to the "/billingcheck" page
    history.push('/billingcheck');
  };

  const handleCancelEdit = () => {
    setEditedRowId(null);
    setEditedBillingNote('');
  };

  const handleSaveEdit = async () => {
    try {
      let url = '';
      if (selectedStatus === 'requesting') {
        url = 'https://golive.mobi/api/user/m-billing/request/';
      } else if (selectedStatus === 'done') {
        url = 'https://golive.mobi/api/user/m-billing/done/';
      }

      if (url !== '') {
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            Authorization: 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            id: editedRowId,
          }),
        });
        if (response.ok) {
          // Note saved successfully
          handleSaveEdit2();
        } else {
          console.error('Failed to save note:', response.statusText);
        }
      } else {
        // If "Select status" is selected, simply cancel edit
        handleCancelEdit();
      }

      // Reload the page
      handleSaveEdit2();
      window.location.reload();
    } catch (error) {
      console.error('Error saving note:', error);
    }
  };

  const handleSaveEdit2 = async () => {
    try {
      const response = await fetch('https://golive.mobi/api/moder/blacklist/list/', {
        method: 'POST',
        headers: {
          Authorization: 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id: editedRowId,
          billing_note: editedBillingNote,
        }),
      });
      if (response.ok) {
        // Note saved successfully, reload the page
        window.location.reload();
      } else {
        console.error('Failed to save note:', response.statusText);
      }
    } catch (error) {
      console.error('Error saving note:', error);
    }
  };

  const handleModelClick = useCallback(
    (id) => {
      localStorage.setItem('id_models', id); // Сохранение ID в localStorage
      history.push('/user_model'); // Переход на страницу "/profile_model"
    },
    [history]
  );
  


  

  const handleClick = () => {
    console.log('Clicked "View" button');
    // The handleClick function should handle all the filtering logic
    const filteredData = data.filter((item) => {
      const modelNameIncludes = item.name && item.name.toLowerCase().includes(searchModels.toLowerCase());
      const watchedIncludes =
        searchWatched === '' ||
        (item.watched === 1 && searchWatched === 'Watched') ||
        (item.watched === 0 && searchWatched === 'Unwatched');
      const paymentSystemIncludes =
        item.payment_system &&
        item.payment_system.toLowerCase().includes(searchPaymentSystem.toLowerCase());
      const statusIncludes =
        searchStatus === '' ||
        (item.watched === 1 && searchStatus === 'Watched') ||
        (item.watched === 0 && searchStatus === 'Unwatched');

      return modelNameIncludes && watchedIncludes && paymentSystemIncludes && statusIncludes;
    });
    setSearchedData(filteredData);
    setIsFiltered(true);
  };
  
  
  useEffect(() => {
    if (watchedInputClicked) {
      handleClick();
    }
  }, [searchWatched, watchedInputClicked]);

  const handleWatchedInputChange = (e) => {
    const selectedWatched = e.target.value;
    setSearchWatched(selectedWatched);
    // Filter data based on the selected watched status
    const filteredData = data.filter((item) => {
      if (selectedWatched === '') {
        return true; // Show all data if "None" is selected
      }
      return (
        (selectedWatched === 'Watched' && item.watched === 1) ||
        (selectedWatched === 'Unwatched' && item.watched === 0)
      );
    });
    setSearchedData(filteredData);
    setIsFiltered(true);
  };
  

  const handleReloadClick = () => {
    console.log('Clicked "Reload" button');
    handleClick(); // Call the handleClick function to get the latest filtered data
  };





  const handleStatusInputChange = (e) => {
    setSearchStatus(e.target.value);
    setStatusInputClicked(true);
  };
  


  const [selectedIds, setSelectedIds] = useState([]);

  

  const handleRemove = async () => {
    // Get the IDs of selected rows
    const selectedIds = data.filter((item) => item.selected).map((item) => item.id);
    setSelectedIds(selectedIds);

    try {
      const response = await fetch('https://golive.mobi/api/moder/blacklist/watched/', {
        method: 'POST',
        headers: {
          Authorization: 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(selectedIds), // Pass the array of IDs as the request body
      });
  
      if (response.ok) {
        // Handle successful response (e.g., show a success message, refresh data)
        console.log('Selected rows successfully deleted!');
        // Reload the data after successful deletion
        fetchData(); // Assuming fetchData fetches data and updates the state
      } else {
        console.log('Error:', response.status);
      }
    } catch (error) {
      console.log('Error:', error);
    }
  };
  
  const handleUnRemove = async () => {
    // Get the IDs of selected rows
    const selectedIds = data.filter((item) => item.selected).map((item) => item.id);
  
    try {
      const requestData = selectedIds.length > 0 ? { id: selectedIds[0] } : {}; // Use the first ID or an empty object
      const response = await fetch('https://golive.mobi/api/user/m-undelete/', {
        method: 'POST',
        headers: {
          Authorization: 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
      });
  
      if (response.ok) {
        // Handle successful response (e.g., show a success message, refresh data)
        console.log('Selected rows successfully deleted!');
        // Reload the data after successful deletion
        fetchData(); // Assuming fetchData fetches data and updates the state
      } else {
        console.log('Error:', response.status);
      }
    } catch (error) {
      console.log('Error:', error);
    }
  };

  function formatTimestampReg(timestamp) {
    if (!timestamp) {
      return '';
    }

    const dateObj = new Date(Number(timestamp));

    if (isNaN(dateObj)) {
      return 'Invalid timestamp';
    }

    const year = dateObj.getFullYear();
    const month = ('0' + (dateObj.getMonth() + 1)).slice(-2);
    const day = ('0' + dateObj.getDate()).slice(-2);
    const hours = ('0' + dateObj.getHours()).slice(-2);
    const minutes = ('0' + dateObj.getMinutes()).slice(-2);

    return `${day}.${month}.${year} ${hours}:${minutes}`;
  }

  function formatDate(timestamp) {
    if (!timestamp) {
      return '';
    }
  
    const dateObj = new Date(timestamp * 1000); // Convert to milliseconds
  
    if (isNaN(dateObj)) {
      return 'Invalid timestamp';
    }
  
    const year = dateObj.getFullYear();
    const month = ('0' + (dateObj.getMonth() + 1)).slice(-2);
    const day = ('0' + dateObj.getDate()).slice(-2);
    const hours = ('0' + dateObj.getHours()).slice(-2);
    const minutes = ('0' + dateObj.getMinutes()).slice(-2);
  
    return `${day}.${month}.${year} ${hours}:${minutes}`;
  }



  const [showNoteInput, setShowNoteInput] = useState(false);
  const [noteInputValue, setNoteInputValue] = useState('');
  const [selectedItemId, setSelectedItemId] = useState(null);

  // Function to toggle the input field visibility
  const toggleNoteInput = (itemId) => {
    setShowNoteInput((prev) => !prev);
    setSelectedItemId(itemId);
  };

  // ... Existing code ...

  // Function to handle saving the note
  const handleSaveNote = async () => {
    try {
      const response = await fetch('https://golive.mobi/api/moder/blacklist/note/', {
        method: 'POST',
        headers: {
          Authorization: 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id: selectedItemId,
          note: noteInputValue,
        }),
      });
      if (response.ok) {
        // Note saved successfully, reset the input field and selected item
        setShowNoteInput(false);
        setNoteInputValue('');
        setSelectedItemId(null);

        // Reload the data after successful update
        fetchData(); // Assuming fetchData fetches data and updates the state
      } else {
        console.error('Failed to save note:', response.statusText);
      }
    } catch (error) {
      console.error('Error saving note:', error);
    }
  };


  return (
    <div>




      <div>
        
        <div style={{ display: 'flex', justifyContent: 'space-between', marginLeft: '88%' }}>
          <div style={{ display: 'flex', marginRight: '1%'}}>
          <button className="remove-button" style={{ width: '175px', marginBottom: '5%', marginTop: '-5%'}} onClick={handleRemove}>Change watch status</button>
          </div>
        </div>
        <table className="table" >
          <thead style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.07)' }}>
            <tr>
              <th style={{  borderRight: '1px solid rgba(0, 0, 0, 0.07)', }}>
                <input
                  className="checkbox"
                  type="checkbox"
                  checked={selectAll}
                  onChange={toggleSelectAll}
                  style={{
                    alignItems: 'center',
                    display: 'flex',
                  }}
                />
              </th>
              <th
                style={{
                  color: 'black',
                  fontFamily: 'Noto Sans Mono',
                  fontWeight: '500',
                  fontSize: '12px',
                  textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)',
                }}
              >
                ID
              </th>
              <th
                style={{
                  color: 'black',
                  fontFamily: 'Noto Sans Mono',
                  fontWeight: '500',
                  fontSize: '12px',
                  textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)',
                }}
              >
                Text
              </th>
              <th
                style={{
                  color: 'black',
                  fontFamily: 'Noto Sans Mono',
                  fontWeight: '500',
                  fontSize: '12px',
                  textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)',
                }}
              >
                Watched status
              </th>
              <th
                style={{
                  color: 'black',
                  fontFamily: 'Noto Sans Mono',
                  fontWeight: '500',
                  fontSize: '12px',
                  textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)',
                }}
              >
                Date
              </th>
              
              <th
                style={{
                  color: 'black',
                  fontFamily: 'Noto Sans Mono',
                  fontWeight: '500',
                  fontSize: '12px',
                  textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)',
                }}
              >
                User 1
              </th>
              <th
                style={{
                  color: 'black',
                  fontFamily: 'Noto Sans Mono',
                  fontWeight: '500',
                  fontSize: '12px',
                  textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)',
                }}
              >
                User 1 - Role
              </th>
              <th
                style={{
                  color: 'black',
                  fontFamily: 'Noto Sans Mono',
                  fontWeight: '500',
                  fontSize: '12px',
                  textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)',
                }}
              >
                User 2
              </th>
              <th
                style={{
                  color: 'black',
                  fontFamily: 'Noto Sans Mono',
                  fontWeight: '500',
                  fontSize: '12px',
                  textAlign: 'left', 
                }}
              >
                User 2 - Role
              </th>
              {/*<th
                style={{
                  color: 'black',
                  fontFamily: 'Noto Sans Mono',
                  fontWeight: '500',
                  fontSize: '12px',
                  textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)',
                
                }}
              >
                Note
              </th>*/}

            </tr>
            
          </thead>
          
          <tbody>
            
          {(isFiltered ? searchedData : data).map((item, index) => (
              <tr
                key={item.id}
                className={`${index % 2 === 0 ? 'even-row' : 'odd-row'} ${
                  item.selected ? 'selected-row' : 'non-selected-row'
                }`} 
              >
                <td style={{  borderRight: '1px solid rgba(0, 0, 0, 0.07)', }}>
                  <input
                    type="checkbox"
                    checked={item.selected}
                    onChange={() => toggleSelectItem(item.id)}
                  />
                </td>
                <td
                  style={{
                    color: item.selected ? '#6D33FF' : '#606C80',
                    fontFamily: 'Noto Sans Mono',
                    fontWeight: '400',
                    fontSize: '12px',
                    textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)',
                  }}
                >
                  
                    {item.id}
                 
                </td>
                <td
                  style={{
                    color: item.selected ? '#6D33FF' : 'black',
                    fontFamily: 'Noto Sans Mono',
                    fontWeight: '400',
                    fontSize: '12px',
                    textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)',
                  }}
                >
                 
                    {item.text}
                  
                </td>

                <td
  style={{
    color: item.selected ? '#6D33FF' : 'black',
    fontFamily: 'Noto Sans Mono',
    fontWeight: '400',
    fontSize: '12px',
    textAlign: 'left',
    borderRight: '1px solid rgba(0, 0, 0, 0.07)',
    background: item.watched === 0
      ? 'rgba(255, 241, 215, 0.5)'
      : 'rgba(216, 255, 212, 0.5)',
  }}
>
  {item.watched === 0 ? 'Unwatched' : 'Watched'}
</td>

                <td
                  style={{
                    color: item.selected ? '#6D33FF' : 'black',
                    fontFamily: 'Noto Sans Mono',
                    fontWeight: '400',
                    fontSize: '12px',
                    textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)',
                  }}
                >
                  
                  {formatDate(item.date)}
                  
                </td>

                
<a
                    style={{
                      color: item.selected ? '#6D33FF' : '#606C80',
                      fontFamily: 'Noto Sans Mono',
                      fontWeight: '400',
                      fontSize: '12px',
                      textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)',
                    }}
                    href={`profile_model/${item.user1}`}
                  >
                <td
                  style={{
                    color: item.selected ? '#6D33FF' : 'black',
                    fontFamily: 'Noto Sans Mono',
                    fontWeight: '400',
                    fontSize: '12px',
                    textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)',
                  }}
                >
                  
                    {item.user1}
                  
                </td>
                </a>
                <td
                  style={{
                    color: item.selected ? '#6D33FF' : '#606C80',
                    fontFamily: 'Noto Sans Mono',
                    fontWeight: '400',
                    fontSize: '12px',
                    textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)',
                  }}
                >
                  
                    
                    {item.user1_status}
                  
                </td>
                <a
                    style={{
                      color: item.selected ? '#6D33FF' : '#606C80',
                      fontFamily: 'Noto Sans Mono',
                      fontWeight: '400',
                      fontSize: '12px',
                      textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)',
                    }}
                    href={`profile_model/${item.user2}`}
                  >
                <td
                  style={{
                    color: item.selected ? '#6D33FF' : '#606C80',
                    fontFamily: 'Noto Sans Mono',
                    fontWeight: '400',
                    fontSize: '12px',
                    textAlign: 'left',
                  }}
                >
                  {item.user2}
                </td>

</a>
                <td style={{
                    color: item.selected ? '#6D33FF' : '#606C80',
                    fontFamily: 'Noto Sans Mono',
                    fontWeight: '400',
                    fontSize: '12px',
                    textAlign: 'left',  borderLeft: '1px solid rgba(0, 0, 0, 0.07)'
                  }}>
                 
                {item.user2_status}

                  
                </td>
                {/*<td
  style={{
    color: item.selected ? '#6D33FF' : 'black',
    fontFamily: 'Noto Sans Mono',
    fontWeight: '400',
    fontSize: '12px',
    textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)',
 
    }}
>
  {item.note}
  </td>*/}

              </tr>
              
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TableGeneral;
