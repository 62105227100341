import React, { useState, useEffect } from "react";
import { StyleSheet, css } from "aphrodite/no-important";
import Table from "./TableActivity_models";
import ModelButton from "./ModelButton";
import { useHistory } from "react-router-dom";
import axios from "axios";
import "./style.css";

const styles = StyleSheet.create({
  h1: {
    fontSize: "7.5em",
    margin: "15px 0px",
    fontWeight: "bold",
  },
  h2: {
    fontWeight: "bold",
  },
});

const Models = () => {
  const history = useHistory();
  const [isFormValid, setIsFormValid] = useState(false);
  const [codeName, setCodeName] = useState("");
  const [name, setName] = useState("");
  const [sum, setSum] = useState("");
  const [paymentSystem, setPaymentSystem] = useState("");
  const [paymentDetail, setPaymentDetail] = useState("");
  const [status, setStatus] = useState("");
  const [note, setNote] = useState("");
  const [comment, setComment] = useState(""); // New state for Comment input

  useEffect(() => {
    const fetchData = async () => {
      try {
        const billingcheck = localStorage.getItem("billingcheck"); // Get the billingcheck value from local storage

        const response = await axios.post(
          "https://golive.mobi/api/moder/blacklist/list/",
          {
            id: billingcheck, // Include the billingcheck in the request body
          },
          {
            headers: {
              Authorization: "Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d",
            },
          }
        );

        const apiData = response.data; // Assuming the API response directly provides the data array

        // Filter the array to include only objects with the specific id
        const formattedData = apiData.filter(item => item.id === billingcheck).map(item => ({
          id: item.id,
          text: item.text,
          watched: item.watched,
          date: item.date,
          note: item.note,
          user1: item.user1,
          user1_status: item.user1_status,
          user2: item.user2,
          user2_status: item.user2_status,
        }));

        console.log('Formatted Data:', formattedData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [localStorage.getItem("billingcheck")]);

  const handleInputChange = () => {
    const nameValue = document.querySelector(".square_field1").value;
    const sumValue = document.querySelector(".square_field2").value;
    const paymentSystemValue = document.querySelector(".square_field3").value;
    const paymentDetailValue = document.querySelector(".square_field4").value;
    const statusValue = document.querySelector(".square_field5").value;
    const noteValue = document.querySelector(".square_field6").value;
    const commentValue = document.querySelector(".square_field7").value; // New input for Comment

    setIsFormValid(
      nameValue !== "" &&
      sumValue !== "" &&
      paymentSystemValue !== "" &&
      paymentDetailValue !== "" &&
      statusValue !== "" &&
      noteValue !== "" &&
      commentValue !== "" // Check if Comment is not empty
    );
  };

  const handleClick = async (e) => {
    e.preventDefault();

    const data = {
      id: localStorage.getItem("billingcheck"),
      note: note,
      comment: comment, // Include the Comment in the data object
    };

    try {
      const response = await axios.post(
        "https://golive.mobi/api/user/m-billing/payment/add/",
        data,
        {
          headers: {
            Authorization: "Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d",
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        history.push("/complaints");
      } else {
        console.error("Error:", response);
        // Handle error response
      }
    } catch (error) {
      console.error("Error:", error);
      // Handle error
    }
  };



  return (
    <div>
      <h2 style={{ marginLeft: "1%", fontFamily: "Inter" }}>
      ComplaintsButton
      </h2>

      <div className="square" style={{ height: 'auto'}}>
        <form className="createmodel" style={{ fontSize: '12px'}}><br /><br /><br />
          <label style={{ marginLeft: '10%', fontFamily: 'Noto Sans Mono'}}>
            ID Complaint:
            <span style={{ marginLeft: '15%'}}>{name}</span>
          </label>
          <br /><br /><br />
          <label style={{ marginLeft: '10%', fontFamily: 'Noto Sans Mono'}}>
            Text complaint:
            <span style={{ marginLeft: '16%'}}>{sum}</span>
          </label>
          <br /><br /><br />
          <label style={{ marginLeft: '10%', fontFamily: 'Noto Sans Mono'}}>
            Watched status:
            <span style={{ marginLeft: '4%'}}>{paymentSystem}</span>
          </label>
          <br /><br /><br />
          <label style={{ marginLeft: '10%', fontFamily: 'Noto Sans Mono'}}>
            Date:
            <span style={{ marginLeft: '4%'}}>{paymentDetail}</span>
          </label>
          <br /><br /><br />
          <label style={{ marginLeft: '10%', fontFamily: 'Noto Sans Mono'}}>
            ID User 1 (Role):
            <span style={{ marginLeft: '4%'}}>{paymentDetail}</span>
          </label>
          <br /><br /><br />
          <label style={{ marginLeft: '10%', fontFamily: 'Noto Sans Mono'}}>
          ID User 2 (Role):
            <span style={{ marginLeft: '4%'}}>{paymentDetail}</span>
          </label>
          <br />
          <label style={{ marginLeft: '10%'}}>
            Note:
            <input
             style={{ marginLeft: '16.5%'}}
              type="text"
              className="square_field6"
              value={note}
              onChange={(e) => setNote(e.target.value)}
            />
          </label>
          <br />
          
      <button
        type="submit"
        onClick={handleClick}
        className={`square_button`}
        
      >
        Save
      </button>
        </form>



      </div>
    </div>
  );
};

export default Models;
