import React, { useState, useEffect, useCallback } from 'react';
import './style.css';
import { useHistory } from 'react-router-dom';
import { Oval } from  'react-loader-spinner'
import TA from './TableApplication'

const TableGeneral = () => {
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [searchGender, setSearchGender] = useState('');
  const [searchPremium, setSearchPremium] = useState('');
  const [activeTab, setActiveTab] = useState('all');
  const [loadingStatus, setLoadingStatus] = useState(null);
  const [isNoteEditing, setIsNoteEditing] = useState(false);
  const [noteText, setNoteText] = useState('');
  const [noteEditingStates, setNoteEditingStates] = useState({});
  const [isLoading, setIsLoading] = useState({});
  const [state, setState] = useState('1')

  const openNoteEditor = (id, initialText) => {
    setNoteEditingStates({ ...noteEditingStates, [id]: true });
    setNoteText(initialText);
  };

  // Функция для закрытия текстового поля редактирования
  const closeNoteEditor = (id) => {
    setNoteEditingStates({ ...noteEditingStates, [id]: false });
    setNoteText('');
  };

  const handleGenderSelect = (event) => {
    setSearchGender(event.target.value);
  };

  const handlePremiumSelect = (event) => {
    setSearchPremium(event.target.value);
  };

  useEffect(() => {
    fetchData();
  }, [currentPage, activeTab]);

  useEffect(() => {
    const filteredByGender = searchGender
      ? data.filter((item) => item.gender === (searchGender === 'man' ? 1 : 2))
      : data;

    const filteredByPremium = searchPremium
      ? data.filter((item) => item.premium === (searchPremium === 'premium' ? 1 : 0))
      : data;

    setFilteredData(filteredByGender.filter((item) => filteredByPremium.includes(item)));
  }, [data, searchGender, searchPremium]);

  const handleStatusChange = (id, status) => {
    setLoadingStatus(id); // Устанавливаем loadingStatus
  
    // Очищаем значение item.status
    const updatedData = data.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          status: '', // Удаление предыдущего значения
        };
      }
      return item;
    });
  
    setData(updatedData);
  
    // Отправить запрос POST на изменение статуса
    const apiUrl = 'https://apm.liveagency.org/api/mark/';
    const requestOptions = {
      method: 'POST',
      headers: {
        Authorization: 'Token 80db1160cdc569c8b54191ab34475c11389e5079',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id: id,
        status: status,
      }),
    };
  
    fetch(apiUrl, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        // Обработка успешного ответа
        console.log('Status changed successfully');
        // Обнуляем loadingStatus
        
        // Устанавливаем currentPage в 1
  
        // Загрузите данные заново
        fetchData();
        setLoadingStatus(null);
      })
      .catch((error) => {
        console.error('Error changing status:', error);
        // В случае ошибки также обнуляем loadingStatus
        setLoadingStatus(null);
      });
  };
  
  
  const handleTabChange = (newTab) => {
    // Установите новый activeTab
    setActiveTab(newTab);
    // Установите currentPage в 1
    setCurrentPage(1);
    // Загрузите данные заново
    fetchData();
  };
  

  const fetchData = () => {
    
    const apiUrl = `https://apm.liveagency.org/api/list/?page=${currentPage}`;
    const requestOptions = {
      method: 'POST',
      headers: {
        Authorization: 'Token 80db1160cdc569c8b54191ab34475c11389e5079',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        filter: activeTab,
      }),
    };
  
    fetch(apiUrl, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.results && Array.isArray(data.results)) {
          setLoading(false);
          setData(data.results);
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      })
      .finally(() => {
        setLoading(false); // Устанавливаем флаг загрузки в false после завершения запроса
      });
      
  };
  

  const handleClick = () => {
    history.push('/modelcreate');
  };

  const [showForm, setShowForm] = useState(false);

  const toggleForm = () => {
    setShowForm(!showForm);
  };

  const initialData = [];

  const toggleSelectAll = () => {
    setSelectAll(!selectAll);
    const updatedData = data.map((item) => ({ ...item, selected: !selectAll }));
    setData(updatedData);
  };

  const toggleSelectItem = (id) => {
    const updatedData = data.map((item) => {
      if (item.id === id) {
        return { ...item, selected: !item.selected };
      }
      return item;
    });
    setData(updatedData);
  };

  const handleNextPage = () => {
    // Проверяем, есть ли ещё элементы на следующей странице
    setLoading(true);
    if (filteredData.length >= 10) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };
  
  const handlePreviousPage = () => {
    setLoading(true);
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };



  function formatTimestampReg(timestamp) {
    if (!timestamp) {
      return '';
    }
  
    // Удаляем "Z" в конце строки
    timestamp = timestamp.replace('Z', '');
  
    const dateObj = new Date(timestamp);
  
    if (isNaN(dateObj)) {
      return 'Invalid timestamp';
    }
  
    const year = dateObj.getFullYear();
    const month = ('0' + (dateObj.getMonth() + 1)).slice(-2);
    const day = ('0' + dateObj.getDate()).slice(-2);
    const hours = ('0' + dateObj.getHours()).slice(-2);
    const minutes = ('0' + dateObj.getMinutes()).slice(-2);
  
    return `${day}.${month}.${year} ${hours}:${minutes}`;
  }

  const submitNote = (id, text) => {
    // Отправьте POST-запрос с текстом заметки
    setIsLoading({ ...isLoading, [id]: true });
    const apiUrl = `https://apm.liveagency.org/api/note/`;
    const requestOptions = {
      method: 'POST',
      headers: {
        Authorization: 'Token 80db1160cdc569c8b54191ab34475c11389e5079',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id: id,
        note: text,
      }),
    };

    fetch(apiUrl, requestOptions)
      .then((response) => {
        // Обработка успешного ответа
        if (response.ok) {
          setIsLoading({ ...isLoading, [id]: false });
          // Закройте текстовое поле редактирования
          closeNoteEditor(id);
          // Обновите данные, если необходимо
          // ...
          fetchData();
        } else {
          // Обработка ошибки при отправке
          console.error('Error submitting note:', response.statusText);
        }
      })
      .catch((error) => {
        setIsLoading({ ...isLoading, [id]: false });
        console.error('Error submitting note:', error);
      });
  };
  

  return (



    <div>
      <div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h2 style={{ marginLeft: '1%', fontFamily: 'inter' }}>Application</h2>
        </div>

        <div style={{ marginBottom: '2%' }}>
          <button
            className={activeTab === 'all' ? 'active-button' : 'inactive-button'}
            onClick={() => {setActiveTab('all'); setCurrentPage(1); setState('1') }}
            style={{ height: '35px', width: '80px', borderRadius: '10px' }}
          >
            All
          </button>
          <button
            className={activeTab === 'waiting' ? 'active-button' : 'inactive-button'}
            onClick={() => { setActiveTab('waiting'); setCurrentPage(1); setState('1') }}
            style={{ height: '35px', width: '80px', borderRadius: '10px' }}
          >
            Waiting
          </button>
          <button
            className={activeTab === 'declined' ? 'active-button' : 'inactive-button'}
            onClick={() => {setActiveTab('declined'); setCurrentPage(1); setState('1') }}
            style={{ height: '35px', width: '80px', borderRadius: '10px' }}
          >
            Declined
          </button>
          <button
            className={ activeTab === 'accepted' ? 'active-button' : 'inactive-button'}
            onClick={() => { setActiveTab('accepted'); setCurrentPage(1); setState('1') }}
            style={{ height: '35px', width: '80px', borderRadius: '10px' }}
          >
            Accepted
          </button>
       {/*   <button
            className={ activeTab === 'detail' ? 'active-button' : 'inactive-button'}
            onClick={() => { setState('2'); setActiveTab('detail'); }}
            style={{ height: '35px', width: '170px', borderRadius: '10px' }}
          >
            Detailed applications
  </button> */}
        </div>

        {loading && (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
  <Oval
    height={200}
    width={200}
    color="#0000FF"
    wrapperStyle={{}}
    wrapperClass=""
    visible={true}
    ariaLabel='oval-loading'
    secondaryColor="#87CEFA"
    strokeWidth={2}
    strokeWidthSecondary={2}
  />
</div>

    )}


    {!loading && state === '1' && (

        <table className="table">
          <thead style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.07)' }}>
            <tr>
              <th
                style={{
                  color: 'black',
                  fontFamily: 'Noto Sans Mono',
                  weight: '500',
                  fontSize: '12px',
                  textAlign: 'left',
                  borderRight: '1px solid rgba(0, 0, 0, 0.07)',
                }}
              >
                ID
              </th>
              <th
                style={{
                  color: 'black',
                  fontFamily: 'Noto Sans Mono',
                  weight: '500',
                  fontSize: '12px',
                  textAlign: 'left',
                  borderRight: '1px solid rgba(0, 0, 0, 0.07)',
                }}
              >
                Name
              </th>
              <th
                style={{
                  color: 'black',
                  fontFamily: 'Noto Sans Mono',
                  weight: '500',
                  fontSize: '12px',
                  textAlign: 'left',
                  borderRight: '1px solid rgba(0, 0, 0, 0.07)',
                }}
              >
                Gender
              </th>
              <th
                style={{
                  color: 'black',
                  fontFamily: 'Noto Sans Mono',
                  weight: '500',
                  fontSize: '12px',
                  textAlign: 'left',
                  borderRight: '1px solid rgba(0, 0, 0, 0.07)',
                }}
              >
                Age
              </th>
              <th
                style={{
                  color: 'black',
                  fontFamily: 'Noto Sans Mono',
                  weight: '500',
                  fontSize: '12px',
                  textAlign: 'left',
                  borderRight: '1px solid rgba(0, 0, 0, 0.07)',
                }}
              >
                Country
              </th>
              <th
                style={{
                  color: 'black',
                  fontFamily: 'Noto Sans Mono',
                  weight: '500',
                  fontSize: '12px',
                  textAlign: 'left',
                  borderRight: '1px solid rgba(0, 0, 0, 0.07)',
                }}
              >
                Experience
              </th>
              <th
                style={{
                  color: 'black',
                  fontFamily: 'Noto Sans Mono',
                  weight: '500',
                  fontSize: '12px',
                  textAlign: 'left',
                  borderRight: '1px solid rgba(0, 0, 0, 0.07)',
                }}
              >
                Good Camera
              </th>
              <th
                style={{
                  color: 'black',
                  fontFamily: 'Noto Sans Mono',
                  weight: '500',
                  fontSize: '12px',
                  textAlign: 'left',
                  borderRight: '1px solid rgba(0, 0, 0, 0.07)',
                }}
              >
                Available Time
              </th>
              <th
                style={{
                  color: 'black',
                  fontFamily: 'Noto Sans Mono',
                  weight: '500',
                  fontSize: '12px',
                  textAlign: 'left',
                  borderRight: '1px solid rgba(0, 0, 0, 0.07)',
                }}
              >
                Salary per Week
              </th>
              <th
                style={{
                  color: 'black',
                  fontFamily: 'Noto Sans Mono',
                  weight: '500',
                  fontSize: '12px',
                  textAlign: 'left',
                  borderRight: '1px solid rgba(0, 0, 0, 0.07)',
                }}
              >
                Instagram (Nick is a link)
              </th>
              <th
                style={{
                  color: 'black',
                  fontFamily: 'Noto Sans Mono',
                  weight: '500',
                  fontSize: '12px',
                  textAlign: 'left',
                  borderRight: '1px solid rgba(0, 0, 0, 0.07)',
                }}
              >
                Communication Type
              </th>
              <th
                style={{
                  color: 'black',
                  fontFamily: 'Noto Sans Mono',
                  weight: '500',
                  fontSize: '12px',
                  textAlign: 'left',
                  borderRight: '1px solid rgba(0, 0, 0, 0.07)',
                }}
              >
                Communication Detail
              </th>
              <th
                style={{
                  color: 'black',
                  fontFamily: 'Noto Sans Mono',
                  weight: '500',
                  fontSize: '12px',
                  textAlign: 'left',
                  borderRight: '1px solid rgba(0, 0, 0, 0.07)',
                }}
              >
                Date
              </th>
              <th
                style={{
                  color: 'black',
                  fontFamily: 'Noto Sans Mono',
                  weight: '500',
                  fontSize: '12px',
                  textAlign: 'left',
                  borderRight: '1px solid rgba(0, 0, 0, 0.07)',
                }}
              >
                Note
              </th>
              <th
                style={{
                  color: 'black',
                  fontFamily: 'Noto Sans Mono',
                  weight: '500',
                  fontSize: '12px',
                  textAlign: 'left',
                  borderRight: '1px solid rgba(0, 0, 0, 0.07)',
                }}
              >
                Status
              </th>
              <th
                style={{
                  color: 'black',
                  fontFamily: 'Noto Sans Mono',
                  weight: '500',
                  fontSize: '12px',
                  textAlign: 'left',
                }}
              >
                Change Status
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((item, index) => (
              <tr
                key={item.id}
                className={`${index % 2 === 0 ? 'even-row' : 'odd-row'} ${
                  item.selected ? 'selected-row' : 'non-selected-row'
                }`}
              >

                <td
                  style={{
                    color: item.selected ? '#6D33FF' : '#606C80',
                    fontFamily: 'Noto Sans Mono',
                    fontWeight: '400',
                    fontSize: '12px',
                    textAlign: 'left',
                    borderRight: '1px solid rgba(0, 0, 0, 0.07)',
                  }}
                >
                  {item.id}
                </td>
                <td
                  style={{
                    color: item.selected ? '#6D33FF' : '#606C80',
                    fontFamily: 'Noto Sans Mono',
                    fontWeight: '400',
                    fontSize: '12px',
                    textAlign: 'left',
                    borderRight: '1px solid rgba(0, 0, 0, 0.07)',
                  }}
                >
                  {item.name}



                </td>
                <td
                        style={{
                          color: item.selected ? '#6D33FF' : '#606C80',
                          fontFamily: 'Noto Sans Mono',
                          fontWeight: '400',
                          fontSize: '12px',
                          textAlign: 'left',
                          borderRight: '1px solid rgba(0, 0, 0, 0.07)',
                          background: item.gender === '2' ? 'rgb(255 212 218 / 72%)' : 'transparent',
                        }}
                      >
                        {item.gender === '1'
                          ? 'male'
                          : item.gender === '2'
                          ? 'female'
                          : item.gender === '3'
                          ? 'non-binary'
                          : ''}
                      </td>
                <td
                  style={{
                    color: item.selected ? '#6D33FF' : 'black',
                    fontFamily: 'Noto Sans Mono',
                    fontWeight: '400',
                    fontSize: '12px',
                    textAlign: 'left',
                    borderRight: '1px solid rgba(0, 0, 0, 0.07)',
                  }}
                >
                  {item.age}
                </td>
                <td
                  style={{
                    color: item.selected ? '#6D33FF' : 'black',
                    fontFamily: 'Noto Sans Mono',
                    fontWeight: '400',
                    fontSize: '12px',
                    textAlign: 'left',
                    borderRight: '1px solid rgba(0, 0, 0, 0.07)',
                  }}
                >
                  {item.country && (
                    <span>
                      <img
                        src={`https://flagcdn.com/h80/${item.country.toLowerCase()}.jpg`}
                        alt={`${item.country} flag`}
                        style={{ marginRight: '8px', verticalAlign: 'middle', width: '25px' }}
                      />
                      {item.country}
                    </span>
                  )}
                </td>
                <td
                  style={{
                    color: item.selected ? '#6D33FF' : '#606C80',
                    fontFamily: 'Noto Sans Mono',
                    fontWeight: '400',
                    fontSize: '12px',
                    textAlign: 'left',
                    borderRight: '1px solid rgba(0, 0, 0, 0.07)',
                  }}
                >
                  {item.experience ? 'Yes' : 'No'}
                </td>
                <td
                  style={{
                    color: item.selected ? '#6D33FF' : '#606C80',
                    fontFamily: 'Noto Sans Mono',
                    fontWeight: '400',
                    fontSize: '12px',
                    textAlign: 'left',
                    borderRight: '1px solid rgba(0, 0, 0, 0.07)',
                  }}
                >
                  {item.good_camera ? 'Yes' : 'No'}
                </td>
                <td
                  style={{
                    color: item.selected ? '#6D33FF' : '#606C80',
                    fontFamily: 'Noto Sans Mono',
                    fontWeight: '400',
                    fontSize: '12px',
                    textAlign: 'left',
                    borderRight: '1px solid rgba(0, 0, 0, 0.07)',
                  }}
                >
                  {item.available_time} hours
                </td>
                <td
                  style={{
                    color: item.selected ? '#6D33FF' : '#606C80',
                    fontFamily: 'Noto Sans Mono',
                    fontWeight: '400',
                    fontSize: '12px',
                    textAlign: 'left',
                    borderRight: '1px solid rgba(0, 0, 0, 0.07)',
                  }}
                >
                  {item.salary_per_week}$
                </td>
                <td
  style={{
    color: item.selected ? '#6D33FF' : '#606C80',
    fontFamily: 'Noto Sans Mono',
    fontWeight: '400',
    fontSize: '12px',
    textAlign: 'left',
    borderRight: '1px solid rgba(0, 0, 0, 0.07)',
  }}
>
  {item.instagram}
</td>

                <td
                  style={{
                    color: item.selected ? '#6D33FF' : '#606C80',
                    fontFamily: 'Noto Sans Mono',
                    fontWeight: '400',
                    fontSize: '12px',
                    textAlign: 'left',
                    borderRight: '1px solid rgba(0, 0, 0, 0.07)',
                  }}
                >
                  {item.communication_type}
                </td>
                <td
  style={{
    color: item.selected ? '#6D33FF' : '#606C80',
    fontFamily: 'Noto Sans Mono',
    fontWeight: '400',
    fontSize: '12px',
    textAlign: 'left',
    borderRight: '1px solid rgba(0, 0, 0, 0.07)',
  }}
>
  {item.communication_detail || ''}
</td>
<td
  style={{
    color: item.selected ? '#6D33FF' : '#606C80',
    fontFamily: 'Noto Sans Mono',
    fontWeight: '400',
    fontSize: '12px',
    textAlign: 'left',
    borderRight: '1px solid rgba(0, 0, 0, 0.07)',
  }}
>
{formatTimestampReg(item.date) || ''}
</td>



<td
  style={{
    color: item.selected ? '#6D33FF' : '#606C80',
    fontFamily: 'Noto Sans Mono',
    fontWeight: '400',
    fontSize: '12px',
    textAlign: 'left',
    borderRight: '1px solid rgba(0, 0, 0, 0.07)',
  }}
>



{isLoading[item.id] ? ( // Показываем индикатор загрузки только для текущей строки
                 <div style={{ width: '20px'}}>   <Oval
      height={40}
      width={40}
      color="#0000FF"
      wrapperStyle={{}}
      wrapperClass=""
      visible={true}
      ariaLabel='oval-loading'
      secondaryColor="#87CEFA"
      strokeWidth={2}
      strokeWidthSecondary={2}
    /></div>
              ) : noteEditingStates[item.id] ? (
                <textarea
                style={{ width: '180px', height: '150px'}}
                  type="text"
                  value={noteText}
                  onChange={(e) => setNoteText(e.target.value)}
                />
              ) : (
                item.note
              )}
</td>

<td
  style={{
    color: item.selected ? '#6D33FF' : '#606C80',
    fontFamily: 'Noto Sans Mono',
    fontWeight: '400',
    fontSize: '12px',
    textAlign: 'left',
    borderRight: '1px solid rgba(0, 0, 0, 0.07)',
    background:
      loadingStatus === item.id
        ? 'transparent' // Фон будет прозрачным, когда статус загружается
        : item.status === 'waiting'
        ? 'rgb(255 251 212 / 72%)'
        : item.status === 'declined'
        ? 'rgba(255, 212, 218, 0.72)'
        : item.status === 'accepted'
        ? 'rgb(217 255 212 / 72%)'
        : 'transparent', // Значение по умолчанию, если статус не совпадает ни с одним из указанных
  }}
>
  {loadingStatus === item.id ? (
    <Oval
      height={40}
      width={40}
      color="#0000FF"
      wrapperStyle={{}}
      wrapperClass=""
      visible={true}
      ariaLabel='oval-loading'
      secondaryColor="#87CEFA"
      strokeWidth={2}
      strokeWidthSecondary={2}
    />
  ) : (
    item.status
  )}
</td>

                <td>
                  <div>

                  {noteEditingStates[item.id] ? (
                <div>
                   <button 
                   className="remove-button"
                   style={{ width: '100px', height: '30px', backgroundColor: '#6D33FF', fontSize: '12.5px' }}
                   onClick={() => submitNote(item.id, noteText)}>Accept</button>

                  <button
                  className="remove-button"
                  style={{ width: '100px', height: '30px', fontSize: '12.5px' }}
                  onClick={() => closeNoteEditor(item.id)}>Cancel</button>
                </div>
              ) : (
                <>
                <div style={{ display: 'flex'}}>
                    <button
                      onClick={() => handleStatusChange(item.id, 'waiting')}
                      className="remove-button"
                      style={{ width: '100px', height: '30px', backgroundColor: 'rgb(95 95 95)', fontSize: '12.5px' }}
                    >
                      Waiting
                    </button>
                    <button
                      onClick={() => handleStatusChange(item.id, 'declined')}
                      className="remove-button"
                      style={{ width: '100px', height: '30px', fontSize: '12.5px' }}
                    >
                      Declined
                    </button>
                    </div>

                    <div style={{ display: 'flex'}}>
                    <button
                      onClick={() => handleStatusChange(item.id, 'accepted')}
                      className="remove-button"
                      style={{ width: '100px', height: '30px', backgroundColor: '#6D33FF', fontSize: '12.5px' }}
                    >
                      Accepted
                    </button>
                    <button
                      onClick={() => openNoteEditor(item.id, item.note)}
                      className="remove-button"
                      style={{ width: '100px', height: '30px', backgroundColor: 'rgb(51 102 255)', fontSize: '12.5px' }}
                    >
                      Change note
                    </button>
                    </div>
                    </>
              )}


                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        )}

       { state === '1' && <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
          <button className="remove-button" style={{ backgroundColor: '#6D33FF' }} onClick={handlePreviousPage}>
            &lt; Previous
          </button>
          <button className="remove-button" style={{ backgroundColor: '#FFF', width: '40px', color: 'black' }}>
            {currentPage}
          </button>
          <button className="remove-button" style={{ backgroundColor: '#6D33FF' }} onClick={handleNextPage}>
            Next &gt;
          </button>
                </div>  }


{ state === '2' &&



              <TA />

}



      </div>
    </div>
  );
};

export default TableGeneral;
