import React, { useState, useEffect } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './custom.css'

const Models = () => {
  const [timeInterval, setTimeInterval] = useState('5min');
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let response;
        switch (timeInterval) {
		case '30sec':
            response = await fetch(`https://admin.golive.mobi/stats/thirty-seconds/${selectedDate.toISOString().split('T')[0]}`);
            break;
            case '1min':
            response = await fetch(`https://admin.golive.mobi/stats/minute/${selectedDate.toISOString().split('T')[0]}`);
            break;
          case '5min':
            response = await fetch(`https://admin.golive.mobi/stats/five/${selectedDate.toISOString().split('T')[0]}`);
            break;
          case '10min':
            response = await fetch(`https://admin.golive.mobi/stats/ten/${selectedDate.toISOString().split('T')[0]}`);
            break;
          case '1hr':
            response = await fetch(`https://admin.golive.mobi/stats/hour/${selectedDate.toISOString().split('T')[0]}`);
            break;
          default:
            response = await fetch(`https://admin.golive.mobi/stats/all`);
        }
        const jsonData = await response.json();
        setData(jsonData);
      } catch (error) {
        console.error('Ошибка загрузки данных:', error);
      }
    };

    fetchData();
  }, [timeInterval, selectedDate]);

  const handleTimeIntervalChange = async (interval) => {
    try {
      let response;
      switch (interval) {
        case '30sec':
          response = await fetch(`https://admin.golive.mobi/stats/thirty-seconds/${selectedDate.toISOString().split('T')[0]}`);
          break;
          case '1min':
          response = await fetch(`https://admin.golive.mobi/stats/minute/${selectedDate.toISOString().split('T')[0]}`);
          break;
        case '5min':
          response = await fetch(`https://admin.golive.mobi/stats/five/${selectedDate.toISOString().split('T')[0]}`);
          break;
        case '10min':
          response = await fetch(`https://admin.golive.mobi/stats/ten/${selectedDate.toISOString().split('T')[0]}`);
          break;
        case '1hr':
          response = await fetch(`https://admin.golive.mobi/stats/hour/${selectedDate.toISOString().split('T')[0]}`);
          break;
        default:
          response = await fetch(`https://admin.golive.mobi/stats/all`);
      }
      const jsonData = await response.json();
      setData(jsonData);
    } catch (error) {
      console.error('Ошибка загрузки данных:', error);
    }
    setTimeInterval(interval);
  };

  const CustomTooltip = ({ active, payload, label }) => {
	if (active && payload && payload.length) {
	  const { total_online, total_turbo, total_busy, interval_start } = payload[0].payload;
	  return (
		<div className="custom-tooltip" style={{ backgroundColor: '#f0f0f0', border: '1px solid #ccc' }}>
		  <p className="label" style={{ fontWeight: 'bold' }}>{`Time: ${interval_start}`}</p>
		  <p className="label" style={{ fontWeight: 'bold', color: '#cb4141' }}>{`Total Online: ${total_online}`}</p>
		  <p className="label" style={{ fontWeight: 'bold', color: '#82ca9d' }}>{`Total Turbo: ${total_turbo}`}</p>
		  <p className="label" style={{ fontWeight: 'bold', color: '#e36b29' }}>{`Total Busy: ${total_busy}`}</p>
		</div>
	  );
	}
	return null;
  };

  return (
	<div className="col-md-6 align-self-center" style={{ marginTop: '1%', fontFamily: 'Arial, sans-serif', textAlign: 'center' }}>
	  <h2 style={{ marginLeft: '1%', fontFamily: 'inter' }}>Statistic Online</h2>
	  <div>
		{/* Кнопки временных интервалов */}
    
    <button className={timeInterval === '30sec' ? 'active-button' : 'inactive-button'} style={{
		  width: '100px',
		  height: '30px',
		  borderRadius: '15px',
		  color: 'black',
		}} onClick={() => handleTimeIntervalChange('30sec')}>30 sec</button>
    <button className={timeInterval === '1min' ? 'active-button' : 'inactive-button'} style={{
		  width: '100px',
		  height: '30px',
		  borderRadius: '15px',
		  color: 'black',
		}} onClick={() => handleTimeIntervalChange('1min')}>1 min</button>
		<button className={timeInterval === '5min' ? 'active-button' : 'inactive-button'} style={{
		  width: '100px',
		  height: '30px',
		  borderRadius: '15px',
		  color: 'black',
		}} onClick={() => handleTimeIntervalChange('5min')}>5 min</button>
		<button className={timeInterval === '10min' ? 'active-button' : 'inactive-button'} style={{
		  width: '100px',
		  height: '30px',
		  borderRadius: '15px',
		  color: 'black',
		}} onClick={() => handleTimeIntervalChange('10min')}>10 min</button>
		<button className={timeInterval === '1hr' ? 'active-button' : 'inactive-button'} style={{
		  width: '100px',
		  height: '30px',
		  borderRadius: '15px',
		  color: 'black',
		}} onClick={() => handleTimeIntervalChange('1hr')}>1 hr</button>
  
		{/* Выбор даты с использованием react-datepicker */}
		<DatePicker style={{
			height: '25px',
			marginLeft: '10%',
			textAlign: 'center',
			borderRadius: '10px',
			color: 'black',
		}} className='customDatePicker' selected={selectedDate} onChange={date => setSelectedDate(date)} />
	  </div>
  
	  <ResponsiveContainer width="100%" height={300}>
		<BarChart data={data}>
		  <XAxis dataKey="time" />
		  <YAxis />
		  <Tooltip content={<CustomTooltip />} />
		  <Legend />
		  <Bar dataKey="total_online" fill="#cb4141" name="Total Online" />
		  <Bar dataKey="total_turbo" fill="#82ca9d" name="Total Turbo" />
		  <Bar dataKey="total_busy" fill="#e36b29" name="Total Busy" />
		  {/* Добавьте другие графики, если необходимо */}
		  <CustomTooltip />
		</BarChart>
	  </ResponsiveContainer>
	</div>
  );
  
};

export default Models;
