import React, { useState, useEffect } from 'react';
import crossoff from './crossoff.svg'
import crosson from './crosson.svg'
import checkon from './checkon.svg'
import checkoff from './checkoff.svg'
import checknull from './checknull.svg'
import crossnull from './crossnull.svg'
import { Oval } from  'react-loader-spinner'

function MyTable() {
  const [promoItems, setPromoItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [inputPage, setInputPage] = useState(1);
  const [totalModels, setTotalModels] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [ view, setView ] = useState(false);
  const [ viewContent, setViewContent ] = useState("");
  const [ viewType, setViewType ] = useState("");
  const [ page, setPage ] = useState(1)

  const fetchData = async (page) => {
    try {
      setPromoItems();
      setLoading(true);
  
      const requestOptions = {
        method: 'POST',
        headers: {
          Authorization: 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          page: page,
          page_size: 5,
          filter: 0,
        }),
      };
  
      const response = await fetch('https://golive.mobi/api/promo/list/', requestOptions);
  
      if (response.ok) {
        const responseData = await response.json();
        setPromoItems(responseData);
        setTotalModels(responseData.total);
        setTotalPages(responseData.total_pages);
      } else {
        console.error('API request failed');
      }
    } catch (error) {
      console.error('API request failed:', error);
    } finally {
      setLoading(false);
    }
  };
  

  useEffect(() => {
    fetchData(page);
  }, [page]);

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      fetchData(inputPage);
    }
  };

  const handleNextPage = () => {
    fetchData(inputPage + 1);
    setPage((prevPage) => prevPage + 1);
  };

  const handlePreviousPage = () => {
    if (inputPage > 1) {
      fetchData(inputPage - 1);
      setPage((prevPage) => prevPage - 1);
    }
  };

  const divHeadStyle = {
    border: '1px solid lightgrey',
    borderRadius: '5px',
    boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)',
    marginBottom: '2%',
    width: '97%',
    marginLeft: 'auto', marginRight: 'auto', display: 'block'
  };

  const tableHeaderStyle = {
    color: 'black',
    fontFamily: 'Noto Sans Mono',
    fontWeight: '500', // Исправил ошибку: weight -> fontWeight
    fontSize: '12px',
    textAlign: 'left',
    borderRight: '1px solid rgba(0, 0, 0, 0.07)',
    borderLeft: '1px solid rgba(0, 0, 0, 0.07)',
    width: '25%',
  };

  const tableHeader2Style = {
    color: '#606C80',
    fontFamily: 'Noto Sans Mono',
    fontWeight: '400',
    fontSize: '12px',
    textAlign: 'left',
    borderRight: '1px solid rgba(0, 0, 0, 0.07)',
    borderLeft: '1px solid rgba(0, 0, 0, 0.07)',
    width: '25%'
  };

  const avatarStyle = {
    color: 'black',
    fontFamily: 'Noto Sans Mono',
    weight: '500',
    fontSize: '12px',
    textAlign: 'left',
    borderRight: '1px solid rgba(0, 0, 0, 0.07)',
    borderLeft: '1px solid rgba(0, 0, 0, 0.07)',
    width: '5%'
  }

  const coverStyle = {
    color: 'black',
    fontFamily: 'Noto Sans Mono',
    weight: '500',
    fontSize: '12px',
    textAlign: 'left',
    borderRight: '1px solid rgba(0, 0, 0, 0.07)',
    width: '11.5%'  
  }

  const publicStyle = {
    color: 'black',
    fontFamily: 'Noto Sans Mono',
    weight: '500',
    fontSize: '12px',
    textAlign: 'left',
    borderRight: '1px solid rgba(0, 0, 0, 0.07)',
    width: '70%' 
  }

  const actionsStyle = {
    color: 'black',
    fontFamily: 'Noto Sans Mono',
    weight: '500',
    fontSize: '12px',
    textAlign: 'left',
    borderRight: '1px solid rgba(0, 0, 0, 0.07)',
  }

  const privateStyle = {
    color: 'black',
    fontFamily: 'Noto Sans Mono',
    weight: '500',
    fontSize: '12px',
    textAlign: 'left',
    borderRight: '1px solid rgba(0, 0, 0, 0.07)',
  }

  const sendVerificationRequest = async (id, url, type, file_type, success, imageIndex) => {
    //setIsLoading({ ...isLoading, [id]: true });
    
    const apiUrl = 'https://golive.mobi/api/verif/complete/';
    const requestOptions = {
      method: 'POST',
      headers: {
        Authorization: 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id: id,
        url: url,
        type: type,
        file_type: file_type,
        success: success,
        web: 1
      }),
    };
  
    try {
      const response = await fetch(apiUrl, requestOptions);
      if (response.ok) {
        
        // Обновите данные, если необходимо
        // ...
        fetchData();
        //await new Promise(resolve => setTimeout(resolve, 1500));
        //setIsLoading({ ...isLoading, [id]: false });
      } else {
        console.error('Error sending verification request:', response.statusText);
      }
    } catch (error) {
      //setIsLoading({ ...isLoading, [id]: false });
      console.error('Error sending verification request:', error);
    }
  };

  async function sendMarkRequest(id) {
    const request = {
      id: id,
      approved: 1,
    };
  
    try {
      const response = await fetch('https://golive.mobi/api/verif/mark/', {
        method: 'POST',
        headers: {
          'Authorization': 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(request),
      });
      fetchData();
      if (!response.ok) {
        console.error('Mark request failed');
      }
    } catch (error) {
      console.error('Mark request failed:', error);
    }
  }

  return (
    <>

{loading && (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center',
              position: 'absolute',
              left: 'auto',
              right: 'auto',
              background: 'white',
              width: '100%',
              height: '100%',
              }}>
      <Oval
        height={200}
        width={200}
        color="#0000FF"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        ariaLabel='oval-loading'
        secondaryColor="#87CEFA"
        strokeWidth={2}
        strokeWidthSecondary={2}
      />
    </div>
    
        )}

{view === true && 
    <div style={{
      position: 'fixed',
      marginTop: '-15%',
      height: '2000px',
      width: '2000px',
      background: 'rgba(255, 255, 255, 0.8)'
    }}>
    
    <img  src={crossoff}
    onClick={() => {setView(false)}}
    style={{
      position: 'fixed',
      width: '35px',
      left: '75%',
      top: '2%',
      background: 'white', borderRadius: '20px', zIndex: '9999'
    }}
    />
    
      <div
        style={{
          zIndex: 999,
          width: 'auto',
          height: '95%',
          backgroundColor: 'white',
          position: 'fixed',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
          border: '1px solid #dddddd',
          borderRadius: '10px',
          boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
          overflow: 'auto'
        }}
      >
    
    {viewType === 'image' &&
        <img
          src={viewContent}
          style={{
            width: 'auto',
            marginLeft: 'auto',
            marginRight: 'auto',
            display: 'block',
            height: '100%'
          }}
          //alt={`Аватар ${imageIndex}`}
        />}
    
    {viewType === 'video' &&
        <video
          controls
          src={viewContent}
          style={{
            width: 'auto',
            marginLeft: 'auto',
            marginRight: 'auto',
            display: 'block',
            height: '100%'
          }}
          //alt={`Аватар ${imageIndex}`}
        />}
    
        </div>
      </div>
    }

     {/* {!loading && (
        <>
          <hr style={{ border: '1px solid rgba(0, 0, 0, 0.05)' }} />
          <div style={{ display: 'flex', marginLeft: '1.5%', fontFamily: 'inter' }}>
            <p>Total models: {totalModels}</p>
            <p style={{ marginLeft: '1%' }}>Total Page: {totalPages}</p>
          </div>
          <hr style={{ border: '1px solid rgba(0, 0, 0, 0.05)' }} />
        </>
     )} */}

{!loading && (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
          <button className="remove-button" style={{ backgroundColor: '#6D33FF' }} onClick={handlePreviousPage}>
            &lt; Previous
          </button>
          <input
            className="remove-button"
            style={{
              backgroundColor: '#FFF',
              width: '40px',
              color: 'black',
              textAlign: 'center',
            }}
            type="number"
            value={inputPage}
            onChange={(e) => setInputPage(parseInt(e.target.value))}
            onKeyPress={handleKeyPress}
          />
          <button className="remove-button" style={{ backgroundColor: '#6D33FF' }} onClick={handleNextPage}>
            Next &gt;
          </button>
        </div>
      )}
      
<br />

{promoItems && promoItems.map((item, index) => ( 


      <div key={index} style={divHeadStyle}>
      <table className="table">
              <thead style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.07)' }}>
                <tr>
                  <th style={tableHeaderStyle}>
                    ID
                  </th>
    
                  <th style={tableHeaderStyle}>
                    Name
                  </th>
    
                  <th style={tableHeaderStyle}>
                    Gender
                  </th>
    
                  <th style={tableHeaderStyle}>
                    Country
                  </th>
    
                  <th style={tableHeaderStyle}>
                    Status
                  </th>
    
                  </tr>
                  </thead>
                  <tbody>
                    <tr>
                    <td style={tableHeader2Style}>
                    {item.id}
                    </td>
                    <td style={tableHeader2Style}>
                    {item.name} 
                    </td>
                    <td style={tableHeader2Style}>
                    {item.gender === 1 && 'Man'}
                    {item.gender === 2 && 'Woman'}
                    {item.gender === 3 && 'Non-binary'}
                    </td>
                    <td style={tableHeader2Style}>
                    {item.countryCode}
                    </td>
                    <td style={tableHeader2Style}>
                    {item.approved === null
                    ? 'Waiting'
                    : item.approved === 0
                    ? 'Rejected'
                    : item.approved === 1
                    ? 'Approved'
                    : item.approved}
                    </td>
                    </tr>  
    </tbody>
    </table>


    <table className="table">
              <thead style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.07)' }}>
                <tr>
                  <th style={avatarStyle}>
                  Promo
                  </th>

                  <th style={avatarStyle}>
                  Promo see live
                  </th>



    
                  </tr>
                  </thead>
                  <tbody>
                    <tr>
                      {/* PROMO */}
                    <td style={coverStyle}>
                    <div style={{ display: 'flex', overflowY: 'auto' }}>
                    {item.images
      .filter((image) => image.type === 'promo') // Filter images with type 'promo'
      .map((image, imageIndex) => (<>

                      <div style={{ border: '1px solid #00000040', borderRadius: '5px', marginRight: '10%', width: '200px'  }}>

                <img
                  key={imageIndex}
                  src={image.cover}
                  alt={`Promo Cover ${imageIndex + 1}`}
                  style={{ width: '200px', margin: '5px' }}
                  onClick={() => {
                    setView(true);
                    setViewContent(image.url);
                    setViewType('video')
                  }}
                />

{image.promo_approved === null &&
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
               <img
                  src={checkoff}
                    style={{
                      width: '30px',
                      //background: 'lightgrey',
                      //border: '1px solid #42c45b',
                      borderRadius: '5px',
                      margin: '5px', // добавление небольшого отступа
                    }}
                    onClick={() => sendVerificationRequest(item.id, image.url, 'promo', 'video', 1)}
                    //onClick={() => sendVerificationRequest2(item.id)}
                  />
                  <img
                    src={crossoff}
                    style={{
                      width: '30px',
                      //background: '#f0253d',
                      //border: '1px solid #c44242',
                      borderRadius: '5px',
                      margin: '5px', // добавление небольшого отступа
                    }}
                    onClick={() => sendVerificationRequest(item.id, image.url, 'avatar', 'image', 0)}
              /></div>}

{image.promo_approved === true &&
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
               <img
                  src={checkon}
                    style={{
                      width: '30px',
                      //background: 'lightgrey',
                      //border: '1px solid #42c45b',
                      borderRadius: '5px',
                      margin: '5px', // добавление небольшого отступа
                    }}
                  />
                  <img
                    src={crossoff}
                    onClick={() => sendVerificationRequest(item.id, image.url, 'promo', 'video', 0)}
                    style={{
                      width: '30px',
                      //background: '#f0253d',
                      //border: '1px solid #c44242',
                      borderRadius: '5px',
                      margin: '5px', // добавление небольшого отступа
                    }}
              /></div>}
    
    
    {image.promo_approved === false &&
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
               <img
                  src={checkoff}
                  onClick={() => sendVerificationRequest(item.id, image.url, 'promo', 'video', 1)}
                    style={{
                      width: '30px',
                      //background: 'lightgrey',
                      //border: '1px solid #42c45b',
                      borderRadius: '5px',
                      margin: '5px', // добавление небольшого отступа
                    }}
                  />
                  <img
                    src={crosson}
                    style={{
                      width: '30px',
                      //background: '#f0253d',
                      //border: '1px solid #c44242',
                      borderRadius: '5px',
                      margin: '5px', // добавление небольшого отступа
                    }}
              /></div>}

</div>
</>))}



    </div>
                    </td>

                    
                    
  
                      {/* PROMO SEE LIVE */}
                    <td style={coverStyle}>
                    <div style={{ display: 'flex', overflowY: 'auto'  }}>
                    {item.images
      .filter((image) => image.type === 'promo_see_live') // Filter images with type 'promo'
      .map((image, imageIndex) => (<>

                      <div style={{ border: '1px solid #00000040', borderRadius: '5px', marginRight: '10%' }}>

                <img
                  key={imageIndex}
                  src={image.cover}
                  alt={`Promo Cover ${imageIndex + 1}`}
                  style={{ width: '200px', margin: '5px' }}
                  onClick={() => {
                    setView(true);
                    setViewContent(image.url);
                    setViewType('video')
                  }}
                />

{image.promo_approved === null &&
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
               <img
                  src={checkoff}
                    style={{
                      width: '30px',
                      //background: 'lightgrey',
                      //border: '1px solid #42c45b',
                      borderRadius: '5px',
                      margin: '5px', // добавление небольшого отступа
                    }}
                    onClick={() => sendVerificationRequest(item.id, image.url, 'promo', 'video', 1)}
                    //onClick={() => sendVerificationRequest2(item.id)}
                  />
                  <img
                    src={crossoff}
                    style={{
                      width: '30px',
                      //background: '#f0253d',
                      //border: '1px solid #c44242',
                      borderRadius: '5px',
                      margin: '5px', // добавление небольшого отступа
                    }}
                    onClick={() => sendVerificationRequest(item.id, image.url, 'avatar', 'image', 0)}
              /></div>}

{image.promo_approved === true &&
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
               <img
                  src={checkon}
                    style={{
                      width: '30px',
                      //background: 'lightgrey',
                      //border: '1px solid #42c45b',
                      borderRadius: '5px',
                      margin: '5px', // добавление небольшого отступа
                    }}
                  />
                  <img
                    src={crossoff}
                    onClick={() => sendVerificationRequest(item.id, image.url, 'promo', 'video', 0)}
                    style={{
                      width: '30px',
                      //background: '#f0253d',
                      //border: '1px solid #c44242',
                      borderRadius: '5px',
                      margin: '5px', // добавление небольшого отступа
                    }}
              /></div>}
    
    
    {image.promo_approved === false &&
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
               <img
                  src={checkoff}
                  onClick={() => sendVerificationRequest(item.id, image.url, 'promo', 'video', 1)}
                    style={{
                      width: '30px',
                      //background: 'lightgrey',
                      //border: '1px solid #42c45b',
                      borderRadius: '5px',
                      margin: '5px', // добавление небольшого отступа
                    }}
                  />
                  <img
                    src={crosson}
                    style={{
                      width: '30px',
                      //background: '#f0253d',
                      //border: '1px solid #c44242',
                      borderRadius: '5px',
                      margin: '5px', // добавление небольшого отступа
                    }}
              /></div>}

</div>
</>))}



    </div>
                    </td>

                    
            

                    
                    
                  </tr>  

    </tbody>
    </table>



    <table className="table">
              <thead style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.07)' }}>
                <tr>


                <th style={avatarStyle}>
                  Promo call me
                  </th>

                  <th style={avatarStyle}>
                  Promo evening
                    </th>

    
                  </tr>
                  </thead>
                  <tbody>
                    <tr>
                     

                    {/* PROMO CALL ME */}
                      <td style={coverStyle}>
                    <div style={{ display: 'flex', overflowY: 'auto'  }}>
                    {item.images
      .filter((image) => image.type === 'promo_call_me') // Filter images with type 'promo'
      .map((image, imageIndex) => (<>

                      <div style={{ border: '1px solid #00000040', borderRadius: '5px', marginRight: '10%' }}>

                <img
                  key={imageIndex}
                  src={image.cover}
                  alt={`Promo Cover ${imageIndex + 1}`}
                  style={{ width: '200px', margin: '5px' }}
                  onClick={() => {
                    setView(true);
                    setViewContent(image.url);
                    setViewType('video')
                  }}
                />

{image.promo_approved === null &&
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
               <img
                  src={checkoff}
                    style={{
                      width: '30px',
                      //background: 'lightgrey',
                      //border: '1px solid #42c45b',
                      borderRadius: '5px',
                      margin: '5px', // добавление небольшого отступа
                    }}
                    onClick={() => sendVerificationRequest(item.id, image.url, 'promo', 'video', 1)}
                    //onClick={() => sendVerificationRequest2(item.id)}
                  />
                  <img
                    src={crossoff}
                    style={{
                      width: '30px',
                      //background: '#f0253d',
                      //border: '1px solid #c44242',
                      borderRadius: '5px',
                      margin: '5px', // добавление небольшого отступа
                    }}
                    onClick={() => sendVerificationRequest(item.id, image.url, 'avatar', 'image', 0)}
              /></div>}

{image.promo_approved === true &&
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
               <img
                  src={checkon}
                    style={{
                      width: '30px',
                      //background: 'lightgrey',
                      //border: '1px solid #42c45b',
                      borderRadius: '5px',
                      margin: '5px', // добавление небольшого отступа
                    }}
                  />
                  <img
                    src={crossoff}
                    onClick={() => sendVerificationRequest(item.id, image.url, 'promo', 'video', 0)}
                    style={{
                      width: '30px',
                      //background: '#f0253d',
                      //border: '1px solid #c44242',
                      borderRadius: '5px',
                      margin: '5px', // добавление небольшого отступа
                    }}
              /></div>}
    
    
    {image.promo_approved === false &&
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
               <img
                  src={checkoff}
                  onClick={() => sendVerificationRequest(item.id, image.url, 'promo', 'video', 1)}
                    style={{
                      width: '30px',
                      //background: 'lightgrey',
                      //border: '1px solid #42c45b',
                      borderRadius: '5px',
                      margin: '5px', // добавление небольшого отступа
                    }}
                  />
                  <img
                    src={crosson}
                    style={{
                      width: '30px',
                      //background: '#f0253d',
                      //border: '1px solid #c44242',
                      borderRadius: '5px',
                      margin: '5px', // добавление небольшого отступа
                    }}
              /></div>}

</div>
</>))}



    </div>
                  </td> 

                    
                    
              
                      {/* PROMO EVENING */}
                   <td style={coverStyle}>
                    <div style={{ display: 'flex', overflowY: 'auto'  }}>
                    {item.images
      .filter((image) => image.type === 'promo_evening_1_1') // Filter images with type 'promo'
      .map((image, imageIndex) => (<>

                      <div style={{ border: '1px solid #00000040', borderRadius: '5px', marginRight: '10%' }}>

                <img
                  key={imageIndex}
                  src={image.cover}
                  alt={`Promo Cover ${imageIndex + 1}`}
                  style={{ width: '200px', margin: '5px' }}
                  onClick={() => {
                    setView(true);
                    setViewContent(image.url);
                    setViewType('video')
                  }}
                />

{image.promo_approved === null &&
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
               <img
                  src={checkoff}
                    style={{
                      width: '30px',
                      //background: 'lightgrey',
                      //border: '1px solid #42c45b',
                      borderRadius: '5px',
                      margin: '5px', // добавление небольшого отступа
                    }}
                    onClick={() => sendVerificationRequest(item.id, image.url, 'promo', 'video', 1)}
                    //onClick={() => sendVerificationRequest2(item.id)}
                  />
                  <img
                    src={crossoff}
                    style={{
                      width: '30px',
                      //background: '#f0253d',
                      //border: '1px solid #c44242',
                      borderRadius: '5px',
                      margin: '5px', // добавление небольшого отступа
                    }}
                    onClick={() => sendVerificationRequest(item.id, image.url, 'avatar', 'image', 0)}
              /></div>}

{image.promo_approved === true &&
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
               <img
                  src={checkon}
                    style={{
                      width: '30px',
                      //background: 'lightgrey',
                      //border: '1px solid #42c45b',
                      borderRadius: '5px',
                      margin: '5px', // добавление небольшого отступа
                    }}
                  />
                  <img
                    src={crossoff}
                    onClick={() => sendVerificationRequest(item.id, image.url, 'promo', 'video', 0)}
                    style={{
                      width: '30px',
                      //background: '#f0253d',
                      //border: '1px solid #c44242',
                      borderRadius: '5px',
                      margin: '5px', // добавление небольшого отступа
                    }}
              /></div>}
    
    
    {image.promo_approved === false &&
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
               <img
                  src={checkoff}
                  onClick={() => sendVerificationRequest(item.id, image.url, 'promo', 'video', 1)}
                    style={{
                      width: '30px',
                      //background: 'lightgrey',
                      //border: '1px solid #42c45b',
                      borderRadius: '5px',
                      margin: '5px', // добавление небольшого отступа
                    }}
                  />
                  <img
                    src={crosson}
                    style={{
                      width: '30px',
                      //background: '#f0253d',
                      //border: '1px solid #c44242',
                      borderRadius: '5px',
                      margin: '5px', // добавление небольшого отступа
                    }}
              /></div>}

</div>
</>))}



    </div>
                    </td>

                    
                    
                  </tr>  

    </tbody>
    </table>

    </div>))}

      {!loading && (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
          <button className="remove-button" style={{ backgroundColor: '#6D33FF' }} onClick={handlePreviousPage}>
            &lt; Previous
          </button>
          <input
            className="remove-button"
            style={{
              backgroundColor: '#FFF',
              width: '40px',
              color: 'black',
              textAlign: 'center',
            }}
            type="number"
            value={inputPage}
            onChange={(e) => setInputPage(parseInt(e.target.value))}
            onKeyPress={handleKeyPress}
          />
          <button className="remove-button" style={{ backgroundColor: '#6D33FF' }} onClick={handleNextPage}>
            Next &gt;
          </button>
        </div>
      )}

      <br />


    </>
  );
}

export default MyTable;
