import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import './style.css'

const Login = () => {
  const history = useHistory();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleLogin = async (e) => {
    e.preventDefault();
    const response = await fetch('https://admin.golive.mobi/loginback', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ username, password }),
    });
  
    if (response.ok) {
      // Successful login - perform necessary actions
      localStorage.setItem('token', '64328259fd7df5e7a6f1707f5654fa3e42917');
      // Redirect user to the "/models" page and refresh the page
      window.location.href = '/models';
    } else {
      // Handle login error
      // setErrorMessage('Incorrect account information.');
    }
  };
  

  return (
    <form className="login-form" onSubmit={handleLogin}>
      <input
        className="login-input"
        type="text"
        placeholder="Username"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
      />
      <input
        className="login-input"
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <button className="login-button" type="submit">Login</button>
      {errorMessage && <p className="error-message">{errorMessage}</p>}
    </form>
  );
};

export default Login;
