import React, { useState, useEffect, useRef } from 'react';
import { StyleSheet, css } from "aphrodite/no-important";
import Table from "./TableActivity_models"
import ModelButton from './Snapshot_fastcheck.jsx'
import Snap_random_fake from './snapshots/Snapshot_random_fake.jsx'
import Snap_random_real from './snapshots/Snapshot_random_real.jsx'
import Snap_private_fake from './snapshots/Snapshot_private_fake.jsx'
import Snap_private_real from './snapshots/Snapshot_private_real.jsx'
import Snap_banned from './snapshots/Snapshot_banned.jsx'

import Snap_banned_man from './snapshots/Snapshot_banned_man.jsx'
import Snap_history from './snapshots/Snapshot_history_banned.jsx'
import Sreal from './snapshots/Snapshot_random_real_man.jsx'
import Sfake from './snapshots/Snapshot_random_fake_man.jsx'
import axios from 'axios';


const styles = StyleSheet.create({
	h1: {
		fontSize: "7.5em",
		margin: "15px 0px",
		fontWeight: "bold",
	},
	h2: {
		fontWeight: "bold",
	},
});
const Models = () => {

	const [checkedFolders, setCheckedFolders] = useState(0);
	const [totalFolders, setTotalFolders] = useState(0);
	const [one, setOne] = useState(false);
	const [two, setTwo] = useState(true);
	const [three, setThree] = useState(false);
	const [four, setFour] = useState(false);
	const [five, setFive] = useState(false);
	const [six, setSix] = useState(false);
	const [seven, setSeven] = useState(false);
	const [eight, setEight] = useState(false);
	const [nine, setNine] = useState(false);
	const [ten, setTen] = useState(false);
	const [ folders_with_id_checked, setFolders_with_id_checked ] = useState(0)		
	const [ folders_with_id_random, setFolders_with_id_random ] = useState(0)		
	const [ folders_with_id_private, setFolders_with_id_private ] = useState(0)		
	const [ folders_with_id_random_checked, setFolders_with_id_random_checked ] = useState(0)		
	const [ folders_with_id_private_checked, setFolders_with_id_private_checked ] = useState(0)		
	
	const toggleOne = () => {
		setOne(!one)
		setTwo(false)
		setThree(false)
		setFour(false)
		setFive(false)
		setSix(false)
		setSeven(false)
		setEight(false)
		setNine(false)
		setTen(false)
	  };
	
	  const toggleTwo = () => {
		setTwo(!two)
		setOne(false)
		setThree(false)
		setFour(false)
		setFive(false)
		setSix(false)
		setSeven(false)
		setEight(false)
		setNine(false)
		setTen(false)
	  };
	
	  const toggleThree = () => {
		setThree(!three)
		setTwo(false)
		setOne(false)
		setFour(false)
		setFive(false)
		setSix(false)
		setSeven(false)
		setEight(false)
		setNine(false)
		setTen(false)
	  };
	  const toggleFour = () => {
		setFour(!four)
		setThree(false)
		setTwo(false)
		setOne(false)
		setFive(false)
		setSix(false)
		setSeven(false)
		setEight(false)
		setNine(false)
		setTen(false)
	  };
	  const toggleFive = () => {
		setFour(false)
		setThree(false)
		setTwo(false)
		setOne(false)
		setSix(false)
		setFive(!five)
		setSeven(false)
		setEight(false)
		setNine(false)
		setTen(false)
	  };
	  const toggleSix = () => {
		setFour(false)
		setThree(false)
		setTwo(false)
		setOne(false)
		setFive(false)
		setSix(!six)
		setSeven(false)
		setEight(false)
		setNine(false)
		setTen(false)
	  };
	  const toggleSeven = () => {
		setFour(false)
		setThree(false)
		setTwo(false)
		setOne(false)
		setFive(false)
		setSix(false)
		setSeven(!seven)
		setEight(false)
		setNine(false)
		setTen(false)
	  };
	  const toggleEight = () => {
		setFour(false)
		setThree(false)
		setTwo(false)
		setOne(false)
		setFive(false)
		setSix(false)
		setSeven(false)
		setEight(!eight)
		setNine(false)
		setTen(false)
	  };
	  const toggleNine = () => {
		setFour(false)
		setThree(false)
		setTwo(false)
		setOne(false)
		setFive(false)
		setSix(false)
		setSeven(false)
		setEight(false)
		setNine(!nine)
		setTen(false)
	  };
	  const toggleTen = () => {
		setFour(false)
		setThree(false)
		setTwo(false)
		setOne(false)
		setFive(false)
		setSix(false)
		setSeven(false)
		setEight(false)
		setNine(false)
		setTen(!ten)
	  };

	  const fetchData = async () => {
		try {
		  const response = await axios.get('https://apilive.golive.mobi/count_v2');
		  const { 
			fake_random_calls_count,
			random_calls_count,
			fake_private_calls_count,
			private_calls_count,
		 } = response.data;
		  setFolders_with_id_random(fake_random_calls_count);
		  setFolders_with_id_private(random_calls_count);
		  setFolders_with_id_random_checked(fake_private_calls_count);
		  setFolders_with_id_private_checked(private_calls_count);
		} catch (error) {
		  console.error('Error fetching data:', error);
		}
	  };


	
	  useEffect(() => {
		const interval = setInterval(() => {
		  fetchData();
		}, 5000);
		return () => clearInterval(interval);
	  }, []);

	return (
		<div class="col-md-6 align-self-center" style={{ marginTop: '1%'}}>



<div style={{ marginTop: '1%', marginBottom: '1%' }}>
<button
        className={`button ${one ? 'active-button' : 'inactive-button'}`}
        onClick={toggleOne}
      >
        Fake Random Call
      </button>
      <button
        className={`button ${two ? 'active-button' : 'inactive-button'}`}
        onClick={toggleTwo}
      >
        Real Random Call
      </button>
	  {/*<button
        className={`button ${eight ? 'active-button' : 'inactive-button'}`}
        onClick={toggleEight}
      >
        Real Random Call Man
	</button>*/}
      <button
        className={`button ${nine ? 'active-button' : 'inactive-button'}`}
        onClick={toggleNine}
      >
        Fake Random Call Man
      </button>
      <button
        className={`button ${three ? 'active-button' : 'inactive-button'}`}
        onClick={toggleThree}
      >
        Fake Private Call
      </button>
      <button
        className={`button ${four ? 'active-button' : 'inactive-button'}`}
        onClick={toggleFour}
      >
        Real Private Call
      </button>
	  <button
        className={`button ${five ? 'active-button' : 'inactive-button'}`}
        onClick={toggleFive}
      >
        Banned woman
      </button>
	  <button
        className={`button ${seven ? 'active-button' : 'inactive-button'}`}
        onClick={toggleSeven}
      >
        Banned man
      </button>
	  {/*<button
        className={`button ${ten ? 'active-button' : 'inactive-button'}`}
        onClick={toggleTen}
      >
        Ban history (woman)
</button>*/}
      </div>

{/*<ModelButton  />*/}

<p style={{
	    marginLeft: '1.5%',
		fontWeight: 600,
}}>
       
	{/*   Unverified series: (Testing on bugs)
	   <br />Random: 
		Fake call: {folders_with_id_random} |
		Real call: {folders_with_id_private / 2} <br /> Private: 
		Fake call: {folders_with_id_random_checked} |
Real call: {folders_with_id_private_checked / 2} */}
		  The counter is being repaired for bugs
        
      </p>

{one && (
	<Snap_random_fake />
)}

{two && (
<Snap_random_real />
)}

{three && (
	<Snap_private_fake />
	)}

{four && (
	<Snap_private_real />
	)}

{five && (
	<Snap_banned />
	)}

{six && (
	<Snap_history />
	)}

{seven && (
	<Snap_banned_man />
	)}

{eight && (
	<Sreal />
	)}

{nine && (
	<Sfake />
	)}	

	



		</div>
	);
};



export default Models;
