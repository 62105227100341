import React, { useState, useEffect } from 'react';

const SecretComponent = () => {
  const [upArrowClicks, setUpArrowClicks] = useState(0);
  const [downArrowClicks, setDownArrowClicks] = useState(0);
  const [digitSixClicked, setDigitSixClicked] = useState(false);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'ArrowUp') {
        setUpArrowClicks(prevCount => prevCount + 1);
      } else if (event.key === 'ArrowDown') {
        setDownArrowClicks(prevCount => prevCount + 1);
      } else if (event.key === '6') {
        setDigitSixClicked(true);
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [upArrowClicks, downArrowClicks, digitSixClicked]);

  useEffect(() => {
    if (upArrowClicks === 2 && downArrowClicks === 2 && digitSixClicked) {
      window.open('https://admin.golive.mobi/eggpage', '_blank');
      setUpArrowClicks(0);
      setDownArrowClicks(0);
      setDigitSixClicked(false);
    }
  }, [upArrowClicks, downArrowClicks, digitSixClicked]);

  return null; // Этот компонент является скрытым и не отображается на странице
};

export default SecretComponent;
