import React, { useState, useEffect } from "react";
import { StyleSheet, css } from "aphrodite/no-important";
import Table from "./TableActivity_models";
import ModelButton from './ModelButton';
import axios from 'axios';

const styles = StyleSheet.create({
    h1: {
        fontSize: "7.5em",
        margin: "15px 0px",
        fontWeight: "bold",
    },
    h2: {
        fontWeight: "bold",
    },
});

const Models = () => {
    const [onlineCount, setOnlineCount] = useState(0);
    const [backgroundCount, setBackgroundCount] = useState(0);
    const [busyCount, setBusyCount] = useState(0);
    const [offlineCount, setOfflineCount] = useState(0);
    const [snoozeCount, setSnoozeCount] = useState(0);
    const [message, setMessage] = useState(""); // Состояние для хранения текста сообщения
    const [statusMessage, setStatusMessage] = useState("");
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [fileInputKey, setFileInputKey] = useState(0); // Add a key to reset the file input
    const [selectedLanguage, setSelectedLanguage] = useState("all"); // Изначально выбран вариант "All languages"
    const [userCount, setUserCount] = useState(0);
    const [totalUsers, setTotalUsers] = useState(0);
    const [ruUsers, setRuUsers] = useState(0);
    const [enUsers, setEnUsers] = useState(0);
    const [esUsers, setEsUsers] = useState(0);

    const handleFileUpload = (e) => {
        const newFiles = [...uploadedFiles, ...e.target.files];
        setUploadedFiles(newFiles);
        // Reset the file input to clear the selected files
        setFileInputKey((prevKey) => prevKey + 1);
    };

    const removeFile = (index) => {
        const newFiles = [...uploadedFiles];
        newFiles.splice(index, 1);
        setUploadedFiles(newFiles);
    };
    
    const onFileDrop = (e) => {
        e.preventDefault();
        const newFiles = [...uploadedFiles];

        for (let i = 0; i < e.dataTransfer.files.length; i++) {
            const file = e.dataTransfer.files[i];
            newFiles.push(file);
        }

        setUploadedFiles(newFiles);
    };

    const sendMessage = async () => {
        if (message.trim() === "") {
          alert("Пожалуйста, введите сообщение.");
          return;
        }
      
        try {
          const formData = new FormData();
          formData.append('message', message);
      
          // Append uploaded files to the FormData
          uploadedFiles.forEach((file) => {
            formData.append('media', file);
          });
      
          let url = "https://admin.golive.mobi/839ru3epwos/dufw90qfu/fei90wj/fi3920jopve/fu3902jgi0/feu20j2evmkjp";
      
          if (selectedLanguage !== "all") {
            url = `https://admin.golive.mobi/839ru3epwos/${selectedLanguage}`;
          }
      
          const response = await axios.post(
            url,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: "Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d",
              },
            }
          );
      
          if (response.status === 200) {
            setStatusMessage("Сообщение успешно отправлено");
            setMessage("");
            setUploadedFiles([]); // Очистка загруженных файлов
          } else {
            setStatusMessage("Ошибка во время отправки");
          }
      
          setTimeout(() => {
            setStatusMessage("");
          }, 5000);
        } catch (error) {
          console.error("Ошибка отправки сообщения:", error);
          setStatusMessage("Ошибка во время отправки");
        }
      };
      
      
    

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch("https://golive.mobi/api/user/m-list/", {
                    method: "POST",
                    headers: {
                        Authorization: "Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d",
                    },
                });
                const data = await response.json();

                // Filter out models where bot is equal to 1 or 2
                const filteredData = data.filter(model => model.bot !== 1 && model.bot !== 2);

                const statusCounts = {
                    online: 0,
                    online_background: 0,
                    busy: 0,
                    offline: 0,
                    snooze: 0,
                };
                filteredData.forEach((model) => {
                    statusCounts[model.status]++;
                });
                setOnlineCount(statusCounts.online);
                setBackgroundCount(statusCounts.online_background);
                setBusyCount(statusCounts.busy);
                setOfflineCount(statusCounts.offline);
                setSnoozeCount(statusCounts.snooze);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        const fetchDataInterval = setInterval(fetchData, 2000); // Fetch data every 5 seconds
        fetchData(); // Initial fetch when the component mounts

        return () => {
            clearInterval(fetchDataInterval); // Cleanup interval on component unmount
        };
    }, []);


    useEffect(() => {
      axios.get("https://admin.golive.mobi/user-count", {
        headers: {
          Authorization: "Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d",
        },
      })
      .then((response) => {
        if (response.status === 200) {
          const { total_count, ru_count, en_count, es_count } = response.data;
          setTotalUsers(total_count);
          setRuUsers(ru_count);
          setEnUsers(en_count);
          setEsUsers(es_count);
        } else {
          console.error("Ошибка при получении количества пользователей");
        }
      })
      .catch((error) => {
        console.error("Ошибка при запросе /user-count:", error);
      });
    }, []);

    return (
        <div className="col-md-6 align-self-center">
            <h2 style={{ fontFamily: 'inter', marginLeft: '1%' }}>Liveagency Bot</h2>
            <hr style={{ border: "1px solid rgba(0, 0, 0, 0.05)", marginTop: '1%', marginBottom: '1%' }} />
            <p style={{ marginLeft: '1%' }}>
                <span style={{ fontFamily: 'inter' }}>Model counter:</span>
                <span style={{ fontFamily: 'inter', marginLeft: '1%' }}>Online:</span> {onlineCount}
                <span style={{ fontFamily: 'inter', marginLeft: '1%' }}>Background:</span> {backgroundCount}
                <span style={{ fontFamily: 'inter', marginLeft: '1%' }}>Busy:</span> {busyCount}
                <span style={{ fontFamily: 'inter', marginLeft: '1%' }}>Offline:</span> {offlineCount + snoozeCount}
            </p>
            <hr style={{ border: "1px solid rgba(0, 0, 0, 0.05)", marginTop: '1%', marginBottom: '1%' }} />
            <p style={{ marginLeft: '1%' }}>

                <p>
        <span style={{ fontFamily: 'inter' }}>Number of users who launched the bot:</span> {totalUsers}<br /> <span style={{ fontFamily: 'inter' }}>Russian user:</span> {ruUsers}<br /> <span style={{ fontFamily: 'inter' }}>English user:</span> {enUsers}<br />
        <span style={{ fontFamily: 'inter' }}>Spanish user:</span> {esUsers}
      </p>


            </p>
            <h3 style={{ marginLeft: '1%' }}>Send a message to Private Messages:</h3>
            <textarea
              style={{ marginLeft: '1%', width: '600px', height: '200px' }}
              placeholder="Enter the message here..."
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            ></textarea>
            <input
              type="file"
              key={fileInputKey}
              accept="image/jpeg, image/png, image/gif, video/mp4"
              onChange={handleFileUpload}
              style={{ marginLeft: '1%' }}
            />
            <div style={{ marginLeft: '1%' }}>
              {uploadedFiles.map((file, index) => (
                <div key={index} style={{ marginBottom: '8px' }}>
                  <span>{file.name}</span>
                  <button style={{ width: '50px', backgroundColor: '#afafaf' }} className="remove-button" onClick={() => removeFile(index)}>❌</button>
                </div>
              ))}
            </div>

            <div style={{ marginLeft: '1%'}}>
  <label  htmlFor="languageSelect">Select the language to send:</label>
  <select  style={{ marginLeft: '1%'}}
    id="languageSelect"
    value={selectedLanguage}
    onChange={(e) => setSelectedLanguage(e.target.value)}
  >
    <option value="all">All languages</option>
    <option value="ru">Russian language</option>
    <option value="en">English language</option>
    <option value="es">Spanish language</option>
  </select>
</div>


            <button className="remove-button" style={{ width: '90px' , marginLeft: '1%' }} onClick={sendMessage}>Send</button>
            {statusMessage && <p style={{ marginLeft: '1%' }}>{statusMessage}</p>}
          </div>
        );
      };
      
      export default Models;
