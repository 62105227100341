import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Oval } from 'react-loader-spinner';
import Modal from 'react-modal';

const Noface = () => {

  const language = "eng"

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedAction, setSelectedAction] = useState(null);
  const [selectedReasons, setSelectedReasons] = useState([]);
  const [videoDurations, setVideoDurations] = useState({});

  const reasonsList = [
    { value: "poorCameraQuality", text: { eng: "Poor Camera Quality", ru: "Плохое качество камеры", es: "Mala calidad de cámara" } },
    { value: "poorLighting", text: { eng: "Poor Lighting", ru: "Плохое освещение", es: "Mala iluminación" } },
    { value: "untidyAppearance", text: { eng: "Untidy Appearance", ru: "Неухоженный внешний вид", es: "Apariencia descuidada" } },
    { value: "poorBackground", text: { eng: "Poor Background", ru: "Плохой задний фон", es: "Fondo pobre" } },
    { value: "cameraMustBeFixed", text: { eng: "Camera Must Be Fixed", ru: "Камера должна быть зафиксирована", es: "La cámara debe estar fija" } },
    { value: "directLightSourceAtFace", text: { eng: "Direct Light Source At Face", ru: "Направьте источник света на лицо", es: "Dirige la fuente de luz hacia la cara" } },
    { value: "doesNotComplyWithRules", text: { eng: "Does Not Comply With Rules", ru: "Не соответствует правилам", es: "No cumple con las reglas" } },
    { value: "presenceOfBackgroundNoise", text: { eng: "Presence Of Background Noise", ru: "Наличие посторонних звуков", es: "Presencia de ruido de fondo" } },
    { value: "presenceOfOthersInFrame", text: { eng: "Presence Of Others In Frame", ru: "Наличие посторонних в кадре", es: "Presencia de otros en el cuadro" } },
    { value: "activityDoesNotMatch", text: { eng: "Activity Does Not Match", ru: "Не соответствие активности", es: "La actividad no coincide" } },
    { value: "inappropriateBehavior", text: { eng: "Inappropriate Behavior", ru: "Недопустимое поведение", es: "Comportamiento inapropiado" } },
    { value: "absenceInFrame", text: { eng: "Absence In Frame", ru: "Отсутствие в кадре", es: "Ausencia en el cuadro" } }
  ];
  

  const toggleReason = (value) => {
    setSelectedReasons((currentReasons) => {
      if (currentReasons.includes(value)) {
        // Если причина уже выбрана, убираем её из массива выбранных причин
        return currentReasons.filter((reason) => reason !== value);
      } else {
        // Если причина не выбрана, добавляем её в массив выбранных причин
        return [...currentReasons, value];
      }
    });
  };
  
  const loadDuration = async (filename) => {
    try {
      const response = await axios.get(`https://streams.golive.mobi/duration/`, { params: { filename } });
      return response.data.dur;
    } catch (error) {
      console.error('Error loading video duration:', error);
      return null;
    }
  };
  

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post('https://golive.mobi/api/stream/preview/list2/', {
        page: currentPage,
        success: 3
      }, {
        headers: {
          Authorization: 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d'
        }
      });
      const listWithDurations = await Promise.all(response.data.list.map(async (item) => {
        const durations = await Promise.all(item.previews_list.map(async (preview) => {
          const filename = preview.url.replace('/recorded_streams/', '/recorded_streams/txt/').replace('.mp4', '.txt').split('/').pop();
          const duration = await loadDuration(filename);
          return {...preview, duration};
        }));
        return {...item, previews_list: durations};
      }));
      setData(listWithDurations);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsLoading(false);
    }
  };
  

  useEffect(() => {
    fetchData();
  }, [currentPage]);

  const handleApproveClick = (userId) => {
    setSelectedUserId(userId);
    setSelectedAction(1); // 1 for Approve
    setShowModal(true);
  };
  
  const handleRejectClick = (userId) => {
    setSelectedUserId(userId);
    setSelectedAction(0); // 0 for Reject
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setSelectedReasons([]);
    setShowModal(false);
  };
  
  
  const handleAction = async (userId, value, reasons) => {
    try {
      await axios.post('https://golive.mobi/api/stream/enable/', {
        id: userId,
        value: value,
        reason: reasons
      }, {
        headers: {
          Authorization: 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d'
        }
      });
  
      
      setSelectedReasons([]);
      fetchData(); // Refresh data after action
    } catch (error) {
      console.error('Error performing action: ', error);
      alert('Failed to perform action.');
    }
  };

  const handleVideoClick = (url) => {
    window.open(url, '_blank');
  };

  const handlePrevPage = () => {
    setCurrentPage(currentPage > 1 ? currentPage - 1 : 1);
  };

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp * 1000); // Convert timestamp to milliseconds
    return date.toLocaleDateString(); // Format date as locale date string
  };

  const handleReasonSubmit = async () => {
    // Проверяем, была ли выбрана какая-либо причина

  
    try {
      // Переводим значения причин в их индексы, начиная с 1
      const selectedReasonIndexes = selectedReasons.map((reason) => {
        return reasonsList.findIndex((item) => item.value === reason) + 1;
      });
  
      // Вызываем handleAction в зависимости от выбранной акции (Approve или Reject)
      await handleAction(selectedUserId, selectedAction, selectedReasonIndexes);
      // После успешного завершения действия закрываем модальное окно
      setShowModal(false);
      fetchData();
    } catch (error) {
      console.error('Error performing action: ', error);
      alert('Failed to perform action.');
    }
  };
    
  
  

  return (
    <div style={styles.tableContainer}>
      <table style={styles.table}>
        <thead>
          <tr>
            <th style={{ ...styles.headerCell, width: '10%' }}>User ID</th>
            <th style={{ ...styles.headerCell, width: '50%' }}>Videos</th>
            <th style={{ ...styles.headerCell, width: '10%' }}>Numbers of reject</th>
            <th style={{ ...styles.headerCell, width: '30%' }}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <tr>
              <td colSpan="4" style={{ textAlign: 'center' }}>
                <Oval color="#00BFFF" height={80} width={80} />
              </td>
            </tr>
          ) : (
            data.map((item) => (
              <tr key={item.id}>
                <td style={{ ...styles.cell, width: '10%' }}>{item.id}</td>
                <td style={{ ...styles.cell, width: '50%' }}>
                  <div style={styles.videoContainer}>
                    {item.previews_list.map((preview) => (
                      <div key={preview.preview_id} style={styles.videoWrapper}>
                      <img style={styles.video} onClick={() => handleVideoClick(preview.url)} src={preview.url.replace('/recorded_streams/', '/recorded_streams/thumb/').replace('.mp4', '.jpg')}
 />
                       
                        
  <div style={styles.date}>{formatDate(preview.date)}<br />
  Dur: {preview.duration}</div>


                      </div>
                    ))}
                  </div>
                </td>
                <td style={{ ...styles.cell, width: '10%' }}>{item.count_disapprove}</td>
                <td style={{ ...styles.cell, width: '30%' }}>
                  <button onClick={() => handleAction(item.id, -1)} style={styles.banButton}>Total ban</button><br />
                  {/*<button onClick={() => handleAction(item.id, 1)} style={styles.approveButton}>Approve</button><br />
                  <button onClick={() => handleAction(item.id, 0)} style={styles.rejectButton}>Reject</button>*/}

<button onClick={() => handleApproveClick(item.id)} style={styles.approveButton}>
  Approve
</button>
<br />
<button onClick={() => handleRejectClick(item.id)} style={styles.rejectButton}>
  Reject
</button>


                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
      <div style={styles.paginationContainer}>
        <button onClick={handlePrevPage} style={styles.pageButton} disabled={currentPage === 1}>Назад</button>
        <span style={styles.pageIndicator}>Страница {currentPage}</span>
        <button onClick={handleNextPage} style={styles.pageButton}>Далее</button>
      </div>


      <Modal
  isOpen={showModal}
  onRequestClose={handleCloseModal}
  contentLabel="Action Modal"
  style={{
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    content: {
      width: '500px',
      height: 'auto',
      maxHeight: '90%', // Prevent modal from being too tall
      overflow: 'auto', // Enable scrolling within the modal if needed
      margin: 'auto',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
  }}
>
  <div>

  <button onClick={handleCloseModal} style={{ width: '100%', padding: '10px', backgroundColor: '#d9534f', color: 'white', border: 'none', textAlign: 'center', fontWeight: 'bold', fontSize: '16px', cursor: 'pointer' }}>
  Close
</button>

    <h2>Provide Reason</h2>
 
  </div>



  <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px' }}>
  {reasonsList.map(({ value, text }) => (
    <label key={value} style={{
      margin: '5px', 
      padding: '5px', 
      display: 'inline-block', 
      background: '#f0f0f0', 
      borderRadius: '10px', 
      border: '1px solid #ccc', 
      alignItems: 'center'
    }}>
      <input
        type="checkbox"
        checked={selectedReasons.includes(value)}
        onChange={() => toggleReason(value)}
        style={{ marginRight: '5px' }}
      />
      {text[language]}
    </label>
  ))}
</div>


 

  <div>
    <button onClick={handleReasonSubmit} style={{ width: '100%' }}>
      Submit
    </button>
  </div>
</Modal>

    </div>
  );
};

const styles = {
  tableContainer: {
    overflowX: 'auto',
    textAlign: 'center'
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    margin: 'auto'
  },
  headerCell: {
    padding: '8px',
    textAlign: 'left',
    borderBottom: '1px solid #ddd'
  },
  cell: {
    padding: '8px',
    borderBottom: '1px solid #ddd',
    maxWidth: '200px',
    overflow: 'hidden'
  },
  videoContainer: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    gap: '10px',
    overflowX: 'auto',
    padding: '8px',
    width: '100%'
  },
  videoWrapper: {
    position: 'relative'
  },
  video: {
    width: '100px',
    height: 'auto',
    display: 'block'
  },
  date: {
    position: 'absolute',
    bottom: '5px',
    left: '5px',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    color: 'white',
    padding: '2px 5px',
    borderRadius: '3px'
  },
  approveButton: {
    padding: '6px 12px',
    backgroundColor: '#28a745',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    marginRight: '5px',
    marginBottom: '5px'
  },
  rejectButton: {
    padding: '6px 12px',
    backgroundColor: '#dc3545',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    marginRight: '5px',
    marginBottom: '5px'
  },
  banButton: {
    padding: '6px 12px',
    backgroundColor: '#ff0000',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    marginRight: '5px',
    marginBottom: '5px'
  },
  paginationContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px'
  },
  pageButton: {
    padding: '10px 20px',
    margin: '0 10px',
    backgroundColor: '#007bff',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer'
  },
  pageIndicator: {
    padding: '10px 20px',
    fontSize: '16px'
  }
};

export default Noface;
