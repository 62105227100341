import React, { useState, useEffect, useCallback } from 'react';
import './style.css';
import { useHistory } from 'react-router-dom';
import { Oval } from  'react-loader-spinner'

const TableGeneral = () => {
  const history = useHistory();

  const handleClick = () => {
    history.push('/modelcreate');
  };

  const [showForm, setShowForm] = useState(false);
  const [loading, setLoading] = useState(true);
  const toggleForm = () => {
    setShowForm(!showForm);
  };
  const [ page, setPage ] = useState(1)
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData();
  }, [page]);

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const response = await fetch('https://golive.mobi/api/user/m-list/pag/', {
        method: 'POST',
        headers: {
          Authorization: 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
          'Content-Type': 'application/json; charset=UTF-8',
        },
        body: JSON.stringify({
          page: page,
          page_size: 20
        }),
      });

      if (response.ok) {
        setLoading(false);
        const responseData = await response.json();
        setData(responseData);
      } else {
        console.log('Error:', response.status);
      }
    } catch (error) {
      console.log('Error:', error);
    }
  }, [page]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);


  

  // Test data for the table
  const initialData = [
    // Add more test data here...
  ];

  const [selectAll, setSelectAll] = useState(false);

  const toggleSelectAll = () => {
    setSelectAll(!selectAll);
    const updatedData = data.map((item) => ({ ...item, selected: !selectAll }));
    setData(updatedData);
  };

  const toggleSelectItem = (id) => {
    const updatedData = data.map((item) => {
      if (item.id === id) {
        return { ...item, selected: !item.selected };
      }
      return item;
    });
    setData(updatedData);
  };

  function formatTimestamp(timestamp) {
    if (!timestamp) {
      return '';
    }

    const dateObj = new Date(timestamp);

    if (isNaN(dateObj)) {
      return 'Invalid timestamp';
    }

    const year = dateObj.getFullYear();
    const month = ('0' + (dateObj.getMonth() + 1)).slice(-2);
    const day = ('0' + dateObj.getDate()).slice(-2);
    const hours = ('0' + dateObj.getHours()).slice(-2);
    const minutes = ('0' + dateObj.getMinutes()).slice(-2);

    return `${day}.${month}.${year} ${hours}:${minutes}`;
  }

  function formatTimestampReg(timestamp) {
    if (!timestamp) {
      return '';
    }

    const dateObj = new Date(Number(timestamp));

    if (isNaN(dateObj)) {
      return 'Invalid timestamp';
    }

    const year = dateObj.getFullYear();
    const month = ('0' + (dateObj.getMonth() + 1)).slice(-2);
    const day = ('0' + dateObj.getDate()).slice(-2);
    const hours = ('0' + dateObj.getHours()).slice(-2);
    const minutes = ('0' + dateObj.getMinutes()).slice(-2);

    return `${day}.${month}.${year} ${hours}:${minutes}`;
  }

  function formatDate(timestamp) {
    if (!timestamp) {
      return '';
    }
  
    const dateObj = new Date(timestamp * 1000); // Convert to milliseconds
  
    if (isNaN(dateObj)) {
      return 'Invalid timestamp';
    }
  
    const year = dateObj.getFullYear();
    const month = ('0' + (dateObj.getMonth() + 1)).slice(-2);
    const day = ('0' + dateObj.getDate()).slice(-2);
    const hours = ('0' + dateObj.getHours()).slice(-2);
    const minutes = ('0' + dateObj.getMinutes()).slice(-2);
  
    return `${day}.${month}.${year} ${hours}:${minutes}`;
  }
  

  const [selectedModelId, setSelectedModelId] = useState(null);

  const handleModelClick = useCallback(
    (id) => {
      localStorage.setItem('id_models', id); // Save ID to localStorage
      const url = `/profile_model/${id}`;
      window.open(url, '_blank'); // Open the URL in a new tab
    },
    []
  );
  
  


  
  const handleRemove = async () => {
    // Get the IDs of selected rows
    const selectedIds = data.filter((item) => item.selected).map((item) => item.id);
  
    try {
      const requestData = [];
selectedIds.forEach((id) => {
  const item = data.find((item) => item.id === id);
  if (item) {
    requestData.push({
      id,
      signed_up: item.signed_up === 1 ? 1 : 0,
    });
  }
});




      const response = await fetch('https://golive.mobi/api/user/m-delete/', {
        method: 'POST',
        headers: {
          Authorization: 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
      });
  
      if (response.ok) {
        // Handle successful response (e.g., show a success message, refresh data)
        console.log('Selected rows successfully deleted!');
        // Reload the data after successful deletion
        fetchData(); // Assuming fetchData fetches data and updates the state
      } else {
        console.log('Error:', response.status);
      }
    } catch (error) {
      console.log('Error:', error);
    }
  };
  
  
  
  const handleUnRemove = async () => {
    // Get the IDs of selected rows
    const selectedIds = data.filter((item) => item.selected).map((item) => item.id);
  
    try {
      const requestData = selectedIds.map((id) => ({ id, signed_up: 1 }));
      const response = await fetch('https://golive.mobi/api/user/m-undelete/', {
        method: 'POST',
        headers: {
          Authorization: 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
      });
  
      if (response.ok) {
        // Handle successful response (e.g., show a success message, refresh data)
        console.log('Selected rows successfully undeleted!');
        // Reload the data after successful deletion
        fetchData(); // Assuming fetchData fetches data and updates the state
      } else {
        console.log('Error:', response.status);
      }
    } catch (error) {
      console.log('Error:', error);
    }
  };
  
  const [sortedData, setSortedData] = useState([]);
  const [selectedSortOption, setSelectedSortOption] = useState('');

  const sortData = (option) => {
    let sorted = [...data];
  
    if (option === 'sortact') {
      sorted = sorted.sort((a, b) => (a.activity?.last_active < b.activity?.last_active ? 1 : -1));
    } else if (option === 'sortf1') {
      sorted = sorted.sort((a, b) => (a.profile_stat?.following < b.profile_stat?.following ? 1 : -1));
    } else if (option === 'sortf2') {
      sorted = sorted.sort((a, b) => (a.profile_stat?.followers < b.profile_stat?.followers ? 1 : -1));
    } else if (option === 'sortf4') {
      sorted = sorted.sort((a, b) => (a.balance < b.balance ? 1 : -1));
    } else {
      // Custom sorting to move bot 0 to the top, bot 2 to the bottom, and bot 1 to the end
      sorted = sorted.sort((a, b) => {
        if (a.bot === 0) return -1;
        if (a.bot === 2) return 1;
        if (a.bot === 1 && b.bot !== 0 && b.bot !== 2) return 1;
 
      });
    }
  
    setSortedData(sorted);
  };
  
  useEffect(() => {
    if (selectedSortOption !== '') {
      sortData(selectedSortOption);
    } else {
      setSortedData(data); // Show unsorted data when no sorting option is selected
    }
  }, [selectedSortOption, data]);
  

  const [sortOptions, setSortOptions] = useState({
    sortact: false,
    sortf1: false,
    sortf2: false,
    sortf4: false,
    noSorting: true, // New option to handle "No sorting"
  });
  
  const handleSortOptionChange = (option) => {
    setSortOptions((prevOptions) => ({
      sortact: option === 'sortact' ? !prevOptions.sortact : false,
      sortf1: option === 'sortf1' ? !prevOptions.sortf1 : false,
      sortf2: option === 'sortf2' ? !prevOptions.sortf2 : false,
      sortf4: option === 'sortf4' ? !prevOptions.sortf4 : false,
      noSorting: option === 'noSorting' ? !prevOptions.noSorting : false,
    }));
  };
  
  useEffect(() => {
    const selectedOption = Object.keys(sortOptions).find((option) => sortOptions[option]);
    setSelectedSortOption(selectedOption);
  }, [sortOptions]);

  const handleSortOptionClick = (option) => {
    handleSortOptionChange(option);
  };

  const handleNextPage = () => {
    setPage(page + 1);
    //window.scrollTo(0, 0); // Прокрутка страницы вверх
  };
  
  const handlePreviousPage = () => {
    if (page > 1) {
      setPage(page - 1);
      //window.scrollTo(0, 0); // Прокрутка страницы вверх
    }
  };

  return (
    <div>

      
      <div>
        <button className="create-button" onClick={handleClick}>
          + New Models
        </button>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h3 style={{ marginLeft: '1%' }}></h3>
          <div style={{ display: 'flex', marginRight: '1%'}}>
          <button className="remove-button" style={{ width: '90px'}} onClick={handleRemove}>Block</button>
          <button className="remove-button" style={{ width: '90px'}} onClick={handleUnRemove}>Unblock</button>
          </div>
        </div>

      {/**  <hr style={{ border: "1px solid rgba(0, 0, 0, 0.05)", marginTop: '1%', marginBottom: '1%' }} />
        <div style={{ marginLeft: '1%', marginBottom: '1%' }}>
        <span style={{ fontWeight: '500' }}>Sort table:</span>
        <button
        style={{ borderRadius: '10px', height: '40px' }}
          className={`sort-button ${sortOptions.noSorting ? 'active-button' : 'inactive-button'}`}
          onClick={() => handleSortOptionClick('noSorting')}
        >
          No sorting
        </button>
        <button style={{ borderRadius: '10px', height: '40px' }}
          className={`sort-button ${sortOptions.sortact ? 'active-button' : 'inactive-button'}`}
          onClick={() => handleSortOptionClick('sortact')}
        >
          Activity
        </button>
        <button style={{ borderRadius: '10px', height: '40px' }}
          className={`sort-button ${sortOptions.sortf1 ? 'active-button' : 'inactive-button'}`}
          onClick={() => handleSortOptionClick('sortf1')}
        >
          Following
        </button>
        <button style={{ borderRadius: '10px', height: '40px' }}
          className={`sort-button ${sortOptions.sortf2 ? 'active-button' : 'inactive-button'}`}
          onClick={() => handleSortOptionClick('sortf2')}
        >
          Followers
        </button>
      </div>
      <hr style={{ border: "1px solid rgba(0, 0, 0, 0.05)", marginTop: '1%', marginBottom: '1%' }} /> */}

{loading && (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
  <Oval
    height={200}
    width={200}
    color="#0000FF"
    wrapperStyle={{}}
    wrapperClass=""
    visible={true}
    ariaLabel='oval-loading'
    secondaryColor="#87CEFA"
    strokeWidth={2}
    strokeWidthSecondary={2}
  />
</div>

    )}

    {!loading && (

        <table className="table">
          <thead style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.07)' }}>
            <tr>
              <th>
                <input
                  className="checkbox"
                  type="checkbox"
                  checked={selectAll}
                  onChange={toggleSelectAll}
                  style={{
                    alignItems: 'center',
                    display: 'flex',
                  }}
                />
              </th>
              <th style={{ color: 'black', fontFamily: 'Noto Sans Mono', fontWeight: '500', fontSize: '12px', textAlign: 'left' }}>ID</th>
              <th style={{ color: 'black', fontFamily: 'Noto Sans Mono', fontWeight: '500', fontSize: '12px', textAlign: 'left' }}>Login</th>
              <th style={{ color: 'black', fontFamily: 'Noto Sans Mono', fontWeight: '500', fontSize: '12px', textAlign: 'left' }}>Name</th>
              <th style={{ color: 'black', fontFamily: 'Noto Sans Mono', fontWeight: '500', fontSize: '12px', textAlign: 'left' }}>Status</th>
              <th style={{ color: 'black', fontFamily: 'Noto Sans Mono', fontWeight: '500', fontSize: '12px', textAlign: 'left' }}>Age</th>
              <th style={{ color: 'black', fontFamily: 'Noto Sans Mono', fontWeight: '500', fontSize: '12px', textAlign: 'left' }}>Gender</th>
              <th style={{ color: 'black', fontFamily: 'Noto Sans Mono', fontWeight: '500', fontSize: '12px', textAlign: 'left' }}>Geo</th>
              <th style={{ color: 'black', fontFamily: 'Noto Sans Mono', fontWeight: '500', fontSize: '12px', textAlign: 'left' }}>First login to the app</th>
              <th style={{ color: 'black', fontFamily: 'Noto Sans Mono', fontWeight: '500', fontSize: '12px', textAlign: 'left' }}>Registration in the panel</th>
              <th style={{ color: 'black', fontFamily: 'Noto Sans Mono', fontWeight: '500', fontSize: '12px', textAlign: 'left' }} onClick={() => handleSortOptionClick('sortact')}>Last activity {sortOptions.sortact ? '▼' : ''}</th>
              <th style={{ color: 'black', fontFamily: 'Noto Sans Mono', fontWeight: '500', fontSize: '12px', textAlign: 'left' }}>Signed up</th>
              <th style={{ color: 'black', fontFamily: 'Noto Sans Mono', fontWeight: '500', fontSize: '12px', textAlign: 'left' }} onClick={() => handleSortOptionClick('sortf4')}>Balance {sortOptions.sortf4 ? '▼' : ''}</th>
              <th style={{ color: 'black', fontFamily: 'Noto Sans Mono', fontWeight: '500', fontSize: '12px', textAlign: 'left' }} onClick={() => handleSortOptionClick('sortf1')}>Following {sortOptions.sortf1 ? '▼' : ''}</th>
              <th style={{ color: 'black', fontFamily: 'Noto Sans Mono', fontWeight: '500', fontSize: '12px', textAlign: 'left' }} onClick={() => handleSortOptionClick('sortf2')}>Followers {sortOptions.sortf2 ? '▼' : ''}</th>
              <th style={{ color: 'black', fontFamily: 'Noto Sans Mono', fontWeight: '500', fontSize: '12px', textAlign: 'left' }}>Premium</th>
              {/**<th style={{ color: 'black', fontFamily: 'Noto Sans Mono', fontWeight: '500', fontSize: '12px', textAlign: 'left' }}>Fan</th>
              <th style={{ color: 'black', fontFamily: 'Noto Sans Mono', fontWeight: '500', fontSize: '12px', textAlign: 'left' }}>Amount of purchases ($)</th>**/}
            </tr>
          </thead>
          <tbody>
          {sortedData
  .filter((item) => item.status !== 'suspended')
  .map((item, index) => (
              <tr
                key={item.id}
                className={`${index % 2 === 0 ? 'even-row' : 'odd-row'} ${item.selected ? 'selected-row' : 'non-selected-row'}`}
              >
                <td style={{ 
                  borderRight: '1px solid rgba(0, 0, 0, 0.07)' }}>
                  <input
                    type="checkbox"
                    checked={item.selected}
                    onChange={() => toggleSelectItem(item.id)}
                  />
                </td>
                <td style={{ color: item.selected ? '#6D33FF' : 'black', fontFamily: 'Noto Sans Mono', fontWeight: '400', fontSize: '12px', textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)' }}>
  {item.status ? (
    <a
    href={`profile_model/${item.id}`}
      style={{ color: item.selected ? '#6D33FF' : 'black', fontFamily: 'Noto Sans Mono', fontWeight: '400', fontSize: '12px', textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)' }}
      //onClick={() => handleModelClick(item.id)} // Call handleModelClick with the model ID
    >
      {item.id} {item.bot === 1 ? '🤖 ' : ''} {item.bot === 2 ? '💼 ' : ''}
    </a>
  ) : (
    <span
      style={{ color: item.selected ? '#6D33FF' : 'black', fontFamily: 'Noto Sans Mono', fontWeight: '400', fontSize: '12px', textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)' }}
    >
      {item.id} {item.bot === 1 ? '🤖 ' : ''} {item.bot === 2 ? '💼 ' : ''}
    </span>
  )}
</td>
<td style={{
                  color: item.selected ? '#6D33FF' : '#606C80',
                  fontFamily: 'Noto Sans Mono',
                  fontWeight: '400',
                  fontSize: '12px',
                  textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)'
                }}>{item.login}</td>
<td style={{ color: item.selected ? '#6D33FF' : 'black', fontFamily: 'Noto Sans Mono', fontWeight: '400', fontSize: '12px', textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)' }}>
  {item.status ? (
    <a
    href={`profile_model/${item.id}`}
      style={{ color: item.selected ? '#6D33FF' : 'black', fontFamily: 'Noto Sans Mono', fontWeight: '400', fontSize: '12px', textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)' }}
      onClick={() => handleModelClick(item.id)} // Call handleModelClick with the model ID
    >
      {item.name} 
    </a>
  ) : (
    <span
      style={{ color: item.selected ? '#6D33FF' : 'black', fontFamily: 'Noto Sans Mono', fontWeight: '400', fontSize: '12px', textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)' }}
    >
      {item.name} 
    </span>
  )}
</td>

                  <td style={{
                  color: item.selected ? '#6D33FF' : '#606C80',
                  fontFamily: 'Noto Sans Mono',
                  fontWeight: '400',
                  fontSize: '12px',
                  textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)'
                }}>{item.status}</td>
                <td style={{
                  color: item.selected ? '#6D33FF' : '#606C80',
                  fontFamily: 'Noto Sans Mono',
                  fontWeight: '400',
                  fontSize: '12px',
                  textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)'
                }}>{item.age}</td>
                <td style={{
  color: item.selected ? '#6D33FF' : '#606C80',
  fontFamily: 'Noto Sans Mono',
  fontWeight: '400',
  fontSize: '12px',
  textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)'
}}>
  {item.gender === 1 ? 'Woman' : 'Man'}
</td>

                <td style={{
                  color: item.selected ? '#6D33FF' : '#606C80',
                  fontFamily: 'Noto Sans Mono',
                  fontWeight: '400',
                  fontSize: '12px',
                  textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)'
                }}>{item.geo}</td>
<td style={{
  color: item.selected ? '#6D33FF' : '#606C80',
  fontFamily: 'Noto Sans Mono',
  fontWeight: '400',
  fontSize: '12px',
  textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)'
}}>
  {formatTimestampReg(item.timeRegister)}
</td>
<td style={{
  color: item.selected ? '#6D33FF' : '#606C80',
  fontFamily: 'Noto Sans Mono',
  fontWeight: '400',
  fontSize: '12px',
  textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)'
}}>
  {formatDate(item.timeRegister_model)}
</td>
<td style={{
  color: item.selected ? '#6D33FF' : '#606C80',
  fontFamily: 'Noto Sans Mono',
  fontWeight: '400',
  fontSize: '12px',
  textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)'
}}>
  {item.activity ? formatDate(item.activity.last_active) : ''}
</td>
<td style={{
  color: item.selected ? '#6D33FF' : '#606C80',
  fontFamily: 'Noto Sans Mono',
  fontWeight: '400',
  fontSize: '12px',
  textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)'
}}>
  {item.signed_up === 1 ? 'Yes' : 'No'}
</td>

               <td style={{
                  color: item.selected ? '#6D33FF' : '#606C80',
                  fontFamily: 'Noto Sans Mono',
                  fontWeight: '400',
                  fontSize: '12px',
                  textAlign: 'left',
                }}>{item.balance}</td>
                <td style={{
  color: item.selected ? '#6D33FF' : '#606C80',
  fontFamily: 'Noto Sans Mono',
  fontWeight: '400',
  fontSize: '12px',
  textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)'
}}>
  {item.profile_stat ? item.profile_stat.following : ''}
</td>
<td style={{
  color: item.selected ? '#6D33FF' : '#606C80',
  fontFamily: 'Noto Sans Mono',
  fontWeight: '400',
  fontSize: '12px',
  textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)'
}}>
  {item.profile_stat ? item.profile_stat.followers : ''}
</td>
<td style={{
  color: item.selected ? '#6D33FF' : '#606C80',
  fontFamily: 'Noto Sans Mono',
  fontWeight: '400',
  fontSize: '12px',
  textAlign: 'left',
}}>
  {item.premium === 1 ? 'Yes' : 'No'}
</td>

                {/**<td style={{
                  color: item.selected ? '#6D33FF' : '#606C80',
                  fontFamily: 'Noto Sans Mono',
                  fontWeight: '400',
                  fontSize: '12px',
                  textAlign: 'left',
                }}>{item.fan}</td>**/}
                {/**<td style={{
                  color: item.selected ? '#6D33FF' : '#606C80',
                  fontFamily: 'Noto Sans Mono',
                  fontWeight: '400',
                  fontSize: '12px',
                  textAlign: 'left',
                }}>{item.amountOfPurchases}</td>**/}
              </tr>
            ))}
          </tbody>
        </table>

    )}


{!loading &&
<div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
          <button className="remove-button" style={{ backgroundColor: '#6D33FF' }} onClick={handlePreviousPage}>
            &lt; Previous
          </button>
          <button className="remove-button" style={{ backgroundColor: '#FFF', width: '40px', color: 'black' }}>
            {page}
          </button>
          <button className="remove-button" style={{ backgroundColor: '#6D33FF' }} onClick={handleNextPage}>
            Next &gt;
          </button>
                </div>
}

      </div>
    </div>
  );
};

export default TableGeneral;
