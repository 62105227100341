import React, { useState, useEffect } from 'react';

import TableGeneral from './TableGeneral_ Complaints';
import TableGeneralw from './TableGeneral_ Complaints_w';
import TableGeneralun from './TableGeneral_ Complaints_unw';

import TA from './TA/TableApplication'
import TA_A from './TA/TableApplication_approved'
import TA_W from './TA/TableApplication_waiting'
import TA_R from './TA/TableApplication_rejected'
import TA_U from './TA/TableApplication_update'

import TA_P_FA from './TA/TableApplication_promo_fa_v2'
import TA_P_AA from './TA/TableApplication_promo_aa_v2'

import VStreamApproved from './VStreamApproved'
import VStreamNApproved from './VStreamNApproved'
import VStreamWait from './VStreamWait'

import PremList from './PremList'

import Findcard from './Findcard'

import Egg from './egg'

import './style.css';

const ProfileInfo = () => {
  const [showProfileInfo, setShowProfileInfo] = useState(true);
  const [showUserActivity, setShowUserActivity] = useState(false);
  const [showUserRegActivity, setShowUserRegActivity] = useState(false);
  const [showUserNoRegActivity, setShowUserNoRegActivity] = useState(false);
  const [data, setData] = useState({});
  const [one, setOne] = useState(true);
  const [two, setTwo] = useState(false);
  const [three, setThree] = useState(false);
  const [four, setFour] = useState(false);
  const [five, setFive] = useState(false);

  const [six, setSix] = useState(false);
  const [seven, setSeven] = useState(false);
  const [eight, setEight] = useState(false);

  const [ state, setState ] = useState('one')


  const toggleOne = () => {
    setOne(!one)
    setTwo(false)
    setThree(false)
    setFive(false)
    setFour(false)
    setSix(false)
  };

  const toggleTwo = () => {
    setTwo(!two)
    setOne(false)
    setThree(false)
    setFive(false)
    setFour(false)
    setSix(false)
  };

  const toggleThree = () => {
    setThree(!three)
    setTwo(false)
    setOne(false)
    setFour(false)
    setFive(false)
    setSix(false)

  };
  const toggleFour = () => {
    setFour(!four)
    setThree(false)
    setTwo(false)
    setOne(false)
    setFive(false)
    setSix(false)

  };
  const toggleFive = () => {
    setFive(!five)
    setFour(false)
    setThree(false)
    setTwo(false)
    setOne(false)
  };



  useEffect(() => {
    fetchData(); // Вызываем функцию для получения данных
    const interval = setInterval(fetchData, 5000); // Обновление данных каждые 5 секунд
  
    return () => clearInterval(interval); // Очищаем интервал при размонтировании компонента
  }, []); // Вызов при загрузке компонента
  
  const fetchData = async () => {
    try {
      const response = await fetch('https://golive.mobi/api/verif/list/count/', {
        method: 'POST', // Указываем метод POST
        headers: {
          Authorization: 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
          'Content-Type': 'application/json'
        },
        // Добавьте тело запроса, если оно необходимо
        body: JSON.stringify({ /* ваше тело запроса */ })
      });
  
      if (response.ok) {
        const result = await response.json();
        setData(result);
      } else {
        // Обработка ошибок при получении данных
        console.error('Ошибка при получении данных');
      }
    } catch (error) {
      console.error('Ошибка:', error);
    }
  };
  

  return (
    <div>


  
<h2  style={{ marginLeft: '1%', fontFamily: 'inter' }}>Verification</h2>

<div style={{ marginTop: '3%' }}>
      <button
        className={`button ${state === 'one' ? 'active-button' : 'inactive-button'}`}
        onClick={() => setState('one')}
      >
        All application ({Object.keys(data).length > 0 ? Object.values(data).filter(value => typeof value === 'number').reduce((acc, curr) => acc + curr, 0) : ''})
      </button>
      <button
        className={`button ${state === 'two' ? 'active-button' : 'inactive-button'}`}
        onClick={() => setState('two')}
      >
        Approved ({(data['1']) !== undefined ? data['1'] : ''})
      </button>
      <button
        className={`button ${state === 'three' ? 'active-button' : 'inactive-button'}`}
        onClick={() => setState('three')}
      >
        Waiting ({(data['2']) !== undefined ? data['2'] : ''})
      </button>
      <button
        className={`button ${state === 'four' ? 'active-button' : 'inactive-button'}`}
        onClick={() => setState('four')}
      >
        Rejected ({(data['0']) !== undefined ? data['0'] : ''})
      </button>
      <button
        className={`button ${state === 'five' ? 'active-button2' : 'inactive-button2'}`}
        onClick={() => setState('five')}
      >
        Update ({(data['4']) !== undefined ? data['4'] : ''})
      </button>

      

      <button
        className={`button ${state === 'six' ? 'active-button' : 'inactive-button'}`}
        onClick={() => setState('six')}
      >
        Promo for approve 
      </button>

      <button
        className={`button ${state === 'seven' ? 'active-button' : 'inactive-button'}`}
        onClick={() => setState('seven')}
      >
        Promo approved 
      </button>

      <button
        className={`button ${state === '11' ? 'active-button' : 'inactive-button'}`}
        onClick={() => setState('11')}
      >
        Premium
      </button>

      <button
        className={`button ${state === 'eight' ? 'active-button' : 'inactive-button'}`}
        onClick={() => setState('eight')}
      >
        Streamers Approved
      </button>

      <button
        className={`button ${state === 'nine' ? 'active-button' : 'inactive-button'}`}
        onClick={() => setState('nine')}
      >
        Streamers Reject
      </button>

      <button
        className={`button ${state === 'ten' ? 'active-button' : 'inactive-button'}`}
        onClick={() => setState('ten')}
      >
        Streamers Wait
      </button>

      <button
        className={`button ${state === 'findcard' ? 'active-button' : 'inactive-button'}`}
        onClick={() => setState('findcard')}
      >
        Find card
      </button>

      
    </div>

      <hr style={{ border: "1px solid rgba(0, 0, 0, 0.05)", marginTop: '2%', marginBottom: '2%' }} />

      {state === 'one' && (
        <div>
          <TA />
        </div>
      )}
      {state === 'two' && (
        <div>
          <TA_A />
        </div>
      )}
      {state === 'three' && (
        <div>
          <TA_W />
        </div>
      )}
      {state === 'four' && (
        <div>
          <TA_R />
        </div>
      )}
      {state === 'five' && (
        <div>
          <TA_U />
        </div>
      )}

    {state === 'six' && (
            <div>
              <TA_P_FA />
            </div>
          )}

    {state === 'seven' && (
            <div>
              <TA_P_AA />
            </div>
          )}

{state === 'eight' && (
            <div>
              <VStreamApproved />
            </div>
          )}

{state === 'nine' && (
            <div>
              <VStreamNApproved />
            </div>
          )}

{state === 'ten' && (
            <div>
              <VStreamWait />
            </div>
          )}

{state === '11' && (
            <div>
              <PremList />
            </div>
          )}        

          {state === 'findcard' && (
            <div>
              <Findcard />
            </div>
          )}     
        
    </div>
  );
};

export default ProfileInfo;