import React, { useState, useEffect, useRef } from 'react';
import { StyleSheet, css } from "aphrodite/no-important";
import Verification from './videos/Verification'
import Verification720 from './videos/Verification720'
import Promo from './videos/Promo'
import Cover from './videos/Cover'
import axios from 'axios';


const styles = StyleSheet.create({
	h1: {
		fontSize: "7.5em",
		margin: "15px 0px",
		fontWeight: "bold",
	},
	h2: {
		fontWeight: "bold",
	},
});
const Models = () => {

	const [checkedFolders, setCheckedFolders] = useState(0);
	const [totalFolders, setTotalFolders] = useState(0);
	const [one, setOne] = useState(true);
	const [two, setTwo] = useState(false);
	const [three, setThree] = useState(false);
	const [four, setFour] = useState(false);
	const [five, setFive] = useState(false);
	const [six, setSix] = useState(false);
	const [seven, setSeven] = useState(false);
	const [eight, setEight] = useState(false);
	const [nine, setNine] = useState(false);
	const [ folders_with_id_checked, setFolders_with_id_checked ] = useState(0)		
	const [ folders_with_id_random, setFolders_with_id_random ] = useState(0)		
	const [ folders_with_id_private, setFolders_with_id_private ] = useState(0)		
	const [ folders_with_id_random_checked, setFolders_with_id_random_checked ] = useState(0)		
	const [ folders_with_id_private_checked, setFolders_with_id_private_checked ] = useState(0)		
	
	const toggleOne = () => {
		setOne(!one)
		setTwo(false)
		setFour(false)
		setThree(false)
	  };
	
	  const toggleTwo = () => {
		setTwo(!two)
		setOne(false)
		setFour(false)
		setThree(false)
	  };

	  const toggleThree = () => {
		setTwo(false)
		setFour(false)
		setOne(false)
		setThree(!three)
	  };

	  const toggleFour = () => {
		setTwo(false)
		setFour(!four)
		setOne(false)
		setThree(false)
	  };
	



	return (
		<div class="col-md-6 align-self-center" style={{ marginTop: '1%'}}>



<div style={{ marginTop: '1%', marginBottom: '1%' }}>
<button
        className={`button ${one ? 'active-button' : 'inactive-button'}`}
        onClick={toggleOne}
      >
        Verification
      </button>
      <button
        className={`button ${two ? 'active-button' : 'inactive-button'}`}
        onClick={toggleTwo}
      >
        Promo
      </button>

	  <button
        className={`button ${four ? 'active-button' : 'inactive-button'}`}
        onClick={toggleFour}
      >
        Cover
      </button>

	  <button
        className={`button ${three ? 'active-button' : 'inactive-button'}`}
        onClick={toggleThree}
      >
        Verification 720p
      </button>

      </div>



{one && (
	<Verification />
)}

{two && (
<Promo />
)}

{three && (
<Verification720 />
)}

{four && (
<Cover />
)}


		</div>
	);
};



export default Models;
