import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

const ModelComponent = () => {
  const { id } = useParams(); // Получаем параметр id из URL

  useEffect(() => {
    if (id) {
      localStorage.setItem('id_models', id); // Записываем id в localStorage
    }
  }, [id]);

  return <div></div>;
};

export default ModelComponent;
