import React, { useState, useEffect, useCallback } from 'react';
import './style.css';
import { Oval } from 'react-loader-spinner';

const StreamPreviewList = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [statusFilter, setStatusFilter] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      alert('Copied!');
    } catch (error) {
      console.error('Failed to copy:', error);
    }
  };  

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await fetch('https://golive.mobi/api/premium/model/list/', {
        method: 'POST',
        headers: {
          Authorization: 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ page, status: statusFilter })
      });
      const json = await response.json();
      if (response.ok) {
        setData(json.list);
        setTotalPages(json.total_pages);
      } else {
        console.error('Failed to fetch data:', json);
        setData([]);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setData([]);
    }
    setLoading(false);
  }, [page, statusFilter]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const changeStatus = async (application_id, status) => {
    setLoading(true);
    try {
      await fetch('https://golive.mobi/api/premium/model/mark/', {
        method: 'POST',
        headers: {
          Authorization: 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ application_id, status })
      });
      fetchData();
    } catch (error) {
      console.error('Error updating status:', error);
    }
    setLoading(false);
  };

  if (loading) {
    return <div className="loader-container"><Oval color="#00BFFF" height={80} width={80} /></div>;
  }

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '10px', margin: '10px', fontSize: '14px' }}>
        <button className={`button ${statusFilter === null ? 'active-button' : 'inactive-button'}`} onClick={() => setStatusFilter(null)}>Waiting</button>
        <button className={`button ${statusFilter === 1 ? 'active-button' : 'inactive-button'}`} onClick={() => setStatusFilter(1)}>Approved</button>
        <button className={`button ${statusFilter === 0 ? 'active-button' : 'inactive-button'}`} onClick={() => setStatusFilter(0)}>Rejected</button>
      </div>
      <table style={{ width: '100%', borderCollapse: 'collapse' }}>
        <thead>
          <tr style={{ backgroundColor: 'rgb(255 255 255)' }}>
            <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>ID</th>
            <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Status</th>
            <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Date</th>
            <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>User ID</th>
            <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Name</th>
            <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Age</th>
            <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Premium</th>
            <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {data.map(item => (
            <tr key={item.application_id}>
              <td style={{ border: '1px solid #ddd', padding: '8px' }}>{item.application_id}</td>
              <td style={{ border: '1px solid #ddd', padding: '8px' }}>{item.status === null ? 'Waiting' : item.status === "1" ? 'Approved' : 'Rejected'}</td>
              <td style={{ border: '1px solid #ddd', padding: '8px' }}>{new Date(item.date * 1000).toLocaleDateString()}</td>
              <td style={{ border: '1px solid #ddd', padding: '8px', cursor: 'pointer' }} onClick={() => copyToClipboard(item.user.id.slice(0, 5))}>{item.user.id.slice(0, 5)}</td>
              <td style={{ border: '1px solid #ddd', padding: '8px' }}>{item.user.name}</td>
              <td style={{ border: '1px solid #ddd', padding: '8px' }}>{item.age}</td>
              <td style={{ border: '1px solid #ddd', padding: '8px' }}>{item.user.premium ? 'Yes' : 'No'}</td>
              <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                {item.status === null && <button className={`button inactive-button`} onClick={() => changeStatus(item.application_id, 1)}>Approve</button>}
                {item.status === null && <button className={`button inactive-button`}  onClick={() => changeStatus(item.application_id, 0)}>Reject</button>}
                {item.status === "1" && <button className={`button inactive-button`}  onClick={() => changeStatus(item.application_id, 0)}>Reject</button>}
                {item.status === '0' && <button className={`button inactive-button`}  onClick={() => changeStatus(item.application_id, 1)}>Approve</button>}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {totalPages > 1 && (
        <div style={{ display: 'flex', justifyContent: 'center', margin: '20px' }}>
          {Array.from({ length: totalPages }, (_, i) => (
            <button key={i + 1} lassName={`button inactive-button`}  onClick={() => setPage(i + 1)} disabled={page === i + 1}>
              {i + 1}
            </button>
          ))}
        </div>
      )}
    </>
  );  
};

export default StreamPreviewList;
