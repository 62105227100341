import React, { useState, useEffect } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const Dashboard = () => {
  const [data, setData] = useState([]);
  const [interval, setInterval] = useState(30); // Default interval of 30 minutes

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`https://admin.golive.mobi/pushstat/${interval}minutes`);
        const jsonData = await response.json();
        setData(jsonData.map(entry => ({
          ...entry,
          average_search_to_push: entry.average_search_to_push,
          average_search_to_dialog: entry.average_search_to_dialog,
          average_push_received: entry.average_push_received,
          average_received_to_started: entry.average_received_to_started,
        })));
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    };
    fetchData();
  }, [interval]);

  return (
    <div>
      <h1>Statistics</h1>
      <div>
        { [1, 5, 10, 30, 60].map(min => (
          <button
            key={min}
            onClick={() => setInterval(min)}
            style={{
              margin: '5px',
              padding: '10px',
              borderRadius: '5px',
              backgroundColor: interval === min ? '#4CAF50' : '#f1f1f1',
              color: interval === min ? 'white' : 'black',
              border: 'none',
              cursor: 'pointer',
              fontWeight: interval === min ? 'bold' : 'normal'
            }}
          >
            {min} Min
          </button>
        ))}
      </div>
      <ResponsiveContainer width="100%" height={400}>
        <BarChart data={data}>
          <XAxis dataKey="interval_start" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="successful_count" fill="#82ca9d" name="Successful" />
          <Bar dataKey="unsuccessful_count" fill="#ff6666" name="Unsuccessful" />
          <Bar dataKey="connected_count" fill="#ffc658" name="Connected" />
          <Bar dataKey="dialog_decline" fill="#ff6347" name="Dialog Decline" />
          <Bar dataKey="dialog_timeout" fill="#4682b4" name="Dialog Timeout" />
        </BarChart>
      </ResponsiveContainer>
      <ResponsiveContainer width="100%" height={400}>
        <BarChart data={data}>
          <XAxis dataKey="interval_start" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="average_search_to_push" fill="#8884d8" name="Search to Push Avg" />
          <Bar dataKey="average_search_to_dialog" fill="#82ca9d" name="Search to Dialog Avg" />
          <Bar dataKey="average_push_received" fill="#ffc658" name="Push Received Avg" />
          <Bar dataKey="average_received_to_started" fill="#ff6347" name="Received to Started Avg" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default Dashboard;
