import React, { useState, useEffect } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const Models = () => {
  const [data, setData] = useState([]);
  const [modelData, setModelData] = useState([]);
  const [intervalMinutes, setIntervalMinutes] = useState(30);

  useEffect(() => {
    fetchData();
    fetchModelData();
  }, [intervalMinutes]);

  const fetchData = () => {
    const apiUrl = intervalMinutes === 1 ? 'https://admin.golive.mobi/staterror/staterr/' : `https://admin.golive.mobi/errint/interval/${intervalMinutes}`;
    
    fetch(apiUrl)
      .then(response => response.json())
      .then(result => {
        setData(result);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  };

  const fetchModelData = () => {
    const modelApiUrl = intervalMinutes === 1 ? 'https://admin.golive.mobi/staterror/staterr_model/' : `https://admin.golive.mobi/errint/m_interval/${intervalMinutes}`;
    
    fetch(modelApiUrl)
      .then(response => response.json())
      .then(result => {
        setModelData(result);
      })
      .catch(error => {
        console.error('Error fetching model data:', error);
      });
  };

  const handleIntervalChange = (minutes) => {
    setIntervalMinutes(minutes);
  };

  const formatLegend = (key) => {
    return key.replace(/^error(_m_)?/, '').replace(/_/g, ' ');
  };

  const errorKeys = data.length > 0 ? Object.keys(data[0]).filter(key => key !== 'name') : [];
  const modelErrorKeys = modelData.length > 0 ? Object.keys(modelData[0]).filter(key => key !== 'name') : [];

  const colors = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#AF19FF', '#82ca9d', '#8884d8', '#ffc658', '#ff7300', '#d63c3c', '#00b200', '#9c65c9', '#ff00ff', '#ffcc00', '#ff9966'];

  const allErrorKeys = Array.from(new Set([...errorKeys, ...modelErrorKeys]));

  return (
    <div>
      <div>
        <h2 style={{ marginLeft: '1%', fontFamily: 'inter' }}>Statistic error</h2>
        <button style={{
          width: '100px',
          height: '30px',
          borderRadius: '15px',
          color: 'black',
        }} className={`button ${intervalMinutes === 1 ? 'active-button' : 'inactive-button'}`} disabled onClick={() => handleIntervalChange(1)}>1 min</button>
        <button style={{
          width: '100px',
          height: '30px',
          borderRadius: '15px',
          color: 'black',
        }} className={`button ${intervalMinutes === 5 ? 'active-button' : 'inactive-button'}`} disabled onClick={() => handleIntervalChange(5)}>5 min</button>
        <button style={{
          width: '100px',
          height: '30px',
          borderRadius: '15px',
          color: 'black',
        }} className={`button ${intervalMinutes === 10 ? 'active-button' : 'inactive-button'}`} disabled onClick={() => handleIntervalChange(10)}>10 min</button>
        <button style={{
          width: '100px',
          height: '30px',
          borderRadius: '15px',
          color: 'black',
        }} className={`button ${intervalMinutes === 30 ? 'active-button' : 'inactive-button'}`} onClick={() => handleIntervalChange(30)}>30 min</button>
      </div>

      <h2 style={{ marginLeft: '1%', fontFamily: 'inter' }}>Error users</h2>

      <ResponsiveContainer width="100%" height={400}>
        <BarChart data={data}>
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          <Legend formatter={(value) => formatLegend(value)} />
          {allErrorKeys.map((errorKey, index) => (
            <Bar key={index} dataKey={errorKey} fill={colors[index % colors.length]} />
          ))}
        </BarChart>
      </ResponsiveContainer>

      <h2 style={{ marginLeft: '1%', fontFamily: 'inter' }}>Error models</h2>

      <ResponsiveContainer width="100%" height={400}>
        <BarChart data={modelData}>
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          <Legend formatter={(value) => formatLegend(value)} />
          {allErrorKeys.map((errorKey, index) => (
            <Bar key={index} dataKey={errorKey} fill={colors[index % colors.length]} />
          ))}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div style={{ backgroundColor: '#fff', padding: '10px', border: '1px solid #ccc' }}>
        <p>{`Time: ${payload[0].payload.name}`}</p>
        {payload.map((item, index) => (
          <p key={index}>{`${item.dataKey}: ${item.value}`}</p>
        ))}
      </div>
    );
  }
  return null;
};

export default Models;
