import React, { useState, useEffect, useCallback } from 'react';
import './style.css';
import { useHistory } from 'react-router-dom';
import check_active from './check_active.svg';
import check_disable from './check_disable.svg';

const TableGeneral = () => {
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchData();
  }, [currentPage]);

  const fetchData = () => {
    // The URL and headers for the API request
    const apiUrl = 'https://golive.mobi/api/user/user-list/';
    const requestOptions = {
      method: 'POST',
      headers: {
        Authorization: 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ page: currentPage }), // Include the current page number in the request payload
    };

    // Fetch the data
    fetch(apiUrl, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        // The API response contains the data in the "list" property
        if (data.list && Array.isArray(data.list)) {
          // Map the received data to match the format used in the table
          const mappedData = data.list.map((item) => ({
            id: item.id,
            gender: item.gender,
            countryCode: item.countryCode,
            timeRegister: item.timeRegister,
            activity: item.activity.last_active,
            balance: item.balance,
            following_count: item.following_count,
            followers_count: item.followers_count,
            premium: item.premium,
            fan_subscribed: item.fan_subscribed,
            paying: item.paying,
          }));
          // Update the state with the fetched data
          setLoading(mappedData.length === 0);
          setData(mappedData);
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };

  const handleClick = () => {
    history.push('/modelcreate');
  };

  const [showForm, setShowForm] = useState(false);

  const toggleForm = () => {
    setShowForm(!showForm);
  };

  // Тестовые данные для таблицы
  const initialData = [
    // Добавьте больше тестовых данных здесь...
  ];

  // Состояние для выбора элементов
  const [data, setData] = useState(initialData);
  const [selectAll, setSelectAll] = useState(false);

  const toggleSelectAll = () => {
    setSelectAll(!selectAll);
    const updatedData = data.map((item) => ({ ...item, selected: !selectAll }));
    setData(updatedData);
  };

  const toggleSelectItem = (id) => {
    const updatedData = data.map((item) => {
      if (item.id === id) {
        return { ...item, selected: !item.selected };
      }
      return item;
    });
    setData(updatedData);
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleModelClick = useCallback(
    (id) => {
      localStorage.setItem('id_models', id); // Save ID to localStorage
      history.push(`/new/user_model/${id}`); // Navigate to the profile_model page with the selected ID
    },
    [history]
  );

  function formatDate(timestamp) {
    if (!timestamp) {
      return '';
    }
  
    const dateObj = new Date(timestamp * 1000); // Convert to milliseconds
  
    if (isNaN(dateObj)) {
      return 'Invalid timestamp';
    }
  
    const year = dateObj.getFullYear();
    const month = ('0' + (dateObj.getMonth() + 1)).slice(-2);
    const day = ('0' + dateObj.getDate()).slice(-2);
    const hours = ('0' + dateObj.getHours()).slice(-2);
    const minutes = ('0' + dateObj.getMinutes()).slice(-2);
  
    return `${day}.${month}.${year} ${hours}:${minutes}`;
  }

  function formatTimestampReg(timestamp) {
    if (!timestamp) {
      return '';
    }

    const dateObj = new Date(Number(timestamp));

    if (isNaN(dateObj)) {
      return 'Invalid timestamp';
    }

    const year = dateObj.getFullYear();
    const month = ('0' + (dateObj.getMonth() + 1)).slice(-2);
    const day = ('0' + dateObj.getDate()).slice(-2);
    const hours = ('0' + dateObj.getHours()).slice(-2);
    const minutes = ('0' + dateObj.getMinutes()).slice(-2);

    return `${day}.${month}.${year} ${hours}:${minutes}`;
  }

  return (
    <div>
      <div style={{ width: '95%',
      marginLeft: 'auto',
      marginRight: 'auto',
      background: 'white',
      borderRadius: '15px',
      boxShadow: 'rgba(112, 144, 176, 0.2) 2px 2px 2px 2px',
      }}>

        



        <table className="table">
          <thead style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.07)' }}>
            <tr>
              <th >
                <input
                  className="checkbox"
                  type="checkbox"
                  checked={selectAll}
                  onChange={toggleSelectAll}
                  style={{
                    alignItems: 'center',
                    display: 'flex',
                  }}
                />
              </th>
              <th style={{ color: 'black', fontWeight: 'bold', fontFamily: "Noto Sans Mono", weight: '500', fontSize: '12px', textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)'}}>ID</th>
              <th style={{ color: 'black', fontWeight: 'bold',fontFamily: "Noto Sans Mono", weight: '500', fontSize: '12px', textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)'}}>Gender</th>
              <th style={{ color: 'black', fontWeight: 'bold',fontFamily: "Noto Sans Mono", weight: '500', fontSize: '12px', textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)'}}>Country</th>
              <th style={{ color: 'black', fontWeight: 'bold',fontFamily: "Noto Sans Mono", weight: '500', fontSize: '12px', textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)'}}>Time register</th>
              <th style={{ color: 'black', fontWeight: 'bold',fontFamily: "Noto Sans Mono", weight: '500', fontSize: '12px', textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)'}}>Last active</th>
              <th style={{ color: 'black', fontWeight: 'bold',fontFamily: "Noto Sans Mono", weight: '500', fontSize: '12px', textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)'}}>Balance</th>
              <th style={{ color: 'black', fontWeight: 'bold',fontFamily: "Noto Sans Mono", weight: '500', fontSize: '12px', textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)'}}>Following</th>
              <th style={{ color: 'black', fontWeight: 'bold',fontFamily: "Noto Sans Mono", weight: '500', fontSize: '12px', textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)'}}>Followers</th>
              <th style={{ color: 'black', fontWeight: 'bold',fontFamily: "Noto Sans Mono", weight: '500', fontSize: '12px', textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)'}}>Premium</th>
              <th style={{ color: 'black', fontWeight: 'bold',fontFamily: "Noto Sans Mono", weight: '500', fontSize: '12px', textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)'}}>Fan subscribe</th>
              <th style={{ color: 'black', fontWeight: 'bold',fontFamily: "Noto Sans Mono", weight: '500', fontSize: '12px', textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)'}}>Paying</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr
              key={item.id}
              className={`${index % 2 === 0 ? 'even-row' : 'odd-row'} ${item.selected ? 'selected-row' : 'non-selected-row'}`}
            >
                <td style={{
    borderRight: '1px solid rgba(0, 0, 0, 0.07)'
    
  }}>
                  <input
                    type="checkbox"
                    checked={item.selected}
                    onChange={() => toggleSelectItem(item.id)}
                    
                  />
                </td>

                <td style={{
    color: item.selected ? '#6D33FF' : '#606C80',
    fontFamily: 'Noto Sans Mono',
    fontWeight: '400',
    fontSize: '12px',
    textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)'
    
  }}>
    
    <a style={{
                  color: item.selected ? '#6D33FF' : '#606C80',
                  fontFamily: 'Noto Sans Mono',
                  fontWeight: '400',
                  fontSize: '12px',
                  textAlign: 'right',
                }} href={`user_model/${item.id}`}>{item.id}</a>
                </td>
                <td style={{
    color: item.selected ? '#6D33FF' : '#606C80',
    fontFamily: 'Noto Sans Mono',
    fontWeight: '400',
    fontSize: '12px',
    textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)'
    
  }}>{item.gender === 1 ? 'Man' : 'Woman'}</td>
                <td style={{
    color: item.selected ? '#6D33FF' : 'black',
    fontFamily: 'Noto Sans Mono',
    fontWeight: '400',
    fontSize: '12px',
    textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)'
    
  }}>{item.countryCode}</td>
                <td style={{
    color: item.selected ? '#6D33FF' : '#606C80',
    fontFamily: 'Noto Sans Mono',
    fontWeight: '400',
    fontSize: '12px',
    textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)'
  }}>{formatTimestampReg(item.timeRegister)}</td>
                <td style={{
    color: item.selected ? '#6D33FF' : '#606C80',
    fontFamily: 'Noto Sans Mono',
    fontWeight: '400',
    fontSize: '12px',
    textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)'
  }}>{formatDate(item.activity)}</td>
                <td style={{
    color: item.selected ? '#6D33FF' : '#606C80',
    fontFamily: 'Noto Sans Mono',
    fontWeight: '400',
    fontSize: '12px',
    textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)'
  }}>{item.balance}</td>
                <td style={{
    color: item.selected ? '#6D33FF' : '#606C80',
    fontFamily: 'Noto Sans Mono',
    fontWeight: '400',
    fontSize: '12px',
    textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)'
    
  }}>{item.following_count}</td>
                <td style={{
    color: item.selected ? '#6D33FF' : '#606C80',
    fontFamily: 'Noto Sans Mono',
    fontWeight: '400',
    fontSize: '12px',
    textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)'
  }}>{item.followers_count}</td>
                <td style={{
    color: item.selected ? '#6D33FF' : '#606C80',
    fontFamily: 'Noto Sans Mono',
    fontWeight: '400',
    fontSize: '12px',
    textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)',
    background:
      item.premium === 0
        ? 'rgba(255, 224, 224, 0.5)'
        : item.premium === 1
        ? 'rgba(216, 255, 212, 0.5)'
        : 'none',
  }}>{item.premium === 0 ? 'No' : 'Yes'}</td>
                <td style={{
    color: item.selected ? '#6D33FF' : '#606C80',
    fontFamily: 'Noto Sans Mono',
    fontWeight: '400',
    fontSize: '12px',
    textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)'
  }}>{item.fan_subscribed}</td>
                <td style={{
    color: item.selected ? '#6D33FF' : '#606C80',
    fontFamily: 'Noto Sans Mono',
    fontWeight: '400',
    fontSize: '12px',
    textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)',
    background:
      item.paying === 0
        ? 'rgba(255, 224, 224, 0.5)'
        : item.paying === 1
        ? 'rgba(216, 255, 212, 0.5)'
        : 'none',
  }}>{item.paying === 0 ? 'No' : 'Yes'}</td>

              </tr>
            ))}
          </tbody>
        </table>

        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
          <button className="remove-button" style={{ backgroundColor: '#6D33FF'}} onClick={handlePreviousPage}>&lt; Previous</button>
          <button className="remove-button" style={{ backgroundColor: '#FFF', width: '40px', color: 'black'}}>{currentPage}</button>
          <button className="remove-button"  style={{ backgroundColor: '#6D33FF'}} onClick={handleNextPage}>Next &gt;</button>
        </div>

        <br />
      </div>
    </div>
  );
};

export default TableGeneral;
