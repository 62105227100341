import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Oval } from 'react-loader-spinner';
import Modal from 'react-modal';

Modal.setAppElement('#root');  // Убедитесь, что это корректный элемент для вашего приложения

const Checkban = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [modalData, setModalData] = useState(null);

  useEffect(() => {
    axios.get('https://streams.golive.mobi/dbsverif/all')
      .then(response => {
        setData(response.data);
        setIsLoading(false);
      })
      .catch(error => {
        console.error("There was an error fetching the data!", error);
        setIsLoading(false);
      });
  }, []);

  const openModal = async (user) => {
    setSelectedUser(user);
    setModalIsOpen(true);
    try {
      const response = await axios.get(`https://streams.golive.mobi/dbsverif/${user.temper}`);
      setModalData(response.data);
    } catch (error) {
      console.error("There was an error fetching the modal data!", error);
    }
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setModalData(null);
  };

  if (isLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Oval height="100" width="100" color="grey" ariaLabel="loading" />
      </div>
    );
  }

  return (
    <div style={{ padding: '20px' }}>
      <table style={{ width: '100%', borderCollapse: 'collapse', boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)' }}>
        <thead>
          <tr style={{ backgroundColor: '#f9f9f9' }}>
            <th style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>UserId</th>
            <th style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>Gender</th>
            <th style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>Photo</th>
            <th style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>Action</th>
          </tr>
        </thead>
        <tbody>
          {data.map((user, index) => (
            <tr key={index} style={{ backgroundColor: index % 2 === 0 ? '#f9f9f9' : '#fff' }}>
              <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>{user.userId}</td>
              <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>{user.gender || 'N/A'}</td>
              <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>
                {user.photo ? <img src={`https://streams.golive.mobi/${user.photo}`} alt="user photo" style={{ width: '50px', borderRadius: '5px' }} /> : 'No photo'}
              </td>
              <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>
                <button 
                  onClick={() => openModal(user)} 
                  style={{
                    backgroundColor: '#4CAF50',
                    color: 'white',
                    border: 'none',
                    padding: '10px 20px',
                    textAlign: 'center',
                    textDecoration: 'none',
                    display: 'inline-block',
                    fontSize: '16px',
                    margin: '4px 2px',
                    cursor: 'pointer',
                    borderRadius: '5px'
                  }}
                >
                  Open room
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="User Details"
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '70%',
            maxHeight: '90vh',
            overflow: 'auto',
            borderRadius: '10px',
            padding: '20px',
            boxShadow: '0 5px 15px rgba(0, 0, 0, 0.3)'
          }
        }}
      >
        <button 
          onClick={closeModal} 
          style={{ 
            position: 'absolute', 
            top: '10px', 
            right: '10px', 
            cursor: 'pointer', 
            background: 'none', 
            border: 'none', 
            fontSize: '24px', 
            color: '#aaa' 
          }}
        >
          ✖
        </button>
        {selectedUser && (
          <div>
            <h2>{selectedUser.userId}</h2>
            <p>Result: {selectedUser.gender || 'N/A'}</p>
            {modalData && (
              <>
                <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                  {modalData.photo.map((photo, index) => (
                    <img key={index} src={`https://${photo}`} alt="user photo" style={{ width: '100px', margin: '1%', borderRadius: '5px' }} />
                  ))}
                </div>
                <div style={{ marginTop: '20px' }}>
                  <video controls preload="auto" style={{ width: '100%', maxHeight: '400px' }}>
                    <source src={`https://${modalData.video}`} type="video/mp4" />
                  </video>
                </div>
              </>
            )}
          </div>
        )}
      </Modal>
    </div>
  );
};

export default Checkban;
