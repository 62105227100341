import React, { useState, useEffect, useMemo, useCallback } from 'react';
import {
  BarChart,
  LineChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Bar,
  Line,
  ResponsiveContainer,
  Brush,
} from 'recharts';
import debounce from 'lodash.debounce';

const Models = () => {
  const [originalData, setOriginalData] = useState([]);
  const [selectedHour, setSelectedHour] = useState(null);
  const [brushRange, setBrushRange] = useState([0, 100]); // Initial brush range
  const hours = Array.from({ length: 24 }, (_, i) => i);

  const filterDataByHour = useCallback((hour) => {
    return originalData.filter((entry) => {
      const entryHour = parseInt(entry.name.split(':')[0]);
      return entryHour === hour;
    });
  }, [originalData]);

  const filteredData = useMemo(() => {
    if (selectedHour !== null) {
      return filterDataByHour(selectedHour);
    }
    return originalData; // Display all data for "All time" option
  }, [filterDataByHour, originalData, selectedHour]);

  const fetchData = useCallback(async () => {
    try {
      const [userStatsResponse, modelStatsResponse] = await Promise.all([
        fetch('https://admin.golive.mobi/stat_search/stat_search_user/'),
        fetch('https://admin.golive.mobi/stat_search/stat_search_model/'),
      ]);

      const [userStatsData, modelStatsData] = await Promise.all([
        userStatsResponse.json(),
        modelStatsResponse.json(),
      ]);

      const combinedData = userStatsData.map((userEntry) => {
        const correspondingModelEntry = modelStatsData.find(
          (modelEntry) => modelEntry.name === userEntry.name
        );
        return {
          name: userEntry.name,
          userRecordCount: userEntry.record_count,
          modelRecordCount: correspondingModelEntry
            ? correspondingModelEntry.record_count
            : 0,
        };
      });

      setOriginalData(combinedData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, []);

  const debouncedSetSelectedHour = debounce((hour) =>
    setSelectedHour(hour)
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleBrushChange = (range) => {
    setBrushRange(range);
  };

  return (
    <div style={{ width: '100%' }}>

<h2 style={{ marginLeft: '1%', fontFamily: 'inter' }}>Statistic Search</h2>


      <div style={{ marginBottom: '10px' }}>
        <select
          value={selectedHour}
          onChange={(e) =>
            debouncedSetSelectedHour(parseInt(e.target.value))
          }
        >
          <option value={null}>None</option>
          {hours.map((hour) => (
            <option key={hour} value={hour}>{`${hour}:00 - ${hour + 1}:00`}</option>
          ))}
          
        </select>
      </div>
      {selectedHour !== null && (
        <ResponsiveContainer width="100%" height={400}>
          {selectedHour !== 'all' ? (
            <BarChart
              margin={{ top: 20, right: 30, left: 50, bottom: 50 }}
              data={filteredData}
            >
              <CartesianGrid />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip cursor={{ strokeDasharray: '3 3' }} />
              <Bar
                dataKey="userRecordCount"
                fill="#8884d8"
                name="User Search"
              />
              <Bar
                dataKey="modelRecordCount"
                fill="#82ca9d"
                name="Model Search"
              />
              <Brush
                dataKey="name"
                height={30}
                stroke="#8884d8"
                onChange={handleBrushChange}
                startIndex={brushRange[0]}
                endIndex={brushRange[1]}
              />
            </BarChart>
          ) : (
            <LineChart
              margin={{ top: 20, right: 30, left: 50, bottom: 50 }}
              data={originalData}
            >
              <CartesianGrid />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip cursor={{ strokeDasharray: '3 3' }} />
              <Line
                type="monotone"
                dataKey="userRecordCount"
                stroke="#8884d8"
                name="User Records"
              />
              <Line
                type="monotone"
                dataKey="modelRecordCount"
                stroke="#82ca9d"
                name="Model Records"
              />
              <Brush
  dataKey="name"  // Используйте свойство, которое соответствует времени/дате
  height={30}
  stroke="#8884d8"
  onChange={handleBrushChange}
  startIndex={Math.floor((originalData.length - 1) * (brushRange[0] / 100))}
  endIndex={Math.floor((originalData.length - 1) * (brushRange[1] / 100))}
/>

            </LineChart>
          )}
        </ResponsiveContainer>
      )}
      <div style={{ marginTop: '10px' }}>
        {selectedHour !== null && (
          <p>
            Selected Hour:{' '}
            {`${selectedHour === 'all' ? 'All time' : `${selectedHour}:00 - ${selectedHour + 1}:00`}`}
          </p>
        )}
      </div>
    </div>
  );
};

export default Models;
