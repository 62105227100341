import React, { useState } from 'react';

import TableGeneral from './TableGeneral_billing';
import './style.css';

const ProfileInfo = () => {
  const [showProfileInfo, setShowProfileInfo] = useState(true);
  const [showUserActivity, setShowUserActivity] = useState(false);

  const toggleProfileInfo = () => {
    setShowProfileInfo(!showProfileInfo);
    setShowUserActivity(false);
  };

  const toggleUserActivity = () => {
    setShowUserActivity(!showUserActivity);
    setShowProfileInfo(false);
  };

  return (
    <div>

    {/**   <div style={{ marginTop: '-2.5%',
    display: 'block' }}>
      <input type="text" className="billing_search_field" placeholder={'2023-06-06 - 2023-06-02'} />
      <input type="text" className="billing_search_field" placeholder='Modeles' />
      <input type="text" className="billing_search_field" placeholder='Status' />
      <input type="text" className="billing_search_field" placeholder='Payment system' />
      <input type="text" className="billing_search_field" placeholder='100' style={{ width: '150px' }} />
      <button className="create-button" >
          View
        </button>
        </div>

<div style={{ marginTop: '-2.5%',
    display: 'block' }}>
        <input type="text" className="billing_search_field" placeholder='Status' />
      <button className="create-button" >
          Change status
        </button>
        </div>

      <hr style={{ border: "1px solid rgba(0, 0, 0, 0.05)", marginTop: '2%', marginBottom: '2%' }} /> */}
          <TableGeneral />
        
    </div>
  );
};

export default ProfileInfo;