import React, { useState, useEffect } from 'react';

const DebugCodeComponent = () => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'q') {
        console.log("test alert")
        setIsVisible(true);
      }
    };

    const handleKeyUp = (event) => {
      if (event.key === 'q') {
        setIsVisible(false);
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('keyup', handleKeyUp);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  return isVisible ? (
    <div
      style={{
        position: 'fixed',
        left: '20px',
        bottom: '20px',
        zIndex: 9999,
        backgroundColor: '#fff',
        padding: '10px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
      }}
    >
      <p>Enter debug-code:</p>
      <input type="text" />
      <button>OK</button>
    </div>
  ) : null;
};

export default DebugCodeComponent;
