import React, { useState, useEffect } from "react";
import { StyleSheet, css } from "aphrodite/no-important";
import { useHistory } from "react-router-dom";
import axios from "axios";
import "./style.css";

const styles = StyleSheet.create({
  h1: {
    fontSize: "7.5em",
    margin: "15px 0px",
    fontWeight: "bold",
  },
  h2: {
    fontWeight: "bold",
  },
});

const Models = () => {
  const history = useHistory();
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [codeName, setCodeName] = useState("");
  const [formData, setFormData] = useState({
    username: "",
    password: "",
    role: "",
  });

  useEffect(() => {
    // Generate a random password of 10 characters when the component mounts
    const generateRandomPassword = () => {
      const randomPassword = Math.random().toString(36).slice(-10);
      setFormData((prevData) => ({
        ...prevData,
        password: randomPassword,
      }));
    };
    generateRandomPassword();
  }, []);

  const handleClick = async (e) => {
    e.preventDefault();

    const data = {
      username: formData.username,
      password: formData.password,
      role: formData.role,
      status: "online",
    };

    try {
      const response = await axios.post("https://admin.golive.mobi/createuser", data, {
        headers: {
          Authorization: "Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d",
          "Content-Type": "application/json",
        },
      });

      if (response.status === 200) {
        setCodeName(response.data.code_name);
        history.push('/settings')
      } else {
        console.error("Error:", response);
      }
    } catch (error) {
      console.error("Error:", error);
    }

    setIsSaveButtonDisabled(true); // Disable the Save button after form submission
    setIsSubmitted(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    setIsFormValid(
      value !== "" && formData.password !== "" && formData.role !== ""
    );

    if (isSubmitted) {
      // Enable the Save button if any field is changed after form submission
      setIsSaveButtonDisabled(false);
    }
  };

  const handleClear = () => {
    // Generate a new random password when the Clear button is clicked
    const generateRandomPassword = () => {
      const randomPassword = Math.random().toString(36).slice(-10);
      setFormData((prevData) => ({
        ...prevData,
        password: randomPassword,
      }));
    };
    generateRandomPassword();

    setFormData({
      username: "",
      role: "",
    });
    setIsFormValid(false);
    setIsSubmitted(false);
    setIsSaveButtonDisabled(false); 
    generateRandomPassword();
    // Enable the Save button when the Clear button is clicked
  };

  return (
    <div>
      <h2 style={{ marginLeft: "1%", fontFamily: "Inter" }}>Create new user</h2>

      <div className="square">
        <form className="createmodel">
          <label className="square_content">
            Username:
            <input
              type="text"
              name="username" style={{ marginLeft: '4.5%' }}
              className="square_field1"
              value={formData.username}
              onChange={handleInputChange}
            />
          </label>
          <br />
          <label className="square_content">
            Password:
            <input
              type="text"
              name="password"
              className="square_field2"
              value={formData.password}
              onChange={handleInputChange}
              // Make the password input read-only to prevent manual editing
            />
          </label>
          <br />
          <label className="square_content">
            Role:
            <select
              name="role"  style={{ marginLeft: '9%' }}
              className="square_field3"
              value={formData.role}
              onChange={handleInputChange}
            >
              <option value="">Select a role</option>
              <option value="sector05a">Admin</option>
              <option value="sectorMod">Moderator</option>
            </select>
          </label>
          <br />
          <button
            style={{
              left: "20%",
              opacity: isSaveButtonDisabled ? "0.5" : "1", // Set the opacity based on the disabled state
              cursor: isSaveButtonDisabled ? "default" : "pointer", // Set cursor style based on the disabled state
            }}
            type="submit"
            onClick={handleClick}
            className={`square_button`}
           // disabled={!isFormValid || isSaveButtonDisabled} // Disable the button based on the new state variable
          >
            Save
          </button>
          <button
            style={{ marginLeft: "2.5%" }}
            type="button"
            onClick={handleClear}
            className="square_button"
          >
            Clear
          </button>
          {codeName && (
            <p
              className="square_content"
              style={{ width: "50%", marginTop: "0%" }}
            >
              Code name model: {codeName}
            </p>
          )}
        </form>
      </div>
    </div>
  );
};

export default Models;
