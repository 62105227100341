import React, { useEffect, useState } from 'react';

const BansList = () => {
  const [bans, setBans] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch('https://apilive.golive.mobi/bans');
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setBans(Array.isArray(data) ? data : [data]);
    } catch (error) {
      console.error('Ошибка при получении данных:', error);
    }
  };

  const handleUnblock = async (longestId, roomId) => {
    const headers = {
      'Authorization': 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
      'Content-Type': 'application/json',
    };
    const body = JSON.stringify([{ id: longestId, signed_up: 1 }]);
    
    try {
      await fetch('https://golive.mobi/api/user/m-undelete/', { method: 'POST', headers, body });
      await fetch(`https://apilive.golive.mobi/checkban/${roomId}`);
      fetchData(); // Обновляем данные после разблокировки
    } catch (error) {
      console.error('Ошибка при разблокировке:', error);
    }
  };

  const handleHide = async (roomId) => {
    try {
      await fetch(`https://apilive.golive.mobi/checkban/${roomId}`);
      fetchData();
      // Действие после 'скрытия' не определено; возможно, обновить список
    } catch (error) {
      console.error('Ошибка при скрытии:', error);
    }
  };

  const renderBan = (ban) => {
    const { room_id, user_1, user_2, banned_photo } = ban;
    const longestId = user_1.length > user_2.length ? user_1 : user_2;
    const profileUrl = `https://admin.golive.mobi/profile_model/${longestId}`;

    return (
<div style={{ display: 'flex', alignItems: 'flex-start', margin: '10px', backgroundColor: '#f9f9f9', padding: '10px', borderRadius: '5px', boxShadow: '0 2px 4px rgba(0,0,0,0.1)', boxSizing: 'border-box' }}>
  <img src={banned_photo} alt="Banned" style={{ width: '200px', marginRight: '20px', borderRadius: '5px' }} />
  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
    <button style={{ marginBottom: '10px', padding: '5px 10px', cursor: 'pointer', backgroundColor: '#007bff', color: 'white', border: 'none', borderRadius: '4px' }} onClick={() => window.open(profileUrl, '_blank')}>Page Model</button>
    <button style={{ marginBottom: '10px', padding: '5px 10px', cursor: 'pointer', backgroundColor: '#28a745', color: 'white', border: 'none', borderRadius: '4px' }} onClick={() => handleUnblock(longestId, room_id)}>Unblock</button>
    <button style={{ padding: '5px 10px', cursor: 'pointer', backgroundColor: '#6c757d', color: 'white', border: 'none', borderRadius: '4px' }} onClick={() => handleHide(room_id)}>Hide</button>
  </div>
</div>


    );
  };

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', margin: '-10px' }}>
      {bans.map((ban, index) => <div key={index} style={{ padding: '10px' }}>{renderBan(ban)}</div>)}
    </div>
  );
};

export default BansList;
