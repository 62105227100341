import React, { useState, useEffect } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const ChartComponent = () => {
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://admin.golive.mobi/data_53');
        const jsonData = await response.json();
        setChartData(jsonData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <h2>Statistic balance</h2>
      <ResponsiveContainer width="100%" height={400}>
        <BarChart data={chartData}>
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip
            content={({ payload, label }) => {
              const totalModel = payload[0]?.payload.total_model || 0;
              const totalBalance = payload[0]?.payload.total_balance || 0;

              return (
                <div style={{ background: '#fff', border: '1px solid #ccc', padding: '10px' }}>
                  <p>Date: {label}</p>
                  <p>Total Model: {totalModel}</p>
                  <p>Total Balance: {totalBalance}</p>
                </div>
              );
            }}
          />
          <Legend />
          <Bar dataKey="total_model" fill="#8884d8" name="Total Model" />
          <Bar dataKey="total_balance" fill="#82ca9d" name="Total Balance" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ChartComponent;
