import React, { useState } from 'react';

import TableGeneral from './TableGeneral_ Complaints';
import TableGeneralw from './TableGeneral_ Complaints_w';
import TableGeneralun from './TableGeneral_ Complaints_unw';
import './style.css';

const ProfileInfo = () => {
  const [showProfileInfo, setShowProfileInfo] = useState(true);
  const [showUserActivity, setShowUserActivity] = useState(false);
  const [showUserRegActivity, setShowUserRegActivity] = useState(false);
  const [showUserNoRegActivity, setShowUserNoRegActivity] = useState(false);

  const [one, setOne] = useState(true);
  const [two, setTwo] = useState(false);
  const [three, setThree] = useState(false);

  const toggleOne = () => {
    setOne(!one)
    setTwo(false)
    setThree(false)
  };

  const toggleTwo = () => {
    setTwo(!two)
    setOne(false)
    setThree(false)
  };

  const toggleThree = () => {
    setThree(!three)
    setTwo(false)
    setOne(false)
  };

  return (
    <div>

  
<h2  style={{ marginLeft: '1%', fontFamily: 'inter' }}>Complaints</h2>
<div style={{ marginTop: '3%' }}>
<button
        className={`button ${one ? 'active-button' : 'inactive-button'}`}
        onClick={toggleOne}
      >
        All complaints
      </button>
      <button
        className={`button ${two ? 'active-button' : 'inactive-button'}`}
        onClick={toggleTwo}
      >
        Unwatched complaints
      </button>
      <button
        className={`button ${three ? 'active-button' : 'inactive-button'}`}
        onClick={toggleThree}
      >
        Watched complaints
      </button>
      </div>
      <hr style={{ border: "1px solid rgba(0, 0, 0, 0.05)", marginTop: '2%', marginBottom: '2%' }} />

      {one && (
        <div>
          <TableGeneral />
        </div>
      )}
      {two && (
        <div>
          <TableGeneralun />
        </div>
      )}
      {three && (
        <div>
          <TableGeneralw />
        </div>
      )}



        
    </div>
  );
};

export default ProfileInfo;