import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Oval } from 'react-loader-spinner';

const Noface = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post('https://streams.golive.mobi/videostreamlist/', {
        page: currentPage
      });
      setData(response.data);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data: ', error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentPage]);

  const handleBan = async (userId) => {
    try {
      await axios.post('https://golive.mobi/api/stream/enable/', {
        id: userId,
        value: 0
      }, {
        headers: {
          Authorization: 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d'
        }
      });

      await axios.post('https://golive.mobi/api/user/m-delete/', [
        {
          id: userId,
          signed_up: 1
        }
      ], {
        headers: {
          Authorization: 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d'
        }
      });

      alert('User banned successfully.');
      fetchData(); // Refresh data after action
    } catch (error) {
      console.error('Error banning user: ', error);
      alert('Failed to ban user.');
    }
  };

  const handleVideoClick = (url) => {
    window.open(url, '_blank');
  };

  const handlePrevPage = () => {
    setCurrentPage(currentPage > 1 ? currentPage - 1 : 1);
  };

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  return (
    <div style={styles.tableContainer}>
      <table style={styles.table}>
        <thead>
          <tr>
            <th style={styles.headerCell}>User ID</th>
            <th style={styles.headerCell}>Videos</th>
            <th style={styles.headerCell}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {isLoading ? <Oval color="#00BFFF" height={80} width={80} /> : data.map((user) => (
            <tr key={user.userId}>
              <td style={styles.cell}>{user.userId}</td>
              <td style={styles.cell}>
                <div style={styles.videoContainer}>
                  {user.videos.map((video) => (
                    <div key={video.activityId} style={styles.videoWrapper}>
                      {video.thumbnail_path ? (
                        <img
                          src={`https://streams.golive.mobi/recorded__live_streams/${video.thumbnail_path}`}
                          alt="thumbnail"
                          style={styles.thumbnail}
                          onClick={() => handleVideoClick(`https://streams.golive.mobi/${video.recorded_live_video}`)}
                        />
                      ) : (
                        <video
                          style={styles.video}
                          onClick={() => handleVideoClick(`https://streams.golive.mobi/${video.recorded_live_video}`)}
                          controls={false}
                          preload="metadata"
                        >
                          <source src={`https://streams.golive.mobi/${video.recorded_live_video}`} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                      )}
                    </div>
                  ))}
                </div>
              </td>
              <td style={styles.cell}>
                <button onClick={() => handleBan(user.userId)} style={styles.button}>Ban</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div style={styles.paginationContainer}>
        <button onClick={handlePrevPage} style={styles.pageButton} disabled={currentPage === 1}>Назад</button>
        <span style={styles.pageIndicator}>Страница {currentPage}</span>
        <button onClick={handleNextPage} style={styles.pageButton}>Далее</button>
      </div>
    </div>
  );
};

const styles = {
  tableContainer: {
    overflowX: 'auto',
    textAlign: 'center'
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    margin: 'auto'
  },
  headerCell: {
    padding: '8px',
    textAlign: 'left',
    borderBottom: '1px solid #ddd',
    width: '33%'
  },
  cell: {
    padding: '8px',
    borderBottom: '1px solid #ddd',
    maxWidth: '200px',
    overflow: 'hidden'
  },
  videoContainer: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    gap: '10px',
    overflowX: 'auto',
    padding: '8px',
    width: '100%'
  },
  videoWrapper: {
    position: 'relative'
  },
  video: {
    width: '100px',
    height: 'auto',
    display: 'block'
  },
  thumbnail: {
    width: '100px',  // Установите подходящий размер для миниатюр
    height: 'auto',
    cursor: 'pointer'
  },
  button: {
    padding: '6px 12px',
    backgroundColor: '#dc3545',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer'
  },
  paginationContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px'
  },
  pageButton: {
    padding: '10px 20px',
    margin: '0 10px',
    backgroundColor: '#007bff',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer'
  },
  pageIndicator: {
    padding: '10px 20px',
    fontSize: '16px'
  }
};

export default Noface;
