import React, { useState } from 'react';
import axios from 'axios';
import { Oval } from 'react-loader-spinner';

const ProfileInfo = () => {
  const [filter, setFilter] = useState(0);
  const [idToFind, setIdToFind] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [currentPage, setCurrentPage] = useState(null);

  const handleSearch = async () => {
    setLoading(true);
    setMessage('');
    setCurrentPage(null);

    try {
      let page = 1;
      while (true) {
        setCurrentPage(page);
        const response = await axios.post('https://admin.golive.mobi/findsearch', {
          filter: filter,
          id_to_find: idToFind
        });

        if (response.data.message) {
          setMessage(response.data.message);
          setCurrentPage(null); // Скрываем сообщение о поиске страниц
          break;
        }
        page += 1;
      }
    } catch (error) {
      setCurrentPage(null); // Скрываем сообщение о поиске страниц
      if (error.response && error.response.data) {
        setMessage(error.response.data.detail);
      } else {
        setMessage('An error occurred while searching.');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={styles.container}>
      <h2 style={styles.heading}>Profile Search</h2>
      <div style={styles.formGroup}>
        <label style={styles.label} htmlFor="filter">Filter:</label>
        <select
          id="filter"
          style={styles.select}
          value={filter}
          onChange={(e) => setFilter(Number(e.target.value))}
        >
          <option value={0}>Rejected</option>
          <option value={1}>Approved</option>
          <option value={2}>Waiting</option>
        </select>
      </div>
      <div style={styles.formGroup}>
        <label style={styles.label} htmlFor="idToFind">ID to Find:</label>
        <input
          type="text"
          id="idToFind"
          style={styles.input}
          value={idToFind}
          onChange={(e) => setIdToFind(e.target.value)}
          placeholder="Enter ID"
        />
      </div>
      <button style={styles.button} onClick={handleSearch}>Find</button>

      {loading && (
        <div style={styles.loader}>
          <Oval height={80} width={80} color="#00BFFF" ariaLabel="loading" />
        </div>
      )}

      {currentPage && <p style={styles.message}>Pages are being searched...</p>}
      {message && <p style={styles.message}>{message}</p>}
    </div>
  );
};

const styles = {
  container: {
    maxWidth: '600px',
    margin: '0 auto',
    padding: '20px',
    textAlign: 'center',
    fontFamily: 'Arial, sans-serif',
    backgroundColor: '#f9f9f9',
    borderRadius: '8px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
  },
  heading: {
    marginBottom: '20px',
    color: '#333',
  },
  formGroup: {
    marginBottom: '15px',
  },
  label: {
    display: 'block',
    marginBottom: '5px',
    fontWeight: 'bold',
    color: '#333',
  },
  select: {
    width: '100%',
    padding: '10px',
    borderRadius: '4px',
    border: '1px solid #ccc',
  },
  input: {
    width: '100%',
    padding: '10px',
    borderRadius: '4px',
    border: '1px solid #ccc',
  },
  button: {
    padding: '10px 20px',
    backgroundColor: '#007BFF',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '16px',
  },
  buttonHover: {
    backgroundColor: '#0056b3',
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
  },
  message: {
    marginTop: '20px',
    color: '#333',
  },
};

export default ProfileInfo;
