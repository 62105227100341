import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import crosssnap from './crosssnap.svg'
import { Oval } from 'react-loader-spinner';

const Models = () => {
  const [moderatorStatus, setModeratorStatus] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingSnap, setIsLoadingSnap] = useState(true);
  const [streams, setStreams] = useState([]);
  const [hoveredStreamId, setHoveredStreamId] = useState(null);
  const [records, setRecords] = useState([]);
  const [ snapOut, setSnapOut ] = useState(false)
  const [hoveredPhotoIndex, setHoveredPhotoIndex] = useState(null);
  const [hoveredPhotoIndices, setHoveredPhotoIndices] = useState([]);
  const [ checkUID, setCheckUID ] = useState('') 
  const [loadingStreams, setLoadingStreams] = useState({});

  const [unavailableStreams, setUnavailableStreams] = useState({});

  let savedUserId = null;

  useEffect(() => {
    // Запрос статуса модератора при монтировании компонента
    fetchModeratorStatus();
    fetchStreams().then(() => setIsLoading(false));
    fetchRecords();
    // Этот useEffect больше не зависит напрямую от изменений streams или moderatorStatus
  }, []);

  const handleMouseEnter = (recordIndex, photoIndex) => {
    setHoveredPhotoIndices(prevIndices => {
      const updatedIndices = [...prevIndices];
      updatedIndices[recordIndex] = photoIndex;
      return updatedIndices;
    });
  };

  const handleMouseLeave = () => {
    setHoveredPhotoIndices([]);
  };
  
  useEffect(() => {
    // Вызов fetchStreams при монтировании и затем каждые 15 секунд
    const interval = setInterval(fetchStreams, 15000);
    return () => clearInterval(interval);
    // Этот вызов не зависит от изменений других состояний и переменных,
    // так что не нужно включать их в список зависимостей
  }, []);
  
  useEffect(() => {
    // Обновление статуса потоков каждые 100 секунд, если moderatorStatus === 1
    let updateInterval = null;
    if (moderatorStatus === 1) {
      updateInterval = setInterval(updateStreamsStatus, 100000);
    }
    return () => clearInterval(updateInterval);
    // Зависит только от moderatorStatus, что предотвращает ненужные вызовы
  }, [moderatorStatus]);
  

  const fetchModeratorStatus = async () => {
    try {
      const response = await fetch('https://golive.mobi/api/stream/modes/get/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
        },
        body: JSON.stringify({}),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      const newStatus = data.active ? "active" : "false";
      setModeratorStatus(newStatus);
    } catch (error) {
      console.error('Error fetching moderator status:', error);
    }
  };


  const fetchViewerCounts = async () => {
    const viewerDataPromises = streams.map(stream => 
      fetch('https://golive.mobi/api/stream/data/viewers/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
        },
        body: JSON.stringify({ "stream_id": stream.stream_id })
      }).then(response => response.json())
        .then(data => {
          if (data.success) {
            return { stream_id: stream.stream_id, count: data.count };
          } else {
            return { stream_id: stream.stream_id, count: 0 };
          }
        })
        .catch(() => ({ stream_id: stream.stream_id, count: 0 }))
    );
  
    const viewerCounts = await Promise.all(viewerDataPromises);
    setStreams(currentStreams => currentStreams.map(stream => ({
      ...stream,
      viewers: viewerCounts.find(v => v.stream_id === stream.stream_id)?.count || 0
    })));
  };
  
  useEffect(() => {
    // Set up an interval to fetch viewer counts every 5 seconds
    const viewerCountInterval = setInterval(() => {
      if (streams.length > 0) { // Only fetch viewer counts if there are streams
        fetchViewerCounts();
      }
    }, 5000);
  
    // Clean up the interval when the component unmounts or streams are empty
    return () => clearInterval(viewerCountInterval);
  }, [streams]); // Include streams in the dependency array to re-set the interval when streams update
  

  {/*const fetchStreams = async () => {
    try {
      const urls = [
        {
          url: 'https://golive.mobi/api/stream/list/sandbox/',
          body: JSON.stringify({
            "id": "cd00895a05534ffd9c8b94dd75058df21683737450",
            "type": "",
            "page_size": 200
          }),
          label: 'Sandbox'
        },
        {
          url: 'https://golive.mobi/api/stream/list/',
          body: JSON.stringify({
            "id": "cd00895a05534ffd9c8b94dd75058df21683737450",
            "type": "",
            "page_size": 200
          }),
          label: 'Production'
        },
        {
          url: 'https://golive.mobi/api/stream/list/sandbox/',
          body: JSON.stringify({
            "test": 1,
            "id": "cd00895a05534ffd9c8b94dd75058df21683737450",
            "type": "",
            "page_size": 200
          }),
          label: 'Sand/Test non-approve'
        }
      ];
  
      const fetchRequests = urls.map((config, index) =>
        fetch(config.url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
          },
          body: config.body,
        }).then(response => response.json().then(data => ({
          streams: data.list.map(stream => ({
            stream_id: stream.stream_id,
            id: stream.id,
            timestamp: Date.now(),
            label: config.label // Переменная config доступна здесь
          }))
        })))
      );
  
      const results = await Promise.all(fetchRequests);
      const newStreams = results.flatMap(result => result.streams);
    setStreams(newStreams);
    setStreams(newStreams);
    } catch (error) {
      console.error('Error fetching streams:', error);
    }
  };*/}

  const fetchStreams = async () => {
    try {
        const urls = [
            {
                url: 'https://golive.mobi/api/stream/list/sandbox/',
                body: JSON.stringify({ "id": "cd00895a05534ffd9c8b94dd75058df21683737450", "type": "", "page_size": 200 }),
                label: 'Sandbox'
            },
            {
                url: 'https://golive.mobi/api/stream/list/',
                body: JSON.stringify({ "id": "cd00895a05534ffd9c8b94dd75058df21683737450", "type": "", "page_size": 200 }),
                label: 'Production'
            },
            {
                url: 'https://golive.mobi/api/stream/list/sandbox/',
                body: JSON.stringify({ "test": 1, "id": "cd00895a05534ffd9c8b94dd75058df21683737450", "type": "", "page_size": 200 }),
                label: 'Sand/Test non-approve'
            }
        ];

        const fetchRequests = urls.map((config) =>
            fetch(config.url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
                },
                body: config.body,
            }).then(response => response.json().then(data => ({
                streams: data.list.map(stream => ({
                    stream_id: stream.stream_id,
                    id: stream.id,
                    timestamp: Date.now(),
                    label: config.label
                }))
            })))
        );

        const results = await Promise.all(fetchRequests);
        const newStreamsData = results.flatMap(result => result.streams);
        mergeStreamData(newStreamsData);
    } catch (error) {
        console.error('Error fetching streams:', error);
    }
};

// Helper function to merge new stream data with existing to preserve viewer counts
const mergeStreamData = (newStreamsData) => {
    setStreams(currentStreams => {
        const mergedStreams = newStreamsData.map(newStream => {
            const existingStream = currentStreams.find(s => s.stream_id === newStream.stream_id);
            return {
                ...newStream,
                viewers: existingStream ? existingStream.viewers : 0 // Preserve viewers if exists, else initialize to 0
            };
        });
        return mergedStreams;
    });
};

  
  
  

  const updateStreamsStatus = async () => {
    const twoMinutesAgo = Date.now() - 270000;
    streams.forEach(async (stream) => {
      if (stream.timestamp < twoMinutesAgo) {
        try {
          const response = await fetch(`https://golive.mobi/api/stream/update/sandbox/`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
            },
            body: JSON.stringify({
              "stream_id": stream.stream_id,
              "status": 0
            }),
          });

          if (!response.ok) {
            throw new Error('Failed to update stream status');
          }
        } catch (error) {
          console.error('Error updating stream status:', error);
        }
      }
    });
  };

  const fetchRecords = async () => {
    setSnapOut(false)
    setRecords([])
    setIsLoadingSnap(true);
    const response = await fetch('https://apilive.golive.mobi/random_user_records/');
    if (response.ok) {
      const data = await response.json();
      setRecords(data.records);
      setCheckUID(data.user_id);
    } else {
      if (response.status === 404) {
        
        setIsLoadingSnap(false);
        setSnapOut(true);
      }
      console.error('Failed to fetch records');
    }
    setIsLoadingSnap(false);
  };
  

  useEffect(() => {
    let statusUpdateInterval = null;
  
    if (moderatorStatus === 1) {
      statusUpdateInterval = setInterval(async () => {
        try {
          const response = await fetch('https://000golive.mobi/api/stream/moderator-sandbox/update/', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
            },
            body: JSON.stringify({
              "moderator_id": "moderator_1",
              "value": 1,
            }),
          });
  
          if (!response.ok) {
            throw new Error('Failed to update status');
          }
          
          console.log('Status updated successfully');
        } catch (error) {
          console.error('Error updating moderator status:', error);
        }
      }, 110 * 1000); // 1 минута и 50 секунд
    }
  
    return () => {
      if (statusUpdateInterval) {
        clearInterval(statusUpdateInterval);
      }
    };
  }, [moderatorStatus]);
  

  const toggleSwitch = async () => {
    setIsLoading(true);
    try {
      const newStatus = moderatorStatus === "active" ? "false" : "active";
      const value = newStatus === "active" ? 1 : 0;
      const response = await fetch('https://golive.mobi/api/stream/modes/update/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
        },
        body: JSON.stringify({
          "name": "active",
          "value": value,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to update status');
      }

      setModeratorStatus(newStatus);
    } catch (error) {
      console.error('Error updating moderator status:', error);
    } finally {
      setIsLoading(false);
    }
  };


  const endStream = async (streamId) => {
    const stream = streams.find(s => s.stream_id === streamId);
    if (!stream) return;
  
    try {
      // Завершение стрима
      let response = await fetch('https://golive.mobi/api/stream/end/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
        },
        body: JSON.stringify({
          "id": stream.id, // Использование id стрима из полученных данных
          "stream_id": streamId, // Идентификатор стрима для завершения
        }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to end stream');
      }
  
      // Отключение стрима
      response = await fetch('https://golive.mobi/api/stream/enable/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
        },
        body: JSON.stringify({
          "id": stream.id,
          "value": -1,
        }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to disable stream');
      }
  
      // Удаление стрима из списка
      setStreams(currentStreams => currentStreams.filter(s => s.stream_id !== streamId));
    } catch (error) {
      console.error('Error ending and disabling stream:', error);
    }
  };
  
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === ' ') {
        event.preventDefault();
        // Сбор всех roomIds из текущих записей
        const allRoomIds = records.map(record => record.room_id);
        // Вызов handleSpacebarPress с массивом всех roomIds
        handleSpacebarPress(allRoomIds);
        
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [records]); // Добавьте records в список зависимостей, чтобы обновлять обработчик при изменении records




  const handleCrossClick = async (roomIds) => {

  
    try {
      await fetch('https://apilive.golive.mobi/random_user_records/checkban', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
        },
        body: JSON.stringify({
          room_ids: roomIds,
          user_ban: checkUID, // Используем сохраненный userId
        }),
      });

      fetchRecords();
    } catch (error) {
      console.error('Ошибка при попытке бана пользователя:', error);
    }
  };
  
  
  
  const handleSpacebarPress = async (roomIds) => {
    try {
      await fetch('https://apilive.golive.mobi/random_user_records/check', {
        method: 'POST', // Используем метод POST для отправки данных в теле запроса
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
        },
        body: JSON.stringify({
          room_ids: roomIds, // Используем правильное имя поля для списка идентификаторов комнат
        }),
      });
  
      // Дожидаемся успешного выполнения запроса и затем вызываем функцию fetchRecords()
      fetchRecords();
    } catch (error) {
      console.error('Error while handling spacebar press:', error);
    }
  };

  
  const approveStream = async (streamId) => {
    setLoadingStreams(prev => ({ ...prev, [streamId]: true })); // Set loading to true for the specific stream
    const stream = streams.find(s => s.stream_id === streamId);
    if (!stream) return;
  
    try {
      const response = await fetch('https://golive.mobi/api/stream/enable/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
        },
        body: JSON.stringify({
          "id": stream.id,
          "value": 1
        }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to approve stream');
      }
  
      console.log('Stream approved successfully');
    } catch (error) {
      console.error('Error approving stream:', error);
    } finally {
      setLoadingStreams(prev => ({ ...prev, [streamId]: false })); // Set loading to false for the specific stream
    }
  };
  

  return (

    <div style={{ display: 'flex', minHeight: '100vh', fontFamily: 'system-ui' }}>
    {/* Левая часть страницы */}
    <div style={{ flex: 1, overflowY: 'auto', maxHeight: '100vh', padding: '20px', backgroundColor: '#f0f0f0' }}  >

    { snapOut ? ( <p>No rooms found. Press Spacebar to reload or try again later</p> ) : null }

    {isLoadingSnap ? (
        <p>Loading snapshots...</p>
      ) : (<>
  {records.map((record, recordIndex) => (
        <div key={recordIndex} style={{ marginBottom: '20px', border: '1px solid #ccc', padding: '10px' }}>
          {/* Отображаем Room ID */}
          <div style={{ textAlign: 'left', marginBottom: '10px' }}>
  <span style={{ float: 'left' }}>Room ID: <strong>{record.room_id}</strong></span>
  <span style={{ float: 'right' }}>Date: <strong>{new Date(parseInt(record.timestamp)).toLocaleString()}</strong></span>
  <div style={{ clear: 'both' }}></div>
</div>

          <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start', gap: '5px' }}>
            {record.photolist.map((photoUrl, photoIndex) => (
              <div
                key={photoIndex}
                style={{
                  
                  height: '180px',
                  position: 'relative',
                  overflow: 'hidden',
                  borderRadius: '5%',
                  filter: hoveredPhotoIndices[recordIndex] === photoIndex ? 'brightness(70%)' : 'none',
                }}
                onMouseEnter={() => handleMouseEnter(recordIndex, photoIndex)}
                onMouseLeave={handleMouseLeave}
                onClick={() => handleCrossClick([record.room_id], record.user_id)}
              >
                <img
                  src={photoUrl}
                  alt={`Photograph ${photoIndex + 1}`}
                  style={{ height: '100%', width: 'auto', maxWidth: 'none', borderRadius: '5%' }}
                />
                
                {hoveredPhotoIndices[recordIndex] === photoIndex && (
                  <div
                    style={{
                      position: 'absolute',
                      top: '0',
                      left: '0',
                      width: '100%',
                      height: '100%',
                      background: 'rgba(255, 255, 255, 0.2)',
                    }}
                  ></div>
                )}

{hoveredPhotoIndices[recordIndex] === photoIndex && (
                  <img
                    src={crosssnap}
                    alt="Cross"
                    style={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      width: '40px',
                      height: '40px',
                      
                    }}
                  />
                )}
              </div>
            ))}
          </div>
        </div>
      ))} </>
)}
</div>




    {/* Правая часть страницы с стримами */}
    <div style={{ flex: 1, padding: '20px' }}>
      
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: '1.5%', marginTop: '1.5%', fontFamily: 'system-ui' }}>
      {isLoading ? (
        <p>Loading status...</p>
      ) : (
        <>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p style={{ margin: '0 10px 0 0' }}>Stream Status - {moderatorStatus === "active" ? 'Production' : 'Sandbox'}</p>
            <div onClick={!isLoading ? toggleSwitch : null} style={{
              cursor: isLoading ? 'default' : 'pointer',
              display: 'flex',
              alignItems: 'center',
            }}>
              <div style={{
                width: '50px',
                height: '25px',
                backgroundColor: moderatorStatus === "active" ? '#4CAF50' : '#ccc',
                borderRadius: '25px',
                transition: 'background-color 0.3s',
                position: 'relative',
              }}>
                <div style={{
                  position: 'absolute',
                  top: '2.5px',
                  left: moderatorStatus === "active" ? '28px' : '2.5px',
                  width: '20px',
                  height: '20px',
                  backgroundColor: '#fff',
                  borderRadius: '50%',
                  transition: 'left 0.3s',
                }}></div>
              </div>
            </div>
          </div>
  
          {streams.length > 0 ? (
            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', marginTop: '20px' }}>
              {streams.map(stream => (
                <div 
                  key={stream.stream_id} 
                  style={{ margin: '10px', position: 'relative', width: '250px', height: '250px' }} // Устанавливаем размер контейнера под размер видео
                  onMouseEnter={() => setHoveredStreamId(stream.stream_id)}
                  onMouseLeave={() => setHoveredStreamId(null)}
                >
                  
                  <div style={{ position: 'absolute', top: '0', left: '0', padding: '5px 10px', color: 'white', backgroundColor: 'rgba(0, 0, 0, 0.3)', borderRadius: '0 0 5px 0', zIndex: 1 }}>
      Viewers: {stream.viewers}
    </div>

                  <ReactPlayer 
  url={`https://pull.golive.mobi/golive/${stream.stream_id}.flv`} 
  playing 
  width="100%" 
  height="100%" 
  style={{ position: 'absolute', top: '0', left: '0', borderRadius: '5%' }} 
  muted={true}
  onReady={() => {
    // Когда стрим готов, убираем его из списка загружаемых
    setLoadingStreams(prev => ({ ...prev, [stream.stream_id]: false }));
  }}
  onBuffer={() => {
    // Когда стрим буферизуется, добавляем его в список загружаемых
    setLoadingStreams(prev => ({ ...prev, [stream.stream_id]: true }));
  }}
  
/>


{loadingStreams[stream.stream_id] && (
      <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
        <Oval color="#00BFFF" height={50} width={50} />
      </div>
    )}



                  {hoveredStreamId === stream.stream_id && (
                    <button 
                      style={{
                        position: 'absolute',
                        top: '30%', // Центрируем кнопку по вертикали
                        left: '50%', // Центрируем кнопку по горизонтали
                        transform: 'translate(-50%, -50%)', // Сдвигаем кнопку на половину её размера для точного центрирования
                        cursor: 'pointer',
                        zIndex: 1000,
                        background: 'rgba(176, 66, 44, 0.7)',
                        color: 'white',
                        border: 'none',
                        padding: '10px 20px',
                        borderRadius: '5px',
                        fontWeight: 'bold',
                      }} 
                      onClick={() => endStream(stream.stream_id)}
                    >
                      Stop and disable stream
                    </button>
                  )}

                  {/* Approve stream button for "Sand/Test non-approve" streams */}
    {hoveredStreamId === stream.stream_id && stream.label === 'Sand/Test non-approve' && (
      <button 
      style={{
        position: 'absolute',
        top: '70%', // Центрируем кнопку по вертикали
        left: '50%', // Центрируем кнопку по горизонтали
        transform: 'translate(-50%, -50%)', // Сдвигаем кнопку на половину её размера для точного центрирования
        cursor: 'pointer',
        zIndex: 1000,
        background: 'rgba(84, 176, 44, 0.7)',
        color: 'white',
        border: 'none',
        padding: '10px 20px',
        borderRadius: '5px',
        fontWeight: 'bold',
      }} 
        onClick={() => approveStream(stream.stream_id)}
      >
        Approve stream
      </button>
    )}

<p style={{ marginTop: '100%', textAlign: 'center',  position: 'absolute',  padding: '5px 10px', color: 'white', backgroundColor: 'rgba(0, 0, 0, 0.3)', borderRadius: '0 0 5px 0', zIndex: 1 }}>{stream.label}</p>
{/*<p style={{ marginTop: '100%', textAlign: 'center' }}>{stream.label}</p>*/}
                </div>
                
              ))}
            </div>
          ) : (
            <p>There are no active streams.</p>
          )}
        </>
      )}
    </div>
      
    </div>
  </div>

    
  );
  
  
  
};

export default Models;
