import React, { useState, useEffect, useRef } from 'react';
import './style.css';
import { useHistory } from 'react-router-dom';
import { Oval } from 'react-loader-spinner';
import axios from 'axios';
import block from './block.svg';
import next from './next.svg';

const TableGeneral = () => {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [selectedRoomId, setSelectedRoomId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [photos, setPhotos] = useState([]);

  const [roomid, setRoomid] = useState('');
  const [user1, setUser1] = useState('');
  const [user2, setUser2] = useState('');
  const [typecall, setTypecall] = useState('');
  const [selectedPhotoIndex, setSelectedPhotoIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPhotoId, setSelectedPhotoId] = useState('');
  const [time, setTime] = useState('');

  const [ show, setShow ] = useState(false)

  const openModal = async (roomId) => {
    try {
      const response = await axios.get(`https://apilive.golive.mobi/picsshow?room_id=${roomId}`);
      const { photo } = response.data[roomId]; // Extract the 'photo' array
      setPhotos(photo);
      setSelectedRoomId(roomId);
      setShowModal(true);
    } catch (error) {
      console.error('Error fetching photos:', error);
    }
  };

  const fetchData = async () => {
    try {
      setIsLoading(true); // Показать индикатор загрузки
      const response = await axios.get('https://apilive.golive.mobi/single_fakerandomman_v3');
      const status = response.status;
      if (status === 200) {
        setRoomid(response.data.room_id);
        setUser1(response.data.user_1);
        setUser2(response.data.user_2);
        setTypecall(response.data.type_call);
        setPhotos(response.data.photo);
        setTime(response.data.timestamp);
        setIsLoading(false); // Скрыть индикатор загрузки
        setShow(true)
      } else {
        console.log('Received status other than 200:', status);
        //await fetchData(); // Повторный вызов функции в случае ошибки
        setIsLoading(false);
        setShowModal(true);
        setShow(false)
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      //await fetchData(); // Повторный вызов функции в случае ошибки
      setIsLoading(false);
      setShowModal(true);
      setShow(false)
    }
  };
  
  

  useEffect(() => {
    fetchData();
  }, []);

  const handlePhotoClick = (index) => {
    setSelectedPhotoIndex(index);
    setSelectedPhotoId(getPhotoId(photos[index]));
  };

  // Функция для извлечения ID из названия файла
  const getPhotoId = (photo) => {
    const fileName = photo.split('-')[2]; // Разбиваем название файла и берем третью часть
    return fileName.substring(0, fileName.lastIndexOf('.')); // Убираем расширение файла
  };

  const handleViewer = async () => {
    const currentRoomId = roomid;
  
    const responsecheck = await axios.get(`https://apilive.golive.mobi/check/${currentRoomId}`);
    console.log(responsecheck.data.message);
  }

  const handleSkip = async () => {
    try {
      setIsLoading(true);
  
      // Store the current roomid value
      setRoomid('');
      setUser1('');
      setUser2('');
      setTypecall('');
      setPhotos([]);
      setTime('')
      const response = await axios.get('https://apilive.golive.mobi/single_fakerandomman_v3');
      if (response.status === 200) {
  
        setRoomid(response.data.room_id);
        setUser1(response.data.user_1);
        setUser2(response.data.user_2);
        setTypecall(response.data.type_call);
        setPhotos(response.data.photo);
        setTime(response.data.timestamp)
        setSelectedPhotoId(getPhotoId(response.data.photo[0]));
        setSelectedPhotoIndex(0); // Выбор первой фотографии
        setIsLoading(false);
        setShow(true);
  
        // Call handleViewer here, after the asynchronous actions are completed
        handleViewer();
      } else if (response.status === 404) {
        console.log('Received 404 error');
        setIsLoading(false);
        // Добавить блок с сообщением "All photos have been moderated"
        setRoomid('');
        setUser1('');
        setUser2('');
        setTypecall('');
        setTime('')
        setPhotos([]);
        setShowModal(true); // Закрыть модальное окно, если оно было открыто
        setShow(false);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsLoading(false);
      setShowModal(true);
      setShow(false);
      // await handleSkip(); // Повторный вызов функции в случае ошибки
    }
  
    // const currentRoomId = roomid;
    // const responsecheck = await axios.get(`https://apilive.golive.mobi/check/${currentRoomId}`);
    // console.log(responsecheck.data.message);
  };
  
  
  
  

  const handleKeyPress = (e) => {
    if (e.key === 'ArrowRight' && selectedPhotoIndex < photos.length - 1) {
      setSelectedPhotoIndex(selectedPhotoIndex + 1);
      setSelectedPhotoId(getPhotoId(photos[selectedPhotoIndex + 1]));
    } else if (e.key === 'ArrowLeft' && selectedPhotoIndex > 0) {
      setSelectedPhotoIndex(selectedPhotoIndex - 1);
      setSelectedPhotoId(getPhotoId(photos[selectedPhotoIndex - 1]));
    } else if (e.key === 'ArrowRight' && selectedPhotoIndex === photos.length - 1) {
      handleSkip(); // Запуск загрузки данных с API
    } else if (e.key === ' ' || e.key === 'Spacebar') {
      e.preventDefault(); // Предотвращаем прокрутку страницы при нажатии пробела
      handleSkip(); // Вызываем handleSkip() при нажатии пробела
      //handleViewer();
    }
  };
  

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [selectedPhotoIndex, photos]);


  const handleBlock = async () => {
    try {
      const requestBody = JSON.stringify([{
        id: user1,
        signed_up: 1,
      }]);
  
      const response = await fetch('https://golive.mobi/api/user/m-delete/', {
        method: 'POST',
        headers: {
          Authorization: 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
          'Content-Type': 'application/json',
        },
        body: requestBody,
      });
  
      if (response.ok) {
        // If the response is successful, perform handleSkip logic here
        await handleSkip();
      } else {
        console.error('Block request failed with status:', response.status);
        // Handle error response as needed
      }
    } catch (error) {
      console.error('Error blocking user:', error);
      // Handle any errors from the fetch request
    }
  };
  
  function formatId(fullString) {
    // Проверка наличия строки
    if (fullString) {
      // Разбиваем строку по дефису
      const parts = fullString.split('-');
  
      // Проверка, что есть хотя бы три части
      if (parts.length >= 3) {
        // Возвращаем третью часть (индекс 2)
        return parts[parts.length - 3];
      }
    }
  
    // Возвращаем исходную строку, если не удается получить нужную часть
    return fullString;
  }
  
  const handleBlockDemo = async () => {
    try {
      const requestBody = JSON.stringify([{
        id: user1,
        signed_up: 1,
      }]);
  
      const response = await fetch('https://golive.mobi/api/user/m-delete/demo/', {
        method: 'POST',
        headers: {
          Authorization: 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
          'Content-Type': 'application/json',
        },
        body: requestBody,
      });
  
      if (response.ok) {
        // If the response is successful, perform handleSkip logic here
        await handleSkip();
      } else {
        console.error('Block request failed with status:', response.status);
        // Handle error response as needed
      }
    } catch (error) {
      console.error('Error blocking user:', error);
      // Handle any errors from the fetch request
    }
  };

  function formatDate(timestamp) {
    if (!timestamp) {
        return '';
    }

    const dateObj = new Date(Number(timestamp)); // Преобразуем timestamp в число

    if (isNaN(dateObj) || dateObj.toString() === 'Invalid Date') {
        // Если преобразование не удалось или результат является недопустимой датой
        return 'Invalid timestamp';
    }

    const year = dateObj.getFullYear();
    const month = ('0' + (dateObj.getMonth() + 1)).slice(-2);
    const day = ('0' + dateObj.getDate()).slice(-2);
    const hours = ('0' + dateObj.getHours()).slice(-2);
    const minutes = ('0' + dateObj.getMinutes()).slice(-2);

    return `${day}.${month}.${year} ${hours}:${minutes}`;
}

  return (
    
    <div>
      <div>
 
      {isLoading && ( // Отображение индикатора загрузки и затемнение
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 9999,
              backdropFilter: 'blur(20px)'
            }}
          >
            <Oval color="#00BFFF" height={50} width={50} />
          </div>
        )}

       {/* <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h2 style={{ marginLeft: '1%', fontFamily: 'inter' }}>Live snapshots</h2>
          </div> */}




        <div
          style={{
            border: '1px solid #bebebe',
            width: '95%',
            display: 'flex',
            marginLeft: 'auto',
            marginRight: 'auto',
            padding: '20px',
            borderRadius: '5px',
            boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)',
            marginBottom: '2%',
          }}
        >

{/*
{photos.length === 0 &&  (
  <div style={{ margin: 'auto', textAlign: 'center' }}>
    <p>All photos have been moderated</p>
    <p>Filter mode: Only real call</p>
  </div>
)} */}

{show === false && (
  <div style={{ margin: 'auto', textAlign: 'center' }}>
    <p>All photos have been moderated</p>
    <p>Filter mode: Only real call</p>
  </div>
)}

{photos.length !== 0 && show === true && (<>

          <div style={{ width: '70%' }}>

          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
  <div style={{ marginRight: '20px' }}>
    <span style={{ marginRight: '20px' }}>Room ID:</span>
    <span style={{ fontWeight: 'bold' }}>{roomid}</span>
  </div>
  <div style={{ marginRight: '20px' }}>
    <span style={{ marginRight: '20px' }}>User ID:</span>
    <span style={{ fontWeight: 'bold' }}>{user1}</span>
  </div>
  <div style={{ marginRight: '20px' }}>
    <span style={{ marginRight: '20px' }}>Type call:</span>
    <span style={{ fontWeight: 'bold' }}>{typecall}</span>
  </div>
  <div style={{ marginRight: '20px' }}>
    <span style={{ marginRight: '20px' }}>Date:</span>
    <span style={{ fontWeight: 'bold' }}>{formatDate(time)}</span>
  </div>
</div>
<hr />
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {photos.map((photo, index) => (
                <img
                  key={index}
                  src={photo}
                  alt={`Snapshot ${index}`}
                  style={{
                    width: '100px',
                    height: 'auto',
                    margin: '5px',
                    cursor: 'pointer',
                    border: index === selectedPhotoIndex ? '4px solid #9e19ff' : 'none',
                    borderRadius: '5px',
                  }}
                  onClick={() => handlePhotoClick(index)}
                />
              ))}
            </div>
          </div>
          <div style={{ width: '30%', marginLeft: 'auto', marginRight: 'auto', display: 'block' }}>
            {photos[selectedPhotoIndex] ? (
              <img
                src={photos[selectedPhotoIndex]}
                alt="Selected Snapshot"
                style={{ width: '70%', height: 'auto', marginLeft: 'auto', marginRight: 'auto', display: 'block', borderRadius: '5px' }}
              />
            ) : (
              <p>No photo selected</p>
            )}
            <p style={{ 
              textAlign: 'center',
              fontWeight: 500,
            }}>ID: {formatId(photos[selectedPhotoIndex])}</p>
            <div style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', width: '70%' }}>
            <button 
              type="button"
                style={{
                  width: '100%',
                  height: '35px',
                  marginTop: '2%',
                  background: '#ff2121',
                  color: 'white',
                  borderRadius: '5px',
                  border: '0px',
                  fontSize: '15px',
                  fontFamily: 'sans-serif',
                }} 
                onClick={handleBlock}
              >
                Block
              </button>
            {/*  <br />
              <button 
              type="button"
                style={{
                  width: '100%',
                  height: '35px',
                  marginTop: '2%',
                  background: '#ff2121',
                  color: 'white',
                  borderRadius: '5px',
                  border: '0px',
                  fontSize: '15px',
                  fontFamily: 'sans-serif',
                }} 
                onClick={handleBlockDemo}
              >
                Demo Block
              </button>*/}
              <br />
              <button 
              type="button"
                style={{
                  width: '100%',
                  height: '35px',
                  marginTop: '2%',
                  background: '#ff2121',
                  color: 'white',
                  borderRadius: '5px',
                  border: '0px',
                  fontSize: '15px',
                  fontFamily: 'sans-serif',
                }} 
                onClick={handleSkip} // Добавлено
              >
                Skip
              </button>
            </div>
            
          </div>
         </> )}
        </div>
       
        <br  />

      </div>
      
    </div>
    
  );
};

export default TableGeneral;
