import React, { useState, useEffect, useCallback } from 'react';
import './style.css';
import { useHistory } from 'react-router-dom';
import check_active from './check_active.svg'
import check_disable from './check_disable.svg'

const TableGeneral = () => {
  const history = useHistory();

  const handleClick = () => {
    history.push('/modelcreate');
  };

  const [showForm, setShowForm] = useState(false);

  const toggleForm = () => {
    setShowForm(!showForm);
  };

  // Тестовые данные для таблицы
  const initialData = [

    // Добавьте больше тестовых данных здесь...
  ];

  const fetchData = useCallback(async () => {
    try {
      const response = await fetch('https://golive.mobi/api/user/m-list/', {
        method: 'POST',
        headers: {
          Authorization: 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const responseData = await response.json();
        setData(responseData);
      } else {
        console.log('Error:', response.status);
      }
    } catch (error) {
      console.log('Error:', error);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // Состояние для выбора элементов
  const [data, setData] = useState(initialData);
  const [selectAll, setSelectAll] = useState(false);

  const toggleSelectAll = () => {
    setSelectAll(!selectAll);
    const updatedData = data.map((item) => ({ ...item, selected: !selectAll }));
    setData(updatedData);
  };

  const toggleSelectItem = (id) => {
    const updatedData = data.map((item) => {
      if (item.id === id) {
        return { ...item, selected: !item.selected };
      }
      return item;
    });
    setData(updatedData);
  };

  const handleModelClick = useCallback(
    (id) => {
      localStorage.setItem('id_models', id); // Save ID to localStorage
      history.push(`/new/profile_model/${id}`); // Navigate to the profile_model page with the selected ID
    },
    [history]
  );

  const handleRemove = async () => {
    // Get the IDs of selected rows
    const selectedIds = data.filter((item) => item.selected).map((item) => item.id);
  
    try {
      const requestData = [];
      selectedIds.forEach((id) => {
        const item = data.find((item) => item.id === id);
        if (item) {
          requestData.push({
            id,
            signed_up: item.signed_up === 1 ? 1 : 0,
          });
        }
      });
      const response = await fetch('https://golive.mobi/api/user/m-delete/', {
        method: 'POST',
        headers: {
          Authorization: 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
      });
  
      if (response.ok) {
        // Handle successful response (e.g., show a success message, refresh data)
        console.log('Selected rows successfully deleted!');
        // Reload the data after successful deletion
        fetchData(); // Assuming fetchData fetches data and updates the state
      } else {
        console.log('Error:', response.status);
      }
    } catch (error) {
      console.log('Error:', error);
    }
  };
  
  
  const handleUnRemove = async () => {
    // Get the IDs of selected rows
    const selectedIds = data.filter((item) => item.selected).map((item) => item.id);
  
    try {
      const requestData = selectedIds.map((id) => ({ id, signed_up: 1 }));
      const response = await fetch('https://golive.mobi/api/user/m-undelete/', {
        method: 'POST',
        headers: {
          Authorization: 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
      });
  
      if (response.ok) {
        // Handle successful response (e.g., show a success message, refresh data)
        console.log('Selected rows successfully undeleted!');
        // Reload the data after successful deletion
        fetchData(); // Assuming fetchData fetches data and updates the state
      } else {
        console.log('Error:', response.status);
      }
    } catch (error) {
      console.log('Error:', error);
    }
  };

  return (<div>
    <div style={{ width: '95%',
    marginLeft: 'auto',
    marginRight: 'auto',
    background: 'white',
    borderRadius: '15px',
    boxShadow: 'rgba(112, 144, 176, 0.2) 2px 2px 2px 2px',
    }}>
      <div>
        <button className="create-button" style={{ marginTop: '1%' }} onClick={handleClick}>
          + New Models
        </button>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h3 style={{marginLeft: '1%'}}></h3>
          <div style={{ display: 'flex', marginRight: '1%'}}>
          <button className="remove-button" style={{ width: '90px'}} onClick={handleRemove}>Block</button>
          <button className="remove-button" style={{ width: '90px'}} onClick={handleUnRemove}>Unblock</button>
          </div>
        </div>

        <table className="table">
          <thead style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.07)' }}>
            <tr>
              <th>
                <input
                  className="checkbox"
                  type="checkbox"
                  checked={selectAll}
                  onChange={toggleSelectAll}
                  style={{
                    alignItems: 'center',
                    display: 'flex',
                  }}
                />
              </th>

              <th style={{ color: 'black', fontFamily: "Noto Sans Mono", weight: '600', fontSize: '12px', textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)'}}>ID</th>
              <th style={{ color: 'black', fontFamily: "Noto Sans Mono", weight: 'bold', fontSize: '12px', textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)'}}>Name</th>
              {/**<th style={{ color: 'black', fontFamily: "Noto Sans Mono", weight: 'bold', fontSize: '12px', textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)'}}>New chats</th>**/}
              <th style={{ color: 'black', fontFamily: "Noto Sans Mono", weight: 'bold', fontSize: '12px', textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)'}}>Outgoing messages</th>
              <th style={{ color: 'black', fontFamily: "Noto Sans Mono", weight: 'bold', fontSize: '12px', textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)'}}>Incoming messages</th>
              <th style={{ color: 'black', fontFamily: "Noto Sans Mono", weight: 'bold', fontSize: '12px', textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)'}}>Outgoing calls</th>
              <th style={{ color: 'black', fontFamily: "Noto Sans Mono", weight: 'bold', fontSize: '12px', textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)'}}>Incoming calls</th>
              <th style={{ color: 'black', fontFamily: "Noto Sans Mono", weight: 'bold', fontSize: '12px', textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)'}}>Total time</th>
              <th style={{ color: 'black', fontFamily: "Noto Sans Mono", weight: 'bold', fontSize: '12px', textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)'}}>Random time</th>
              <th style={{ color: 'black', fontFamily: "Noto Sans Mono", weight: 'bold', fontSize: '12px', textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)'}}>Private time</th>
              {/**<th style={{ color: 'black', fontFamily: "Noto Sans Mono", weight: 'bold', fontSize: '12px', textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)'}}>Gifts</th>
              <th style={{ color: 'black', fontFamily: "Noto Sans Mono", weight: 'bold', fontSize: '12px', textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)'}}>Fan</th>
                <th style={{ color: 'black', fontFamily: "Noto Sans Mono", weight: 'bold', fontSize: '12px', textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)'}}>Average cost of a gift</th>**/}
            </tr>
          </thead>
          <tbody>
          {data
    .filter((item) => item.signed_up === 1) // Filter the data based on signed_up === 1
    .map((item, index) => (
              <tr
              key={item.id}
              className={`${index % 2 === 0 ? 'even-row' : 'odd-row'} ${item.selected ? 'selected-row' : 'non-selected-row'}`}
            >
                <td>
                  <input
                    type="checkbox"
                    checked={item.selected}
                    onChange={() => toggleSelectItem(item.id)}
                    style={{
                      borderRight: '1px solid rgba(0, 0, 0, 0.07)'
                      
                    }}
                  />
                </td>

                <td style={{
                  color: item.selected ? '#6D33FF' : '#606C80',
                  fontFamily: 'Noto Sans Mono',
                  fontWeight: '400',
                  fontSize: '12px',
                  textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)',  borderRight: '1px solid rgba(0, 0, 0, 0.07)'
                }}>
                  
                  <a style={{
                  color: item.selected ? '#6D33FF' : '#606C80',
                  fontFamily: 'Noto Sans Mono',
                  fontWeight: '400',
                  fontSize: '12px',
                  textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)',  borderRight: '1px solid rgba(0, 0, 0, 0.07)'
                }} href={`profile_model/${item.id}`}>{item.id}
                  </a>
                  </td>
                  <td style={{
                  color: item.selected ? '#6D33FF' : 'black',
                  fontFamily: 'Noto Sans Mono',
                  fontWeight: '400',
                  fontSize: '12px',
                  textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)',  borderRight: '1px solid rgba(0, 0, 0, 0.07)'
                }}>
                  <a href={`profile_model/${item.id}`} style={{
                  color: item.selected ? '#6D33FF' : 'black',
                  fontFamily: 'Noto Sans Mono',
                  fontWeight: '400',
                  fontSize: '12px',
                  textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)',  borderRight: '1px solid rgba(0, 0, 0, 0.07)'
                }} >
                  {item.name}
                  </a>
                  </td>
                  <td style={{
  color: item.selected ? '#6D33FF' : '#606C80',
  fontFamily: 'Noto Sans Mono',
  fontWeight: '400',
  fontSize: '12px',
  textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)', background: 'rgba(255, 241, 215, 0.3)'
}}>
  {item.messages?.outcoming ?? 0}
</td>
                 {/**  <td style={{
    color: item.selected ? '#6D33FF' : '#606C80',
    fontFamily: 'Noto Sans Mono',
    fontWeight: '400',
    fontSize: '12px',
    textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)'
  }}>{item.newChats}</td>**/}
<td style={{
  color: item.selected ? '#6D33FF' : '#606C80',
  fontFamily: 'Noto Sans Mono',
  fontWeight: '400',
  fontSize: '12px',
  textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)', background: 'rgba(216, 255, 212, 0.3)'
}}>
  {item.messages?.incoming ?? 0}
</td>
<td style={{
  color: item.selected ? '#6D33FF' : '#606C80',
  fontFamily: 'Noto Sans Mono',
  fontWeight: '400',
  fontSize: '12px',
  textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)', background: 'rgba(255, 241, 215, 0.3)'
}}>
  {item.calls?.outcoming ?? 0}
</td>
<td style={{
  color: item.selected ? '#6D33FF' : '#606C80',
  fontFamily: 'Noto Sans Mono',
  fontWeight: '400',
  fontSize: '12px',
  textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)', background: 'rgba(216, 255, 212, 0.3)'
}}>
  {item.calls?.incoming ?? 0}
</td>
<td style={{
  color: item.selected ? '#6D33FF' : '#606C80',
  fontFamily: 'Noto Sans Mono',
  fontWeight: '400',
  fontSize: '12px',
  textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)'
}}>
  {item.time?.total ?? 0}
</td>
<td style={{
  color: item.selected ? '#6D33FF' : '#606C80',
  fontFamily: 'Noto Sans Mono',
  fontWeight: '400',
  fontSize: '12px',
  textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)'
}}>
  {item.time?.random ?? 0}
</td>
<td style={{
  color: item.selected ? '#6D33FF' : '#606C80',
  fontFamily: 'Noto Sans Mono',
  fontWeight: '400',
  fontSize: '12px',
  textAlign: 'left', 
}}>
  {item.time?.private ?? 0}
</td>
                {/**<td style={{
    color: item.selected ? '#6D33FF' : '#606C80',
    fontFamily: 'Noto Sans Mono',
    fontWeight: '400',
    fontSize: '12px',
    textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)'
  }}>{item.privateTime}</td>
                <td style={{
    color: item.selected ? '#6D33FF' : '#606C80',
    fontFamily: 'Noto Sans Mono',
    fontWeight: '400',
    fontSize: '12px',
    textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)'
  }}>{item.gifts}</td>
                <td style={{
    color: item.selected ? '#6D33FF' : '#606C80',
    fontFamily: 'Noto Sans Mono',
    fontWeight: '400',
    fontSize: '12px',
    textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)'
  }}>{item.avgCostOfGift}</td>**/}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
    <br />
    </div>
  );
};

export default TableGeneral;
