import React, { useState, useEffect } from 'react';
import { Oval } from 'react-loader-spinner';

const BansList = () => {
  const [bans, setBans] = useState([]);
  const [userNames, setUserNames] = useState({});
  const [modalImage, setModalImage] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchBans();
  }, []);

  const fetchBans = () => {
    setIsLoading(true);
    fetch('https://apilive.golive.mobi/bans')
      .then((res) => res.json())
      .then(data => {
        setBans(data);
        fetchUserNames(data);
      })
      .catch((err) => {
        console.error('Error fetching bans:', err);
      });
  };

  // Загрузка имен пользователей опущена для краткости

  const handleHide = (roomId) => {
    setIsLoading(true);
    fetch(`https://apilive.golive.mobi/checkban/${roomId}`)
      .then(response => {
        if (!response.ok) throw new Error('Failed to hide the ban.');
        setBans(prevBans => prevBans.filter(ban => ban.room_id !== roomId));
      })
      .catch(error => console.error('Error hiding ban: ', error))
      .finally(() => setIsLoading(false));
  };

  const fetchUserNames = async (bansData) => {
    const uniqueUserIds = [...new Set(bansData.flatMap(ban => [ban.user_1, ban.user_2]))];
    const names = {};
    for (const userId of uniqueUserIds) {
      await fetch('https://golive.mobi/api/user/status/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
        },
        body: JSON.stringify({ id: userId }),
      })
      .then(res => res.json())
      .then(data => {
        names[userId] = data.name || userId;
      })
      .catch(err => console.error(`Error fetching name for user ${userId}:`, err));
    }
    setUserNames(names);
    setIsLoading(false);
  };

  const openModal = (imageUrl) => {
    setModalImage(imageUrl);
    setIsModalOpen(true);
  };

  const closeModal = () => setIsModalOpen(false);

  const unblockUser = (userId) => {
    setIsLoading(true);
    fetch('https://golive.mobi/api/user/m-undelete/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
      },
      body: JSON.stringify([{ id: userId, signed_up: 1 }]),
    })
    .then(response => {
      if (response.ok) {
        alert(`User ${userId} unblocked successfully.`);
        fetchBans(); // Refresh list after successful unblock
      } else {
        alert('Failed to unblock user.');
      }
    })
    .catch(error => {
      console.error('Error unblocking user: ', error);
    })
    .finally(() => setIsLoading(false));
  };

  

  if (isLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: 'white' }}>
        <Oval color="#4CAF50" height={80} width={80} />
      </div>
    );
  }

  const getLongestUserId = (user1, user2) => user1.length > user2.length ? user1 : user2;

  return (
    <div style={{ width: '100%', fontFamily: 'system-ui', fontSize: '16px' }}>
      <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '20px' }}>
        <thead>
          <tr>
            <th>User</th>
            <th>Profile</th>
            <th>Nudity</th>
            <th>Photo</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {bans.map((ban) => (
            <tr key={ban.room_id}>
              <td>{userNames[ban.user_1] || ban.user_1}</td>
              <td>
                <a href={`https://admin.golive.mobi/profile_model/${ban.user_1}`} target="_blank" rel="noopener noreferrer" style={{ color: 'blue', textDecoration: 'none' }}>Profile</a>
              </td>
              <td>Nudity</td>
              <td>
                <img src={ban.banned_photo} alt="Thumbnail" style={{ width: '120px', height: 'auto', cursor: 'pointer' }} onClick={() => openModal(ban.banned_photo)} />
              </td>
              <td>
                <button onClick={() => unblockUser(getLongestUserId(ban.user_1, ban.user_2))} style={{ backgroundColor: '#4CAF50', color: 'white', padding: '8px 15px', border: 'none', borderRadius: '5px', cursor: 'pointer', fontSize: '14px' }}>
                  Unblock
                </button>
                <button onClick={() => handleHide(ban.room_id)} style={{ backgroundColor: '#f44336', color: 'white', padding: '8px 15px', border: 'none', borderRadius: '5px', cursor: 'pointer', fontSize: '14px', marginLeft: '5%' }}>
                  Hide
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {isModalOpen && (
        <div style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0, 0, 0, 0.6)', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 1000 }}>
          <div style={{ position: 'relative', padding: '20px', backgroundColor: '#fff', borderRadius: '8px' }}>
            <img src={modalImage} alt="Banned" style={{ maxHeight: '80vh', maxWidth: '80vw' }} />
            <button onClick={closeModal} style={{ position: 'absolute', top: '10px', right: '10px', padding: '5px 10px', backgroundColor: '#f44336', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer' }}>
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default BansList;

