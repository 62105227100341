import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const ProfileInfo = () => {
  const history = useHistory();

  useEffect(() => {
    // Add the value to localStorage
    localStorage.setItem('design', 'new');

    // Navigate to the '/new/models' page
    history.push('/new/models');
  }, []);

  return (
    <div>
      {/* You can add additional content here if needed */}
    </div>
  );
};

export default ProfileInfo;
