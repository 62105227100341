import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Oval } from 'react-loader-spinner';

const Noface = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post('https://golive.mobi/api/stream/preview/list2/', {
        page: currentPage,
        success: 1
      }, {
        headers: {
          Authorization: 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d'
        }
      });
      setData(response.data.list);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data: ', error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentPage]);


  
  const handleAction = async (userId, value) => {
    try {
      await axios.post('https://golive.mobi/api/stream/enable/', {
        id: userId,
        value: value
      }, {
        headers: {
          Authorization: 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d'
        }
      });

      alert('User action completed successfully.');
      fetchData(); // Refresh data after action
    } catch (error) {
      console.error('Error performing action: ', error);
      alert('Failed to perform action.');
    }
  };

  const handleVideoClick = (url) => {
    window.open(url, '_blank');
  };

  const handlePrevPage = () => {
    setCurrentPage(currentPage > 1 ? currentPage - 1 : 1);
  };

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp * 1000); // Convert timestamp to milliseconds
    return date.toLocaleDateString(); // Format date as locale date string
  };

  return (
    <div style={styles.tableContainer}>
      <table style={styles.table}>
        <thead>
          <tr>
            <th style={{ ...styles.headerCell, width: '10%' }}>User ID</th>
            <th style={{ ...styles.headerCell, width: '50%' }}>Videos</th>
            <th style={{ ...styles.headerCell, width: '10%' }}>Numbers of reject</th>
            <th style={{ ...styles.headerCell, width: '30%' }}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <tr>
              <td colSpan="4" style={{ textAlign: 'center' }}>
                <Oval color="#00BFFF" height={80} width={80} />
              </td>
            </tr>
          ) : (
            data.map((item) => (
              <tr key={item.id}>
                <td style={{ ...styles.cell, width: '10%' }}>{item.id}</td>
                <td style={{ ...styles.cell, width: '50%' }}>
                  <div style={styles.videoContainer}>
                    {item.previews_list.map((preview) => (
                      <div key={preview.preview_id} style={styles.videoWrapper}>
                      <img style={styles.video} onClick={() => handleVideoClick(preview.url)} src={preview.url.replace('/recorded_streams/', '/recorded_streams/thumb/').replace('.mp4', '.jpg')}
 />
                        <div style={styles.date}>{formatDate(preview.date)}</div>
                      </div>
                    ))}
                  </div>
                </td>
                <td style={{ ...styles.cell, width: '10%' }}>{item.count_disapprove}</td>
                <td style={{ ...styles.cell, width: '30%' }}>
                  <button onClick={() => handleAction(item.id, -1)} style={styles.banButton}>Total ban</button><br />
                  <button onClick={() => handleAction(item.id, 1)} style={styles.approveButton}>Approve</button><br />
                  <button onClick={() => handleAction(item.id, 0)} style={styles.rejectButton}>Reject</button>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
      <div style={styles.paginationContainer}>
        <button onClick={handlePrevPage} style={styles.pageButton} disabled={currentPage === 1}>Назад</button>
        <span style={styles.pageIndicator}>Страница {currentPage}</span>
        <button onClick={handleNextPage} style={styles.pageButton}>Далее</button>
      </div>
    </div>
  );
};

const styles = {
  tableContainer: {
    overflowX: 'auto',
    textAlign: 'center'
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    margin: 'auto'
  },
  headerCell: {
    padding: '8px',
    textAlign: 'left',
    borderBottom: '1px solid #ddd'
  },
  cell: {
    padding: '8px',
    borderBottom: '1px solid #ddd',
    maxWidth: '200px',
    overflow: 'hidden'
  },
  videoContainer: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    gap: '10px',
    overflowX: 'auto',
    padding: '8px',
    width: '100%'
  },
  videoWrapper: {
    position: 'relative'
  },
  video: {
    width: '100px',
    height: 'auto',
    display: 'block'
  },
  date: {
    position: 'absolute',
    bottom: '5px',
    left: '5px',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    color: 'white',
    padding: '2px 5px',
    borderRadius: '3px'
  },
  approveButton: {
    padding: '6px 12px',
    backgroundColor: '#28a745',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    marginRight: '5px',
    marginBottom: '5px'
  },
  rejectButton: {
    padding: '6px 12px',
    backgroundColor: '#dc3545',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    marginRight: '5px',
    marginBottom: '5px'
  },
  banButton: {
    padding: '6px 12px',
    backgroundColor: '#ff0000',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    marginRight: '5px',
    marginBottom: '5px'
  },
  paginationContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px'
  },
  pageButton: {
    padding: '10px 20px',
    margin: '0 10px',
    backgroundColor: '#007bff',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer'
  },
  pageIndicator: {
    padding: '10px 20px',
    fontSize: '16px'
  }
};

export default Noface;
