import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import Menu from "./Components/Menu";
import Brand from "./Components/Brand";
import Error from "./Components/Error";
import Models from "./Components/Models";
import ModelCreate from "./Components/ModelCreate";
import AdminCreate from "./Components/AdminCreate";
import BillingWindow from "./Components/BillingWindow";
import Complants from "./Components/ComplaintsButton";
import AllUsers from "./Components/All_users";
import ModerationTable from "./Components/ModerationTable";
import Billing from "./Components/Billing";
import Login from "./Components/Login";
import Profile_Model from './Components/Profile_Model'
import User_Model from './Components/User_Model'
import AbuseWindow from './Components/AbuseWindow'
import UsersButton from './Components/UsersButton'
import SettingsButton from './Components/SettingsButton'
import Telegram from './Components/Telegram'
import Snapshots from './Components/Snapshots.jsx'
import Streams from './Components/Streams.jsx'
import Verification from './Components/Verification.jsx'
import ModelsNew from './Components/new_models/Models.jsx'
import Video from './Components/Videos.jsx'
import Statistic from './Components/StatisticList.jsx'
import Bans from './Components/Bans.jsx'
import Feedback from './Components/Feedback.jsx'
import SL from './Components/StreamsList.jsx'
import Chatbot from './Components/Chatbot.jsx'
import Push from './Components/Push.jsx'

import Menu_v2 from "./Components_v2/Menu";
import Brand_v2 from "./Components_v2/Brand";
import Error_v2 from "./Components_v2/Error";
import Models_v2 from "./Components_v2/Models";
import ModelCreate_v2 from "./Components_v2/ModelCreate";
import BillingWindow_v2 from "./Components_v2/BillingWindow";
import Complants_v2 from "./Components_v2/ComplaintsButton";
import AllUsers_v2 from "./Components_v2/All_users";
import Billing_v2 from "./Components_v2/Billing";
import Login_v2 from "./Components_v2/Login";
import Profile_Model_v2 from './Components_v2/Profile_Model'
import User_Model_v2 from './Components_v2/User_Model'
import AbuseWindow_v2 from './Components_v2/AbuseWindow'
import UsersButton_v2 from './Components_v2/UsersButton'

import Profile_Mode from './Components/Profile_Mode'

import axios from 'axios';

import newversion from './Components_v2/NewVersion'
import oldversion from './Components_v2/OldVersion'

import checkerror from './errorcheck.js'
import ErrorBlock from './errorcheck.js';

// HOC для проверки авторизации
function ProtectedRoute({ component: Component, isAuthenticated, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
}

function App() {
  const [status, setStatus] = useState('');
  const [error, setError] = useState(null); // Step 1: Add error state

  useEffect(() => {
    const checkAuthStatus = async () => {
      try {
        const userToken = localStorage.getItem('usertoken');
        const tempToken = localStorage.getItem('temptoken');

        // Check if both tokens are available in localStorage before sending the request
        if (userToken && tempToken) {
          // Set up an axios interceptor to add the Authorization header with the token
          axios.interceptors.request.use((config) => {
            config.headers.Authorization = `Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d`;
            return config;
          });

          const response = await axios.post('https://admin.golive.mobi/ustatus', {
            usertoken: userToken,
            temptoken: tempToken,
          });

          // Check the response and update the state (setStatus) based on the status
          const { status, role } = response.data;
          setStatus(status);
          localStorage.setItem('auth', status);
          localStorage.setItem('role', role)
        } else {
          // Tokens are missing in localStorage
          console.error('Tokens are missing in localStorage.');
        }
      } catch (error) {
        // Handle errors here if they occur during the request
        console.error('Error while checking authentication status:', error);
      }
    };

    

    checkAuthStatus(); // Call the function to send the POST request
  }, []); 





  // Состояние авторизации
  const isAuthenticated = localStorage.getItem('auth') === 'auth'; // Проверка наличия токена в localStorage

  const [localStorageDesign, setLocalStorageDesign] = useState(localStorage.getItem('design'));

  // Function to check and update the localStorageDesign state when localStorage changes
  const updateLocalStorageDesign = () => {
    setLocalStorageDesign(localStorage.getItem('design'));
  };

  useEffect(() => {
    // Listen for changes to the "design" key in localStorage
    window.addEventListener('storage', updateLocalStorageDesign);
    return () => {
      // Cleanup the event listener when the component is unmounted
      window.removeEventListener('storage', updateLocalStorageDesign);
    };
  }, []);

  let menuComponent;
  if (localStorageDesign === 'new') {
    menuComponent = <Menu_v2 />;
  } else {
    menuComponent = isAuthenticated ? <Menu /> : null;
  }

  const backgroundStyle = {
    background: localStorageDesign === 'new' ? '#fff' : 'transparent',
    height: 'fit-content'
  };

  
  
  return (
    <div className="App" style={backgroundStyle}>




      <Router>
      {menuComponent}
        <Switch>
          <Route path="/login">
           
            <Login />
          </Route>
          <Route path="/error">
            <Error />
          </Route>

          {/* Old style */}
          <ProtectedRoute
            path="/models-legacy"
            component={Models}
            isAuthenticated={isAuthenticated}
          />

<ProtectedRoute
            path="/models-new"
            component={ModelsNew}
            isAuthenticated={isAuthenticated}
          />
          
          <ProtectedRoute
            path="/profile_model/:id"
            component={Profile_Model}
            isAuthenticated={isAuthenticated}
          />

          <ProtectedRoute
            path="/user_model/:id"
            component={User_Model}
            isAuthenticated={isAuthenticated}
          />
          <ProtectedRoute
            path="/abuse"
            component={AbuseWindow}
            isAuthenticated={isAuthenticated}
          />
          <ProtectedRoute
            path="/streamlist"
            component={SL}
            isAuthenticated={isAuthenticated}
          />
          <ProtectedRoute
            path="/bans"
            component={Bans}
            isAuthenticated={isAuthenticated}
          />
          <ProtectedRoute
            path="/statistic"
            component={Statistic}
            isAuthenticated={isAuthenticated}
          />
          <ProtectedRoute
            path="/users"
            component={UsersButton}
            isAuthenticated={isAuthenticated}
          />
          <ProtectedRoute
            path="/test"
            component={Chatbot}
            isAuthenticated={isAuthenticated}
          />
          <ProtectedRoute
            path="/push"
            component={Push}
            isAuthenticated={isAuthenticated}
          />
          <ProtectedRoute
            path="/modelcreate"
            component={ModelCreate}
            isAuthenticated={isAuthenticated}
          />
          <ProtectedRoute
            path="/admincreate"
            component={AdminCreate}
            isAuthenticated={isAuthenticated}
          />
          <ProtectedRoute
            path="/video"
            component={Video}
            isAuthenticated={isAuthenticated}
          />
          <ProtectedRoute
            path="/billingcheck"
            component={BillingWindow}
            isAuthenticated={isAuthenticated}
          />
          <ProtectedRoute
            path="/complaints"
            component={Complants}
            isAuthenticated={isAuthenticated}
          />
          <ProtectedRoute
            path="/verification"
            component={Verification}
            isAuthenticated={isAuthenticated}
          />
          <ProtectedRoute
            path="/allusers"
            component={AllUsers}
            isAuthenticated={isAuthenticated}
          />
          <ProtectedRoute
            path="/telegram"
            component={Telegram}
            isAuthenticated={isAuthenticated}
          />
          <ProtectedRoute
            path="/applications"
            component={ModerationTable}
            isAuthenticated={isAuthenticated}
          />
          <ProtectedRoute
            path="/snapshots"
            component={Snapshots}
            isAuthenticated={isAuthenticated}
          />
          <ProtectedRoute
            path="/terminal"
            component={Streams}
            isAuthenticated={isAuthenticated}
          />
          <ProtectedRoute
            path="/feedback"
            component={Feedback}
            isAuthenticated={isAuthenticated}
          />
          <ProtectedRoute
            path="/billing"
            component={Billing}
            isAuthenticated={isAuthenticated}
          />
          <ProtectedRoute
            path="/settings"
            component={SettingsButton}
            isAuthenticated={isAuthenticated}
          />
          {/* Old style */}

          {/* Old style */}
          <ProtectedRoute
            path="/new/models"
            component={Models_v2}
            isAuthenticated={isAuthenticated}
          />

          <ProtectedRoute
            path="/new/profile_model/:id"
            component={Profile_Model_v2}
            isAuthenticated={isAuthenticated}
          />
          <ProtectedRoute
            path="/new/user_model/:id"
            component={User_Model_v2}
            isAuthenticated={isAuthenticated}
          />
          <ProtectedRoute
            path="/new/abuse"
            component={AbuseWindow_v2}
            isAuthenticated={isAuthenticated}
          />
          <ProtectedRoute
            path="/new/users"
            component={UsersButton_v2}
            isAuthenticated={isAuthenticated}
          />
          <ProtectedRoute
            path="/new/modelcreate"
            component={ModelCreate_v2}
            isAuthenticated={isAuthenticated}
          />
          <ProtectedRoute
            path="/new/billingcheck"
            component={BillingWindow_v2}
            isAuthenticated={isAuthenticated}
          />
          <ProtectedRoute
            path="/new/complaints"
            component={Complants_v2}
            isAuthenticated={isAuthenticated}
          />
          <ProtectedRoute
            path="/new/allusers"
            component={AllUsers_v2}
            isAuthenticated={isAuthenticated}
          />
          <ProtectedRoute
            path="/new/billing"
            component={Billing_v2}
            isAuthenticated={isAuthenticated}
          />
          {/* Old style */}

          {/* DEBUG FUNCTION */}
          
          <ProtectedRoute
            path="/debug/oldversion"
            component={oldversion}
            isAuthenticated={isAuthenticated}
          />

          <ProtectedRoute
            path="/debug/newversion"
            component={newversion}
            isAuthenticated={isAuthenticated}
          />

          {/* DEBUG FUNCTION */}

          <Route path="/:brand">
            <Brand />
          </Route>

          <Route path="/" component={Login}></Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;