import React, { useEffect, useState } from "react";
import { StyleSheet, css } from "aphrodite/no-important";
import { NavLink, useLocation } from "react-router-dom";
import "./style.css";

{/*const BRANDS = ["❌ Dashboard", "❌ All users", "Models", "❌ Moderation", "❌ Chats", "Billing"];*/}
const BRANDS = ["Models", "Users", "Complaints", "Billing"];

const Menu = () => {
  const [activePage, setActivePage] = useState("");
  const location = useLocation();

  useEffect(() => {
    const path = location.pathname.substr(1); // Remove the first character "/"
    setActivePage(path);
  }, [location]);

  useEffect(() => {
    const savedActivePage = localStorage.getItem("activePage");
    if (savedActivePage && BRANDS.includes(savedActivePage)) {
      setActivePage(savedActivePage);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("activePage", activePage);
  }, [activePage]);

  const isModelsPage = activePage === "models";

  const handleClick = (brand, event) => {
    if (brand.includes("❌")) {
console.log('lock')
    }
    setActivePage(brand);
  };
  

  return (
    <div>
      <nav className="navbar" style={{marginTop: '-0.5%', borderRadius: '15px', marginLeft: 'auto', marginRight: 'auto', width: '95%' }}>
        <ul className="navbar-list">
          {BRANDS.map((brand) => (

             
            <NavLink
              className={`text ${
                activePage === brand || (isModelsPage && brand === "Models") ? "active" : ""
              }`}
              label={brand}
              style={{ fontFamily: "inter", fontSize: "14px" }}
              to={`/new/${brand.toLowerCase()}`}
              key={brand}
              onClick={() => handleClick(brand)}
            >
              <li className="navbar-item">{brand}</li>
            </NavLink>
          ))}
        </ul>
      </nav>
    </div>
  );
};

export default Menu;
