import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Oval } from 'react-loader-spinner';

const App = () => {
    const [prompt, setPrompt] = useState('');
    const [prompt_inst, setPrompt_inst] = useState('');
    const [response, setResponse] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [debugInfo, setDebugInfo] = useState('');
    const [noise, setNoise] = useState('');

    useEffect(() => {
        const interval = setInterval(() => {
            let noisePart1 = Math.floor(Math.random() * 1e8).toString().padStart(8, '0');
            let noisePart2 = Math.floor(Math.random() * 1e3).toString().padStart(3, '0');

            // Choose 3 random indices to change in noisePart1 and noisePart2
            let indices = [];
            while (indices.length < 3) {
                let index = Math.floor(Math.random() * 11); // Total length of noisePart1 and noisePart2 is 11
                if (!indices.includes(index)) {
                    indices.push(index);
                }
            }

            // Change the digits at the selected indices
            indices.forEach(index => {
                if (index < 8) {
                    noisePart1 = noisePart1.substr(0, index) + Math.floor(Math.random() * 10) + noisePart1.substr(index + 1);
                } else {
                    index -= 8;
                    noisePart2 = noisePart2.substr(0, index) + Math.floor(Math.random() * 10) + noisePart2.substr(index + 1);
                }
            });

            setNoise(`${noisePart1}-${noisePart2}`);
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');
        setResponse('');
        setDebugInfo('');

        let promptText = `${prompt_inst} ${prompt}`;
        const chance = Math.random();
        const seed = Math.floor(Math.random() * 1e15).toString().padStart(15, '0');
        const emojiText = " (the message should be left with one emoji or smiley at the end, you need to use different emojis and not just this one 😊 or add keyboard options like these :), ;), :)";
        if (chance < 0.3) {
            promptText += emojiText;
        }

        setDebugInfo(`Chance value: ${chance.toFixed(2)}\nSeed answer: ${seed}\nNoise of Recurrent Communications: ${noise}`);

        try {
            const res = await axios.post('https://apilive.golive.mobi/request', {
                model: 'new_girl',
                prompt: promptText,
                stream: false
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (res.status === 200) {
                setResponse(res.data.response);
            } else {
                setError('An error occurred');
            }
        } catch (err) {
            setError('An error occurred');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', fontFamily: 'Arial, sans-serif', marginTop: '50px' }}>
            <h1 style={{ marginBottom: '20px' }}>Chat with Model</h1>
            <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', maxWidth: '400px' }}>
                <textarea 
                    value={prompt_inst} 
                    onChange={(e) => setPrompt_inst(e.target.value)} 
                    placeholder="Enter your instruction here..."
                    style={{ width: '100%', height: '100px', padding: '10px', marginBottom: '20px', fontSize: '16px', borderRadius: '5px', border: '1px solid #ccc' }}
                />

<textarea 
                    value={prompt} 
                    onChange={(e) => setPrompt(e.target.value)} 
                    placeholder="Enter your instruction here..."
                    style={{ width: '100%', height: '100px', padding: '10px', marginBottom: '20px', fontSize: '16px', borderRadius: '5px', border: '1px solid #ccc' }}
                />
                <button type="submit" style={{ padding: '10px 20px', fontSize: '16px', borderRadius: '5px', border: 'none', backgroundColor: '#007BFF', color: '#fff', cursor: 'pointer', marginBottom: '20px' }}>
                    Send
                </button>
            </form>
            {loading && <Oval color="#00BFFF" height={80} width={80} />}
            {error && <p style={{ color: 'red', marginTop: '20px' }}>{error}</p>}
            {response && (
                <div style={{ marginTop: '20px', padding: '20px', border: '1px solid #ccc', borderRadius: '5px', backgroundColor: '#f9f9f9', width: '100%', maxWidth: '400px' }}>
                    <p style={{ fontSize: '18px', margin: '0' }}>{response}</p>
                </div>
            )}
            {debugInfo && (
                <div style={{ marginTop: '20px', padding: '10px', border: '1px solid #ccc', borderRadius: '5px', backgroundColor: '#f0f0f0', width: '100%', maxWidth: '400px' }}>
                    <p style={{ fontSize: '14px', margin: '0', color: '#555', whiteSpace: 'pre-wrap' }}>Debug Info:<br />{debugInfo}</p>
                    <br />
                    <p style={{ fontSize: '14px', margin: '0', color: '#555', whiteSpace: 'pre-wrap' }}>FAQ:<br />If the chance is equal to or less than 0.30, then the message will contain an emoji depending on the shade of the answer</p>
                </div>
            )}
        </div>
    );
};

export default App;
