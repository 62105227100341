import React, { useState, useEffect } from 'react';
import Statistic from './Statistic'
import StatisticOnline from './StatisticOnline'
import StatisticError from './StatisticError'
import StatisticOnlinePing from './StatisticOnlinePing'
import StatisticSearch from './StatisticSearchFull'
import StatisticBalance from './StatisticBalance'
import StreamStat from './StreamStat'
import StreamStatProd from './StreamStatProd'
import StatisticS from './StatisticS';

const Models = () => {

	const [ select, setSelect ] = useState('1');

  return (
    <div className="col-md-6 align-self-center" style={{ marginTop: '1%', fontFamily: 'Arial, sans-serif', textAlign: 'center' }}>

	{/*
	<div>
 
        <button className='inactive-button' style={{
			width: '150px',
			height: '30px',
			borderRadius: '15px',
			color: 'black',
		}} onClick={() => setSelect('1')}>Statistic models</button>
        <button style={{
			width: '150px',
			height: '30px',
			borderRadius: '15px',
			color: 'black',
		}} className='inactive-button' onClick={() => setSelect('2')}>Statistic Push</button>
		<button style={{
			width: '150px',
			height: '30px',
			borderRadius: '15px',
			color: 'black',
		}} className='inactive-button' onClick={() => setSelect('3')}>Statistic Online</button>
		<button style={{
			width: '150px',
			height: '30px',
			borderRadius: '15px',
			color: 'black',
		}} className='inactive-button' onClick={() => setSelect('4')}>Statistic Search</button>
		<button style={{
			width: '150px',
			height: '30px',
			borderRadius: '15px',
			color: 'black',
		}} className='inactive-button' onClick={() => setSelect('5')}>Statistic Error</button>
		<button style={{
			width: '150px',
			height: '30px',
			borderRadius: '15px',
			color: 'black',
		}} className='inactive-button' onClick={() => setSelect('6')}>Statistic Balance</button>
      </div>

	{select == 1 && <Statistic /> }
	{select == 2 && <StatisticOnline /> }
	{select == 5 && <StatisticError /> }
	{select == 3 && <StatisticOnlinePing /> }
	{select == 4 && <StatisticSearch /> }
	{select == 6 && <StatisticBalance /> }


	*/}

{select == 1 && <><StreamStat /> <hr /> <StreamStatProd /><hr /><Statistic /><hr /><StatisticOnline /><hr /><StatisticError /><hr /><StatisticOnlinePing /><hr /><StatisticBalance /><hr />{/*<StatisticSearch /><hr />*/}<StatisticS /><br /><br /></> }

    </div>
  );
};

export default Models;
