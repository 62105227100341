import React, { useState, useEffect, useCallback } from 'react';
import './style.css';
import { useHistory } from 'react-router-dom';

const TableGeneral = () => {
  const history = useHistory();

  const handleClick = () => {
    history.push('/admincreate');
  };

  const [showForm, setShowForm] = useState(false);

  const toggleForm = () => {
    setShowForm(!showForm);
  };

  const [data, setData] = useState([]);

  const fetchData = useCallback(async () => {
    try {
      const response = await fetch('https://admin.golive.mobi/usersadmin', {
        method: 'POST',
        headers: {
          Authorization: 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const responseData = await response.json();
        setData(responseData);
      } else {
        console.log('Error:', response.status);
      }
    } catch (error) {
      console.log('Error:', error);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);


  const handleDelete = async () => {
    if (selectedUsernames.length === 0) {
      console.log('Please select at least one user to delete.');
      return;
    }
  
    // Show a confirmation dialog to the user
    const confirmDelete = window.confirm('Are you sure you want to delete the selected user(s)?');
    if (!confirmDelete) {
      return;
    }
  
    try {
      const response = await fetch('https://admin.golive.mobi/deleusr', {
        method: 'POST',
        headers: {
          Authorization: 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username: selectedUsernames[0] }), // Send the first selected username (if it exists)
      });
  
      if (response.ok) {
        console.log('Selected user successfully deleted!');
        fetchData(); // Assuming fetchData fetches data and updates the state
      
      } else {
        console.log('Error:', response.status);
      }
    } catch (error) {
      console.log('Error:', error);
    }
  };
  

  // Test data for the table
  const initialData = [
    // Add more test data here...
  ];

  const [selectAll, setSelectAll] = useState(false);

  const toggleSelectAll = () => {
    setSelectAll(!selectAll);
    const updatedData = data.map((item) => ({ ...item, selected: !selectAll }));
    setData(updatedData);
  };

  const [selectedUsernames, setSelectedUsernames] = useState([]);

  // Function to toggle the selection of a user by their ID
  const toggleSelectItem = (id) => {
    setSelectedUsernames((prevSelected) =>
      prevSelected.includes(id) ? prevSelected.filter((username) => username !== id) : [...prevSelected, id]
    );
  };

  function formatTimestamp(timestamp) {
    if (!timestamp) {
      return '';
    }

    const dateObj = new Date(timestamp);

    if (isNaN(dateObj)) {
      return 'Invalid timestamp';
    }

    const year = dateObj.getFullYear();
    const month = ('0' + (dateObj.getMonth() + 1)).slice(-2);
    const day = ('0' + dateObj.getDate()).slice(-2);
    const hours = ('0' + dateObj.getHours()).slice(-2);
    const minutes = ('0' + dateObj.getMinutes()).slice(-2);

    return `${day}.${month}.${year} ${hours}:${minutes}`;
  }

  function formatTimestampReg(timestamp) {
    if (!timestamp) {
      return '';
    }

    const dateObj = new Date(Number(timestamp));

    if (isNaN(dateObj)) {
      return 'Invalid timestamp';
    }

    const year = dateObj.getFullYear();
    const month = ('0' + (dateObj.getMonth() + 1)).slice(-2);
    const day = ('0' + dateObj.getDate()).slice(-2);
    const hours = ('0' + dateObj.getHours()).slice(-2);
    const minutes = ('0' + dateObj.getMinutes()).slice(-2);

    return `${day}.${month}.${year} ${hours}:${minutes}`;
  }

  function formatDate(timestamp) {
    if (!timestamp) {
      return '';
    }
  
    const dateObj = new Date(timestamp * 1000); // Convert to milliseconds
  
    if (isNaN(dateObj)) {
      return 'Invalid timestamp';
    }
  
    const year = dateObj.getFullYear();
    const month = ('0' + (dateObj.getMonth() + 1)).slice(-2);
    const day = ('0' + dateObj.getDate()).slice(-2);
    const hours = ('0' + dateObj.getHours()).slice(-2);
    const minutes = ('0' + dateObj.getMinutes()).slice(-2);
  
    return `${day}.${month}.${year} ${hours}:${minutes}`;
  }
  

  const [selectedModelId, setSelectedModelId] = useState(null);

  const handleModelClick = useCallback(
    (id) => {
      localStorage.setItem('id_models', id); // Save ID to localStorage
      const url = `/profile_model/${id}`;
      window.open(url, '_blank'); // Open the URL in a new tab
    },
    []
  );
  
  


  
  const handleRemove = async () => {
    try {
      const response = await fetch('https://admin.golive.mobi/block', {
        method: 'POST',
        headers: {
          Authorization: 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username: selectedUsernames[0] }), // Send the first selected username (if it exists)
      });
  
      if (response.ok) {
        console.log('Selected rows successfully blocked!');
        fetchData(); // Assuming fetchData fetches data and updates the state
      } else {
        console.log('Error:', response.status);
      }
    } catch (error) {
      console.log('Error:', error);
    }
  };
  
  const handleUnRemove = async () => {
    try {
      const response = await fetch('https://admin.golive.mobi/unblock', {
        method: 'POST',
        headers: {
          Authorization: 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username: selectedUsernames[0] }), // Send the first selected username (if it exists)
      });
  
      if (response.ok) {
        console.log('Selected rows successfully unblocked!');
        fetchData(); // Assuming fetchData fetches data and updates the state
      } else {
        console.log('Error:', response.status);
      }
    } catch (error) {
      console.log('Error:', error);
    }
  };
  
  
  const handleChangeRole = async () => {
    if (selectedUsernames.length !== 1) {
      console.log('Please select one user to change the role.');
      return;
    }
  
    try {
      const response = await fetch('https://admin.golive.mobi/changerole', {
        method: 'POST',
        headers: {
          Authorization: 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username: selectedUsernames[0] }), // Send the first selected username (if it exists)
      });
  
      if (response.ok) {
        console.log('Role changed successfully!');
        fetchData(); // Assuming fetchData fetches data and updates the state
        
      } else {
        console.log('Error:', response.status);
      }
    } catch (error) {
      console.log('Error:', error);
    }
  };
  

  return (
    <div>
      <div>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop:'0%' }}>
          <h2 style={{ marginLeft: '1%', fontFamily: 'inter' }}>Settings</h2>
        </div>
        <button className="create-button" onClick={handleClick}>
          + New User
        </button>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h3 style={{ marginLeft: '1%' }}></h3>
          <div style={{ display: 'flex', marginRight: '1%'}}>
          <button className="remove-button" style={{ width: '100px' }} onClick={handleDelete}>
              Delete User
            </button>
          <button className="remove-button" style={{ width: '100px' }} onClick={handleChangeRole}>
  Change role
</button>
          <button className="remove-button" style={{ width: '90px'}} onClick={handleRemove}>Block</button>
          <button className="remove-button" style={{ width: '90px'}} onClick={handleUnRemove}>Unblock</button>
          </div>
        </div>

        <table className="table">
          <thead style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.07)' }}>
            <tr>
              <th>
                <input
                  className="checkbox"
                  type="checkbox"
                  checked={selectAll}
                  onChange={toggleSelectAll}
                  style={{
                    alignItems: 'center',
                    display: 'flex',
                  }}
                />
              </th>
              <th style={{ color: 'black', fontFamily: 'Noto Sans Mono', fontWeight: '500', fontSize: '12px', textAlign: 'left' }}>User</th>
              <th style={{ color: 'black', fontFamily: 'Noto Sans Mono', fontWeight: '500', fontSize: '12px', textAlign: 'left' }}>Role</th>
              <th style={{ color: 'black', fontFamily: 'Noto Sans Mono', fontWeight: '500', fontSize: '12px', textAlign: 'left' }}>Status</th>

            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr
              key={item.id}
              className={`${index % 2 === 0 ? 'even-row' : 'odd-row'} ${
                item.selected ? 'selected-row' : 'non-selected-row'
              }`}
            >
                <td style={{ 
                  borderRight: '1px solid rgba(0, 0, 0, 0.07)' }}>
                  <input
                  type="checkbox"
                  checked={item.selected}
                  onChange={() => toggleSelectItem(item.username)} // Use username as ID for selection
                />
                </td>
             
                  <td style={{
                  color: item.selected ? '#6D33FF' : '#606C80',
                  fontFamily: 'Noto Sans Mono',
                  fontWeight: '400',
                  fontSize: '12px',
                  textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)'
                }}>{item.username}</td>
                <td style={{
                  color: item.selected ? '#6D33FF' : '#606C80',
                  fontFamily: 'Noto Sans Mono',
                  fontWeight: '400',
                  fontSize: '12px',
                  textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)'
                }}>{item.role === 'sector05a' ? 'Admin' : item.role === 'sector01b' ? 'Curator' : item.role}</td>
                <td style={{
  color: item.selected ? '#6D33FF' : '#606C80',
  fontFamily: 'Noto Sans Mono',
  fontWeight: '400',
  fontSize: '12px',
  textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)'
}}>
  {item.status === 'online' ? 'Active user' : item.status === 'block' ? 'Blocked user' : item.status}
</td>

 

                {/**<td style={{
                  color: item.selected ? '#6D33FF' : '#606C80',
                  fontFamily: 'Noto Sans Mono',
                  fontWeight: '400',
                  fontSize: '12px',
                  textAlign: 'left',
                }}>{item.fan}</td>**/}
                {/**<td style={{
                  color: item.selected ? '#6D33FF' : '#606C80',
                  fontFamily: 'Noto Sans Mono',
                  fontWeight: '400',
                  fontSize: '12px',
                  textAlign: 'left',
                }}>{item.amountOfPurchases}</td>**/}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TableGeneral;
