import React, { useState, useEffect } from 'react';
import android from './android.svg'
import apple from './apple.svg'
import crossoff from './crossoff.svg'
import crosson from './crosson.svg'
import checkon from './checkon.svg'
import checkoff from './checkoff.svg'
import checknull from './checknull.svg'
import crossnull from './crossnull.svg'
import { Oval } from  'react-loader-spinner'

function MyTable() {
  const [_double, set_double] = useState([]);

  const [ status, setStatus ] = useState('null')

  const [ page, setPage ] = useState(1)

  const [loading, setLoading] = useState({});

  const [loading1, setLoading1] = useState({});

  const [ loader, setLoader ] = useState({});

  const [isLoading, setIsLoading] = useState({});

  const [item, setItem] = useState([]);

  const [key, setKey] = useState(0);

  const [ view, setView ] = useState(false);
  const [ viewContent, setViewContent ] = useState("");
  const [ viewType, setViewType ] = useState("");
  const [inputPage, setInputPage] = useState(page);


  const fetchData = async (pageNumber) => {
    try {
      setLoading(true);
      set_double([]); // Очистить сохраненные данные
      const response = await fetch('https://golive.mobi/api/verif/list/', {
        method: 'POST',
        headers: {
          Authorization: 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          page: pageNumber,
          page_size: 5,
        }),
      });

      if (response.ok) {
        const _data = await response.json();
        set_double(_data.list);
        setLoading(false);
        window.scrollTo(0, 0); // Прокрутка вверх страницы
      } else {
        console.error('API request failed');
      }
    } catch (error) {
      console.error('API request failed:', error);
    }
  };

  useEffect(() => {
    fetchData(page);
  }, [page]);
  
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      setPage(inputPage);
    }
  };

  // для обновления значения

  useEffect(() => {
    fetchDataOnce(item.id); // Убедитесь, что здесь передается соответствующий идентификатор
  }, [page]);
  
  async function fetchDataOnce(id) {
    try {
      // setLoading1(true); // Возможно, вам это не понадобится
      //setLoader({ ...loader, [id]: true });
      //setLoader(true);
      const response = await fetch('https://golive.mobi/api/verif/list/', {
        method: 'POST',
        headers: {
          'Authorization': 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          page: page,
          page_size: 5,
        }),
      });
  
      if (response.ok) {
        const _data = await response.json();
        set_double(_data.list);
        // setLoading1(false);
        // setIsLoading({ ...isLoading, [id]: false });
        //setLoader({ ...loader, [id]: false }); // Устанавливаем загрузку для данного идентификатора в false
        //setLoader(false);
      } else {
        console.error('API request failed');
      }
    } catch (error) {
      console.error('API request failed:', error);
    }
  }

  

  // для обновления значения
  
  const handleNextPage = () => {
    setPage((prevPage) => prevPage + 1);
    setInputPage(page + 1); // Обновление вводимого значения при нажатии "Next"
    window.scrollTo(0, 0); // Прокрутка страницы вверх
  };
  
  const handlePreviousPage = () => {
    if (page > 1) {
      setPage((prevPage) => prevPage - 1);
      setInputPage(page - 1); // Обновление вводимого значения при нажатии "Previous"
      window.scrollTo(0, 0); // Прокрутка страницы вверх
    }
  };

  function shortenId(id) {
    return id.substring(0, 7);
  }

  function switchone () {
    setStatus("success")
  }

  function switchtwo () {
    setStatus("failed")
  }

  async function sendVerificationRequest2(id, url, type, file_type, success, imageIndex) {
    const request = {
      id: id,
      url: url,
      type: type,
      file_type: file_type,
      success: success,
      web: 1
    };

    try {
      const response = await fetch('https://golive.mobi/api/verif/complete/', {
        method: 'POST',
        headers: {
          'Authorization': 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(request),
      });
      //fetchData();
      fetchDataOnce();
      if (!response.ok) {
        console.error('Verification request failed');
      } else {
        const updatedImages = [...item.images];
        updatedImages[imageIndex].status = success ? 1 : 0; // Assuming 1 for checkon and 0 for crosson
        const updatedItem = { ...item, images: updatedImages };
        setItem(updatedItem); // Обновление состояния компонента
        setKey(prevKey => prevKey + 1); // Обновление ключа для перерендеринга блока
      }
    } catch (error) {
      console.error('Verification request failed:', error);
    }
  }

  const sendVerificationRequest = async (id, url, type, file_type, success, imageIndex) => {
    setIsLoading({ ...isLoading, [id]: true });
    const apiUrl = 'https://golive.mobi/api/verif/complete/';
    const requestOptions = {
      method: 'POST',
      headers: {
        Authorization: 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id: id,
        url: url,
        type: type,
        file_type: file_type,
        success: success,
        web: 1
      }),
    };
  
    try {
      const response = await fetch(apiUrl, requestOptions);
      if (response.ok) {
        
        // Обновите данные, если необходимо
        // ...
        fetchDataOnce();
        await new Promise(resolve => setTimeout(resolve, 1500));
        setIsLoading({ ...isLoading, [id]: false });
      } else {
        console.error('Error sending verification request:', response.statusText);
      }
    } catch (error) {
      setIsLoading({ ...isLoading, [id]: false });
      console.error('Error sending verification request:', error);
    }
  };
  
  

  async function sendMarkRequest(id) {
    const request = {
      id: id,
      approved: 1,
    };
  
    try {
      const response = await fetch('https://golive.mobi/api/verif/mark/', {
        method: 'POST',
        headers: {
          'Authorization': 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(request),
      });
      fetchData();
      if (!response.ok) {
        console.error('Mark request failed');
      }
    } catch (error) {
      console.error('Mark request failed:', error);
    }
  }


  const updateItemStatus = (index, newStatus) => {
    const updatedItems = [...item];
    updatedItems[index].status = newStatus;
    setItem(updatedItems);
  };
  

  return (

    

<>

{view === true && 
<div style={{
  position: 'fixed',
  marginTop: '-15%',
  height: '2000px',
  width: '2000px',
  background: 'rgba(255, 255, 255, 0.8)'
}}>

<img  src={crossoff}
onClick={() => {setView(false)}}
style={{
  position: 'fixed',
  width: '35px',
  left: '75%',
  top: '2%',
  background: 'white', borderRadius: '20px', zIndex: '9999'
}}
/>

  <div
    style={{
      zIndex: 999,
      width: 'auto',
      height: '95%',
      backgroundColor: 'white',
      position: 'fixed',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      border: '1px solid #dddddd',
      borderRadius: '10px',
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
      overflow: 'auto'
    }}
  >

{viewType === 'image' &&
    <img
      src={viewContent}
      style={{
        width: 'auto',
        marginLeft: 'auto',
        marginRight: 'auto',
        display: 'block',
        height: '100%'
      }}
      //alt={`Аватар ${imageIndex}`}
    />}

{viewType === 'video' &&
    <video
      controls
      src={viewContent}
      style={{
        width: 'auto',
        marginLeft: 'auto',
        marginRight: 'auto',
        display: 'block',
        height: '100%'
      }}
      //alt={`Аватар ${imageIndex}`}
    />}

    </div>
  </div>
}


{loading && (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center',
          position: 'absolute',
          left: 'auto',
          right: 'auto',
          background: 'white',
          width: '100%',
          height: '100%',
          }}>
  <Oval
    height={200}
    width={200}
    color="#0000FF"
    wrapperStyle={{}}
    wrapperClass=""
    visible={true}
    ariaLabel='oval-loading'
    secondaryColor="#87CEFA"
    strokeWidth={2}
    strokeWidthSecondary={2}
  />
</div>

    )}

    {_double.map((item, index) => (
      <div key={index} style={{
        border: '1px solid lightgrey',
borderRadius: '5px',
boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)',
marginBottom: '2%',
width: '97%',
marginLeft: 'auto', marginRight: 'auto', display: 'block'
      }}>

{isLoading[item.id] && (
    <div style={{
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '65%',
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
        zIndex: 2,
    }}>
            <Oval
                height={40}
                width={40}
                color="#0000FF"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel='oval-loading'
                secondaryColor="#87CEFA"
                strokeWidth={2}
                strokeWidthSecondary={2}
            />
    </div>
)}


<div >



{!loading &&

<table className="table">
          <thead style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.07)' }}>
            <tr>
              <th
                style={{
                  color: 'black',
                  fontFamily: 'Noto Sans Mono',
                  weight: '500',
                  fontSize: '12px',
                  textAlign: 'left',
                  borderRight: '1px solid rgba(0, 0, 0, 0.07)',
                  borderLeft: '1px solid rgba(0, 0, 0, 0.07)',
                  width: '25%'
                }}
              >
                ID
              </th>

              <th
                style={{
                  color: 'black',
                  fontFamily: 'Noto Sans Mono',
                  weight: '500',
                  fontSize: '12px',
                  textAlign: 'left',
                  borderRight: '1px solid rgba(0, 0, 0, 0.07)',
                  width: '25%'
                }}
              >
                Name
              </th>

              <th
                style={{
                  color: 'black',
                  fontFamily: 'Noto Sans Mono',
                  weight: '500',
                  fontSize: '12px',
                  textAlign: 'left',
                  borderRight: '1px solid rgba(0, 0, 0, 0.07)',
                  width: '25%'
                }}
              >
                Gender
              </th>

              <th
                style={{
                  color: 'black',
                  fontFamily: 'Noto Sans Mono',
                  weight: '500',
                  fontSize: '12px',
                  textAlign: 'left',
                  borderRight: '1px solid rgba(0, 0, 0, 0.07)',
                  width: '25%'
                }}
              >
                Country
              </th>

              </tr>
              </thead>
              <tbody>
                <tr>
                <td
                  style={{
                    color: item.selected ? '#6D33FF' : '#606C80',
                    fontFamily: 'Noto Sans Mono',
                    fontWeight: '400',
                    fontSize: '12px',
                    textAlign: 'left',
                    borderRight: '1px solid rgba(0, 0, 0, 0.07)',
                    borderLeft: '1px solid rgba(0, 0, 0, 0.07)',
                    width: '25%'
                  }}
                >
                  {item.id}
                </td>
                <td
                  style={{
                    color: item.selected ? '#6D33FF' : '#606C80',
                    fontFamily: 'Noto Sans Mono',
                    fontWeight: '400',
                    fontSize: '12px',
                    textAlign: 'left',
                    borderRight: '1px solid rgba(0, 0, 0, 0.07)',
                    width: '25%'
                  }}
                >
                <span style={{ display: 'flex'}}> {item.name} {item.origin === 'lifeagency'
                   &&
                    <img src={android} style={{ width: '24px', marginTop: '-1.5%', marginLeft: '3%'}} /> }

                  {item.origin === 'lifeagencyios'
                   &&
                    <img src={apple} style={{ width: '18px', marginTop: '-1%', marginLeft: '1%' }} /> }
                 </span>    
                </td>
                <td
                  style={{
                    color: item.selected ? '#6D33FF' : '#606C80',
                    fontFamily: 'Noto Sans Mono',
                    fontWeight: '400',
                    fontSize: '12px',
                    textAlign: 'left',
                    borderRight: '1px solid rgba(0, 0, 0, 0.07)',
                    width: '25%'
                  }}
                >
                  {item.gender === 1 && 'Man'}
                  {item.gender === 2 && 'Woman'}
                  {item.gender === 3 && 'Non-binary'}
                </td>
                <td
                  style={{
                    color: item.selected ? '#6D33FF' : '#606C80',
                    fontFamily: 'Noto Sans Mono',
                    fontWeight: '400',
                    fontSize: '12px',
                    textAlign: 'left',
                    borderRight: '1px solid rgba(0, 0, 0, 0.07)',
                    width: '25%'
                  }}
                >
                  {item.countryCode}
                </td>
                </tr>
</tbody>
</table>    }

{!loading &&
<hr  style={{ backgroundColor: '#00000012', height: '1px', border: '0', marginTop: '0%', width: '98%' }} />    
}



{/* долбанная таблица */}

{!loading &&
<table className="table" style={{ marginTop: '-0.5%'}}>
          <thead style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.07)' }}>
            <tr>
              <th
                style={{
                  color: 'black',
                  fontFamily: 'Noto Sans Mono',
                  weight: '500',
                  fontSize: '12px',
                  textAlign: 'left',
                  borderRight: '1px solid rgba(0, 0, 0, 0.07)',
                  borderLeft: '1px solid rgba(0, 0, 0, 0.07)',
                  width: '5%'
                }}
              >
                Avatar
              </th>

              <th
                style={{
                  color: 'black',
                  fontFamily: 'Noto Sans Mono',
                  weight: '500',
                  fontSize: '12px',
                  textAlign: 'left',
                  borderRight: '1px solid rgba(0, 0, 0, 0.07)',
                  width: '11.5%'
                }}
              >
                Cover
              </th>


              <th
                style={{
                  color: 'black',
                  fontFamily: 'Noto Sans Mono',
                  weight: '500',
                  fontSize: '12px',
                  textAlign: 'left',
                  borderRight: '1px solid rgba(0, 0, 0, 0.07)',
                  width: '70%'
                }}
              >
                Public album
              </th>

              <th
                style={{
                  color: 'black',
                  fontFamily: 'Noto Sans Mono',
                  weight: '500',
                  fontSize: '12px',
                  textAlign: 'left',
                  borderRight: '1px solid rgba(0, 0, 0, 0.07)',
                  //width: '70%'
                }}
              >
                Actions
              </th>

              </tr>
              </thead>
              <tbody>
                <tr>
                <td
  style={{
    color: item.selected ? '#6D33FF' : '#606C80',
    fontFamily: 'Noto Sans Mono',
    fontWeight: '400',
    fontSize: '12px',
    textAlign: 'left',
    borderRight: '1px solid rgba(0, 0, 0, 0.07)',
    borderLeft: '1px solid rgba(0, 0, 0, 0.07)',
    overflowX: 'auto',
    width: '12%'
  }}
>
  <div key={key} style={{ display: 'flex', width: '115px' }}>
  {item.images.map((image, imageIndex) => {
  if (image.type === 'avatar') {
    return (
      <div key={image.id} style={{ border: '1px solid #00000040', borderRadius: '5px', marginRight: '10%' }}>
        <img
          style={{ width: '150px', height: '250px', flexShrink: 0, marginLeft: '0%', marginRight: '0%', borderRadius: '2%', borderBottomLeftRadius: '0px', borderBottomRightRadius: '0px' }}
          src={image.url}
          alt={`Аватар ${imageIndex}`}
          onClick={() => {
            setView(true);
            setViewContent(image.url);
            setViewType('image')
          }}
        />

        {image.status === null &&
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
           <img
              src={checkoff}
                style={{
                  width: '30px',
                  //background: 'lightgrey',
                  //border: '1px solid #42c45b',
                  borderRadius: '5px',
                  margin: '5px', // добавление небольшого отступа
                }}
                onClick={() => sendVerificationRequest(item.id, image.url, 'avatar', 'image', 1)}
                //onClick={() => sendVerificationRequest2(item.id)}
              />
              <img
                src={crossoff}
                style={{
                  width: '30px',
                  //background: '#f0253d',
                  //border: '1px solid #c44242',
                  borderRadius: '5px',
                  margin: '5px', // добавление небольшого отступа
                }}
                onClick={() => sendVerificationRequest(item.id, image.url, 'avatar', 'image', 0)}
          /></div>}


{image.status === 1 &&
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
           <img
              src={checkon}
                style={{
                  width: '30px',
                  //background: 'lightgrey',
                  //border: '1px solid #42c45b',
                  borderRadius: '5px',
                  margin: '5px', // добавление небольшого отступа
                }}
              />
              <img
                src={crossoff}
                onClick={() => sendVerificationRequest(item.id, image.url, 'avatar', 'image', 0)}
                style={{
                  width: '30px',
                  //background: '#f0253d',
                  //border: '1px solid #c44242',
                  borderRadius: '5px',
                  margin: '5px', // добавление небольшого отступа
                }}
          /></div>}


{image.status === 0 &&
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
           <img
              src={checkoff}
              onClick={() => sendVerificationRequest(item.id, image.url, 'avatar', 'image', 1)}
                style={{
                  width: '30px',
                  //background: 'lightgrey',
                  //border: '1px solid #42c45b',
                  borderRadius: '5px',
                  margin: '5px', // добавление небольшого отступа
                }}
              />
              <img
                src={crosson}
                style={{
                  width: '30px',
                  //background: '#f0253d',
                  //border: '1px solid #c44242',
                  borderRadius: '5px',
                  margin: '5px', // добавление небольшого отступа
                }}
          /></div>}
      </div>
    );
  }
  return null;
})}
  </div>
</td>


<td
  style={{
    color: item.selected ? '#6D33FF' : '#606C80',
    fontFamily: 'Noto Sans Mono',
    fontWeight: '400',
    fontSize: '12px',
    textAlign: 'left',
    borderRight: '1px solid rgba(0, 0, 0, 0.07)',
    borderLeft: '1px solid rgba(0, 0, 0, 0.07)',
    overflowX: 'auto',
    //overflowX: 'scroll',
    maxWidth: '20px'
  }}
>
<div style={{ display: 'flex', width: '115px' }}>
  {item.images.map((image, imageIndex) => {
  if (image.type === 'cover') {
    return (
      <div key={image.id} style={{ border: '1px solid #00000040', borderRadius: '5px', marginRight: '10%' }}>
        <img
          style={{ width: '150px', height: '250px', flexShrink: 0, marginLeft: '0%', marginRight: '0%', borderRadius: '2%', borderBottomLeftRadius: '0px', borderBottomRightRadius: '0px' }}
          src={image.url}
          alt={`Аватар ${imageIndex}`}
          onClick={() => {
            setView(true);
            setViewContent(image.url);
            setViewType('image')
          }}
        />


        {image.status === null &&
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
           <img
              src={checkoff}
              onClick={() => sendVerificationRequest(item.id, image.url, 'cover', 'image', 1)}
                style={{
                  width: '30px',
                  //background: 'lightgrey',
                  //border: '1px solid #42c45b',
                  borderRadius: '5px',
                  margin: '5px', // добавление небольшого отступа
                }}
              />
              <img
                src={crossoff}
                onClick={() => sendVerificationRequest(item.id, image.url, 'cover', 'image', 0)}
                style={{
                  width: '30px',
                  //background: '#f0253d',
                  //border: '1px solid #c44242',
                  borderRadius: '5px',
                  margin: '5px', // добавление небольшого отступа
                }}
          /></div>}


{image.status === 1 &&
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
           <img
              src={checkon}
                style={{
                  width: '30px',
                  //background: 'lightgrey',
                  //border: '1px solid #42c45b',
                  borderRadius: '5px',
                  margin: '5px', // добавление небольшого отступа
                }}
              />
              <img
                src={crossoff}
                onClick={() => sendVerificationRequest(item.id, image.url, 'cover', 'image', 0)}
                style={{
                  width: '30px',
                  //background: '#f0253d',
                  //border: '1px solid #c44242',
                  borderRadius: '5px',
                  margin: '5px', // добавление небольшого отступа
                }}
          /></div>}


{image.status === 0 &&
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
           <img
              src={checkoff}
              onClick={() => sendVerificationRequest(item.id, image.url, 'cover', 'image', 1)}
                style={{
                  width: '30px',
                  //background: 'lightgrey',
                  //border: '1px solid #42c45b',
                  borderRadius: '5px',
                  margin: '5px', // добавление небольшого отступа
                }}
              />
              <img
                src={crosson}
                style={{
                  width: '30px',
                  //background: '#f0253d',
                  //border: '1px solid #c44242',
                  borderRadius: '5px',
                  margin: '5px', // добавление небольшого отступа
                }}
          /></div>}
      </div>
    );
  }
  return null;
})}
  </div>
</td>

<td
  style={{
    color: item.selected ? '#6D33FF' : '#606C80',
    fontFamily: 'Noto Sans Mono',
    fontWeight: '400',
    fontSize: '12px',
    textAlign: 'left',
    borderRight: '1px solid rgba(0, 0, 0, 0.07)',
    borderLeft: '1px solid rgba(0, 0, 0, 0.07)',
    overflowX: 'auto',
    //maxWidth: '20px'
  }}
>
<div style={{ display: 'flex', width: '115px' }}>
  {item.images.map((image, imageIndex) => {
    if (image.type === 'public') {
      const url = image.url;
      const isImage = /\.(jpeg|jpg|png|gif)$/.test(url);
      const isVideo = /\.(mp4|avi|mov)$/.test(url);

      return (
        <div key={image.id} style={{ border: '1px solid #00000040', borderRadius: '5px', marginRight: '10%' }}>
          {isImage && (
            <img
              style={{
                width: '150px',
                height: '250px',
                flexShrink: 0,
                marginLeft: '0%',
                marginRight: '0%',
                borderRadius: '2%',
                borderBottomLeftRadius: '0px',
                borderBottomRightRadius: '0px',
              }}
              src={url}
              alt={`Аватар ${imageIndex}`}
              onClick={() => {
                setView(true);
                setViewContent(image.url);
                setViewType('image')
              }}
            />
          )}
          {isVideo && (
            <video
              style={{
                width: '150px',
                height: '250px',
                flexShrink: 0,
                marginLeft: '0%',
                marginRight: '0%',
                borderRadius: '2%',
                borderBottomLeftRadius: '0px',
                borderBottomRightRadius: '0px',
              }}
              src={url}
              alt={`Video ${imageIndex}`}
              onClick={() => {
                setView(true);
                setViewContent(image.url);
                setViewType('video')
              }}
            />

            
          )}

    
        {isImage && image.status === null &&
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
           <img
           onClick={() => sendVerificationRequest(item.id, image.url, 'public', 'image', 1)}
              src={checkoff}
                style={{
                  width: '30px',
                  //background: 'lightgrey',
                  //border: '1px solid #42c45b',
                  borderRadius: '5px',
                  margin: '5px', // добавление небольшого отступа
                }}
              />
              <img
              onClick={() => sendVerificationRequest(item.id, image.url, 'public', 'image', 0)}
                src={crossoff}
                style={{
                  width: '30px',
                  //background: '#f0253d',
                  //border: '1px solid #c44242',
                  borderRadius: '5px',
                  margin: '5px', // добавление небольшого отступа
                }}
          /></div>}


{isImage && image.status === 1 &&
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
           <img
           
              src={checkon}
                style={{
                  width: '30px',
                  //background: 'lightgrey',
                  //border: '1px solid #42c45b',
                  borderRadius: '5px',
                  margin: '5px', // добавление небольшого отступа
                }}
              />
              <img
              onClick={() => sendVerificationRequest(item.id, image.url, 'public', 'image', 0)}
                src={crossoff}
                style={{
                  width: '30px',
                  //background: '#f0253d',
                  //border: '1px solid #c44242',
                  borderRadius: '5px',
                  margin: '5px', // добавление небольшого отступа
                }}
          /></div>}


{isImage && image.status === 0 &&
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
           <img
           onClick={() => sendVerificationRequest(item.id, image.url, 'public', 'image', 1)}
              src={checkoff}
                style={{
                  width: '30px',
                  //background: 'lightgrey',
                  //border: '1px solid #42c45b',
                  borderRadius: '5px',
                  margin: '5px', // добавление небольшого отступа
                }}
              />
              <img
                src={crosson}
                style={{
                  width: '30px',
                  //background: '#f0253d',
                  //border: '1px solid #c44242',
                  borderRadius: '5px',
                  margin: '5px', // добавление небольшого отступа
                }}
          /></div>}


{isVideo && image.status === null &&
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
           <img
           onClick={() => sendVerificationRequest(item.id, image.url, 'public', 'video', 1)}
              src={checkoff}
                style={{
                  width: '30px',
                  //background: 'lightgrey',
                  //border: '1px solid #42c45b',
                  borderRadius: '5px',
                  margin: '5px', // добавление небольшого отступа
                }}
              />
              <img
              onClick={() => sendVerificationRequest(item.id, image.url, 'public', 'video', 0)}
                src={crossoff}
                style={{
                  width: '30px',
                  //background: '#f0253d',
                  //border: '1px solid #c44242',
                  borderRadius: '5px',
                  margin: '5px', // добавление небольшого отступа
                }}
          /></div>}


{isVideo && image.status === 1 &&
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
           <img
           
              src={checkon}
                style={{
                  width: '30px',
                  //background: 'lightgrey',
                  //border: '1px solid #42c45b',
                  borderRadius: '5px',
                  margin: '5px', // добавление небольшого отступа
                }}
              />
              <img
              onClick={() => sendVerificationRequest(item.id, image.url, 'public', 'video', 0)}
                src={crossoff}
                style={{
                  width: '30px',
                  //background: '#f0253d',
                  //border: '1px solid #c44242',
                  borderRadius: '5px',
                  margin: '5px', // добавление небольшого отступа
                }}
          /></div>}


{isVideo && image.status === 0 &&
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
           <img
           onClick={() => sendVerificationRequest(item.id, image.url, 'public', 'video', 1)}
              src={checkoff}
                style={{
                  width: '30px',
                  //background: 'lightgrey',
                  //border: '1px solid #42c45b',
                  borderRadius: '5px',
                  margin: '5px', // добавление небольшого отступа
                }}
              />
              <img
                src={crosson}
                style={{
                  width: '30px',
                  //background: '#f0253d',
                  //border: '1px solid #c44242',
                  borderRadius: '5px',
                  margin: '5px', // добавление небольшого отступа
                }}
          /></div>}


      </div>
    );
  }
  return null;
})}
  </div>
</td>



<td
  style={{
    color: item.selected ? '#6D33FF' : '#606C80',
    fontFamily: 'Noto Sans Mono',
    fontWeight: '400',
    fontSize: '12px',
    textAlign: 'left',
    borderRight: '1px solid rgba(0, 0, 0, 0.07)',
    borderLeft: '1px solid rgba(0, 0, 0, 0.07)',
    
    //maxWidth: '20px'
  }}
>
  {item.approved == null &&
  <button
  style={{
    fontFamily: 'inherit',
    backgroundColor: '#FCD900',
    width: '85px',
    borderRadius: '3px',
    border: '0px',
    height: '30px',
    fontWeight: 700,
  }}
  onClick={() => sendMarkRequest(item.id)}
  >Approve</button> }
</td>

                </tr>
</tbody>
</table>   }

{/* Еще одна долбанная таблица */}

{!loading &&
<table className="table" style={{ marginTop: '-0.5%'}}>
          <thead style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.07)' }}>
            <tr>
              <th
                style={{
                  color: 'black',
                  fontFamily: 'Noto Sans Mono',
                  weight: '500',
                  fontSize: '12px',
                  textAlign: 'left',
                  borderRight: '1px solid rgba(0, 0, 0, 0.07)',
                  borderLeft: '1px solid rgba(0, 0, 0, 0.07)',
                  width: '5%'
                }}
              >
                Verification
              </th>

              <th
                style={{
                  color: 'black',
                  fontFamily: 'Noto Sans Mono',
                  weight: '500',
                  fontSize: '12px',
                  textAlign: 'left',
                  borderRight: '1px solid rgba(0, 0, 0, 0.07)',
                  width: '11.5%'
                }}
              >
                Video cover
              </th>



              <th
                style={{
                  color: 'black',
                  fontFamily: 'Noto Sans Mono',
                  weight: '500',
                  fontSize: '12px',
                  textAlign: 'left',
                  borderRight: '1px solid rgba(0, 0, 0, 0.07)',
                  width: '70%'
                }}
              >
                Private album
              </th>



              </tr>
              </thead>
              <tbody>
                <tr>
                

<td
  style={{
    color: item.selected ? '#6D33FF' : '#606C80',
    fontFamily: 'Noto Sans Mono',
    fontWeight: '400',
    fontSize: '12px',
    textAlign: 'left',
    borderRight: '1px solid rgba(0, 0, 0, 0.07)',
    borderLeft: '1px solid rgba(0, 0, 0, 0.07)',
    overflowX: 'auto',

    width: '12%'
  }}
>
<div style={{ display: 'flex', width: '115px' }}>
  {item.images.map((image, imageIndex) => {
  if (image.type === 'verification') {
    return (
      <div key={image.id} style={{ border: '1px solid #00000040', borderRadius: '5px', marginRight: '10%' }}>
        <video
          style={{ width: '150px', height: '250px', flexShrink: 0, marginLeft: '0%', marginRight: '0%', borderRadius: '2%', borderBottomLeftRadius: '0px', borderBottomRightRadius: '0px' }}
          src={image.url}
          alt={`Аватар ${imageIndex}`}
          onClick={() => {
            setView(true);
            setViewContent(image.url);
            setViewType('video')
          }}
        />



      {image.status === null &&
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
           <img
              src={checkoff}
              onClick={() => sendVerificationRequest(item.id, image.url, 'verification', 'video', 1)}
                style={{
                  width: '30px',
                  //background: 'lightgrey',
                  //border: '1px solid #42c45b',
                  borderRadius: '5px',
                  margin: '5px', // добавление небольшого отступа
                }}
              />
              <img
                src={crossoff}
                onClick={() => sendVerificationRequest(item.id, image.url, 'verification', 'video', 0)}
                style={{
                  width: '30px',
                  //background: '#f0253d',
                  //border: '1px solid #c44242',
                  borderRadius: '5px',
                  margin: '5px', // добавление небольшого отступа
                }}
          /></div>}


{image.status === 1 &&
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
           <img
              src={checkon}
              
                style={{
                  width: '30px',
                  //background: 'lightgrey',
                  //border: '1px solid #42c45b',
                  borderRadius: '5px',
                  margin: '5px', // добавление небольшого отступа
                }}
              />
              <img
                src={crossoff}
                onClick={() => sendVerificationRequest(item.id, image.url, 'verification', 'video', 0)}
                style={{
                  width: '30px',
                  //background: '#f0253d',
                  //border: '1px solid #c44242',
                  borderRadius: '5px',
                  margin: '5px', // добавление небольшого отступа
                }}
          /></div>}


{image.status === 0 &&
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
           <img
              src={checkoff}
              onClick={() => sendVerificationRequest(item.id, image.url, 'verification', 'video', 1)}
                style={{
                  width: '30px',
                  //background: 'lightgrey',
                  //border: '1px solid #42c45b',
                  borderRadius: '5px',
                  margin: '5px', // добавление небольшого отступа
                }}
              />
              <img
                src={crosson}
                style={{
                  width: '30px',
                  //background: '#f0253d',
                  //border: '1px solid #c44242',
                  borderRadius: '5px',
                  margin: '5px', // добавление небольшого отступа
                }}
          /></div>}
      </div>
    );
  }
  return null;
})}
  </div>
</td>

<td
  style={{
    color: item.selected ? '#6D33FF' : '#606C80',
    fontFamily: 'Noto Sans Mono',
    fontWeight: '400',
    fontSize: '12px',
    textAlign: 'left',
    borderRight: '1px solid rgba(0, 0, 0, 0.07)',
    borderLeft: '1px solid rgba(0, 0, 0, 0.07)',
    overflowX: 'auto',

    maxWidth: '20px'
  }}
>
<div style={{ display: 'flex', width: '115px' }}>
  {item.images.map((image, imageIndex) => {
  if (image.type === 'video_cover') {
    return (
      <div key={image.id} style={{ border: '1px solid #00000040', borderRadius: '5px', marginRight: '10%' }}>
        <video
          style={{ width: '150px', height: '250px', flexShrink: 0, marginLeft: '0%', marginRight: '0%', borderRadius: '2%', borderBottomLeftRadius: '0px', borderBottomRightRadius: '0px' }}
          src={image.url}
          alt={`Аватар ${imageIndex}`}
          onClick={() => {
            setView(true);
            setViewContent(image.url);
            setViewType('video')
          }}
        />


        {image.status === null &&
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
           <img
              src={checkoff}
              onClick={() => sendVerificationRequest(item.id, image.url, 'video_cover', 'video', 1)}
                style={{
                  width: '30px',
                  //background: 'lightgrey',
                  //border: '1px solid #42c45b',
                  borderRadius: '5px',
                  margin: '5px', // добавление небольшого отступа
                }}
              />
              <img
              onClick={() => sendVerificationRequest(item.id, image.url, 'video_cover', 'video', 0)}
                src={crossoff}
                style={{
                  width: '30px',
                  //background: '#f0253d',
                  //border: '1px solid #c44242',
                  borderRadius: '5px',
                  margin: '5px', // добавление небольшого отступа
                }}
          /></div>}


{image.status === 1 &&
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
           <img
              src={checkon}
                style={{
                  width: '30px',
                  //background: 'lightgrey',
                  //border: '1px solid #42c45b',
                  borderRadius: '5px',
                  margin: '5px', // добавление небольшого отступа
                }}
              />
              <img
              onClick={() => sendVerificationRequest(item.id, image.url, 'video_cover', 'video', 0)}
                src={crossoff}
                style={{
                  width: '30px',
                  //background: '#f0253d',
                  //border: '1px solid #c44242',
                  borderRadius: '5px',
                  margin: '5px', // добавление небольшого отступа
                }}
          /></div>}


{image.status === 0 &&
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
           <img
           onClick={() => sendVerificationRequest(item.id, image.url, 'video_cover', 'video', 1)}
              src={checkoff}
                style={{
                  width: '30px',
                  //background: 'lightgrey',
                  //border: '1px solid #42c45b',
                  borderRadius: '5px',
                  margin: '5px', // добавление небольшого отступа
                }}
              />
              <img
                src={crosson}
                style={{
                  width: '30px',
                  //background: '#f0253d',
                  //border: '1px solid #c44242',
                  borderRadius: '5px',
                  margin: '5px', // добавление небольшого отступа
                }}
          /></div>}
      </div>
    );
  }
  return null;
})}
  </div>
</td>



<td
  style={{
    color: item.selected ? '#6D33FF' : '#606C80',
    fontFamily: 'Noto Sans Mono',
    fontWeight: '400',
    fontSize: '12px',
    textAlign: 'left',
    borderRight: '1px solid rgba(0, 0, 0, 0.07)',
    borderLeft: '1px solid rgba(0, 0, 0, 0.07)',
    overflowX: 'auto',

    //maxWidth: '20px'
  }}
>
<div style={{ display: 'flex', width: '115px' }}>
  {item.images.map((image, imageIndex) => {
    if (image.type === 'private') {
      const url = image.url;
      const isImage = /\.(jpeg|jpg|png|gif)$/.test(url);
      const isVideo = /\.(mp4|avi|mov)$/.test(url);

      return (
        <div key={image.id} style={{ border: '1px solid #00000040', borderRadius: '5px', marginRight: '10%' }}>
          {isImage && (
            <img
              style={{
                width: '150px',
                height: '250px',
                flexShrink: 0,
                marginLeft: '0%',
                marginRight: '0%',
                borderRadius: '2%',
                borderBottomLeftRadius: '0px',
                borderBottomRightRadius: '0px',
              }}
              src={url}
              alt={`Аватар ${imageIndex}`}
              onClick={() => {
                setView(true);
                setViewContent(image.url);
                setViewType('image')
              }}
            />
          )}
          {isVideo && (
            <video
              style={{
                width: '150px',
                height: '250px',
                flexShrink: 0,
                marginLeft: '0%',
                marginRight: '0%',
                borderRadius: '2%',
                borderBottomLeftRadius: '0px',
                borderBottomRightRadius: '0px',
              }}
              src={url}
              alt={`Video ${imageIndex}`}
              onClick={() => {
                setView(true);
                setViewContent(image.url);
                setViewType('video')
              }}
            />
          )}

          
              {isImage && image.status === null &&
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
           <img
           onClick={() => sendVerificationRequest(item.id, image.url, 'private', 'image', 1)}
              src={checkoff}
                style={{
                  width: '30px',
                  //background: 'lightgrey',
                  //border: '1px solid #42c45b',
                  borderRadius: '5px',
                  margin: '5px', // добавление небольшого отступа
                }}
              />
              <img
              onClick={() => sendVerificationRequest(item.id, image.url, 'private', 'image', 0)}
                src={crossoff}
                style={{
                  width: '30px',
                  //background: '#f0253d',
                  //border: '1px solid #c44242',
                  borderRadius: '5px',
                  margin: '5px', // добавление небольшого отступа
                }}
          /></div>}


{isImage && image.status === 1 &&
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
           <img
           
              src={checkon}
                style={{
                  width: '30px',
                  //background: 'lightgrey',
                  //border: '1px solid #42c45b',
                  borderRadius: '5px',
                  margin: '5px', // добавление небольшого отступа
                }}
              />
              <img
              onClick={() => sendVerificationRequest(item.id, image.url, 'private', 'image', 0)}
                src={crossoff}
                style={{
                  width: '30px',
                  //background: '#f0253d',
                  //border: '1px solid #c44242',
                  borderRadius: '5px',
                  margin: '5px', // добавление небольшого отступа
                }}
          /></div>}


{isImage && image.status === 0 &&
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
           <img
           onClick={() => sendVerificationRequest(item.id, image.url, 'private', 'image', 1)}
              src={checkoff}
                style={{
                  width: '30px',
                  //background: 'lightgrey',
                  //border: '1px solid #42c45b',
                  borderRadius: '5px',
                  margin: '5px', // добавление небольшого отступа
                }}
              />
              <img
                src={crosson}
                style={{
                  width: '30px',
                  //background: '#f0253d',
                  //border: '1px solid #c44242',
                  borderRadius: '5px',
                  margin: '5px', // добавление небольшого отступа
                }}
          /></div>}


{isVideo && image.status === null &&
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
           <img
           onClick={() => sendVerificationRequest(item.id, image.url, 'private', 'video', 1)}
              src={checkoff}
                style={{
                  width: '30px',
                  //background: 'lightgrey',
                  //border: '1px solid #42c45b',
                  borderRadius: '5px',
                  margin: '5px', // добавление небольшого отступа
                }}
              />
              <img
              onClick={() => sendVerificationRequest(item.id, image.url, 'private', 'video', 0)}
                src={crossoff}
                style={{
                  width: '30px',
                  //background: '#f0253d',
                  //border: '1px solid #c44242',
                  borderRadius: '5px',
                  margin: '5px', // добавление небольшого отступа
                }}
          /></div>}

{isVideo && image.status === 1 &&
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
           <img
           
              src={checkon}
                style={{
                  width: '30px',
                  //background: 'lightgrey',
                  //border: '1px solid #42c45b',
                  borderRadius: '5px',
                  margin: '5px', // добавление небольшого отступа
                }}
              />
              <img
              onClick={() => sendVerificationRequest(item.id, image.url, 'private', 'video', 0)}
                src={crossoff}
                style={{
                  width: '30px',
                  //background: '#f0253d',
                  //border: '1px solid #c44242',
                  borderRadius: '5px',
                  margin: '5px', // добавление небольшого отступа
                }}
          /></div>}


{isVideo && image.status === 0 &&
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
           <img
           onClick={() => sendVerificationRequest(item.id, image.url, 'private', 'video', 1)}
              src={checkoff}
                style={{
                  width: '30px',
                  //background: 'lightgrey',
                  //border: '1px solid #42c45b',
                  borderRadius: '5px',
                  margin: '5px', // добавление небольшого отступа
                }}
              />
              <img
                src={crosson}
                style={{
                  width: '30px',
                  //background: '#f0253d',
                  //border: '1px solid #c44242',
                  borderRadius: '5px',
                  margin: '5px', // добавление небольшого отступа
                }}
          /></div>}
      </div>
    );
  }
  return null;
})}
  </div>
</td>

<td
  style={{
    color: item.selected ? '#6D33FF' : '#606C80',
    fontFamily: 'Noto Sans Mono',
    fontWeight: '400',
    fontSize: '12px',
    textAlign: 'left',
    borderTop: '0px solid rgba(0, 0, 0, 0.07)',
    borderRight: '1px solid rgba(0, 0, 0, 0.07)',
    borderLeft: '1px solid rgba(0, 0, 0, 0.07)',
    overflowX: 'auto',

    //maxWidth: '20px'
  }}
>

</td>

                </tr>
</tbody>
</table>   }

{/* долбанная таблица */}

</div>

        </div>
       ))}

{!loading &&
<div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
          <button className="remove-button" style={{ backgroundColor: '#6D33FF' }} onClick={handlePreviousPage}>
            &lt; Previous
          </button>
          {/*<button className="remove-button" style={{ backgroundColor: '#FFF', width: '40px', color: 'black' }}>
            {page}
</button>*/}

          <input
          className="remove-button"
          style={{ backgroundColor: '#FFF', width: '40px', color: 'black' }}
          type="number"
          value={inputPage}
          onChange={(e) => setInputPage(parseInt(e.target.value))}
          onKeyPress={handleKeyPress}
        />

          <button className="remove-button" style={{ backgroundColor: '#6D33FF' }} onClick={handleNextPage}>
            Next &gt;
          </button>
                </div>
}



</>   );
}

export default MyTable;
