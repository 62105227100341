import React, { useState } from "react";
import { StyleSheet, css } from "aphrodite/no-important";
import Table from "./TableActivity_models";
import ModelButton from "./ModelButton";
import { useHistory } from "react-router-dom";
import axios from "axios";
import "./style.css";

const styles = StyleSheet.create({
  h1: {
    fontSize: "7.5em",
    margin: "15px 0px",
    fontWeight: "bold",
  },
  h2: {
    fontWeight: "bold",
  },
});

const Models = () => {
  const history = useHistory();
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [codeName, setCodeName] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    contactSystem: "",
    contactDetail: "",
    paymentSystem: "",
    paymentDetail: "",
  });

  const handleClick = async (e) => {
    e.preventDefault();

    const data = {
      name: formData.name,
      contact_system: formData.contactSystem,
      contact_detail: formData.contactDetail,
      payment_system: formData.paymentSystem,
      payment_detail: formData.paymentDetail,
    };

    try {
      const response = await axios.post(
        "https://golive.mobi/api/user/m-signup/",
        data,
        {
          headers: {
            Authorization: `Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d`,
            "Content-Type": 'application/json',
          },
        }
      );

      if (response.status === 200) {
        setCodeName(response.data.code_name);
      } else {
        console.error("Error:", response);
      }
    } catch (error) {
      console.error("Error:", error);
    }

    setIsSaveButtonDisabled(true); // Disable the Save button after form submission
    setIsSubmitted(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    setIsFormValid(
      value !== "" ||
      formData.contactSystem !== "" ||
      formData.contactDetail !== "" ||
      formData.paymentSystem !== "" ||
      formData.paymentDetail !== ""
    );

    if (isSubmitted) {
      // Enable the Save button if any field is changed after form submission
      setIsSaveButtonDisabled(false);
    }
  };

  const handleClear = () => {
    setFormData({
      name: "",
      contactSystem: "",
      contactDetail: "",
      paymentSystem: "",
      paymentDetail: "",
    });
    setIsFormValid(false);
    setIsSubmitted(false);
    setIsSaveButtonDisabled(false); // Enable the Save button when the Clear button is clicked
  };

  return (
    <div>
      <h2 style={{ marginLeft: "1%", fontFamily: "Inter" }}>
        Create new Model
      </h2>

      <div className="square">
        <form className="createmodel">
          <label className="square_content">
            Name:
            <input
              type="text"
              name="name"
              className="square_field1"
              value={formData.name}
              onChange={handleInputChange}
            />
          </label>
          <br />
          <label className="square_content">
            Messenger for communication:
            <input
              type="text"
              name="contactSystem"
              className="square_field2"
              value={formData.contactSystem}
              onChange={handleInputChange}
            />
          </label>
          <br />
          <label className="square_content">
            Contact for communication:
            <input
              type="text"
              name="contactDetail"
              className="square_field3"
              value={formData.contactDetail}
              onChange={handleInputChange}
            />
          </label>
          <br />
          <label className="square_content">
            Withdrawal payment:
            <input
              type="text"
              name="paymentSystem"
              className="square_field4"
              value={formData.paymentSystem}
              onChange={handleInputChange}
            />
          </label>
          <br />
          <label className="square_content">
            Withdrawal details:
            <input
              style={{ marginLeft: '15%' }}
              type="text"
              name="paymentDetail"
              className="square_field5"
              value={formData.paymentDetail}
              onChange={handleInputChange}
            />
          </label>
          <br />
          <button
            style={{
              left: '20%',
              opacity: isSaveButtonDisabled ? '0.5' : '1', // Set the opacity based on the disabled state
              cursor: isSaveButtonDisabled ? 'default' : 'pointer', // Set cursor style based on the disabled state
            }}
            type="submit"
            onClick={handleClick}
            className={`square_button ${!isFormValid ? "disabled" : ""}`}
            disabled={!isFormValid || isSaveButtonDisabled} // Disable the button based on the new state variable
          >
            Save
          </button>
          <button
          style={{ marginLeft: '2.5%'}}
            type="button"
            onClick={handleClear}
            className="square_button"
            
          >
            Clear
          </button>
          {codeName && (
            <p className="square_content" style={{ width: '50%', marginTop: '0%' }}>
              Code name model: {codeName}
            </p>
          )}
        </form>
      </div>
    </div>
  );
};

export default Models;
