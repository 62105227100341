import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";

const Menu = () => {
  const [activePage, setActivePage] = useState("");
  const location = useLocation();

  useEffect(() => {
    const path = location.pathname.substr(1); // Remove the first character "/"
    setActivePage(path);
  }, [location]);

  useEffect(() => {
    const savedActivePage = localStorage.getItem("activePage");
    if (savedActivePage && BRANDS.includes(savedActivePage)) {
      setActivePage(savedActivePage);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("activePage", activePage);
  }, [activePage]);

  const isModelsPage = activePage === "models";

  const handleClick = (brand, event) => {
    if (brand.includes("❌")) {
      console.log('lock')
    }
    setActivePage(brand);
  };

  // Извлеките роль из localStorage
  const userRole = localStorage.getItem("role");

  // Определите BRANDS в зависимости от роли пользователя
  //const BRANDS = userRole === "sector05a"
    //? ["Models", "Users", "Complaints", "Applications", "Billing", "Telegram", "Settings"]
    //: userRole === "sector01b"
    //? ["Applications"]
    //: [];

  
    let BRANDS;

    if (userRole === "sector05a") {
      BRANDS = ["Models-new", "Users", "Complaints", "Applications", "Snapshots", "Verification", "Feedback", "Streamlist", "Terminal", "Video", "Bans", "Statistic", "Billing", "Telegram", "Settings", "Test", "Push"];
    } else if (userRole === "sectorMod") {
      BRANDS = ["Models-new", "Snapshots", "Verification", "Statistic", "Terminal", "Bans", "Test"];
    } else {
      BRANDS = ["Video"];
    }
    
    
  return (
    <div>
      <nav className="navbar">
        <ul className="navbar-list">
          {BRANDS.map((brand) => (
            <NavLink
              className={`text ${
                activePage === brand || (isModelsPage && brand === "Models") ? "active" : ""
              }`}
              label={brand}
              style={{ fontFamily: "inter", fontSize: "14px" }}
              to={`/${brand.toLowerCase()}`}
              key={brand}
              onClick={() => handleClick(brand)}
            >
              <li className="navbar-item">{brand}</li>
            </NavLink>

            
          ))}
        </ul>
      </nav>
    </div>
  );
};

export default Menu;
