import React, { useState, useEffect } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const Models = () => {

	
	const [timeInterval, setTimeInterval] = useState('10min');
	const [data, setData] = useState([]);
	const [totalCounts, setTotalCounts] = useState({
	  unsuccessful_count: 0,
	  successful_count: 0,
	  connected_count: 0,
	});
  
	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await fetch('https://admin.golive.mobi/qat/');
				const jsonData = await response.json();
				setData(jsonData);
				updateTotalCounts(jsonData);
	
				// Дополнительный запрос для получения данных "Пуш получен"
				const pushReceiveResponse = await fetch('https://admin.golive.mobi/medium/');
				const pushReceiveData = await pushReceiveResponse.json();
				
				// Обновление данных в state для всех временных интервалов
				setData((prevData) => (
					prevData.map((item, index) => ({
						...item,
						push_receive: pushReceiveData[index].push_receive,
						average_time: pushReceiveData[index].average_time,
					}))
				));
	
			} catch (error) {
				console.error('Ошибка загрузки данных:', error);
			}
		};
	
		fetchData();
	}, []);

	
  
	const updateTotalCounts = (data) => {
		const counts = data.reduce(
		  (acc, cur) => {
			acc.unsuccessful_count += cur.unsuccessful_count;
			acc.successful_count += cur.successful_count;
			acc.connected_count += cur.connected_count;
			acc.dialog_decline += cur.dialog_decline; // Добавление dialog_decline
			acc.dialog_timeout += cur.dialog_timeout; // Добавление dialog_timeout
			return acc;
		  },
		  { unsuccessful_count: 0, successful_count: 0, connected_count: 0, dialog_decline: 0, dialog_timeout: 0 }
		);
		setTotalCounts(counts);
	  };
  
	  const filterDataByInterval = (interval) => {
		switch (interval) {
		  case '1min':
			return data.map(item => ({ ...item, time: formatTime(item.timestamp_start) }));
		  case '10min':
			return aggregateDataByInterval(10);
		  case '30min':
			return aggregateDataByInterval(30);
		  case '1hr':
			return aggregateDataByInterval(60);
		  default:
			return data;
		}
	  };

// ...

const aggregateDataByInterval = (minutes) => {
	if (data.length === 0) {
	  return [];
	}
  
	const aggregatedData = [];
	let index = 0;
  
	while (index < data.length) {
	  const tempData = {
		unsuccessful_count: 0,
		successful_count: 0,
		connected_count: 0,
		dialog_decline: 0,
		dialog_timeout: 0,
		push_receive: 0,
		average_time: 0, // Добавим инициализацию average_time
		timestamp_start: data[index].timestamp_start,
		timestamp_end: data[Math.min(index + minutes, data.length - 1)].timestamp_start,
	  };
  
	  for (let i = index; i < index + minutes && i < data.length; i++) {
		const currentItem = data[i];
		tempData.unsuccessful_count += currentItem.unsuccessful_count;
		tempData.successful_count += currentItem.successful_count;
		tempData.connected_count += currentItem.connected_count;
		tempData.dialog_decline += currentItem.dialog_decline;
		tempData.dialog_timeout += currentItem.dialog_timeout;
		tempData.push_receive += currentItem.push_receive;
		tempData.average_time += currentItem.average_time; // Накапливаем значения average_time
	  }
  
	  // Вычисляем среднее значение для average_time
	  tempData.average_time /= minutes;
  
	  aggregatedData.push({
		...tempData,
		time: `${formatTime(tempData.timestamp_start)} - ${formatTime(tempData.timestamp_end)}`,
	  });
  
	  index += minutes;
	}
  
	return aggregatedData;
  };
  
  
  
	  
	  
	  const formatTime = (timestamp) => {
		const date = new Date(timestamp * 1000); // Преобразование в миллисекунды
		// Вычитаем три часа (3 * 60 * 60 * 1000 миллисекунд) из времени
		date.setTime(date.getTime() - (3 * 60 * 60 * 1000));
		
		const hours = date.getHours().toString().padStart(2, '0');
		const minutes = date.getMinutes().toString().padStart(2, '0');
		return `${hours}:${minutes}`;
	  };
	  
	  
  
	  const filteredData = filterDataByInterval(timeInterval);

	  const chartData = filteredData.map((item) => ({
		time: item.time,
		unsuccessful_count: item.unsuccessful_count,
		successful_count: item.successful_count,
		connected_count: item.connected_count,
		push_receive: item.push_receive,
		average_time: item.average_time.toFixed(1), // Ограничиваем количество знаков после запятой
	  }));
	  
	  

	const handleTimeIntervalChange = (interval) => {
	  setTimeInterval(interval);
	};

	const [one, setOne] = useState(false);
	const [two, setTwo] = useState(true);
	const [three, setThree] = useState(false);
	const [four, setFour] = useState(false);
	const [five, setFive] = useState(false);
  
	const toggleOne = () => {
	  setOne(!one)
	  setTwo(false)
	  setThree(false)
	  setFive(false)
	  setFour(false)
	};
  
	const toggleTwo = () => {
	  setTwo(!two)
	  setOne(false)
	  setThree(false)
	  setFive(false)
	  setFour(false)
	};
  
	const toggleThree = () => {
	  setThree(!three)
	  setTwo(false)
	  setOne(false)
	  setFour(false)
	  setFive(false)
	};
	const toggleFour = () => {
	  setFour(!four)
	  setThree(false)
	  setTwo(false)
	  setOne(false)
	  setFive(false)
	};
	const toggleFive = () => {
	  setFive(!five)
	  setFour(false)
	  setThree(false)
	  setTwo(false)
	  setOne(false)
	};

  return (
    <div className="col-md-6 align-self-center" style={{ marginTop: '1%', fontFamily: 'Arial, sans-serif', textAlign: 'center' }}>

<h2 style={{marginLeft: '1%', fontFamily: 'inter'}}>Statistic</h2>

      <div>
        {/* Кнопки временных интервалов */}
        <button className={`button ${one ? 'active-button' : 'inactive-button'}`} style={{
			width: '100px',
			height: '30px',
			borderRadius: '15px',
			color: 'black',
		}}   onClick={() => {
			setTimeInterval('1min');
			toggleOne();
		  }}>1 min</button>
        <button style={{
			width: '100px',
			height: '30px',
			borderRadius: '15px',
			color: 'black',
		}} className={`button ${two ? 'active-button' : 'inactive-button'}`}   onClick={() => {
			setTimeInterval('10min');
			toggleTwo();
		  }}>10 min</button>
        <button style={{
			width: '100px',
			height: '30px',
			borderRadius: '15px',
			color: 'black',
		}} className={`button ${three ? 'active-button' : 'inactive-button'}`}   onClick={() => {
			setTimeInterval('30min');
			toggleThree();
		  }}>30 min</button>
        <button style={{
			width: '100px',
			height: '30px',
			borderRadius: '15px',
			color: 'black',
		}} className={`button ${four ? 'active-button' : 'inactive-button'}`}   onClick={() => {
			setTimeInterval('1hr');
			toggleFour();
		  }}>1 hr</button>
      </div>

      <ResponsiveContainer width="100%" height={300}>
	  <BarChart data={chartData}>
  <XAxis dataKey="time" />
  <YAxis />
  <Tooltip labelFormatter={(value) => `Time: ${value}`} />





  <Legend />
  <Bar dataKey="unsuccessful_count" fill="#cb4141" name="Не найдена модель" />
  <Bar dataKey="successful_count" fill="#82ca9d" name="Диалог состоялся" />
  <Bar dataKey="push_receive" fill="#ffc658" name="Пуш получен" />
  <Bar dataKey="connected_count" fill="#8884d8" name="Найдена модель" />
  <Bar dataKey="average_time" fill="#ff7300" name="Среднее время" />
</BarChart>

      </ResponsiveContainer>

      {/* Таблица с общими значениями */}
      <div style={{ marginTop: '20px', fontFamily: 'Arial, sans-serif', textAlign: 'center', width: '95%', marginLeft: 'auto', marginRight: 'auto' }}>
        <table style={{ margin: '0 auto', borderCollapse: 'collapse', border: '2px solid rgb(204 204 204)', width: '100%', borderRadius: '15px' }}>
          <tbody>
            <tr style={{ border: '2px solid rgb(204 204 204)' }}>
              <td style={{ padding: '10px', border: '2px solid rgb(204 204 204)', backgroundColor: '#f2f2f2', width: '50%' }}>Не найдена модель</td>
              <td style={{ padding: '10px', border: '2px solid rgb(204 204 204)', backgroundColor: '#fff', width: '50%' }}>{totalCounts.unsuccessful_count}</td>
            </tr>
            <tr style={{ border: '2px solid rgb(204 204 204)' }}>
              <td style={{ padding: '10px', border: '2px solid rgb(204 204 204)', backgroundColor: '#f2f2f2', width: '50%' }}>Диалог состоялся</td>
              <td style={{ padding: '10px', border: '2px solid rgb(204 204 204)', backgroundColor: '#fff', width: '50%' }}>{totalCounts.successful_count}</td>
            </tr>
            <tr style={{ border: '2px solid rgb(204 204 204)' }}>
              <td style={{ padding: '10px', border: '2px solid rgb(204 204 204)', backgroundColor: '#f2f2f2', width: '50%' }}>Найдена модель</td>
              <td style={{ padding: '10px', border: '2px solid rgb(204 204 204)', backgroundColor: '#fff', width: '50%' }}>{totalCounts.connected_count}</td>
            </tr>
			<tr style={{ border: '2px solid rgb(204 204 204)' }}>
              <td style={{ padding: '10px', border: '2px solid rgb(204 204 204)', backgroundColor: '#f2f2f2', width: '50%' }}>Отказы в диалоге</td>
              <td style={{ padding: '10px', border: '2px solid rgb(204 204 204)', backgroundColor: '#fff', width: '50%' }}>{totalCounts.dialog_decline}</td>
            </tr>
            <tr style={{ border: '2px solid rgb(204 204 204)' }}>
              <td style={{ padding: '10px', border: '2px solid rgb(204 204 204)', backgroundColor: '#f2f2f2', width: '50%' }}>Таймауты диалога</td>
              <td style={{ padding: '10px', border: '2px solid rgb(204 204 204)', backgroundColor: '#fff', width: '50%' }}>{totalCounts.dialog_timeout}</td>
            </tr>
			<tr style={{ border: '2px solid rgb(204 204 204)' }}>
              <td style={{ padding: '10px', border: '2px solid rgb(204 204 204)', backgroundColor: '#f2f2f2', width: '50%' }}>Всего запросов</td>
              <td style={{ padding: '10px', border: '2px solid rgb(204 204 204)', backgroundColor: '#fff', width: '50%' }}>{totalCounts.connected_count + totalCounts.unsuccessful_count}</td>
            </tr>
          </tbody>
        </table>
      </div>

    </div>
  );
};

export default Models;
