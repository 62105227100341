import React, { useState, useEffect, useCallback } from 'react';
import './style.css';
import { useHistory } from 'react-router-dom';
import check_active from './check_active.svg';
import check_disable from './check_disable.svg';
import { Oval } from  'react-loader-spinner'
import UserCount from './UserCount'

const TableGeneral = () => {
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [searchGender, setSearchGender] = useState('');
  const [searchPremium, setSearchPremium] = useState('');
  const [userType, setUserType] = useState('all');

  const handleGenderSelect = (event) => {
    setSearchGender(event.target.value);
  };

  const handlePremiumSelect = (event) => {
    setSearchPremium(event.target.value);
  };

  useEffect(() => {
    fetchData();
  }, [currentPage]);

  useEffect(() => {
    const filteredByGender = searchGender
      ? data.filter((item) => item.gender === (searchGender === 'man' ? 1 : 2))
      : data;

    const filteredByPremium = searchPremium
      ? data.filter((item) => item.premium === (searchPremium === 'premium' ? 1 : 0))
      : data;

    setFilteredData(filteredByGender.filter((item) => filteredByPremium.includes(item)));
  }, [data, searchGender, searchPremium]);

  const fetchData = () => {
  setLoading(true);
  const apiUrl = 'https://golive.mobi/api/user/user-list/';
  const requestBody = userType === 'paying' 
    ? JSON.stringify({ page: currentPage, paying: 1 })
    : JSON.stringify({ page: currentPage });

  const requestOptions = {
    method: 'POST',
    headers: {
      Authorization: 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
      'Content-Type': 'application/json',
    },
    body: requestBody,
  };

  fetch(apiUrl, requestOptions)
    .then((response) => response.json())
    .then((data) => {
      if (data.list && Array.isArray(data.list)) {
        const mappedData = data.list.map((item) => ({
          id: item.id,
          gender: item.gender,
          countryCode: item.countryCode,
          timeRegister: item.timeRegister,
          activity: item.activity.last_active,
          balance: item.balance,
          following_count: item.following_count,
          followers_count: item.followers_count,
          premium: item.premium,
          fan_subscribed: item.fan_subscribed,
          paying: item.paying,
          last_search: item.activity.last_search, 
        }));
        setLoading(mappedData.length === 0);
        setData(mappedData);
      }
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
    })
    .finally(() => {
      setLoading(false); // Устанавливаем флаг загрузки в false после завершения запроса
    });
};

const handleUserTypeChange = (type) => {
  setUserType(type);
  setCurrentPage(1); // Сброс страницы на первую при изменении типа пользователей
};

useEffect(() => {
  fetchData();
}, [userType, currentPage]);

  const handleClick = () => {
    history.push('/modelcreate');
  };

  const [showForm, setShowForm] = useState(false);

  const toggleForm = () => {
    setShowForm(!showForm);
  };

  const initialData = [];

  const toggleSelectAll = () => {
    setSelectAll(!selectAll);
    const updatedData = data.map((item) => ({ ...item, selected: !selectAll }));
    setData(updatedData);
  };

  const toggleSelectItem = (id) => {
    const updatedData = data.map((item) => {
      if (item.id === id) {
        return { ...item, selected: !item.selected };
      }
      return item;
    });
    setData(updatedData);
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleModelClick = useCallback(
    (id) => {
      localStorage.setItem('id_models', id);
      history.push(`/user_model/${id}`);
    },
    [history]
  );

  function formatDate(timestamp) {
    if (!timestamp) {
      return '';
    }

    const dateObj = new Date(timestamp * 1000);

    if (isNaN(dateObj)) {
      return 'Invalid timestamp';
    }

    const year = dateObj.getFullYear();
    const month = ('0' + (dateObj.getMonth() + 1)).slice(-2);
    const day = ('0' + dateObj.getDate()).slice(-2);
    const hours = ('0' + dateObj.getHours()).slice(-2);
    const minutes = ('0' + dateObj.getMinutes()).slice(-2);

    return `${day}.${month}.${year} ${hours}:${minutes}`;
  }

  function formatTimestampReg(timestamp) {
    if (!timestamp) {
      return '';
    }

    const dateObj = new Date(Number(timestamp));

    if (isNaN(dateObj)) {
      return 'Invalid timestamp';
    }

    const year = dateObj.getFullYear();
    const month = ('0' + (dateObj.getMonth() + 1)).slice(-2);
    const day = ('0' + dateObj.getDate()).slice(-2);
    const hours = ('0' + dateObj.getHours()).slice(-2);
    const minutes = ('0' + dateObj.getMinutes()).slice(-2);

    return `${day}.${month}.${year} ${hours}:${minutes}`;
  }

  function formatDateTimeLS(dateTimeString) {
    if (!dateTimeString) {
      return "NULL";
    }
  
    const options = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: false
    };
  
    const dateTime = new Date(dateTimeString);
    return dateTime.toLocaleString('en-US', options);
  }

  return (
    <div>
{/*
<div style={{ display: 'flex', justifyContent: 'space-between', marginTop:'-1%' }}>
          <h2 style={{ marginLeft: '1%', fontFamily: 'inter' }}>Billing</h2>
        </div>

      <div style={{ marginTop: '-3.5%', display: 'block' }}>


      <select
          className="billing_search_field"
          value={searchGender}
          placeholder='Status'
          onChange={handleGenderSelect}
        >
          <option value="">Gender</option>
          <option value="man">Man</option>
          <option value="woman">Woman</option>
        </select>

        <select
          className="billing_search_field"
          value={searchPremium}
          placeholder='Status'
          onChange={handlePremiumSelect}
        >
          <option value="">Premium user</option>
          <option value="premium">Premium</option>
          <option value="nopremium">Not premium</option>
        </select>


  </div> */}


      
      <div>

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h2 style={{marginLeft: '1%', fontFamily: 'inter'}}>All users</h2>
          
        </div>
        <UserCount />

        
        <div style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '20px', paddingLeft: '10px' }}>
      <button
        className={`remove-button ${userType === 'all' ? 'active' : ''}`}
        style={{ 
          backgroundColor: userType === 'all' ? '#6D33FF' : '#FFF', 
          color: userType === 'all' ? '#FFF' : 'black',
          marginRight: '10px'
        }}
        onClick={() => handleUserTypeChange('all')}
      >
        All users
      </button>
      <button
        className={`remove-button ${userType === 'paying' ? 'active' : ''}`}
        style={{ 
          backgroundColor: userType === 'paying' ? '#6D33FF' : '#FFF', 
          color: userType === 'paying' ? '#FFF' : 'black',
          marginRight: '10px'
        }}
        onClick={() => handleUserTypeChange('paying')}
      >
        Paying users
      </button>
    </div>

        {loading && (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
  <Oval
    height={200}
    width={200}
    color="#0000FF"
    wrapperStyle={{}}
    wrapperClass=""
    visible={true}
    ariaLabel='oval-loading'
    secondaryColor="#87CEFA"
    strokeWidth={2}
    strokeWidthSecondary={2}
  />
</div>

    )}

    {!loading && (

        <table className="table">
          <thead style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.07)' }}>
            <tr>
              <th >
                <input
                  className="checkbox"
                  type="checkbox"
                  checked={selectAll}
                  onChange={toggleSelectAll}
                  style={{
                    alignItems: 'center',
                    display: 'flex',
                  }}
                />
              </th>
              <th style={{ color: 'black', fontFamily: "Noto Sans Mono", weight: '500', fontSize: '12px', textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)'}}>ID</th>
              <th style={{ color: 'black', fontFamily: "Noto Sans Mono", weight: '500', fontSize: '12px', textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)'}}>Gender</th>
              <th style={{ color: 'black', fontFamily: "Noto Sans Mono", weight: '500', fontSize: '12px', textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)'}}>Country</th>
              <th style={{ color: 'black', fontFamily: "Noto Sans Mono", weight: '500', fontSize: '12px', textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)'}}>Time register</th>
              <th style={{ color: 'black', fontFamily: "Noto Sans Mono", weight: '500', fontSize: '12px', textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)'}}>Last active</th>
              <th style={{ color: 'black', fontFamily: "Noto Sans Mono", weight: '500', fontSize: '12px', textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)'}}>Balance</th>
              <th style={{ color: 'black', fontFamily: "Noto Sans Mono", weight: '500', fontSize: '12px', textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)'}}>Singup - To connetion</th>
              <th style={{ color: 'black', fontFamily: "Noto Sans Mono", weight: '500', fontSize: '12px', textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)'}}>Time to connection</th>
              <th style={{ color: 'black', fontFamily: "Noto Sans Mono", weight: '500', fontSize: '12px', textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)'}}>Premium</th>
              <th style={{ color: 'black', fontFamily: "Noto Sans Mono", weight: '500', fontSize: '12px', textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)'}}>Fan subscribe</th>
              <th style={{ color: 'black', fontFamily: "Noto Sans Mono", weight: '500', fontSize: '12px', textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)'}}>Paying</th>
              <th style={{ color: 'black', fontFamily: "Noto Sans Mono", weight: '500', fontSize: '12px', textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)'}}>Last search</th>
            </tr>
          </thead>
          <tbody>
          {filteredData.map((item, index) => (
              <tr
              key={item.id}
              className={`${index % 2 === 0 ? 'even-row' : 'odd-row'} ${item.selected ? 'selected-row' : 'non-selected-row'}`}
            >
                <td style={{
    borderRight: '1px solid rgba(0, 0, 0, 0.07)'
    
  }}>
                  <input
                    type="checkbox"
                    checked={item.selected}
                    onChange={() => toggleSelectItem(item.id)}
                    
                  />
                </td>

                <td style={{
    color: item.selected ? '#6D33FF' : '#606C80',
    fontFamily: 'Noto Sans Mono',
    fontWeight: '400',
    fontSize: '12px',
    textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)'
    
  }}>
    
    <a style={{
                  color: item.selected ? '#6D33FF' : '#606C80',
                  fontFamily: 'Noto Sans Mono',
                  fontWeight: '400',
                  fontSize: '12px',
                  textAlign: 'right',
                }} href={`user_model/${item.id}`}>{item.id}</a>
                </td>
                <td style={{
    color: item.selected ? '#6D33FF' : '#606C80',
    fontFamily: 'Noto Sans Mono',
    fontWeight: '400',
    fontSize: '12px',
    textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)'
    
  }}>{item.gender === 1 ? 'Man' : 'Woman'}</td>
                <td style={{
    color: item.selected ? '#6D33FF' : 'black',
    fontFamily: 'Noto Sans Mono',
    fontWeight: '400',
    fontSize: '12px',
    textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)'
    
  }}>{item.countryCode}</td>
                <td style={{
    color: item.selected ? '#6D33FF' : '#606C80',
    fontFamily: 'Noto Sans Mono',
    fontWeight: '400',
    fontSize: '12px',
    textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)'
  }}>{formatTimestampReg(item.timeRegister)}</td>
                <td style={{
    color: item.selected ? '#6D33FF' : '#606C80',
    fontFamily: 'Noto Sans Mono',
    fontWeight: '400',
    fontSize: '12px',
    textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)'
  }}>{formatDate(item.activity)}</td>
                <td style={{
    color: item.selected ? '#6D33FF' : '#606C80',
    fontFamily: 'Noto Sans Mono',
    fontWeight: '400',
    fontSize: '12px',
    textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)'
  }}>{item.balance}</td>
                <td style={{
    color: item.selected ? '#6D33FF' : '#606C80',
    fontFamily: 'Noto Sans Mono',
    fontWeight: '400',
    fontSize: '12px',
    textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)'
    
  }}>{item.following_count}</td>
                <td style={{
    color: item.selected ? '#6D33FF' : '#606C80',
    fontFamily: 'Noto Sans Mono',
    fontWeight: '400',
    fontSize: '12px',
    textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)'
  }}>{item.followers_count}</td>
                <td style={{
    color: item.selected ? '#6D33FF' : '#606C80',
    fontFamily: 'Noto Sans Mono',
    fontWeight: '400',
    fontSize: '12px',
    textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)',
    background:
      item.premium === 0
        ? 'rgba(255, 224, 224, 0.5)'
        : item.premium === 1
        ? 'rgba(216, 255, 212, 0.5)'
        : 'none',
  }}>{item.premium === 0 ? 'No' : 'Yes'}</td>
                <td style={{
    color: item.selected ? '#6D33FF' : '#606C80',
    fontFamily: 'Noto Sans Mono',
    fontWeight: '400',
    fontSize: '12px',
    textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)'
  }}>{item.fan_subscribed}</td>
                <td style={{
    color: item.selected ? '#6D33FF' : '#606C80',
    fontFamily: 'Noto Sans Mono',
    fontWeight: '400',
    fontSize: '12px',
    textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)',
    background:
      item.paying === 0
        ? 'rgba(255, 224, 224, 0.5)'
        : item.paying === 1
        ? 'rgba(216, 255, 212, 0.5)'
        : 'none',
  }}>{item.paying === 0 ? 'No' : 'Yes'}</td>
                <td style={{
    color: item.selected ? '#6D33FF' : '#606C80',
    fontFamily: 'Noto Sans Mono',
    fontWeight: '400',
    fontSize: '12px',
    textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)'}}>{formatDateTimeLS(item.last_search)}</td>


              </tr>
            ))}
          </tbody>

<tfoot>
  
</tfoot>

        </table>
)}




        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
          <button className="remove-button" style={{ backgroundColor: '#6D33FF'}} onClick={handlePreviousPage}>&lt; Previous</button>
          <button className="remove-button" style={{ backgroundColor: '#FFF', width: '40px', color: 'black'}}>{currentPage}</button>
          <button className="remove-button"  style={{ backgroundColor: '#6D33FF'}} onClick={handleNextPage}>Next &gt;</button>
        </div>

        
      </div>
    </div>
  );
};

export default TableGeneral;
