import React, { useState, useEffect, useCallback } from 'react';
import Modal from 'react-modal';
import './style.css';
import { useHistory } from 'react-router-dom';
import { Oval } from 'react-loader-spinner';
import crossoff from './crossoff.svg'

const TableGeneral = () => {
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [searchGender, setSearchGender] = useState('');
  const [searchPremium, setSearchPremium] = useState('');

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');

  // Функция для открытия модального окна с видео
  const openModal = (url) => {
    setVideoUrl(url);
    setModalIsOpen(true);
  };

  // Функция для закрытия модального окна
  const closeModal = () => {
    setModalIsOpen(false);
    setVideoUrl('');
  };

  const handleGenderSelect = (event) => {
    setSearchGender(event.target.value);
  };

  const handlePremiumSelect = (event) => {
    setSearchPremium(event.target.value);
  };

  useEffect(() => {
    fetchData();
  }, [currentPage]);

  useEffect(() => {
    const filteredByGender = searchGender
      ? data.filter((item) => item.gender === (searchGender === 'man' ? 1 : 2))
      : data;

    const filteredByPremium = searchPremium
      ? data.filter((item) => item.premium === (searchPremium === 'premium' ? 1 : 0))
      : data;

    setFilteredData(filteredByGender.filter((item) => filteredByPremium.includes(item)));
  }, [data, searchGender, searchPremium]);

  const fetchData = () => {
    setLoading(true);
    const apiUrl = 'https://golive.mobi/api/stf/vrf/promo/';
    const requestOptions = {
      method: 'POST',
      headers: {
        Authorization: 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ page: currentPage }),
    };

    fetch(apiUrl, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.list && Array.isArray(data.list)) {
          const mappedData = data.list.map((item) => ({
            id: item.id,
            cover: item.cover,
            url: item.url,


          }));
          setLoading(mappedData.length === 0);
          setData(mappedData);
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      })
      .finally(() => {
        setLoading(false); // Устанавливаем флаг загрузки в false после завершения запроса
      });
  };

  const handleClick = () => {
    history.push('/modelcreate');
  };

  const [showForm, setShowForm] = useState(false);

  const toggleForm = () => {
    setShowForm(!showForm);
  };

  const initialData = [];

  const toggleSelectAll = () => {
    setSelectAll(!selectAll);
    const updatedData = data.map((item) => ({ ...item, selected: !selectAll }));
    setData(updatedData);
  };

  const toggleSelectItem = (id) => {
    const updatedData = data.map((item) => {
      if (item.id === id) {
        return { ...item, selected: !item.selected };
      }
      return item;
    });
    setData(updatedData);
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleModelClick = useCallback(
    (id) => {
      localStorage.setItem('id_models', id);
      history.push(`/user_model/${id}`);
    },
    [history]
  );

  function formatDate(timestamp) {
    if (!timestamp) {
      return '';
    }

    const dateObj = new Date(timestamp * 1000);

    if (isNaN(dateObj)) {
      return 'Invalid timestamp';
    }

    const year = dateObj.getFullYear();
    const month = ('0' + (dateObj.getMonth() + 1)).slice(-2);
    const day = ('0' + dateObj.getDate()).slice(-2);
    const hours = ('0' + dateObj.getHours()).slice(-2);
    const minutes = ('0' + dateObj.getMinutes()).slice(-2);

    return `${day}.${month}.${year} ${hours}:${minutes}`;
  }

  function formatTimestampReg(timestamp) {
    if (!timestamp) {
      return '';
    }

    const dateObj = new Date(Number(timestamp));

    if (isNaN(dateObj)) {
      return 'Invalid timestamp';
    }

    const year = dateObj.getFullYear();
    const month = ('0' + (dateObj.getMonth() + 1)).slice(-2);
    const day = ('0' + dateObj.getDate()).slice(-2);
    const hours = ('0' + dateObj.getHours()).slice(-2);
    const minutes = ('0' + dateObj.getMinutes()).slice(-2);

    return `${day}.${month}.${year} ${hours}:${minutes}`;
  }

  const customStyles = {
    content: {
        position: 'absolute',
        inset: '40px',
        border: '1px solid rgb(204, 204, 204)',
        background: 'rgb(255, 255, 255)',
        borderRadius: '4px',
        outline: 'none',
        width: '35%',
        marginLeft: 'auto',
        marginRight: 'auto',
        padding: '0px',
        overflow: 'hidden'
    },
  };

  return (
    <div>



      
      <div>

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h2 style={{marginLeft: '1%', fontFamily: 'inter'}}>Promo</h2>
          
        </div>
        
        {loading && (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
  <Oval
    height={200}
    width={200}
    color="#0000FF"
    wrapperStyle={{}}
    wrapperClass=""
    visible={true}
    ariaLabel='oval-loading'
    secondaryColor="#87CEFA"
    strokeWidth={2}
    strokeWidthSecondary={2}
  />
</div>

    )}

{!loading && (

<div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
<button className="remove-button" style={{ backgroundColor: '#6D33FF'}} onClick={handlePreviousPage}>&lt; Previous</button>
<button className="remove-button" style={{ backgroundColor: '#FFF', width: '40px', color: 'black'}}>{currentPage}</button>
<button className="remove-button"  style={{ backgroundColor: '#6D33FF'}} onClick={handleNextPage}>Next &gt;</button>
</div>

)}

    {!loading && (




        <table className="table">



          <thead style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.07)' }}>
            <tr>
              <th >
                <input
                  className="checkbox"
                  type="checkbox"
                  checked={selectAll}
                  onChange={toggleSelectAll}
                  style={{
                    alignItems: 'center',
                    display: 'flex',
                  }}
                />
              </th>
              <th style={{ color: 'black', fontFamily: "Noto Sans Mono", weight: '500', fontSize: '12px', textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)'}}>ID</th>
              <th style={{ color: 'black', fontFamily: "Noto Sans Mono", weight: '500', fontSize: '12px', textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)'}}>Video</th>
              <th style={{ color: 'black', fontFamily: "Noto Sans Mono", weight: '500', fontSize: '12px', textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)'}}>Action</th>
            </tr>
          </thead>
          <tbody>
          {filteredData.map((item, index) => (
              <tr
              key={item.id}
              className={`${index % 2 === 0 ? 'even-row' : 'odd-row'} ${item.selected ? 'selected-row' : 'non-selected-row'}`}
            >
                <td style={{
    borderRight: '1px solid rgba(0, 0, 0, 0.07)'
    
  }}>
                  <input
                    type="checkbox"
                    checked={item.selected}
                    onChange={() => toggleSelectItem(item.id)}
                    
                  />
                </td>

                <td style={{
    color: item.selected ? '#6D33FF' : '#606C80',
    fontFamily: 'Noto Sans Mono',
    fontWeight: '400',
    fontSize: '12px',
    textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)'
    
  }}>
    
    <a  style={{
                  color: item.selected ? '#6D33FF' : '#606C80',
                  fontFamily: 'Noto Sans Mono',
                  fontWeight: '400',
                  fontSize: '12px',
                  textAlign: 'right',
                }} href={`user_model/${item.id}`}>{item.id}</a>
                </td>
                <td style={{
    color: item.selected ? '#6D33FF' : 'black',
    fontFamily: 'Noto Sans Mono',
    fontWeight: '400',
    fontSize: '12px',
    textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)'
    
  }}><img onClick={() => openModal(item.url)} src={item.cover} style={{ width: '15%', borderRadius: '10px'}} /></td>
                <td style={{
    color: item.selected ? '#6D33FF' : '#606C80',
    fontFamily: 'Noto Sans Mono',
    fontWeight: '400',
    fontSize: '12px',
    textAlign: 'left', borderRight: '1px solid rgba(0, 0, 0, 0.07)'
  }}><button
  style={{
    fontFamily: 'inherit',
    backgroundColor: '#FCD900',
    width: '85px',
    borderRadius: '3px',
    border: '0px',
    height: '45px',
    fontWeight: 700,
    width: '100px'
  }}
  onClick={() => openModal(item.url)}
  >View video</button></td>
                

              </tr>
            ))}
          </tbody>
        </table>
)}


{!loading && (

<div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
<button className="remove-button" style={{ backgroundColor: '#6D33FF'}} onClick={handlePreviousPage}>&lt; Previous</button>
<button className="remove-button" style={{ backgroundColor: '#FFF', width: '40px', color: 'black'}}>{currentPage}</button>
<button className="remove-button"  style={{ backgroundColor: '#6D33FF'}} onClick={handleNextPage}>Next &gt;</button>
</div>

)}

   <br /><br />     
      </div>


      <Modal



        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        ariaHideApp={false}
      >

<img onClick={closeModal} src={crossoff} style={{ position: 'absolute', top: '10px', right: '10px', width: '40px', zIndex: 99999 }} />
<video width="100%" height="100%" controls>
        <source src={videoUrl} type="video/mp4" />
        Ваш браузер не поддерживает видео.
      </video>
        
      </Modal>

    </div>
  );
};

export default TableGeneral;
