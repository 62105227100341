import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import './style.css';

const Login = () => {
  const history = useHistory();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const userRole = localStorage.getItem("role");
  const handleLogin = async (e) => {
    e.preventDefault();
    const response = await fetch('https://admin.golive.mobi/loginback', {
    
    method: 'POST',
    headers: {
      Authorization: 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ username, password }), // Send the first selected username (if it exists)
    });

    if (response.ok) {
      
      const data = await response.json();
      if (data.status === 'online') {
        console.log("tt")
        console.log(data.status)
        console.log("ee")

        localStorage.clear()
        localStorage.setItem('role', data.role); // Assuming the API returns a "token" property in the response
        localStorage.setItem('usertoken', data.usertoken); // Assuming the API returns a "token" property in the response
        localStorage.setItem('username', data.username); // Assuming the API returns a "token" property in the response
        localStorage.setItem('temptoken', data.temptoken); // Assuming the API returns a "token" property in the response
        // Redirect user to the "/models" page and refresh the page
        localStorage.setItem('auth', 'auth')
        

       { userRole === "sector05a" ? (
        window.location.href = "/models-new"
        ) : window.location.href = "/bans" }


      } else {
        // Successful login - perform necessary actions
        setErrorMessage('The user account has been disabled');
        console.log(data.status)
        window.location.href = "/login"
        
      }
    } else {
      setErrorMessage('Incorrect account information.');
    }
  };

  return (
    <form className="login-form" onSubmit={handleLogin}>
      <input
        className="login-input"
        type="text"
        placeholder="Username"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
      />
      <input
        className="login-input"
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <button className="login-button" type="submit">
        Login
      </button>
      {errorMessage && <p className="error-message">{errorMessage}</p>}
    </form>
  );
};

export default Login;

{/*const response = await fetch('https://admin.golive.mobi/loginback', {*/}