import React, { useState } from 'react';
import { Oval } from 'react-loader-spinner';

const Checkban = () => {
  const [name, setName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [models, setModels] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  /*const fetchModels = async (pageNum = 1) => {
    setIsLoading(true);
    try {
      const response = await fetch(`https://golive.mobi/api/user/status/name/?page=${pageNum}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d'
        },
        body: JSON.stringify({ name, "deleted": 1 })
      });
      const data = await response.json();
      setModels(data.list.filter(model => model.chat_only === 1 && model.id.length > 25 && model.model_bot === 0));
      setPage(data.page);
      setTotalPages(data.total_pages);
    } catch (error) {
      console.error("Failed to fetch models:", error);
    }
    setIsLoading(false);
  };*/

  const fetchModels = async (pageNum = 1) => {
    setIsLoading(true);
    try {
      const response = await fetch(`https://golive.mobi/api/user/status/name/?page=${pageNum}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d'
        },
        body: JSON.stringify({ name, "deleted": 0 })
      });
      const data = await response.json();
      const filteredModels = data.list.filter(model => model.id.length > 1 && model.model_bot === 0);
      await fetchBanStatus(filteredModels);
      setPage(data.page);
      setTotalPages(data.total_pages);
    } catch (error) {
      console.error("Failed to fetch models:", error);
    }
    setIsLoading(false);
  };

  const fetchBanStatus = async (models) => {
    try {
      const bansResponse = await fetch(`https://apilive.golive.mobi/bans`);
      const bansData = await bansResponse.json();
      const updatedModels = models.map(model => {
        if (model.block_status === '') {
          const banRecord = bansData.find(ban => ban.user_1 === model.id);
          model.block_status = banRecord ? 'Nudity' : 'Out of frame';
        }
        return model;
      });
      setModels(updatedModels);
    } catch (error) {
      console.error("Failed to fetch ban status:", error);
      // В случае ошибки, просто используем модели без обновления статуса
      setModels(models);
    }
  };

  const unblockModel = async (id, modelName) => {
    const userToken = localStorage.getItem("usertoken"); // Получаем токен пользователя из localStorage
    setIsLoading(true);
    try {
      await fetch('https://golive.mobi/api/user/m-undelete/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d'
        },
        body: JSON.stringify([{ id, signed_up: 1 }])
      });
      // Отправляем событие разблокировки на сервер FastAPI
      await fetch('https://admin.golive.mobi/unblock_check/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userToken,
          modelName,
          eventType: "unblock_moderator"
        })
      });
      alert('Пользователь успешно разблокирован.');
      fetchModels(page); // Refresh the list of models
    } catch (error) {
      console.error("Failed to unblock model:", error);
    }
    setIsLoading(false);
  };
  

  if (isLoading) {
    return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: 'white' }}>
      <Oval color="#4CAF50" height={80} width={80} />
    </div>;
  }

  return (
    <div>
      <div style={{ padding: '20px' }}>
        <label htmlFor="modelName">Enter the name of the user:</label>
        <input
          id="modelName"
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          style={{ margin: '0 10px' }}
        />
        <button onClick={() => fetchModels()} style={{ padding: '5px 15px', backgroundColor: '#4CAF50', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer' }}>Search</button>
      </div>
      <div>
        {models.length > 0 ? models.map((model) => (
          <div key={model.id} style={{ border: '1px solid #ccc', padding: '10px', margin: '10px 0', display: 'flex', alignItems: 'center' }}>
            <img src={`https://golive.mobi${model.avatar}`} alt="Model" style={{ width: '100px', height: '150px', marginRight: '20px' }} />
            <div>
              <p>Name: {model.name}</p>
              <p>Age: {model.age}</p>
              <p>Country Code: {model.countryCode}</p>
              

            </div>
            <div style={{ marginLeft: 'auto', textAlign: 'right' }}>
            <button onClick={() => unblockModel(model.id, model.name)} style={{ padding: '5px 15px', backgroundColor: '#4CAF50', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer', marginBottom: '5px' }}>
            Unblock
      </button>
              <br />
              <button onClick={() => window.open(`https://admin.golive.mobi/profile_model/${model.id}`, '_blank')} style={{ padding: '5px 15px', backgroundColor: '#007bff', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer' }}>View Profile</button>
            </div>
          </div>
        )) : <p>No users found.</p>}
      </div>
      {totalPages > 1 && (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
          {Array.from({ length: totalPages }, (_, i) => i + 1).map(number => (
            <button key={number} onClick={() => fetchModels(number)} style={{ margin: '0 5px', padding: '5px 10px', backgroundColor: number === page ? '#007bff' : '#4CAF50', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer' }}>
              {number}
            </button>
          ))}
        </div>
      )}
    </div>
  );
  
  
};

export default Checkban;
