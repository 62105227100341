import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Oval } from 'react-loader-spinner';

const Models = () => {
  const [activeTab, setActiveTab] = useState('usersconnect');
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [error, setError] = useState('');
  const [autoRefresh, setAutoRefresh] = useState(true);
  const logContainerRef = useRef(null);

  useEffect(() => {
    if (activeTab !== 'logs') {
      fetchData();
    }
  }, [activeTab, searchQuery]);

  useEffect(() => {
    if (activeTab === 'logs') {
      const interval = setInterval(() => {
        if (autoRefresh) {
          fetchLogs();
        }
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [activeTab, autoRefresh]);

  const fetchData = async () => {
    setLoading(true);
    setError('');
    let url = `https://sckt.golive.mobi/pushdata/${activeTab}`;
    if (searchQuery) {
      url += `?user_id=${searchQuery}`;
    }

    try {
      const response = await axios.get(url);
      if (response.data.length === 0) {
        setError('No data found.');
      } else {
        setData(response.data);
      }
    } catch (error) {
      setError('An error occurred while fetching data.');
    } finally {
      setLoading(false);
    }
  };

  const fetchLogs = async () => {
    setError('');
    let url = `https://sckt.golive.mobi/pushdata/logs`;
    try {
      const response = await axios.get(url);
      if (response.data.length === 0) {
        setError('No logs found.');
      } else {
        setData(response.data);
        if (logContainerRef.current) {
          logContainerRef.current.scrollTop = logContainerRef.current.scrollHeight;
        }
      }
    } catch (error) {
      setError('An error occurred while fetching logs.');
    }
  };

  const formatObjectForDisplay = (object) => {
    if (!object) return <span>null or undefined</span>;

    return (
      <ul>
        {Object.entries(object).map(([key, value], i) => (
          <li key={i}>{`${key}: ${value !== null && value !== undefined ? value.toString() : 'null or undefined'}`}</li>
        ))}
      </ul>
    );
  };

  const renderTableContent = () => {
    if (loading) {
      return (
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <Oval height={40} width={40} color="blue" />
        </div>
      );
    }

    if (error) {
      return <p style={{ textAlign: 'center', color: 'red' }}>{error}</p>;
    }

    if (data.length === 0) {
      return <p style={{ textAlign: 'center' }}>No data found.</p>;
    }

    return (
      <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '20px' }}>
        <thead>
          <tr>
            {Object.keys(data[0]).map((key) => (
              <th key={key} style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>
                {key}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              {Object.entries(item).map(([key, value], i) => (
                <td key={i} style={{ border: '1px solid #ddd', padding: '8px' }}>
                  {typeof value === 'object' ? formatObjectForDisplay(value) : value !== null && value !== undefined ? value.toString() : 'null or undefined'}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const renderLogsContent = () => {
    if (loading) {
      return (
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <Oval height={40} width={40} color="blue" />
        </div>
      );
    }

    if (error) {
      return <p style={{ textAlign: 'center', color: 'red' }}>{error}</p>;
    }

    if (data.length === 0) {
      return <p style={{ textAlign: 'center' }}>No logs found.</p>;
    }

    return (
      <div ref={logContainerRef} style={{ height: '80vh', overflowY: 'scroll', border: '1px solid #ddd', padding: '8px' }}>
        {data.map((log, index) => (
          <div key={index} style={{ marginBottom: '10px' }}>
            <strong>{new Date(log.timestamp).toLocaleString()}</strong>: {log.message}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div style={{ width: '100%', padding: '0' }}>
      <div style={{ textAlign: 'center', marginBottom: '20px' }}>
        <button onClick={() => setActiveTab('usersconnect')} style={{ margin: '0 10px', padding: '10px 20px', fontSize: '16px', cursor: 'pointer' }}>Users Connect</button>
        <button onClick={() => setActiveTab('pushes')} style={{ margin: '0 10px', padding: '10px 20px', fontSize: '16px', cursor: 'pointer' }}>Pushes</button>
        <button onClick={() => setActiveTab('logs')} style={{ margin: '0 10px', padding: '10px 20px', fontSize: '16px', cursor: 'pointer' }}>Detail Logs</button>
        <input type="text" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} placeholder="Search by user ID" style={{ margin: '0 10px', padding: '10px 20px', fontSize: '16px', width: '200px' }} />
        <button onClick={() => fetchData()} style={{ padding: '10px 20px', fontSize: '16px', cursor: 'pointer' }}>Search</button>
        {activeTab === 'logs' && (
          <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <label>Auto-refresh: <input type="checkbox" checked={autoRefresh} onChange={() => setAutoRefresh(!autoRefresh)} style={{ marginLeft: '10px' }} /></label>
          </div>
        )}
      </div>
      {activeTab === 'logs' ? renderLogsContent() : renderTableContent()}
    </div>
  );
};

export default Models;
