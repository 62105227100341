import React, { useState, useEffect, useRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { BarChart, Bar, CartesianGrid, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, Brush, LineChart, Line } from 'recharts';
import { Oval } from 'react-loader-spinner';

const Models = () => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [data, setData] = useState(null);
  const [dialogData, setDialogData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [dialogLoading, setDialogLoading] = useState(false);
  const [error, setError] = useState(null);
  const [dialogError, setDialogError] = useState(null);
  const abortControllerRef = useRef(null);

  useEffect(() => {
    if (selectedDate) {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }

      abortControllerRef.current = new AbortController();

      const fetchData = async () => {
        setLoading(true);
        setDialogLoading(true);
        setError(null);
        setDialogError(null);

        const year = selectedDate.getFullYear();
        const month = selectedDate.getMonth() + 1;
        const day = selectedDate.getDate();
        const hour = selectedDate.getHours();
        const minute = selectedDate.getMinutes();
        const minuteStart = Math.floor(minute / 15) * 15;
        const minuteEnd = Math.min(minuteStart + 15, 59);

        try {
          const [statusResponse, dialogResponse] = await Promise.all([
            fetch('https://golive.mobi/api/status_changes/', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ year, month, day, hour, minute_start: minuteStart, minute_end: minuteEnd }),
              signal: abortControllerRef.current.signal
            }),
            fetch('https://golive.mobi/api/dialog_count/', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ year, month, day, hour, minute_start: minuteStart, minute_end: minuteEnd }),
              signal: abortControllerRef.current.signal
            })
          ]);

          if (statusResponse.status === 200) {
            const statusResult = await statusResponse.json();
            setData(statusResult);
          } else {
            throw new Error(`Status Error: ${statusResponse.status}`);
          }

          if (dialogResponse.status === 200) {
            const dialogResult = await dialogResponse.json();
            setDialogData(dialogResult);
          } else {
            throw new Error(`Dialog Error: ${dialogResponse.status}`);
          }
        } catch (error) {
          if (error.name !== 'AbortError') {
            setError(error.message);
            setDialogError(error.message);
          }
        } finally {
          if (!abortControllerRef.current.signal.aborted) {
            setLoading(false);
            setDialogLoading(false);
          }
        }
      };

      fetchData();

      return () => {
        abortControllerRef.current.abort();
      };
    }
  }, [selectedDate]);

  const parseData = (data) => {
    if (!data) return [];

    return Object.keys(data).map((timestamp) => {
      const dateObj = new Date(timestamp);
      const time = dateObj.toTimeString().split(' ')[0];
      return {
        name: time,
        'User Search': data[timestamp].search.user,
        'Model Search': data[timestamp].search.model,
        'User Busy': data[timestamp].busy.user,
        'Model Busy': data[timestamp].busy.model,
      };
    });
  };

  const parseDialogData = (data) => {
    if (!data) return [];

    return Object.keys(data).map((timestamp) => {
      const dateObj = new Date(timestamp);
      const time = dateObj.toTimeString().split(' ')[0];
      return {
        name: time,
        'Dialog Count': data[timestamp].dialog,
      };
    });
  };

  const initialBrushPosition = (data) => {
    if (!data || data.length === 0) return { startIndex: 0, endIndex: 0 };

    const endIndex = Math.floor(data.length * 0.05);
    return { startIndex: 0, endIndex: endIndex };
  };

  const parsedData = parseData(data);
  const parsedDialogData = parseDialogData(dialogData);
  const brushPosition = initialBrushPosition(parsedData);

  const getMaxValue = (data) => {
    if (!data || data.length === 0) return 0;

    return Math.max(
      ...data.map((entry) => Math.max(entry['User Search'], entry['Model Search'], entry['User Busy'], entry['Model Busy']))
    );
  };

  const getMaxDialogValue = (data) => {
    if (!data || data.length === 0) return 0;

    return Math.max(...data.map((entry) => entry['Dialog Count']));
  };

  const maxValue = getMaxValue(parsedData);
  const maxDialogValue = getMaxDialogValue(parsedDialogData);

  return (
    <div style={{ width: '100%', height: '100vh' }}>
      <h1>Statistic search and busy</h1>
      <DatePicker
        selected={selectedDate}
        onChange={(date) => setSelectedDate(date)}
        showTimeSelect
        timeFormat="HH:mm"
        timeIntervals={15}
        dateFormat="Pp"
        placeholderText="Select a date and time"
      />

      {!selectedDate && <p>Please select a date and time.</p>}

      {(loading || dialogLoading) && <Oval color="#00BFFF" height={80} width={80} />}

      {error && <p>{error}</p>}
      {dialogError && <p>{dialogError}</p>}

      {data && !loading && !error && (
        <ResponsiveContainer width="100%" height={400}>
          <BarChart data={parsedData} margin={{ top: 20 }}>
            <CartesianGrid stroke="#ccc" />
            <XAxis dataKey="name" />
            <YAxis domain={[0, maxValue + 2]} />
            <Tooltip />
            <Legend />
            <Bar stackId="a" dataKey="User Search" fill="#6a8fdb" />
            <Bar stackId="a" dataKey="Model Search" fill="#4c6fb3" />
            <Bar stackId="b" dataKey="User Busy" fill="#ffcc80" />
            <Bar stackId="b" dataKey="Model Busy" fill="#ff8c00" />
            <Line type="monotone" dataKey="Dialog Count" data={parsedDialogData} stroke="#8884d8" strokeWidth={3} />
            <Brush dataKey="name" height={30} stroke="#8884d8" startIndex={brushPosition.startIndex} endIndex={brushPosition.endIndex} />
          </BarChart>
        </ResponsiveContainer>
      )}

      <h1>Statistic dialog</h1>

      {dialogData && !dialogLoading && !dialogError && (
        <ResponsiveContainer width="100%" height={400}>
          <LineChart data={parsedDialogData} margin={{ top: 20 }}>
            <CartesianGrid stroke="#ccc" />
            <XAxis dataKey="name" />
            <YAxis domain={[0, maxDialogValue + 2]} />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="Dialog Count" stroke="#8884d8" strokeWidth={3} />
            <Brush dataKey="name" height={30} stroke="#8884d8" startIndex={brushPosition.startIndex} endIndex={brushPosition.endIndex} />
          </LineChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};

export default Models;
