import React, { useState } from 'react';
import TableActivity from './TableActivity_models';
import TableGeneral from './TableGeneral_models';
import TableGeneralReg from './TableGeneral_reg_models';
import TableGeneralNoReg from './TableGeneral_noreg_models';
import TableGeneralSUS from './TableGeneral_SUS_models';
import TableGeneralBot from './TableGeneral_Bot';
import TableGeneralOnline from './TableGeneral_Online';
import './style.css';

const ProfileInfo = () => {
  const [showProfileInfo, setShowProfileInfo] = useState(false);
  const [showUserActivity, setShowUserActivity] = useState(true);
  const [showUserRegActivity, setShowUserRegActivity] = useState(false);
  const [showUserNoRegActivity, setShowUserNoRegActivity] = useState(false);
  const [showUserSUSActivity, setShowUserSUSActivity] = useState(false);
  const [showBotActivity, setShowBotActivity] = useState(false);
  const [showOnlineActivity, setShowOnlineActivity] = useState(false);

  const toggleProfileInfo = () => {
    setShowProfileInfo(!showProfileInfo);
    setShowUserActivity(false);
    setShowUserRegActivity(false);
    setShowUserNoRegActivity(false);
    setShowUserSUSActivity(false);
    setShowBotActivity(false);
    setShowOnlineActivity(false);
  };

  const toggleUserActivity = () => {
    setShowUserActivity(!showUserActivity);
    setShowProfileInfo(false);
    setShowUserRegActivity(false);
    setShowUserNoRegActivity(false);
    setShowUserSUSActivity(false);
    setShowBotActivity(false);
    setShowOnlineActivity(false);
  };

  const toggleUserRegActivity = () => {
    setShowUserRegActivity(!showUserRegActivity);
    setShowProfileInfo(false);
    setShowUserActivity(false);
    setShowUserNoRegActivity(false);
    setShowUserSUSActivity(false);
    setShowBotActivity(false);
    setShowOnlineActivity(false);
  };

  const toggleUserNoRegActivity = () => {
    setShowUserNoRegActivity(!showUserNoRegActivity);
    setShowProfileInfo(false);
    setShowUserActivity(false);
    setShowUserRegActivity(false);
    setShowUserSUSActivity(false);
    setShowBotActivity(false);
    setShowOnlineActivity(false);
  };

  const toggleUserSUSActivity = () => {
    setShowUserSUSActivity(!showUserSUSActivity);
    setShowUserNoRegActivity(false);
    setShowProfileInfo(false);
    setShowUserActivity(false);
    setShowUserRegActivity(false);
    setShowBotActivity(false);
    setShowOnlineActivity(false);
  };

  const toggleBotActivity = () => {
    setShowBotActivity(!showBotActivity);
    setShowUserNoRegActivity(false);
    setShowProfileInfo(false);
    setShowUserActivity(false);
    setShowUserRegActivity(false);
    setShowUserSUSActivity(false);
    setShowOnlineActivity(false);
  };

  const toggleOnlineActivity = () => {
    setShowOnlineActivity(!showOnlineActivity);
    setShowUserNoRegActivity(false);
    setShowProfileInfo(false);
    setShowUserActivity(false);
    setShowUserRegActivity(false);
    setShowUserSUSActivity(false);
    setShowBotActivity(false);
  };




  return (
    <div>
            <button
        className={`button ${showUserActivity ? 'active-button' : 'inactive-button'}`}
        onClick={toggleUserActivity}
      >
        Activity profiles
      </button>
      <button
        className={`button ${showProfileInfo ? 'active-button' : 'inactive-button'}`}
        onClick={toggleProfileInfo}
      >
        All profiles
      </button>
      <button
        className={`button ${showUserNoRegActivity ? 'active-button' : 'inactive-button'}`}
        onClick={toggleUserNoRegActivity}
      >
        Unregistered Models
      </button>
      <button
        className={`button ${showUserRegActivity ? 'active-button' : 'inactive-button'}`}
        onClick={toggleUserRegActivity}
      >
        Registered models
      </button>
      <button
        className={`button ${showUserSUSActivity ? 'active-button' : 'inactive-button'}`}
        onClick={toggleUserSUSActivity}
      >
        Suspended profiles
      </button>

      <button
        className={`button ${showBotActivity ? 'active-button' : 'inactive-button'}`}
        onClick={toggleBotActivity}
      >
        Bot profiles
      </button>

      <button
        className={`button ${showOnlineActivity ? 'active-button' : 'inactive-button'}`}
        onClick={toggleOnlineActivity}
      >
        Profiles online
      </button>
      <hr style={{ border: "1px solid rgba(0, 0, 0, 0.05)", marginTop: '2%', marginBottom: '2%' }} />

      {showProfileInfo && (
        <div>
          <TableGeneral />
        </div>
      )}
      {showUserActivity && (
        <div>
          <TableActivity />
        </div>
      )}
      {showUserRegActivity && (
        <div>
          <TableGeneralReg />
        </div>
      )}
      {showUserNoRegActivity && (
        <div>
          <TableGeneralNoReg />
        </div>
      )}
      {showUserSUSActivity && (
        <div>
          <TableGeneralSUS />
        </div>
      )}
      {showBotActivity && (
        <div>
          <TableGeneralBot />
        </div>
      )}
            {showOnlineActivity && (
        <div>
          <TableGeneralOnline />
        </div>
      )}
    </div>
  );
};

export default ProfileInfo;
