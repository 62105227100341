import React, { useState, useEffect } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Oval } from 'react-loader-spinner';

const BarChartComponent = () => {
  const [data, setData] = useState([]);
  const [date, setDate] = useState(new Date());
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData(date);
  }, [date]);

  const fetchData = async (selectedDate) => {
    const formattedDate = `${selectedDate.getDate()}.${selectedDate.getMonth() + 1}.${selectedDate.getFullYear()}`;
    setLoading(true);
    try {
      const result = await axios.get(`https://admin.golive.mobi/streams/streams/?collection=${formattedDate}`);
      const fullData = fillDataWithZeros(result.data.interval_counts);
      setData(fullData);
    } catch (error) {
      console.error('Error fetching data: ', error);
      setData([]);
    }
    setLoading(false);
  };

  // Функция для заполнения данных с нулевыми интервалами
  function fillDataWithZeros(data) {
    if (data.length === 0) return [];

    // Отсортировать по времени
    const sortedData = data.sort((a, b) => a.time.localeCompare(b.time));
    const filledData = [];
    let currentTime = sortedData[0].time;

    sortedData.forEach((item, index) => {
      while (currentTime !== item.time) {
        filledData.push({ time: currentTime, count: 0 });
        currentTime = incrementTimeByFiveMinutes(currentTime);
      }
      filledData.push(item);
      currentTime = incrementTimeByFiveMinutes(currentTime);
    });

    // Включить последний интервал если он не был включен
    if (filledData[filledData.length - 1].time !== sortedData[sortedData.length - 1].time) {
      filledData.push({ time: sortedData[sortedData.length - 1].time, count: 0 });
    }

    return filledData;
  }

  // Функция для увеличения времени на пять минут
  function incrementTimeByFiveMinutes(time) {
    const [hours, minutes] = time.split(':').map(Number);
    const date = new Date(0, 0, 0, hours, minutes, 0);
    date.setMinutes(date.getMinutes() + 5);
    return date.toTimeString().slice(0, 5);
  }

  return (
    <div style={{ padding: '20px' }}>

    <p>Statistics of all streams</p>

  <div style={{ marginBottom: '20px', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
    <span>Select a date:</span>
    <DatePicker
      selected={date}
      onChange={(date) => setDate(date)}
      dateFormat="d.M.yyyy"
      className="date-picker"
      placeholderText="Select a date"
      style={{ marginLeft: '10px' }}
    />
  </div>
  <div>Selected date: {date.toLocaleDateString()}</div> {/* Возврат вывода выбранной даты */}
  <ResponsiveContainer width="100%" height={500}>
    {loading ? (
      <Oval color="#8884d8" height={80} width={80} />
    ) : (
      <BarChart
        data={data}
        margin={{ top: 15, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="time" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="count" fill="#8884d8" name="Number of Active Streams" />
      </BarChart>
    )}
  </ResponsiveContainer>
</div>

  );
};

export default BarChartComponent;
