import React, { useState, useEffect } from "react";
import axios from "axios";

const ErrorBlock = () => {
  const [showError, setShowError] = useState(false);
  const [errorNumber, setErrorNumber] = useState(null);
  const [errorDescription, setErrorDescription] = useState(null);

  const errorDescriptions = {
    400: "Bad Request: The server cannot understand the request.",
    401: "Unauthorized: You need to authenticate to access this resource.",
    404: "Not Found: The requested resource could not be found on the server.",
    500: "Internal Server Error: The server encountered a problem and could not process the request.",
    // Add more error descriptions as needed
  };

  const fetchData = async () => {
    try {
      const response = await axios.post(
        "https://golive.mobi/api/user/m-list/",
        {
          // Add any data you want to send with the POST request here
        },
        {
          headers: {
            Authorization: "Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d",
          },
        }
      );

      if (response.status !== 200) {
        setShowError(true);
      } else {
        setShowError(false);
      }
    } catch (error) {
      setShowError(true);
      const status = error?.response?.status;
      setErrorNumber(status || "Unknown");
      setErrorDescription(errorDescriptions[status] || "Unknown Error");
    }
  };

  // Fetch data on component mount
  useEffect(() => {
    fetchData();
  }, []);

  const redBlockStyle = {
    width: "500px",
    height: "100px",
    backgroundColor: showError ? "red" : "transparent",
    color: showError ? "white" : "transparent",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "16px",
    margin: "20px auto",
    zIndex: 99,
    position: "absolute",
    borderRadius: "10px",
    marginLeft: "1%",
  };

  const errorNumberStyle = {
    fontSize: "16px",
    
  };

  return showError ? (
    <div style={redBlockStyle}><p>
      Error!<br /> Error receiving data from backend</p>
    </div>
  ) : null;
};

export default ErrorBlock;
