import React, { useState, useEffect } from "react";
import { StyleSheet, css } from "aphrodite/no-important";
import Table from "./TableActivity_models";
import ModelButton from './ModelButton';

const styles = StyleSheet.create({
    h1: {
        fontSize: "7.5em",
        margin: "15px 0px",
        fontWeight: "bold",
    },
    h2: {
        fontWeight: "bold",
    },
});

const Models = () => {
    const [onlineCount, setOnlineCount] = useState(0);
    const [backgroundCount, setBackgroundCount] = useState(0);
    const [turboCount, setTurboCount] = useState(0);
    const [busyCount, setBusyCount] = useState(0);
    const [offlineCount, setOfflineCount] = useState(0);
    const [snoozeCount, setSnoozeCount] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch("https://golive.mobi/api/web/m-count/", {
                    method: "POST",
                    headers: {
                        Authorization: "Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d",
                        'Content-Type': 'application/json; charset=UTF-8',
                    },
                    body: JSON.stringify({
                        page: 1,
                        page_size: 200
                    }),
                });
                const data = await response.json();

                setOnlineCount(data.online);
                setBackgroundCount(data.online_background);
                setBusyCount(data.busy);
                setOfflineCount(data.offline);
                setTurboCount(data.offline);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        const fetchDataInterval = setInterval(fetchData, 10000); // Fetch data every 15 seconds
        fetchData(); // Initial fetch when the component mounts

        return () => {
            clearInterval(fetchDataInterval); // Cleanup interval on component unmount
        };
    }, []);

    return (
        <div className="col-md-6 align-self-center">
            <h2 style={{ fontFamily: 'inter', marginLeft: '1%' }}>Models (legacy)</h2>
            <hr style={{ border: "1px solid rgba(0, 0, 0, 0.05)", marginTop: '1%', marginBottom: '1%' }} />
            <p style={{ marginLeft: '1%' }}>
                <span style={{ fontFamily: 'inter' }}>Model counter:</span>
                <span style={{ fontFamily: 'inter', marginLeft: '1%' }}>Online:</span> {onlineCount}
                <span style={{ fontFamily: 'inter', marginLeft: '1%' }}>Turbo:</span> {turboCount}
                <span style={{ fontFamily: 'inter', marginLeft: '1%' }}>Background:</span> {backgroundCount}
                <span style={{ fontFamily: 'inter', marginLeft: '1%' }}>Busy:</span> {busyCount}
                <span style={{ fontFamily: 'inter', marginLeft: '1%' }}>Offline:</span> {offlineCount}
            </p>
            <hr style={{ border: "1px solid rgba(0, 0, 0, 0.05)", marginTop: '1%', marginBottom: '1%' }} />
            <ModelButton />
        </div>
    );
};

export default Models;
