import React, { useEffect } from 'react';
import { StyleSheet, css } from "aphrodite/no-important";
import Table from "./TableActivity_models"
import ModelButton from './ModelButton'



const styles = StyleSheet.create({
	h1: {
		fontSize: "7.5em",
		margin: "15px 0px",
		fontWeight: "bold",
	},
	h2: {
		fontWeight: "bold",
	},
});
const Models = () => {
	return (
		<div class="col-md-6 align-self-center">
			
<ModelButton />

		</div>
	);
};

export default Models;
