import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import no_avatar from '../assets/no_avatar.png';
import './style.css';

const UserProfile = () => {

  const { id } = useParams(); // Получаем параметр id из URL

  useEffect(() => {
    if (id) {
      localStorage.setItem('id_models', id); // Записываем id в localStorage
    }
  }, [id]);

  const [userProfile, setUserProfile] = useState(null);
  const [showPublicPhotos, setShowPublicPhotos] = useState("public");
  const [selectedMedia, setSelectedMedia] = useState(null);
  const [showStories, setShowStories] = useState(false); // New state to handle showing/hiding stories

  const u_id = localStorage.getItem('id_models');

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const response = await fetch('https://golive.mobi/api/user/public-view2/', {
          headers: {
            Authorization: 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify({
            user2_id: id,
          }),
        });

        if (!response.ok) {
          throw new Error('Error fetching user profile');
        }

        const data = await response.json();
        setUserProfile(data);

        if (userProfile && userProfile.name && userProfile.age) {
          document.title = `${userProfile.name}, ${userProfile.age}`;
        }
      } catch (error) {
        console.error('Error fetching user profile:', error);
      }
    };

    fetchUserProfile();
  }, [userProfile]);



  const togglePhotosView1 = () => {
    setShowPublicPhotos("public");
    setShowStories(false); // Hide stories when switching to public photos
  };

  const togglePhotosView2 = () => {
    setShowPublicPhotos("private");
    setShowStories(false); // Hide stories when switching to private photos
  };

  const togglePhotosView3 = () => {
    setShowPublicPhotos("stories");
    setShowStories(false); // Hide stories when switching to private photos
  };

  const toggleShowStories = () => {
    setShowStories(!showStories); // Toggle the state for showing/hiding stories
    setSelectedMedia(null); // Close the selected media popup if it's open
  };

  const handleMediaClick = (mediaURL) => {
    window.open(mediaURL, '_blank');
  };

  if (!userProfile) {
    return <div>Loading...</div>;
  }

  const {
    avatar,
    name,
    flag,
    countryCode,
    age,
    timeRegister,
    followers_count,
    following_count,
    public: publicPhotos,
    private: privatePhotos,
    bio,
    status,
    story,
    bot,
    cover
  } = userProfile;

  const formattedTimeRegister = new Date(Number(timeRegister)).toLocaleString();

  const publicPhotoCount = userProfile?.public?.length || 0;
  const privatePhotoCount = userProfile?.private?.length || 0;
  const storiesCount = userProfile?.story?.length || 0;

  return (
    
    <div className="profile-container">
      
      <div className="profile-header">

      <div>

      {avatar === "https://golive.mobi" ? 
      <img className="avatar" src={no_avatar} alt={'No_avatar'} />
      : 
      <img className="avatar" src={avatar} alt={'avatar'} />}

{cover === "" ?

null


: 
<span>
  <p style={{  marginLeft: '37%', position: 'absolute', marginTop: '-10%', fontWeight: '600'}}>Cover profile:</p>
  <img style={{ marginLeft: '35%', position: 'absolute', width: '20%' }} className="avatar" src={cover} alt={'avatar'} />

</span>}

</div>

      

<div style={{ display: 'flex'}}>
        <h1 style={{ marginTop: '1%', marginBottom: '1%'}}>{name} </h1>

        {bot === 1 && ( <div
            className="bot-block"
            style={{
              border: '2px solid #0045ff',
              padding: '5px 10px',
              borderRadius: '15px',
              marginLeft: '10px',
              width: '3.5%',
              height: '5%',
              fontWeight: 400,
              marginTop: "1.5%"
            }}
          >
            Bot 🤖
          </div>)}
          </div>
        <div>
        <img className="flag" src={flag} alt={countryCode} />
        <span style={{ marginTop: '0.5%', color: '#707070'}}> {countryCode} · Age: {age}</span>
        </div>
        <span style={{ marginTop: '0.5%', color: '#707070'}}>Joined: {formattedTimeRegister}</span>
        
        <span style={{ marginTop: '0.5%', color: '#707070'}}>Status: <span style={{color: 'black'}}>{status}</span></span>
       
        <div className="followers"  style={{ marginTop: '0.5%'}}>
          <span><span style={{ color: '#707070' }}>Followers:</span> {followers_count}</span>
          <span style={{ marginLeft: '1%'}}><span style={{ color: '#707070' }}>Following:</span> {following_count}</span>
        </div>
        <div>
        <button  onClick={togglePhotosView1} className="remove-button" style={{ backgroundColor: 'rgb(255 255 255)', marginTop: '1%', marginLeft: '0%', border: '2px solid rgb(109, 51, 255)', color: 'black'}}>
        {`Public Photo (${publicPhotoCount})`}
        </button>
        
        <button  onClick={togglePhotosView2}   className="remove-button" style={{ backgroundColor: 'rgb(255 255 255)', marginTop: '1%', marginLeft: '0%', border: '2px solid rgb(109, 51, 255)', color: 'black'}}>
        {`Private Photo (${privatePhotoCount})`}
        </button>

        <button onClick={togglePhotosView3}  className="remove-button" style={{ backgroundColor: 'rgb(255 255 255)', marginTop: '1%', marginLeft: '0%', border: '2px solid rgb(109, 51, 255)', color: 'black'}}>
        {`Stories (${storiesCount})`}
        </button>
        </div>
      </div>




   


<div className="photo-gallery">
        {showPublicPhotos === "public" ? (
          <div className="public-photos">
            <h2>Public Photos</h2>
            <div className="photo-grid">
              {publicPhotos.map((media, index) => {
                if (media.file_type === 'image') {
                  return (
                    <img
                      key={index}
                      className="photo-item"
                      src={media.file}
                      alt={`Public Photo ${index}`}
                      onClick={() => handleMediaClick(media.file)}
                    />
                  );
                } else if (media.file_type === 'video') {
                  return (
                    <video
                      key={index}
                      className="photo-item"
                      src={media.file}
                      alt={`Public Video ${index}`}
                      onClick={() => handleMediaClick(media.file)}
                    />
                  );
                } else {
                  return null;
                }
              })}
            </div>
          </div>
        ) : (
          null
        )}
      </div>


      <div className="photo-gallery">
        {showPublicPhotos === "private" ? (
          <div className="public-photos">
            <h2>Private Photos</h2>
            <div className="photo-grid">
              {privatePhotos.map((media, index) => {
                if (media.file_type === 'image') {
                  return (
                    <img
                      key={index}
                      className="photo-item"
                      src={media.file}
                      alt={`Private Photo ${index}`}
                      onClick={() => handleMediaClick(media.file)}
                    />
                  );
                } else if (media.file_type === 'video') {
                  return (
                    <video
                      key={index}
                      className="photo-item"
                      src={media.file}
                      alt={`Private Video ${index}`}
                      onClick={() => handleMediaClick(media.file)}
                    />
                  );
                } else {
                  return null;
                }
              })}
            </div>
          </div>
        ) : (
          null
        )}
      </div>

      <div className="photo-gallery">
      {showPublicPhotos === "stories" ? (
      <div className="public-photos">
        <h2>Stories</h2>
        <div className="photo-grid">
          {story.map((media, index) => {
            if (media.file_type === 'image') {
              return (
                <img
                  key={index}
                  className="photo-item"
                  src={media.file}
                  alt={`Stories ${index}`}
                  onClick={() => handleMediaClick(media.file)}
                />
              );
            } else if (media.file_type === 'video') {
              return (
                <video
                  key={index}
                  className="photo-item"
                  src={media.file}
                  alt={`Stories ${index}`}
                  onClick={() => handleMediaClick(media.file)}
                />
              );
            } else {
              return null;
            }
          })}
        </div>
      </div>
    ) : null}
      </div>





      {selectedMedia && (
        <div className="popup">
          {selectedMedia.includes('.mp4') ? (
            <video controls>
              <source src={selectedMedia} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          ) : (
            <img src={selectedMedia} alt="Selected Media" />
          )}
          <button onClick={() => setSelectedMedia(null)}>Close</button>
        </div>
      )}
    </div>
  );
};

export default UserProfile;
