import React, { useState } from 'react';

import TableGeneral from './TableGeneral_users';
import './style.css';

const ProfileInfo = () => {
  const [showProfileInfo, setShowProfileInfo] = useState(true);
  const [showUserActivity, setShowUserActivity] = useState(false);

  const toggleProfileInfo = () => {
    setShowProfileInfo(!showProfileInfo);
    setShowUserActivity(false);
  };

  const toggleUserActivity = () => {
    setShowUserActivity(!showUserActivity);
    setShowProfileInfo(false);
  };

  return (
    <div>
    <br />
    <div style={{ marginLeft: '2.5%', background: '#fff', width: '95%', borderRadius: '15px', boxShadow: 'rgba(112, 144, 176, 0.2) 2px 2px 2px 2px', height: '58px'}}>
    <span><br /></span>
<h2 style={{fontFamily: 'inter', marginLeft: '1%', marginTop: '-0.5%'}}>All users</h2>


<span><br /><br /></span>
      </div>
      <hr style={{ border: "1px solid rgba(0, 0, 0, 0.05)", marginTop: '2%', marginBottom: '2%' }} />

          <TableGeneral />
        
    
    </div>
  );
};

export default ProfileInfo;
