import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const ProfileInfo = () => {
  const history = useHistory();

  useEffect(() => {
    // Remove the 'design' value from localStorage
    localStorage.removeItem('design');
    // Redirect to the '/models' page
    history.push('/models');
  }, []);

  return (
    <div>
      {/* You can add some content here if needed */}
    </div>
  );
};

export default ProfileInfo;
