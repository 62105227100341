import React, { useState, useEffect, useCallback } from 'react';
import './style.css';
import { useHistory } from 'react-router-dom';
import check_active from './check_active.svg';
import check_disable from './check_disable.svg';
import edit_button from '../assets/edit_button.svg';
import accept_button from '../assets/accept.svg';
import decline_button from '../assets/decline.svg';

const TableGeneral = () => {
  const history = useHistory();

  const [showForm, setShowForm] = useState(false);
  const [searchModels, setSearchModels] = useState('');
  const [searchStatus, setSearchStatus] = useState('');
  const [data, setData] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [editedRowId, setEditedRowId] = useState(null);
  const [editedBillingNote, setEditedBillingNote] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [searchedData, setSearchedData] = useState([]);
  const [modelname, setModelname] = useState('');
  const [statusInputClicked, setStatusInputClicked] = useState(false);
  const [searchPaymentSystem, setSearchPaymentSystem] = useState('');
  const [paymentSystemInputClicked, setPaymentSystemInputClicked] = useState(false);

  const [numRowsToShow, setNumRowsToShow] = useState(data.length);

  const handleNumRowsChange = (e) => {
    const value = parseInt(e.target.value);
    setNumRowsToShow(value > 0 ? value : data.length);
  };


  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    // Filter the data array based on the value in localStorage
    const filteredItems = data.filter(item => item.name === modelname);
    setFilteredData(filteredItems);
  }, [modelname]);


  

  const [modelsInputClicked, setModelsInputClicked] = useState(false);

  const handlePaymentSystemInputChange = (e) => {
    setSearchPaymentSystem(e.target.value);
    setPaymentSystemInputClicked(true);
  };

  useEffect(() => {
    if (modelsInputClicked) {
      handleClick();
    }
  }, [searchModels, modelsInputClicked]);

  useEffect(() => {
    if (statusInputClicked) {
      handleClick();
    }
  }, [searchStatus, statusInputClicked]);

  useEffect(() => {
    if (paymentSystemInputClicked) {
      handleClick();
    }
  }, [searchPaymentSystem, paymentSystemInputClicked]);

  // ... rest of the code ...

  const handleModelsInputChange = (e) => {
    setSearchModels(e.target.value);
    setModelsInputClicked(true);
  };

  const fetchData = async () => {
    try {
      const response = await fetch('https://golive.mobi/api/user/m-billing/listing/', {
        method: 'POST',
        headers: {
          Authorization: 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
        },
        body: JSON.stringify({}),
      });
      const apiData = await response.json();
      const formattedData = apiData.map(item => ({
        id: item.id,
        name: item.name,
        payment_system: item.payment_system,
        payment_detail: item.payment_detail,
        balance_good: item.balance_good,
        balance_waiting: item.balance_waiting,
        billing_status: item.billing_status,
        billing_note: item.billing_note,
      }));
      console.log('Formatted Data:', formattedData);

    setData(formattedData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const toggleSelectAll = () => {
    setSelectAll(!selectAll);
    const updatedData = data.map(item => ({ ...item, selected: !selectAll }));
    setData(updatedData);
  };

  const toggleSelectItem = id => {
    const updatedData = data.map(item => {
      if (item.id === id) {
        return { ...item, selected: !item.selected };
      }
      return item;
    });
    setData(updatedData);
  };

  const handleEditButtonClick = (id, billingNote, billingStatus) => {
    setEditedRowId(id);
    setEditedBillingNote(billingNote);
    setSelectedStatus(billingStatus);
    
    // Save the selected ID to localStorage
    localStorage.setItem('billingcheck', id);
    
    // Redirect to the "/billingcheck" page
    history.push('/billingcheck');
  };

  const handleCancelEdit = () => {
    setEditedRowId(null);
    setEditedBillingNote('');
  };

  const handleSaveEdit = async () => {
    try {
      let url = '';
      if (selectedStatus === 'requesting') {
        url = 'https://golive.mobi/api/user/m-billing/request/';
      } else if (selectedStatus === 'done') {
        url = 'https://golive.mobi/api/user/m-billing/done/';
      }

      if (url !== '') {
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            Authorization: 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            id: editedRowId,
          }),
        });
        if (response.ok) {
          // Note saved successfully
          handleSaveEdit2();
        } else {
          console.error('Failed to save note:', response.statusText);
        }
      } else {
        // If "Select status" is selected, simply cancel edit
        handleCancelEdit();
      }

      // Reload the page
      handleSaveEdit2();
      window.location.reload();
    } catch (error) {
      console.error('Error saving note:', error);
    }
  };

  const handleSaveEdit2 = async () => {
    try {
      const response = await fetch('https://golive.mobi/api/user/m-billing/note/', {
        method: 'POST',
        headers: {
          Authorization: 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id: editedRowId,
          billing_note: editedBillingNote,
        }),
      });
      if (response.ok) {
        // Note saved successfully, reload the page
        window.location.reload();
      } else {
        console.error('Failed to save note:', response.statusText);
      }
    } catch (error) {
      console.error('Error saving note:', error);
    }
  };

  const handleModelClick = useCallback(
    (id) => {
      localStorage.setItem('id_models', id); // Сохранение ID в localStorage
      history.push('/profile_model'); // Переход на страницу "/profile_model"
    },
    [history]
  );
  

  const handleClick = () => {
    console.log('Clicked "View" button');
    if (searchModels.trim() === '' && searchStatus.trim() === '' && searchPaymentSystem.trim() === '') {
      // If all inputs are empty, display all data
      setSearchedData(data);
    } else {
      // If there's input in any of the fields, filter based on the entered values
      const filteredData = data.filter(item => {
        const modelNameIncludes = item.name.toLowerCase().includes(searchModels.toLowerCase());
        const idIncludes = String(item.id).toLowerCase().includes(searchModels.toLowerCase());
        const statusIncludes = item.billing_status.toLowerCase().includes(searchStatus.toLowerCase());
        const paymentSystemIncludes = item.payment_system.toLowerCase().includes(searchPaymentSystem.toLowerCase());
        return (modelNameIncludes || idIncludes) && statusIncludes && paymentSystemIncludes;
      });
      setSearchedData(filteredData);
    }
    setIsFiltered(true);
  };
  
  

  const handleReloadClick = () => {
    console.log('Clicked "Reload" button');
    handleClick(); // Call the handleClick function to get the latest filtered data
  };





  const handleStatusInputChange = (e) => {
    setSearchStatus(e.target.value);
    setStatusInputClicked(true);
  };
  

 

  return (
    <div>



        <div>
    
    <div style={{ marginLeft: '2.5%', background: '#fff', width: '95%', borderRadius: '15px', boxShadow: 'rgba(112, 144, 176, 0.2) 2px 2px 2px 2px', height: '125px', marginTop: '1.5%'}}>
    <span><br /></span>
<h2 style={{fontFamily: 'inter', marginLeft: '1%', marginTop: '-0.5%'}}>Billing</h2>



<div style={{ marginTop: '-5%', display: 'block' }}>
      <input  style={{ marginLeft: '1%', marginTop: '3.5% !important' }}
          type="text"
          className="billing_search_field"
          placeholder="Models / ID"
          value={searchModels}
          onChange={handleModelsInputChange}
        />



<select
          className="billing_search_field"
          value={searchStatus}
          placeholder='Status'
          onChange={handleStatusInputChange}
        >
          <option value="">All status</option>
          <option value="waiting">Waiting</option>
          <option value="paid">Paid</option>
        </select>

        <input
  type="text"
  className="billing_search_field"
  placeholder="Payment System"
  value={searchPaymentSystem}
  onChange={handlePaymentSystemInputChange}
/>



       {/* <input
          type="text"
          className="billing_search_field"
          placeholder="Status"
          value={searchStatus}
          onChange={e => setSearchStatus(e.target.value)}
        />
        <button className="create-button" onClick={handleClick}>
          View
        </button>

        <button className="create-button" onClick={handleReloadClick}>
          Reload
        </button>*/}
  </div>


</div>




<span><br /><br /></span>
      </div>
      <hr style={{ border: "1px solid rgba(0, 0, 0, 0.05)", marginTop: '-1%', marginBottom: '2%' }} />




      

      <div  style={{ width: '95%',
      marginLeft: 'auto',
      marginRight: 'auto',
      background: 'white',
      borderRadius: '15px',
      boxShadow: 'rgba(112, 144, 176, 0.2) 2px 2px 2px 2px',
      }} >
        

        <table className="table" >
          <thead style={{ borderRight: '1px solid rgba(0, 0, 0, 0.07)' }}>
            <tr>
              <th style={{  borderRight: '1px solid rgba(0, 0, 0, 0.07)', }}>
                <input
                  className="checkbox"
                  type="checkbox"
                  checked={selectAll}
                  onChange={toggleSelectAll}
                  style={{
                    alignItems: 'center',
                    display: 'flex',
                    borderColor: '#CBBEEA',
                  }}
                />
              </th>
              <th
                style={{
                  color: 'black',
                  fontFamily: 'Noto Sans Mono',
                  fontWeight: '500',
                  fontSize: '12px',
                  textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)',
                  borderColor: '#CBBEEA', fontWeight: 'bold',
                }}
              >
                ID
              </th>
              <th
                style={{
                  color: 'black',
                  fontFamily: 'Noto Sans Mono',
                  fontWeight: '500',
                  fontSize: '12px',
                  textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)',
                  borderColor: '#CBBEEA', fontWeight: 'bold',
                }}
              >
                Models
              </th>
              <th
                style={{
                  color: 'black',
                  fontFamily: 'Noto Sans Mono',
                  fontWeight: '500',
                  fontSize: '12px',
                  textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)', fontWeight: 'bold',
                }}
              >
                Payment system
              </th>
              <th
                style={{
                  color: 'black',
                  fontFamily: 'Noto Sans Mono',
                  fontWeight: '500',
                  fontSize: '12px',
                  textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)', fontWeight: 'bold',
                }}
              >
                Payment account
              </th>
              <th
                style={{
                  color: 'black',
                  fontFamily: 'Noto Sans Mono',
                  fontWeight: '500',
                  fontSize: '12px',
                  textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)', fontWeight: 'bold',
                  fontWeight: 'bold'
                }}
              >
                Payout
              </th>
              <th
                style={{
                  color: 'black',
                  fontFamily: 'Noto Sans Mono',
                  fontWeight: '500',
                  fontSize: '12px',
                  textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)', fontWeight: 'bold',
                }}
              >
                Unapproved balance
              </th>
              <th
                style={{
                  color: 'black',
                  fontFamily: 'Noto Sans Mono',
                  fontWeight: '500',
                  fontSize: '12px',
                  textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)', fontWeight: 'bold',
                }}
              >
                Status
              </th>
              <th
                style={{
                  color: 'black',
                  fontFamily: 'Noto Sans Mono',
                  fontWeight: '500',
                  fontSize: '12px',
                  textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)', fontWeight: 'bold',
                }}
              >
                Note
              </th>
              <th
                style={{
                  color: 'black',
                  fontFamily: 'Noto Sans Mono',
                  fontWeight: '500',
                  fontSize: '12px',
                  textAlign: 'left',  
                }}
              >
                Actions
              </th>
            </tr>
            
          </thead>
          
          <tbody>
            
          {(isFiltered ? searchedData : data).map((item, index) => (
              <tr
                key={item.id}
                className={`${index % 2 === 0 ? 'even-row' : 'odd-row'} ${
                  item.selected ? 'selected-row' : 'non-selected-row'
                }`}
              >
                <td style={{  borderRight: '1px solid rgba(0, 0, 0, 0.07)', }}>
                  <input
                    type="checkbox"
                    checked={item.selected}
                    onChange={() => toggleSelectItem(item.id)}
                  />
                </td>
                <td
                  style={{
                    color: item.selected ? '#6D33FF' : '#606C80',
                    fontFamily: 'Noto Sans Mono',
                    fontWeight: '400',
                    fontSize: '12px',
                    textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)',
                  }}
                >
                  <a
                    style={{
                      color: item.selected ? '#6D33FF' : '#606C80',
                      fontFamily: 'Noto Sans Mono',
                      fontWeight: '400',
                      fontSize: '12px',
                      textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)',
                    }}
                    href={`profile_model/${item.id}`}
                   // onClick={() => handleModelClick(item.id)}
                  >
                    {item.id}
                  </a>
                </td>
                <td
                  style={{
                    color: item.selected ? '#6D33FF' : 'black',
                    fontFamily: 'Noto Sans Mono',
                    fontWeight: '400',
                    fontSize: '12px',
                    textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)',
                  }}
                >
                  <a
                    href={`profile_model/${item.id}`}
                    style={{
                      color: item.selected ? '#6D33FF' : 'black',
                      fontFamily: 'Noto Sans Mono',
                      fontWeight: '400',
                      fontSize: '12px',
                      textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)',
                    }}
                    >          
                    {item.name}
                  </a>
                </td>

                <td
                  style={{
                    color: item.selected ? '#6D33FF' : 'black',
                    fontFamily: 'Noto Sans Mono',
                    fontWeight: '400',
                    fontSize: '12px',
                    textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)',
                  }}
                >
                  
                    {item.payment_system}
                  
                </td>

                <td
                  style={{
                    color: item.selected ? '#6D33FF' : 'black',
                    fontFamily: 'Noto Sans Mono',
                    fontWeight: '400',
                    fontSize: '12px',
                    textAlign: 'left',  
                  }}
                >
                  
                    {item.payment_detail}
                  
                </td>

                <td
  style={{
    color: item.selected ? '#6D33FF' : 'black',
    fontFamily: 'Noto Sans Mono',
    fontWeight: '400',
    fontSize: '12px',
    textAlign: 'left',  
    fontWeight: 'bold',
    background: item.balance_good < 30
      ? `rgba(255, 241, 215, 0.5)` 
      : `rgba(216, 255, 212, 0.5)`
  }}
>
  {item.balance_good}$
</td>

                <td
                  style={{
                    color: item.selected ? '#6D33FF' : 'black',
                    fontFamily: 'Noto Sans Mono',
                    fontWeight: '400',
                    fontSize: '12px',
                    textAlign: 'left', 
                  }}
                >
                  
                    {item.balance_waiting}$
                  
                </td>

                <td
                  style={{
                    color: item.selected ? '#6D33FF' : '#606C80',
                    fontFamily: 'Noto Sans Mono',
                    fontWeight: '400',
                    fontSize: '12px',
                    textAlign: 'left',  borderLeft: '1px solid rgba(0, 0, 0, 0.07)', borderRight: '1px solid rgba(0, 0, 0, 0.07)',
                    background:
      item.billing_status === 'waiting'
        ? 'rgba(255, 241, 215, 0.5)'
        : item.billing_status === 'paid'
        ? 'rgba(216, 255, 212, 0.5)'
        : 'none',
                  }}
                >
                  
                    
                    {item.billing_status}
                  
                </td>

                <td
                  style={{
                    color: item.selected ? '#6D33FF' : '#606C80',
                    fontFamily: 'Noto Sans Mono',
                    fontWeight: '400',
                    fontSize: '12px',
                    textAlign: 'left',  borderRight: '1px solid rgba(0, 0, 0, 0.07)',
                  }}
                >
                  {editedRowId === item.id ? (
                    <input
                      type="text"
                      value={editedBillingNote}
                      onChange={e => setEditedBillingNote(e.target.value)}
                      className="note-input"
                    />
                  ) : (
                    item.billing_note
                  )}
                </td>
                <td style={{ textAlign: 'right' }}>
                 
                <img
  onClick={() => handleEditButtonClick(item.id, item.note)}
  src={edit_button}
  alt="Edit"
  style={{ cursor: 'pointer', marginRight: '10%' }}
/>

                  
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TableGeneral;
